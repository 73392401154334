/* import __COLOCATED_TEMPLATE__ from './easter-egg.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

interface EasterEggSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EasterEggComponent extends Component<EasterEggSignature> {
  lottiePlayer = LottiePlayer;

  @service declare segment: Services['segment'];

  @tracked animationReady = false;

  constructor(owner: unknown, args: EasterEggSignature['Args']) {
    super(owner, args);

    this.segment.track('easter_egg_found');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EasterEgg: typeof EasterEggComponent;
  }
}
