/* import __COLOCATED_TEMPLATE__ from './new.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ACTIVITY_TAGS } from 'qonto/constants/categories';

interface BeneficiaryUploadNewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BeneficiaryUploadNewComponent extends Component<BeneficiaryUploadNewSignature> {
  @service declare router: Services['router'];
  @service declare abilities: Services['abilities'];

  abortRoute = 'transfers.new';
  confirmRoute = 'beneficiaries.multi.review';

  fileUploadTask = dropTask(async multiBeneficiary => {
    let mb = await multiBeneficiary.save();

    let beneficiaries = await mb.beneficiaries;

    if (this.abilities.can('assign category')) {
      for (let beneficiary of beneficiaries) {
        beneficiary.activityTag = ACTIVITY_TAGS.OTHER_EXPENSE;
      }
    }

    this.router.transitionTo(this.confirmRoute, mb);
  });

  @action
  handleGoBack() {
    this.router.transitionTo(this.abortRoute);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BeneficiaryUpload::New': typeof BeneficiaryUploadNewComponent;
  }
}
