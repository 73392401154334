/* import __COLOCATED_TEMPLATE__ from './account.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { FlowsChecksAccount } from 'qonto/react/components/flows/checks/account';

interface FlowsCheckAccountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCheckAccountComponent extends Component<FlowsCheckAccountSignature> {
  account = FlowsChecksAccount;

  @service declare segment: Services['segment'];

  @action
  // @ts-expect-error
  setAccount(account) {
    // @ts-expect-error
    let { context } = this.args;
    context.check.bankAccount = account;
    this.segment.track('checks_deposit_account_selected');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Check::Account': typeof FlowsCheckAccountComponent;
  }
}
