/* import __COLOCATED_TEMPLATE__ from './accounting-hub-switcher.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { registerJsURL } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { NavigationDropdownFooter } from 'qonto/react/components/navigation-dropdown/footer';

const TABS = Object.freeze({
  MY_CLIENTS: 'my-clients',
  MY_BUSINESS: 'my-business',
});

interface DualNavAccountingHubSwitcherSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DualNavAccountingHubSwitcherComponent extends Component<DualNavAccountingHubSwitcherSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare userManager: Services['userManager'];

  @tracked selectedTab =
    // @ts-expect-error
    this.organizationManager.membership.role === ROLES.REPORTING
      ? TABS.MY_CLIENTS
      : TABS.MY_BUSINESS;

  TABS = TABS;
  registerJsURL = registerJsURL;
  Footer = NavigationDropdownFooter;

  @action
  // @ts-expect-error
  selectTab(tab) {
    this.selectedTab = tab;
  }

  get isMyClientsSelected() {
    return this.selectedTab === TABS.MY_CLIENTS;
  }

  get isMyBusinessSelected() {
    return !this.isMyClientsSelected;
  }

  get orgListFilterMode() {
    return this.isMyClientsSelected ? 'include' : 'exclude';
  }

  get selectedTabId() {
    return this.isMyClientsSelected ? TABS.MY_CLIENTS : TABS.MY_BUSINESS;
  }

  get userHasOnlyClients() {
    if (this.isMyClientsSelected) return false;

    // @ts-expect-error
    return this.organizationManager.organizations.every(
      // @ts-expect-error
      organization => organization.membershipRole === ROLES.REPORTING
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::AccountingHubSwitcher': typeof DualNavAccountingHubSwitcherComponent;
  }
}
