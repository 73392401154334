/* import __COLOCATED_TEMPLATE__ from './cockpit.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DIRECT_DEBIT_COLLECTION_STATUS } from 'qonto/constants/direct-debit-collections';

interface DirectDebitCollectionsCockpitSignature {
  // The arguments accepted by the component
  Args: {
    isSDDCollectionsEndpointError?: boolean;
    isHoldDataLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLUListElement;
}

export default class DirectDebitCollectionsCockpit extends Component<DirectDebitCollectionsCockpitSignature> {
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  get holdSubtitleText() {
    // @ts-expect-error
    let { onHold } = this.args;

    if (onHold) {
      let fromText = this.intl.t('sdd-collections.cockpit.hold.subtitle.from');

      let mainText = this.intl.t('sdd-collections.cockpit.hold.subtitle.sdd-count-held', {
        count: onHold.count,
      });

      return `${fromText} ${mainText}`;
    }
  }

  get holdSubtitleTooltipText() {
    return this.intl.t('sdd-collections.cockpit.hold.tooltip', {
      // @ts-expect-error
      guardingPeriodPercentage: this.intl.formatNumber(this.args.guardingPercentage, {
        minimumFractionDigits: 0,
        style: 'percent',
      }),
    });
  }

  @action
  onHoldTileClick() {
    this.router.transitionTo('direct-debit-collections.completed', {
      queryParams: {
        statusFilter: [DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD],
      },
    });

    this.segment.track('incoming-direct-debit_hold-cockpit_filtered');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Cockpit': typeof DirectDebitCollectionsCockpit;
  }
}
