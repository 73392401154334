/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

interface EmptyStatesReceivableInvoicesDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesReceivableInvoicesDummyDataComponent extends Component<EmptyStatesReceivableInvoicesDummyDataSignature> {
  sortBy = '-issue_date,-number';
  noop() {}
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::ReceivableInvoices::DummyData': typeof EmptyStatesReceivableInvoicesDummyDataComponent;
  }
}
