/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Button, LottiePlayer } from '@repo/design-system-kit';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import type { DataContext } from 'qonto/routes/flows/setup/transfers/international-out/data-context';

interface Signature {
  Args: FlowStepArgs<
    DataContext & {
      beneficiary: NonNullable<DataContext['beneficiary']>;
      confirmationResult: NonNullable<DataContext['confirmationResult']>;
      fees: NonNullable<DataContext['fees']>;
      quote: NonNullable<DataContext['quote']>;
      targetAccount: NonNullable<DataContext['targetAccount']>;
      transferDetails: NonNullable<DataContext['transferDetails']>;
    }
  >;
}

export default class FlowsTransfersInternationalOutNewSuccessComponent extends Component<Signature> {
  button = Button;
  lottiePlayer = LottiePlayer;

  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  assetPath = '/lotties/transfers/fx-success.json';

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.segment.track(EVENTS.TRANSFER_CREATED);
  }

  get title(): string {
    return this.#isScheduled
      ? this.intl.t('international-out.success.scheduled.title')
      : this.intl.t('international-out.success.title');
  }

  get subtitle(): string {
    return this.#isScheduled
      ? this.intl.t('international-out.success.scheduled.subtitle')
      : this.intl.t('international-out.success.subtitle');
  }

  get hasInvoice(): boolean {
    return Boolean(this.args.context.invoice);
  }

  get #isScheduled(): boolean {
    return Boolean(this.args.context.schedule);
  }

  @action
  viewTransfer(): void {
    this.router.transitionTo('transfers.pending', {
      queryParams: { highlight: this.internationalOutManager.getIdempotencyKey() },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::New::Success': typeof FlowsTransfersInternationalOutNewSuccessComponent;
  }
}
