/* import __COLOCATED_TEMPLATE__ from './date.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

interface ExpressionDateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ExpressionDateComponent extends Component<ExpressionDateSignature> {
  @service declare intl: Services['intl'];

  get selectedInterval() {
    // @ts-expect-error
    return this.intervalOptions.find(({ key }) => key === this.args.expression.values?.[0]);
  }

  get dateFrom() {
    // @ts-expect-error
    let date = this.args.expression.values[0];
    return date && !isNaN(date) ? dayjs(date).format(DATE_PICKER_FIELD_FORMAT) : null;
  }

  get dateTo() {
    // @ts-expect-error
    let date = this.args.expression.values[1];
    return date && !isNaN(date) ? dayjs(date).format(DATE_PICKER_FIELD_FORMAT) : null;
  }

  get intervalOptions() {
    return [
      { key: 'today', label: this.intl.t('transactions.filters.dates.intervals.today') },
      { key: 'yesterday', label: this.intl.t('transactions.filters.dates.intervals.yesterday') },
      {
        key: 'current_week',
        label: this.intl.t('transactions.filters.dates.intervals.current-week'),
      },
      { key: 'last_week', label: this.intl.t('transactions.filters.dates.intervals.past-week') },
      {
        key: 'current_month',
        label: this.intl.t('transactions.filters.dates.intervals.current-month'),
      },
      {
        key: 'last_month',
        label: this.intl.t('transactions.filters.dates.intervals.past-month'),
      },
    ];
  }

  // Min and max values copy the logic from qonto-api
  // https://gitlab.qonto.co/qonto/qonto-api/-/blob/master/app/contexts/transactions/contracts/query_language/filter.rb

  get minDate() {
    return new Date('2015-01-02');
  }

  get maxDate() {
    return dayjs().add(2, 'year').subtract(1, 'day').toDate();
  }

  @action
  // @ts-expect-error
  updateDateFrom(date) {
    // @ts-expect-error
    let { operator, operatorType } = this.args.expression;
    let isSingleDate = ['lt', 'lte', 'gt', 'gte'].includes(operator);

    let start = dayjs(date).startOf('day').valueOf();
    let end = operatorType === 'range' ? null : dayjs(date).endOf('day').valueOf();

    let value;

    if (isSingleDate) {
      if (operator === 'lte') {
        start = dayjs(date).endOf('day').valueOf();
      }

      value = date ? [start] : [];
    } else {
      value = date ? [start, end] : [null, this.dateTo ? dayjs(this.dateTo).valueOf() : undefined];
    }

    // @ts-expect-error
    this.args.onSelect(value);
  }

  @action
  // @ts-expect-error
  updateDateTo(date) {
    // @ts-expect-error
    let { operatorType } = this.args.expression;

    let start = this.dateFrom ? dayjs(this.dateFrom).valueOf() : undefined;
    let end = dayjs(date).endOf('day').valueOf();
    let emptyDateValue = operatorType === 'range' ? [start, null] : [start];

    let value = date ? [start, end] : emptyDateValue;

    // @ts-expect-error
    this.args.onSelect(value);
  }

  @action
  // @ts-expect-error
  updateInterval({ key }) {
    // @ts-expect-error
    this.args.onSelect([key]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Filters::Preset::Expression::Date': typeof ExpressionDateComponent;
  }
}
