export default {
  "wrapper": "at",
  "badge-trial": "aM",
  "header": "aP",
  "list": "al body-2",
  "main": "aX",
  "card": "aC",
  "container": "ak",
  "header-button": "aJ",
  "error-text": "as",
  "mt-16": "aF"
};
