/* import __COLOCATED_TEMPLATE__ from './verify-delivery-address.hbs'; */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FlowsCardsVerifyDeliveryAddressSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsCardsVerifyDeliveryAddress extends Component<FlowsCardsVerifyDeliveryAddressSignature> {
  @service declare segment: Services['segment'];
  @service declare sensitiveActions: Services['sensitiveActions'];

  constructor(owner: unknown, args: FlowsCardsVerifyDeliveryAddressSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.args.context.isChangeAddress = false;
  }

  @action
  // @ts-expect-error
  handleClick(provider) {
    this.segment.track('cards_renewal_address_chosen', {
      address_type: 'default',
    });

    // @ts-expect-error
    this.sensitiveActions.runTask
      .perform(this.confirmAddressTask, provider.renewTask)
      .catch(ignoreCancelation);
  }

  confirmAddressTask = task(async renewTask => {
    // @ts-expect-error
    let { hasSingleCardToRenew, organizationAddress } = this.args.context;

    // default address is company (business) address
    let address = organizationAddress.serialize();
    let shipToBusiness = true;

    // @ts-expect-error
    set(this.args.context, 'address', address);
    // @ts-expect-error
    set(this.args.context, 'shipToBusiness', shipToBusiness);

    // in case there is a single expiring card, we go to the next step
    // @ts-expect-error
    if (hasSingleCardToRenew) return this.args.transitionToNext();

    // errors are handled in the renewTask itself
    let response = await renewTask.perform(address, shipToBusiness);

    // @ts-expect-error
    set(this.args.context, 'renewalCardsCount', response?.renewalCardsCount);
    // @ts-expect-error
    set(this.args.context, 'renewalCard', response?.renewalCard);

    if (response?.renewalCardsCount) {
      // Notifies description to go success step
      // @ts-expect-error
      set(this.args.context, 'isAddressConfirmed', true);
      // @ts-expect-error
      this.args.transitionToNext();
    }
  });

  @action
  changeAddress() {
    // Notifies description to go to custom address step
    // @ts-expect-error
    this.args.context.isChangeAddress = true;
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::VerifyDeliveryAddress': typeof FlowsCardsVerifyDeliveryAddress;
  }
}
