/* import __COLOCATED_TEMPLATE__ from './dismissable-link-card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DismissableLinkCardSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DismissableLinkCard = templateOnlyComponent<DismissableLinkCardSignature>();

export default DismissableLinkCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DismissableLinkCard: typeof DismissableLinkCard;
  }
}
