export default {
  "journey": "jV",
  "content": "jL",
  "distance": "jT",
  "google-link-container": "jz",
  "link-icon": "ja",
  "continue-btn": "jH",
  "origin": "jO",
  "input-width": "jm"
};
