export default {
  "form": "Nq",
  "fieldset": "NZ",
  "form-body": "NR",
  "conditional-select": "NQ",
  "conditional-text": "Ne",
  "group": "NB",
  "add-filter-button": "NE btn btn--tertiary",
  "footer": "Nd",
  "btn-icon": "NI",
  "add-icon": "Nt",
  "cancel-button": "NM btn",
  "form-header": "NP",
  "align-start": "Nl",
  "align-right": "NX",
  "button-wrapper": "NC",
  "wrapper": "Nk",
  "label": "NJ body-2",
  "input-wrapper": "Ns",
  "input": "NF input-field body-2",
  "error": "Ng",
  "error-message": "ND"
};
