export default {
  "mandate-new-no-mandate": "rI",
  "left-side": "rt",
  "left-side__wrapper": "rM",
  "left-side__wrapper-title": "rP",
  "left-side__wrapper-details": "rl",
  "left-side__wrapper-details-item": "rX",
  "right-side": "rC",
  "multi-accounts": "rk",
  "right-side__wrapper": "rJ",
  "right-side__wrapper-name": "rs",
  "right-side__wrapper-address": "rF",
  "right-side__wrapper-iban-dashed": "rg",
  "right-side__wrapper-address-label": "rD",
  "right-side__wrapper-iban-label": "rV",
  "right-side__wrapper-bic-label": "rL",
  "right-side__wrapper-address-dashed": "rT",
  "right-side__wrapper-bic-dashed": "rz",
  "dropdown-icon": "ra",
  "dropdown": "rH",
  "multi-accounts-illustration": "rO"
};
