export default {
  "row": "Tb",
  "icon": "Ty",
  "cell": "TU",
  "empty": "Tj",
  "no-padding": "TW",
  "cell-content": "Tu",
  "right": "zS",
  "cube": "zc",
  "row--sidebar-open": "zq",
  "status": "zZ"
};
