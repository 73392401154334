/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface CollapseOptionOptionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLButtonElement;
}

export default class CollapseOptionOptionComponent extends Component<CollapseOptionOptionSignature> {
  optionId = `${guidFor(this)}-option-id`;

  get stringValue() {
    // @ts-expect-error
    return String(this.args.value);
  }

  get isHidden() {
    // @ts-expect-error
    return this.args.collapsed && !this.isSelected;
  }

  get isSelected() {
    // @ts-expect-error
    return this.args.value === this.args.selectedValue;
  }

  get isNotDisabled() {
    // @ts-expect-error
    return !this.args.disabled;
  }

  get showClearButton() {
    // @ts-expect-error
    return this.args.clearable && this.isSelected && this.isNotDisabled;
  }

  selectOptionTask = dropTask(async () => {
    // @ts-expect-error
    if (this.args.disabled) return;

    // @ts-expect-error
    let { clearable, value } = this.args;

    if (!this.isSelected) {
      // @ts-expect-error
      await this.args.select({
        value,
        clearable,
      });
    } else {
      // @ts-expect-error
      // eslint-disable-next-line ember/no-array-prototype-extensions
      this.args.clear({ value });
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CollapseOptions::Option': typeof CollapseOptionOptionComponent;
  }
}
