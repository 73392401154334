export default {
  "header": "Pe",
  "hidden": "PB",
  "header-inline": "PE",
  "header-inline__search-bar": "Pd",
  "header-bottom": "PI",
  "margin-top": "Pt",
  "header-bottom-loading": "PM",
  "header-bottom-loading-state": "PP",
  "actions__filters": "Pl",
  "actions__filters__toggle-btn": "PX",
  "tabs": "PC",
  "header-inline__filters": "Pk",
  "filter-ml": "PJ",
  "filter-clear": "Ps"
};
