export default {
  "row": "zo body-1",
  "active": "zn",
  "cell": "zi body-2",
  "empty": "zA",
  "no-padding": "zY",
  "cell-content": "zh",
  "item-disabled": "zf",
  "item-amount": "zK body-1",
  "centered-cell": "zG",
  "debitor": "zr",
  "item-counterparty-name": "zb",
  "item-counterparty-reference": "zy",
  "item-counterparty-name-status": "zU",
  "item-counterparty-name-text": "zj",
  "amount-on-hold": "zW",
  "icon-on-hold": "zu"
};
