/* import __COLOCATED_TEMPLATE__ from './missing-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { PAY_LATER_VALIDATION_ERRORS } from 'qonto/constants/financing';

const MISSING_ERRORS_ORDER = [
  PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_NAME,
  PAY_LATER_VALIDATION_ERRORS.MISSING_INVOICE_AMOUNT,
  PAY_LATER_VALIDATION_ERRORS.MISSING_ISSUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_IDENTIFIER,
];

interface FlowsTransfersSepaPayLaterErrorsMissingDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaPayLaterErrorsMissingDetailsComponent extends Component<FlowsTransfersSepaPayLaterErrorsMissingDetailsSignature> {
  @service declare intl: Services['intl'];
  @service declare payByInvoiceUploadManager: Services['payByInvoiceUploadManager'];
  @service declare organizationManager: Services['organizationManager'];

  get errors() {
    // @ts-expect-error
    let { errors } = this.args.context.validationError;
    // @ts-expect-error
    let sortedErrors = [];

    MISSING_ERRORS_ORDER.forEach(error => {
      if (errors.includes(error)) sortedErrors.push(error);
    });

    // @ts-expect-error
    return sortedErrors;
  }

  get detailsErrorMap() {
    return {
      [PAY_LATER_VALIDATION_ERRORS.MISSING_INVOICE_AMOUNT]: this.intl.t(
        'pay-later-flow.errors.missing-details.amount'
      ),
      [PAY_LATER_VALIDATION_ERRORS.MISSING_ISSUE_DATE]: this.intl.t(
        'pay-later-flow.errors.missing-details.issue-date'
      ),
      [PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_IDENTIFIER]: this.intl.t(
        'pay-later-flow.errors.missing-details.supplier-identifier',
        {
          htmlSafe: true,
          legalCountry: this.organizationManager.organization.legalCountry.toLowerCase(),
        }
      ),
      [PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_NAME]: this.intl.t(
        'pay-later-flow.errors.missing-details.supplier-name'
      ),
    };
  }

  @action
  handleRestart() {
    // @ts-expect-error
    this.payByInvoiceUploadManager.resetState();
    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        invoiceId: null,
        reset: true,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::PayLater::Errors::MissingDetails': typeof FlowsTransfersSepaPayLaterErrorsMissingDetailsComponent;
  }
}
