/* import __COLOCATED_TEMPLATE__ from './capital-deposit-item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { gt, reads } from 'macro-decorators';

interface CapitalDepositItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositItem extends Component<CapitalDepositItemSignature> {
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @reads('args.shareholder.capitalAmount') capitalPromised;
  // @ts-expect-error
  @reads('args.shareholder.bankAccount.balance') accountBalance;

  // @ts-expect-error
  @gt('accountBalance', 0) isCapitalUnderDepositProcess;

  get isCapitalDeposited() {
    return this.capitalPromised === this.accountBalance;
  }

  get pickTooltipInfo() {
    switch (true) {
      case this.accountBalance > this.capitalPromised:
        return this.intl.t('on_boarding.tooltip.exceeding-amount', {
          // @ts-expect-error
          deposit_account_exceeding_amount: this.intl.formatMoney(this.accountBalance),
          // @ts-expect-error
          amount_back: this.intl.formatMoney(this.accountBalance - this.capitalPromised),
        });
      case this.isCapitalDeposited:
        return this.intl.t('on_boarding.tooltip.complete', {
          // @ts-expect-error
          amount: this.intl.formatMoney(this.accountBalance),
        });
      case this.isCapitalUnderDepositProcess:
        return this.intl.t('on_boarding.tooltip.mismatch', {
          // @ts-expect-error
          amount_remained: this.intl.formatMoney(this.capitalPromised - this.accountBalance),
        });
      default:
        return this.intl.t('on_boarding.tooltip.start', {
          // @ts-expect-error
          amount: this.intl.formatMoney(this.capitalPromised),
        });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CapitalDepositItem: typeof CapitalDepositItem;
  }
}
