/* import __COLOCATED_TEMPLATE__ from './landing.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

// @ts-expect-error
import { REDIRECTION_OPTIONS } from 'qonto/routes/flows/setup/accounting-discovery';

export const PARTNER_REPOSITORY_URL = 'https://qonto.com/fr/accountants-partners';

interface FlowsAccountingDiscoveryLandingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsAccountingDiscoveryLanding extends Component<FlowsAccountingDiscoveryLandingSignature> {
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: FlowsAccountingDiscoveryLandingSignature['Args']) {
    super(owner, args);
    this.segment.track('accounting-discovery-flow_landing-page_displayed');
  }

  @action
  handleInvitationClick() {
    // @ts-expect-error
    this.args.context.selectedOption = REDIRECTION_OPTIONS.INVITATION;
    // @ts-expect-error
    this.args.pushForwardFlow('member-invite', 'personal-info');
  }

  @action
  handleIntegrationsClick() {
    // @ts-expect-error
    this.args.context.selectedOption = REDIRECTION_OPTIONS.INTEGRATIONS;
    // @ts-expect-error
    this.args.completeFlow();
  }

  @action
  handlePartnerRepositoryClick() {
    window.open(PARTNER_REPOSITORY_URL, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountingDiscovery::Landing': typeof FlowsAccountingDiscoveryLanding;
  }
}
