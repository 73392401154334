export default {
  "header": "Nh",
  "header-top": "Nf",
  "header-inner": "NK",
  "disclaimer": "NG mb-16",
  "header-main-wrapper": "Nr",
  "header-main": "Nb",
  "logo": "Ny mr-16",
  "header-right": "NU",
  "partner-description": "Nj body-2"
};
