/* import __COLOCATED_TEMPLATE__ from './color-picker-item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ColorPickerItemSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ColorPickerItem = templateOnlyComponent<ColorPickerItemSignature>();

export default ColorPickerItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GenerateLogo::ColorPickerItem': typeof ColorPickerItem;
  }
}
