/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

interface EmptyStatesAccountsDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesAccountsDummyDataComponent extends Component<EmptyStatesAccountsDummyDataSignature> {
  noop() {}
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::Accounts::DummyData': typeof EmptyStatesAccountsDummyDataComponent;
  }
}
