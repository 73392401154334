/* import __COLOCATED_TEMPLATE__ from './french-company-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

interface FrenchCompanyDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class FrenchCompanyDetailsComponent extends Component<FrenchCompanyDetailsSignature> {
  @service declare intl: Services['intl'];

  isLegalCapitalShareVisible = this.settings.legalCapitalShare;
  isCommercialRegisterNumberVisible = this.settings.commercialRegisterNumber;

  get settings() {
    // @ts-expect-error
    return this.args.context.settings;
  }

  @cached
  get paymentConditions() {
    return [
      {
        value: 'on_receipts',
        label: this.intl.t('receivable-invoices.invoice-settings.payment-condition.on_receipts'),
      },
      {
        value: 'compensated_for_sales',
        label: this.intl.t(
          'receivable-invoices.invoice-settings.payment-condition.compensated_for_sales'
        ),
      },
      {
        value: 'exempt',
        label: this.intl.t('receivable-invoices.invoice-settings.payment-condition.exempt'),
      },
    ];
  }

  get selectedPaymentCondition() {
    let option = this.settings?.vatPaymentCondition;

    if (option) {
      return this.paymentConditions.find(type => type.value === option);
    }
  }

  @action
  // @ts-expect-error
  updatePaymentCondition(condition) {
    this.settings.vatPaymentCondition = condition.value;
  }

  get vatNumberErrorMessage() {
    let errors = this.settings.errors.get('vatNumber');
    if (errors.length === 0) return null;

    // @ts-expect-error
    if (errors.some(error => error.message === 'invalid')) {
      return this.intl.t(
        'receivable-invoices.invoice-setting.settings-modal.general.company-details.vat-error-message'
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::CompanyDetails::FrenchCompanyDetails': typeof FrenchCompanyDetailsComponent;
  }
}
