/* import __COLOCATED_TEMPLATE__ from './add-tax-beneficiary.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { getNRCFullName, getNRCIban } from 'qonto/utils/nrc';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
} from 'qonto/utils/transfers';

interface FlowsTransfersSepaNewAddTaxAgencySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaNewAddTaxAgencyComponent extends Component<FlowsTransfersSepaNewAddTaxAgencySignature> {
  @service declare abilities: Services['abilities'];
  @service declare beneficiariesManager: Services['beneficiariesManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  @tracked taxBeneficiary = null;

  constructor(owner: unknown, args: FlowsTransfersSepaNewAddTaxAgencySignature['Args']) {
    super(owner, args);
    this._prefillTaxBeneficiary();
  }

  @action
  // @ts-expect-error
  transitionToNext(taxBeneficiary) {
    this._setTransferBeneficiary(taxBeneficiary);
    this.toastFlashMessages.toastSuccess(this.intl.t('transfers.beneficiaries.add-modal.success'));
    // @ts-expect-error
    this.args.transitionToNext();
  }

  // @ts-expect-error
  _createBeneficiaryRecord(prefilledOptions) {
    return this.beneficiariesManager.createSepaBeneficiary(
      this.organizationManager.organization,
      prefilledOptions
    );
  }

  _prefillTaxBeneficiary() {
    // @ts-expect-error
    let { taxBeneficiaryName } = this.args.context.nrcOptions;

    let iban = getNRCIban(taxBeneficiaryName);
    let name = getNRCFullName(taxBeneficiaryName);

    this.taxBeneficiary = this._createBeneficiaryRecord({
      ...(iban && { iban }),
      ...(name && { name }),
      activityTag: 'tax',
    });
  }

  // @ts-expect-error
  _setTransferBeneficiary(taxBeneficiary) {
    // @ts-expect-error
    let { sepaTransfer } = this.args.context;

    sepaTransfer.set('beneficiary', taxBeneficiary);

    copyBeneficiaryIntoTransfer(sepaTransfer, taxBeneficiary, { forceCopy: true });

    copyBeneficiaryLabelsIntoTransfer(sepaTransfer, taxBeneficiary);

    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(sepaTransfer, taxBeneficiary);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::New::AddTaxBeneficiary': typeof FlowsTransfersSepaNewAddTaxAgencyComponent;
  }
}
