/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

interface CardSettingsOptionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardSettingsOptionsComponent extends Component<CardSettingsOptionsSignature> {
  // @ts-expect-error
  changeset = new Changeset(this.args.card);

  get isCtaDisabled() {
    return this.submitTask.isRunning;
  }

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    // @ts-expect-error
    if (!this.args.disablePersistingState && !this.isCtaDisabled) {
      this.changeset.execute();
    }
  }

  @action
  // @ts-expect-error
  toggleCardProperty(property) {
    let { changeset } = this;
    changeset.set(property, !changeset.get(property));
    this.persistState();
  }

  submitTask = dropTask(async () => {
    this.changeset.execute();
    // @ts-expect-error
    await this.args.onSubmit();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::Options': typeof CardSettingsOptionsComponent;
  }
}
