/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface CardTabsHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardTabsHeader extends Component<CardTabsHeaderSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get isApprover() {
    return this.abilities.can('review card request');
  }

  get isRequestsActive() {
    // @ts-expect-error
    return this.args.active === 'requests';
  }

  get isMyCardsActive() {
    // @ts-expect-error
    return this.args.active === 'my-cards';
  }

  get isTeamActive() {
    // @ts-expect-error
    return this.args.active === 'team';
  }

  get isArchivedActive() {
    // @ts-expect-error
    return this.args.active === 'archived';
  }

  get requestsTab() {
    if (this.isApprover) {
      let title = this.intl.t('cards.tabs.request.approver.title');
      // @ts-expect-error
      let label = this.args.hasNotifications
        ? this.intl.t('cards.tabs.request.approver.aria-label.pending')
        : this.intl.t('cards.tabs.request.approver.aria-label.no-pending');
      return { title, label, icon: 'icon_nav_tasks' };
    }

    let title = this.intl.t('cards.tabs.request.requester.title');
    let label = this.intl.t('cards.tabs.request.requester.aria-label');
    return { title, label, icon: 'icon_nav_requests' };
  }

  @action
  // @ts-expect-error
  trackTab(switched_to) {
    this.segment.track('cards_tabs-navigation_switched', { switched_to });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Tabs::Header': typeof CardTabsHeader;
  }
}
