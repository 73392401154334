export default {
  "envelope": "Vz",
  "email": "Va",
  "logo": "VH",
  "subject": "VO",
  "message": "Vm",
  "payment-details": "Vp",
  "contact": "Vx",
  "detail": "Vw",
  "cta": "VN"
};
