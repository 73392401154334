/* import __COLOCATED_TEMPLATE__ from './members.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { compare } from '@ember/utils';
import Component from '@glimmer/component';

import { Selector } from '@repo/design-system-kit';

// @ts-expect-error
import { NUM_OF_NAMES_TO_DISPLAY } from 'qonto/constants/budget';
import { ROLES } from 'qonto/constants/membership';

interface FlowsBudgetsCreateBudgetTeamSelectionMembersSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsBudgetsCreateBudgetTeamSelectionMembers extends Component<FlowsBudgetsCreateBudgetTeamSelectionMembersSignature> {
  selector = Selector;

  @service declare intl: Services['intl'];

  get teamMembersList() {
    // @ts-expect-error
    let { team, memberships } = this.args.teamInfos;

    let membersCount = this.intl.t('team-budgets.creation.team-selection.team.members', {
      count: team.activeMembershipsCount ?? 0,
    });

    if (!team.activeMembershipsCount) {
      return membersCount;
    }

    let teamManagersCount = team.hasMany('teamManagers').ids().length;
    let managers = memberships
      // @ts-expect-error
      .filter(({ role }) => role === ROLES.MANAGER)
      // @ts-expect-error
      .sort((a, b) => compare(a.lastName, b.lastName));

    let managersList = this.intl.t('team-budgets.creation.team-selection.team.managers', {
      count: teamManagersCount,
      managerNameOne: managers[0]?.fullName,
      managerNameTwo: managers[1]?.fullName,
      remaining: teamManagersCount - NUM_OF_NAMES_TO_DISPLAY,
    });

    return htmlSafe(`${membersCount} &middot; ${managersList}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::CreateBudget::TeamSelection::Members': typeof FlowsBudgetsCreateBudgetTeamSelectionMembers;
  }
}
