/* import __COLOCATED_TEMPLATE__ from './bic-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { formatBIC, handleFormatBIC } from 'qonto/utils/format-input';

interface BICFieldSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BICField extends Component<BICFieldSignature> {
  get bic() {
    // @ts-expect-error
    let { value } = this.args;
    return formatBIC(value);
  }

  @action
  // @ts-expect-error
  handleOnInput(event) {
    let { target } = event;
    handleFormatBIC(target);
    // @ts-expect-error
    this.args.update(target.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::BicField': typeof BICField;
  }
}
