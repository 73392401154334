export default {
  "row": "lr",
  "active": "lb",
  "status": "ly",
  "align-right": "lU",
  "quick-actions": "lj",
  "cell-approve": "lW",
  "account-select": "lu",
  "hidden": "XS",
  "no-padding": "Xc",
  "animated": "Xq",
  "fadein-item": "XZ",
  "cell": "XR body-2",
  "cell-content": "XQ",
  "amount": "Xe body-1",
  "subtitle": "XB caption"
};
