/* import __COLOCATED_TEMPLATE__ from './success-reset-pin.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

interface FlowsCardsSuccessResetPinSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsSuccessResetPin extends Component<FlowsCardsSuccessResetPinSignature> {
  lottiePlayer = LottiePlayer;

  @tracked animationReady = false;

  @action displayLottie() {
    // see https://github.com/emberjs/ember.js/issues/17202#issuecomment-437927226
    this.animationReady = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::SuccessResetPin': typeof FlowsCardsSuccessResetPin;
  }
}
