/* import __COLOCATED_TEMPLATE__ from './signature.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Signature } from 'qonto/react/financing/components/flows/pay-later/application/signature/signature';

interface FlowsPayLaterApplicationSignatureSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsPayLaterApplicationSignature extends Component<FlowsPayLaterApplicationSignatureSignature> {
  signature = Signature;

  @service declare zendeskWidget: Services['zendeskWidget'];

  constructor(owner: unknown, args: FlowsPayLaterApplicationSignatureSignature['Args']) {
    super(owner, args);
    this.zendeskWidget.hide();
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    this.zendeskWidget.show();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::Signature': typeof FlowsPayLaterApplicationSignature;
  }
}
