/* import __COLOCATED_TEMPLATE__ from './receivable-invoices-promo-box.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { PromotionalCard } from '@repo/design-system-kit';

import { Addons } from 'qonto/constants/addons';

interface DualNavReceivableInvoicesPromoBoxSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DualNavReceivableInvoicesPromoBoxComponent extends Component<DualNavReceivableInvoicesPromoBoxSignature> {
  promotionalCard = PromotionalCard;

  upgradeBadge = BADGE_TYPE.UPGRADE;
  tryForFreeBadge = BADGE_TYPE.TRY_FOR_FREE;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service
  declare accountReceivableOnboardingManager: Services['accountReceivableOnboardingManager'];

  constructor(owner: unknown, args: DualNavReceivableInvoicesPromoBoxSignature['Args']) {
    super(owner, args);

    if (this.shouldDisplay) {
      this.segment.track('ar_add_on-promo_modal-displayed');
    }
  }

  get shouldDisplay() {
    let isValidRoute = ['receivable-invoices.index', 'quotes.index'].includes(
      this.router.currentRouteName
    );

    return (
      isValidRoute &&
      this.abilities.can('view accountsReceivableAdvancedCustomization') &&
      !this.isArAddonActive &&
      this.accountReceivableOnboardingManager.hasBeenStarted
    );
  }

  get isArAddonActive() {
    return this.subscriptionManager.hasAddon(Addons.AccountsReceivable);
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial('ar_advanced_customization') || this.hasInitialTrial
    );
  }

  get badgeType() {
    if (this.hasAvailableFreeTrial) {
      return this.tryForFreeBadge;
    } else {
      return this.upgradeBadge;
    }
  }

  get badgeText() {
    if (this.hasAvailableFreeTrial) {
      return this.intl.t('promotion-system.badge.trial.label');
    } else {
      return this.intl.t('upsell.add-ons.badge');
    }
  }

  @action showAddonValueProposition() {
    this.router.transitionTo('receivable-invoices.addon');

    this.segment.track('ar_add_on-promo_modal-clicked', {
      cta_type: 'customization',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::ReceivableInvoicesPromoBox': typeof DualNavReceivableInvoicesPromoBoxComponent;
  }
}
