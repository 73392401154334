/* import __COLOCATED_TEMPLATE__ from './select-account.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BankAccountsSelectStep } from 'qonto/react/components/bank-accounts/select-step';

interface F24ManualDeclarationSelectAccountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24ManualDeclarationSelectAccountComponent extends Component<F24ManualDeclarationSelectAccountSignature> {
  selectStep = BankAccountsSelectStep;

  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @action setAccount(account) {
    this.segment.track('f24_create-flow_step4-account-selected');
    // @ts-expect-error
    this.args.context.f24Order.bankAccount = account;
  }

  // @ts-expect-error
  @action hasSufficientFunds({ authorizedBalance }) {
    // @ts-expect-error
    return authorizedBalance >= this.args.context.f24Order.documentAmount;
  }

  get shouldDisplayTopUpInstruction() {
    // @ts-expect-error
    let f24Amount = this.args.context.f24Order.documentAmount;

    // compare to f24 total amount
    // if false: you can pay
    let shouldDisplay = !this.organizationManager.accounts.some(
      elem => elem.authorizedBalance >= f24Amount
    );

    return shouldDisplay;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::F24::ManualDeclaration::SelectAccount': typeof F24ManualDeclarationSelectAccountComponent;
  }
}
