/* import __COLOCATED_TEMPLATE__ from './edit-notification-rule.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';
import { restartableTask, task, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

interface QontoHubModalsEditNotificationRuleSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class QontoHubModalsEditNotificationRule extends Component<QontoHubModalsEditNotificationRuleSignature> {
  amountField = AmountField;

  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get amountInEUR() {
    // @ts-expect-error
    return this.args.data.hubNotificationRule.value / 100;
  }

  get errorMessage() {
    // @ts-expect-error
    let amount = this.args.data.hubNotificationRule.value;

    if (!amount || amount < 0) {
      return this.intl.t('validations.errors.blank');
    }

    return null;
  }

  triggerInitialAlertCountPerDayTask = task(async () => {
    // @ts-expect-error
    let notificationRule = this.args.data.hubNotificationRule.value;

    // @ts-expect-error
    if (notificationRule > 0 && this.args.data.hubNotificationRule.trigger === 'transaction') {
      await this.estimateAlertCountPerDayTask.perform();
    }
  });

  estimateAlertCountPerDayTask = restartableTask(async () => {
    await timeout(DEBOUNCE_MS);

    try {
      // @ts-expect-error
      let { estimate } = await this.args.data.hubNotificationRule.estimate();

      return estimate.average;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      // @ts-expect-error
      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  confirmTask = task(async e => {
    e.preventDefault();
    try {
      // @ts-expect-error
      await this.args.data.hubNotificationRule.save();

      this.toastFlashMessages.toastSuccess(
        this.intl.t('qonto-hub.connect.details.settings.edit-rule-success')
      );
      // @ts-expect-error
      this.args.close('confirm');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      // @ts-expect-error
      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  changeValueTask = task(async value => {
    await this.estimateAlertCountPerDayTask.cancelAll({ resetState: true });

    let amount = parseInt(value, 10);

    // @ts-expect-error
    this.args.data.hubNotificationRule.set('value', Number.isNaN(amount) ? 0 : amount * 100);

    // @ts-expect-error
    if (amount > 0 && this.args.data.hubNotificationRule.trigger === 'transaction') {
      await this.estimateAlertCountPerDayTask.perform();
    }
  });

  @action
  cancel() {
    // @ts-expect-error
    this.args.data.hubNotificationRule.rollbackAttributes();
    // @ts-expect-error
    this.args.close('cancel');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::Modals::EditNotificationRule': typeof QontoHubModalsEditNotificationRule;
  }
}
