/* import __COLOCATED_TEMPLATE__ from './time-frame.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

interface FlowsBudgetsTimeFrameSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsBudgetsTimeFrame extends Component<FlowsBudgetsTimeFrameSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];

  startDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);

  // @ts-expect-error
  @tracked error;
  @tracked selectedDates = {
    endDate:
      // @ts-expect-error
      this.args.context.selectedEndDate ??
      dayjs(this.startDate).add(11, 'month').endOf('month').format(DATE_PICKER_FIELD_FORMAT),
    startDate:
      // @ts-expect-error
      this.args.context.selectedStartDate ??
      dayjs(this.startDate).startOf('month').format(DATE_PICKER_FIELD_FORMAT),
  };

  // @ts-expect-error
  @action handleDatesSelected(selectedDates) {
    this.selectedDates = selectedDates;
    this.error = null;
  }

  @action submit() {
    this.segment.track('budget-creation_budget-period-definition_clicked');
    if (!this.selectedDates.endDate) {
      this.error = this.intl.t('validations.errors.presence');
    } else {
      // @ts-expect-error
      this.args.context.selectedEndDate = this.selectedDates.endDate;
      // @ts-expect-error
      this.args.context.selectedStartDate = this.selectedDates.startDate;
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::CreateBudget::TimeFrame': typeof FlowsBudgetsTimeFrame;
  }
}
