/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';
import { SubscriptionSuccess } from '@repo/domain-kit/pricing';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

interface SubscriptionChangeSuccessSignature {
  // The arguments accepted by the component
  Args: {
    context: {
      refererPage: string;
      newPlan: string;
      previousPlan: string;
      recurrence: string;
      currentPlan: string;
      changeType: string;
      currentPricePlanCode: string;
      subscription: { product: { code: 'string' } };
    };
    transitionToNext: () => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SubscriptionChangeSuccessComponent extends Component<SubscriptionChangeSuccessSignature> {
  lottiePlayer = LottiePlayer;
  subscriptionSuccess = SubscriptionSuccess;

  @tracked textAreaValue = '';

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  constructor(owner: unknown, args: SubscriptionChangeSuccessSignature['Args']) {
    super(owner, args);
    this.sendTrackingEventOnLoad();
  }

  sendTrackingEventOnLoad() {
    let { changeType, currentPlan, recurrence, currentPricePlanCode, subscription } =
      this.args.context;

    if (changeType === 'change-recurrence') {
      this.segment.track('account-closing_change-recurrence_completed', {
        current_plan: currentPlan,
      });
    } else if (changeType === 'switch-plan') {
      this.segment.track('account-closing_switch-plan_completed', {
        current_plan: currentPlan,
      });
    } else if (changeType === 'annual-switch-plan') {
      this.segment.track('account-closing_annual-switch-plan_completed', {
        current_plan: currentPlan,
      });
    }

    this.segment.track('checkout_displayed_success', {
      target_plan_recurrence: recurrence,
      target_plan: subscription.product?.code,
      current_plan: currentPricePlanCode,
    });
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasNewFeedbackTextArea() {
    return variation('feature--boolean-improved-feedback-on-success');
  }

  get CTAText() {
    if (this.hasNewFeedbackTextArea) {
      return this.intl.t('subscription.change.success.feedback-cta');
    }
    if (this.isConnectUpsell) {
      return this.intl.t('subscription.change.success.connect-cta');
    }
    return this.intl.t('btn.close');
  }

  get subtitle() {
    if (this.isConnectUpsell) {
      return this.intl.t('subscription.change.success.connect-subtitle');
    }
    return this.intl.t('subscription.change.success.subtitle');
  }

  get isConnectUpsell() {
    return this.args.context.refererPage?.includes('connect');
  }

  get typeformLink() {
    const { context } = this.args;
    const membershipId = this.organizationManager.organization?.id;
    const currentPlan = context.newPlan;
    const previousPlan = context.previousPlan;
    const recurrence = context.recurrence;
    return `${this.intl.t('subscription.change.success.feedback-linkUrl')}#membership_id=${membershipId}&current_plan=${currentPlan}&previous_plan=${previousPlan}&recurrence=${recurrence}`;
  }

  @action
  handleClick() {
    if (this.hasNewFeedbackTextArea) {
      let feedback = this.textAreaValue.trim();
      if (feedback) {
        let { recurrence, newPlan, previousPlan } = this.args.context;
        this.segment.track('plans_change_confirmation_success_feedback_clicked', {
          membership_id: this.organizationManager.membership.id,
          input_content: feedback,
          target_plan: newPlan,
          current_plan: previousPlan,
          recurrence,
        });
        this.toastFlashMessages.toastInfo(
          this.intl.t('subscription.change.success.feedback-submitted')
        );
      }
    } else {
      this.segment.track('plans_change_confirmation_success_closed_clicked');
    }
    this.args.transitionToNext();
  }

  @action
  onUpdateTextArea(value: string) {
    this.textAreaValue = value;
  }

  @action
  handleClickShareFeedback() {
    this.segment.track('plans_change_confirmation_success_feedback_clicked', {
      current_plan: this.args.context.newPlan,
      previous_plan: this.args.context.previousPlan,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::Success': typeof SubscriptionChangeSuccessComponent;
  }
}
