/* import __COLOCATED_TEMPLATE__ from './physical-card-kyc-intro.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface FlowsCardsPhysicalKycIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsPhysicalKycIntro extends Component<FlowsCardsPhysicalKycIntroSignature> {
  @action
  handleTransitionToNext() {
    // @ts-expect-error
    this.args.pushForwardFlow('management-kyc', 'user-info');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::PhysicalCardKycIntro': typeof FlowsCardsPhysicalKycIntro;
  }
}
