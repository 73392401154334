/* import __COLOCATED_TEMPLATE__ from './self-transfer.hbs'; */
import Component from '@glimmer/component';

import { SelfTransfer } from 'qonto/react/financing/components/flows/pay-later/transfer/errors/self-transfer';

interface FlowsTransfersSepaPayLaterErrorsSelfTransferComponentProps {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class FlowsTransfersSepaPayLaterErrorsSelfTransferComponent extends Component<FlowsTransfersSepaPayLaterErrorsSelfTransferComponentProps> {
  SelfTransfer = SelfTransfer;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::PayLater::Errors::SelfTransfer': typeof FlowsTransfersSepaPayLaterErrorsSelfTransferComponent;
  }
}
