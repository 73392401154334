/* import __COLOCATED_TEMPLATE__ from './multi-accounts.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const CARD_IMAGES = {
  de: '/illustrations/upsell/de/trigger.png',
  en: '/illustrations/upsell/en/trigger.png',
  es: '/illustrations/upsell/es/trigger.png',
  fr: '/illustrations/upsell/fr/trigger.png',
  it: '/illustrations/upsell/it/trigger.png',
  pt: '/illustrations/upsell/pt/trigger.png',
};

interface DiscoverUpsellMultiAccountsSignature {
  // The arguments accepted by the component
  Args: {
    isReporting?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DiscoverUpsellMultiAccountsComponent extends Component<DiscoverUpsellMultiAccountsSignature> {
  badgeHighlight = BadgeHighlight;

  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  get imagePath() {
    // @ts-expect-error
    return CARD_IMAGES[this.localeManager.locale];
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription?.findTrial('multi_accounts');
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  @action onTrialClick() {
    let { role } = this.organizationManager.membership;
    let origin = this.router.currentRouteName.includes('accounts') && 'accounts_page';

    this.segment.track('upsell_multi-account_clicked', {
      role,
      price_plan: this.subscriptionManager.currentPricePlan.code,
      origin,
    });

    this.upsellManager.transitionToTrialLineup(this.trialInfo);
  }

  @action onClickUpsell() {
    let { role } = this.organizationManager.membership;
    let { end_date } = this.subscriptionManager.currentSubscription?.activeTrial || {};

    this.segment.track('upsell_multi-account_clicked', {
      role,
      price_plan: this.subscriptionManager.currentPricePlan.code,
      end_date,
    });
  }

  initRecommendationTask = dropTask(async () => {
    let { role } = this.organizationManager.membership;
    this.segment.track('upsell_multi-account_clicked', {
      role,
      price_plan: this.subscriptionManager.currentPricePlan.code,
    });

    await this.upsellManager.upgradeRecommendationWithTransition('multi_accounts').catch(error => {
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 404) {
        this.sentry.captureException(error);
      }
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
      });
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Upsell::MultiAccounts': typeof DiscoverUpsellMultiAccountsComponent;
  }
}
