/* import __COLOCATED_TEMPLATE__ from './promotion-recommendation.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';

import { apiBaseURL, billingNamespace } from 'qonto/constants/hosts';
// @ts-expect-error
import { getPromotionRecommendationsConfig, PROMOTION_TYPES } from 'qonto/constants/promotion';
import { SUBSCRIPTION_PRODUCT_TYPES } from 'qonto/constants/subscriptions';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface PromotionRecommendationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PromotionRecommendationComponent extends Component<PromotionRecommendationSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare router: Services['router'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @tracked recommendationInfo = null;

  constructor(owner: unknown, args: PromotionRecommendationSignature['Args']) {
    super(owner, args);
    this.getPromotionRecommendation();
  }

  get recommendation() {
    if (
      this.shouldSkip ||
      this.fetchRecommendationTask.last?.isError ||
      this.fetchRecommendationTask.isRunning
    ) {
      return null;
    }

    return this.calculateRecommendation;
  }

  async getPromotionRecommendation() {
    if (this.shouldSkip) {
      return;
    }

    this.recommendationInfo = null;
    let isMandatesEmpty = await this.isMandatesEmpty();
    if (isMandatesEmpty) {
      return;
    }
    let featureNames = Object.keys(this.routeConfig);
    // @ts-expect-error
    this.recommendationInfo = await this.fetchFirstSuccessfulRecommendation(featureNames);

    this.trackShownEvent();
  }

  trackShownEvent() {
    let recommendation = this.recommendation;
    if (!recommendation) {
      return;
    }

    let { trialInfo, featureConfig } = recommendation;

    this.segment.track('promotion-card_shown', {
      'promotion-card_type': this.getTrackingCtaType(trialInfo),
      'promotion-card_feature': featureConfig.tracking.featureName,
      ...(featureConfig.tracking?.upsell_type
        ? { upsell_type: featureConfig.tracking.upsell_type }
        : null),
    });
  }

  fetchRecommendationTask = restartableTask(async ({ featureName }) => {
    return await this.networkManager.request(
      `${apiBaseURL}/${billingNamespace}/subscriptions/upgrade_recommendation?feature_code=${featureName}`
    );
  });

  fetchMandatesTask = restartableTask(async () => {
    return await this.store.query('mandate', {
      organization_id: this.organizationManager.organization.id,
    });
  });

  get shouldSkip() {
    if (
      !this.abilities.can('update subscription') ||
      // @ts-expect-error
      !this.args.activeSubMenuItem ||
      !this.routeConfig
    ) {
      return true;
    }

    return false;
  }

  get routeConfig() {
    // @ts-expect-error
    let subMenuId = this.args.activeSubMenuItem.id;
    return getPromotionRecommendationsConfig(this.intl)[subMenuId];
  }

  // @ts-expect-error
  getPromotionType(trialInfo) {
    if (trialInfo) {
      return trialInfo.productType === SUBSCRIPTION_PRODUCT_TYPES.ADDON
        ? PROMOTION_TYPES.TRY_ADDON
        : PROMOTION_TYPES.TRY;
    }
    return PROMOTION_TYPES.UPSELL;
  }

  // @ts-expect-error
  getTrackingCtaType(trialInfo) {
    return trialInfo ? PROMOTION_TYPES.TRY : PROMOTION_TYPES.UPSELL;
  }

  get calculateRecommendation() {
    let { recommended_product: product, recommended_recurrence: recurrence } =
      this.fetchRecommendationTask.last?.value || {};

    if (!product?.id) {
      return null;
    }

    // @ts-expect-error
    if (!this.recommendationInfo || !this.routeConfig[this.recommendationInfo.featureName]) {
      return null;
    }

    // @ts-expect-error
    let featureConfig = this.routeConfig[this.recommendationInfo.featureName];

    let canTryAddonRecommendation = Boolean(
      product.type === SUBSCRIPTION_PRODUCT_TYPES.ADDON && featureConfig[PROMOTION_TYPES.TRY_ADDON]
    );
    let canTryCoreRecommendation = Boolean(
      product.type === SUBSCRIPTION_PRODUCT_TYPES.PLAN && featureConfig[PROMOTION_TYPES.TRY]
    );

    let trialInfo =
      canTryCoreRecommendation || canTryAddonRecommendation
        ? this.subscriptionManager.currentSubscription?.availableTrials?.find(
            // @ts-expect-error
            ({ productId }) => productId === product.id
          )
        : false;

    return {
      product,
      featureConfig: featureConfig[this.getPromotionType(trialInfo)],
      recurrence,
      trialInfo,
    };
  }

  @action
  // @ts-expect-error
  onCtaClick(recommendation) {
    let { featureConfig, product, recurrence, trialInfo } = recommendation;
    let featureName = featureConfig.tracking.featureName;

    this.segment.track(`${featureName}_cta_clicked`, {
      cta_type: this.getTrackingCtaType(trialInfo),
      origin: 'dual_nav',
      ...(featureConfig.tracking?.upsell_type
        ? { upsell_type: featureConfig.tracking.upsell_type }
        : null),
    });

    if (trialInfo && trialInfo.productType === SUBSCRIPTION_PRODUCT_TYPES.PLAN) {
      this.upsellManager.transitionToTrialLineup(trialInfo);
    } else {
      let { code: productId, type: productType } = product;
      this.upsellManager.transitionToLineup({ productId, recurrence, productType });
    }
  }

  // @ts-expect-error
  async fetchFirstSuccessfulRecommendation(featureNames) {
    for (let featureName of featureNames) {
      let result = await this.fetchRecommendationTask
        .perform({ featureName })
        .catch(ignoreCancelation)
        .catch(error => {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.httpStatus !== 404) {
            if (errorInfo.shouldSendToSentry) {
              this.sentry.captureException(error);
            }
          }
          return null;
        });

      if (result) {
        return { featureName };
      }
    }
    return null;
  }

  async isMandatesEmpty() {
    // @ts-expect-error
    if (this.args.activeSubMenuItem.id !== 'outgoing-direct-debits') {
      return false;
    }

    let response = await this.fetchMandatesTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    // @ts-expect-error
    return response ? response.meta.total_count === 0 : true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::PromotionRecommendation': typeof PromotionRecommendationComponent;
  }
}
