/* import __COLOCATED_TEMPLATE__ from './settlement.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { all, dropTask, race, rawTimeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { APPROVAL_WORKFLOW_PROMOTION_STORAGE_KEY } from 'qonto/constants/approval-workflow';
import { FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS } from 'qonto/constants/french-einvoicing-settings';
// @ts-expect-error
import { TRANSFER_EVENTS } from 'qonto/constants/listeners';
import {
  INSTANT_SETTLEMENT_MAXIMAL_WAITING_TIME_MS,
  INSTANT_SETTLEMENT_MINIMAL_WAITING_TIME_MS,
  SEPA_TRACKING_SETTLEMENT_CTA,
  SEPA_TRACKING_SETTLEMENT_EVENTS,
  STATUS,
} from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import {
  InstantTransfer,
  RegularTransfer,
  RequestTransfer,
} from 'qonto/react/components/transfers/sepa/settlement';
// @ts-expect-error
import cloneProperties from 'qonto/utils/clone-properties';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { createSepaSettlementTrackingEventPayload } from 'qonto/utils/transfers';

interface FlowsTransfersSepaNewSettlementSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaNewSettlementComponent extends Component<FlowsTransfersSepaNewSettlementSignature> {
  @service declare intl: Services['intl'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare payByInvoiceUploadManager: Services['payByInvoiceUploadManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];
  @service declare flow: Services['flow'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];

  @tracked isTimeout = false;
  @tracked declinedReason = null;

  RequestTransferComponent = RequestTransfer;
  RegularTransferComponent = RegularTransfer;
  InstantTransferComponent = InstantTransfer;

  constructor(owner: unknown, args: FlowsTransfersSepaNewSettlementSignature['Args']) {
    super(owner, args);

    if (this.sepaTransfer.instant) {
      this.sepaTransfer.status = STATUS.PROCESSING;

      this.waitForInstantTransferSettlement
        .perform(this.sepaTransfer.id)
        .then(({ settlementStatus, declinedReason, isTimeout }) => {
          this.sepaTransfer.status = settlementStatus;

          this.isTimeout = isTimeout;
          this.declinedReason = declinedReason;

          // @ts-expect-error
          // We sync the transfer settlement result with the flow context so that
          // the `onComplete` action knows about it
          this.args.context.settlementResult = {
            declinedReason,
            isTimeout,
          };
        })
        .catch(ignoreCancelation);
    }

    if (this.showApprovalWorkflowPromotion) {
      safeLocalStorage.setItem(APPROVAL_WORKFLOW_PROMOTION_STORAGE_KEY, 'true');
    }
  }

  get showApprovalWorkflowPromotion() {
    // @ts-expect-error
    return this.args.context.showApprovalWorkflowPromotion;
  }

  get sepaTransfer() {
    // @ts-expect-error
    return this.args.context.sepaTransfer;
  }

  get transferRequest() {
    // @ts-expect-error
    return this.args.context.transferRequest;
  }

  get frenchEinvoicingSettings() {
    let { organization } = this.organizationManager;
    if (organization.id) {
      return this.store.peekRecord('einvoicing-fr-settings', organization.id);
    }
  }

  get showEInvoicingOptInModal() {
    if (this.sepaTransfer.instant && !this.sepaTransfer.isCompleted) {
      return false;
    }
    // @ts-expect-error
    const { invoice } = this.args.context;

    const isEinvoicingDisabled =
      this.frenchEinvoicingSettings?.einvoicingReceivingStatus ===
      FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.disabled;

    const shouldShow =
      variation('feature--boolean-einvoicing-transfer-success') && invoice && isEinvoicingDisabled;

    if (shouldShow) {
      this.segment.track('einvoicing-transfer_card_viewed');
    }

    return shouldShow;
  }

  onClickButtonPrimaryTask = dropTask(
    async (action: string, { transfer, declinedReason, confirmTransferTask } = {}) => {
      switch (action) {
        case 'retry-as-regular':
          // @ts-expect-error
          await this.retryInstantAsRegularTask({
            confirmTransferTask,
            declinedInstantTransfer: transfer,
          });
          break;
        case 'back-to-supplier-invoices':
          this.backToSupplierInvoices();
          break;
        case 'make-another-transfer':
          this.makeAnotherTransfer({ transfer, declinedReason });
          break;
        case 'see-request-details':
          this.seeTransferDetails({ transfer, isRequest: true });
          break;
        case 'go-to-approval-workflows':
          this.goToApprovalWorkflows();
          break;
      }
    }
  );

  @action
  // @ts-expect-error
  onClickButtonSecondary(action: string, { transfer, shouldTrack } = {}) {
    switch (action) {
      case 'close':
        // @ts-expect-error
        this.args.completeFlow();
        break;
      case 'see-transfer-details':
        this.seeTransferDetails({ transfer });
        break;
      case 'pay-later':
        this.transitionToPayLater();
        break;
      case 'back-to-supplier-invoices':
        this.backToSupplierInvoices(shouldTrack);
        break;
    }
  }

  @action
  openEInvoicingConsentModal() {
    this.modals.open('e-invoicing-opt-in-pop-up', {
      segmentation: this.frenchEinvoicingSettings?.segmentation,
      origin: 'pay-by-invoice',
    });

    this.segment.track('einvoicing-transfer_CTA_clicked');
  }

  backToSupplierInvoices(shouldTrack = true) {
    if (shouldTrack) {
      this.segment.track('transfer-sepa_supplier-invoice_clicked', {
        origin: 'supplier-invoice',
      });
    }

    return this.router.transitionTo('supplier-invoices');
  }

  goToApprovalWorkflows() {
    this.segment.track('approval-workflows-supplier-invoices_payment-success_clicked');

    return this.router.transitionTo('settings.approval-workflows');
  }

  transitionToPayLater() {
    // @ts-expect-error
    this.payByInvoiceUploadManager.resetState();

    this.router.transitionTo('financing.pay-later');
  }

  seeTransferDetails({ transfer, isRequest = false }: { transfer: any; isRequest?: boolean }) {
    // @ts-expect-error
    let { invoice, origin } = this.args.context;

    this.segment.track(
      SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED,
      // @ts-expect-error
      createSepaSettlementTrackingEventPayload({
        invoice,
        origin,
        sepaTransfer: transfer,
        isRequest,
        cta: isRequest
          ? SEPA_TRACKING_SETTLEMENT_CTA.SEE_REQUEST
          : SEPA_TRACKING_SETTLEMENT_CTA.TRANSFER_DETAILS,
      })
    );
    // @ts-expect-error
    this.payByInvoiceUploadManager.resetState();

    this.router.transitionTo(isRequest ? 'transfers.requests' : 'transfers.past', {
      queryParams: {
        highlight: transfer.id,
      },
    });
  }

  retryInstantAsRegularTask = async ({
    confirmTransferTask,
    declinedInstantTransfer,
  }: {
    instantTransfer: any;
    confirmTransferTask: any;
    declinedInstantTransfer: any;
  }) => {
    // @ts-expect-error
    let { context } = this.args;
    let { origin } = context;

    // Create a new transfer record for fallback creation
    let newTransfer = this.store.createRecord('transfer');
    context.sepaTransfer = newTransfer;
    await cloneProperties(declinedInstantTransfer, newTransfer);

    let fallbackConfirmationResult = await confirmTransferTask.perform(newTransfer);

    if (fallbackConfirmationResult) {
      context.isInstantFallback = true;

      this.segment.track(
        SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED,
        // @ts-expect-error
        createSepaSettlementTrackingEventPayload({
          sepaTransfer: declinedInstantTransfer,
          cta: SEPA_TRACKING_SETTLEMENT_CTA.FALLBACK,
          origin,
        })
      );

      // @ts-expect-error
      this.args.backToStep('summary');
    }
  };

  makeAnotherTransfer({ transfer, declinedReason }: { transfer: any; declinedReason: string }) {
    // @ts-expect-error
    let { invoice, origin } = this.args.context;
    let eventName;

    if (transfer.isDeclined) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED;
    } else {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED;
    }

    this.segment.track(
      eventName,
      // @ts-expect-error
      createSepaSettlementTrackingEventPayload({
        invoice,
        origin,
        sepaTransfer: transfer,
        cta: SEPA_TRACKING_SETTLEMENT_CTA.NEW_TRANSFER,
        isQontoBeneficiary: transfer.get('beneficiary.qontoBankAccount'),
        declinedReason,
      })
    );
    // @ts-expect-error
    this.payByInvoiceUploadManager.resetState();

    // @ts-expect-error
    if (this.args.context.isDedicatedFlow) {
      // @ts-expect-error
      this.args.restartFlow();
    } else {
      this.router.transitionTo('transfers.landing');
    }
  }

  waitForInstantTransferSettlement = dropTask(async (transferId: string) => {
    let settlementStatus = null;
    let declinedReason = null;
    let isTimeout = false;

    let { event, payload } = await race([
      this.waitForTimeoutEvent(),
      this.waitForSettlementEvent(),
    ]);

    switch (event) {
      case TRANSFER_EVENTS.INSTANT_TIMEOUT: {
        ({ settlementStatus, declinedReason, isTimeout } = await this.handleTimeout(transferId));
        break;
      }
      case TRANSFER_EVENTS.INSTANT_DECLINED: {
        settlementStatus = STATUS.DECLINED;
        declinedReason = payload?.object?.declined_reason;
        break;
      }
      case TRANSFER_EVENTS.INSTANT_SETTLED:
        settlementStatus = STATUS.COMPLETED;
        break;
    }

    return {
      settlementStatus,
      declinedReason,
      isTimeout,
    };
  });

  waitForTimeoutEvent = async () => {
    await rawTimeout(INSTANT_SETTLEMENT_MAXIMAL_WAITING_TIME_MS);
    return { event: TRANSFER_EVENTS.INSTANT_TIMEOUT };
  };

  waitForSettlementEvent = async () => {
    const [settlementEvent] = await all([
      race([
        // @ts-expect-error
        this.notifierManager.waitForEventTask.perform(TRANSFER_EVENTS.INSTANT_SETTLED),
        // @ts-expect-error
        this.notifierManager.waitForEventTask.perform(TRANSFER_EVENTS.INSTANT_DECLINED),
      ]),
      rawTimeout(INSTANT_SETTLEMENT_MINIMAL_WAITING_TIME_MS),
    ]);

    return settlementEvent;
  };

  handleTimeout = async (transferId: string) => {
    const transfer = await this.store.findRecord('transfer', transferId);
    const isTimeout = !(transfer.isCompleted || transfer.isDeclined);
    return {
      settlementStatus: transfer.status,
      declinedReason: transfer.declinedReason,
      isTimeout,
    };
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::New::Settlement': typeof FlowsTransfersSepaNewSettlementComponent;
  }
}
