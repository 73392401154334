/* import __COLOCATED_TEMPLATE__ from './rule-card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SkeletonLoader } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface QontoHubDisplayRuleSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QontoHubDisplayRuleComponent extends Component<QontoHubDisplayRuleSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  // @ts-expect-error
  @tracked showOptionsButton;
  placeholderLine = SkeletonLoader.Line;
  constructor(owner: unknown, args: QontoHubDisplayRuleSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    if (this.args.rule.trigger === 'transaction') {
      this.estimateAlertCountPerDayTask.perform().catch(ignoreCancelation);
    }
  }
  get amount() {
    // @ts-expect-error
    return this.args.rule.value / 100;
  }

  estimateAlertCountPerDayTask = task(
    waitFor(async () => {
      try {
        // @ts-expect-error
        let { estimate } = await this.args.rule.estimate();

        return estimate.average;
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

        // @ts-expect-error
        if (!error.isAdapterError) {
          throw error;
        }
      }
    })
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::RuleCard': typeof QontoHubDisplayRuleComponent;
  }
}
