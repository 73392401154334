/* import __COLOCATED_TEMPLATE__ from './terms-and-conditions.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { PLACEHOLDER_SECTIONS } from '@qonto/ui-kit/addon/constants/pdf-preview';
import { TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import {
  ONBOARDING_STEP_STATUS,
  ONBOARDING_TRACKING_EVENTS,
} from 'qonto/constants/receivable-invoice';
import { ArFeaturesPromoPopup } from 'qonto/react/receivable-invoices/components/promotions/ar-features-promo-popup';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { getNextInvoiceNumberPreview } from 'qonto/utils/invoices/preview';

interface FlowsAccountReceivableOnboardingTermsAndConditionsSignature {
  // The arguments accepted by the component
  Args: {
    pushFlow: (flowName: string, stepId: string) => void;
    context: Record<string, any>;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountReceivableOnboardingTermsAndConditions extends Component<FlowsAccountReceivableOnboardingTermsAndConditionsSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare abilities: Services['abilities'];

  arFeaturesPromoPopup = ArFeaturesPromoPopup;
  textAreaField = TextAreaField;

  @tracked showTermsLinkFields = Boolean(this.settings.tcLinkUrl || this.settings.tcLinkText);
  @tracked showPopup = false;

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (!this.settings || this.settings.isDestroyed || this.settings.isDestroying) return;

    this.settings.rollbackAttributes();
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get settings() {
    return this.args.context.settings;
  }

  get onboardingState() {
    return this.args.context.onboardingState;
  }

  get stepStatus() {
    return this.onboardingState.substepStatusCompanyDetails;
  }

  get placeholderConfig() {
    return {
      enablePlaceholders: true,
      activeSections: [
        PLACEHOLDER_SECTIONS.TERMS_AND_CONDITIONS,
        PLACEHOLDER_SECTIONS.NUMBER,
        PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION,
      ],
    };
  }

  get exampleInvoice() {
    return {
      organization: {
        legalCountry: this.organization.legalCountry,
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        address: this.organization.address,
        vatNumber: this.settings.vatNumber,
      },
      contactEmail: this.settings.contactEmail,
      number: this.nextInvoiceNumberPreview,
    };
  }

  get nextInvoiceNumberPreview() {
    return getNextInvoiceNumberPreview(this.settings);
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return (
      this.upsellManager.hasAvailableFreeTrial('ar_advanced_customization') || this.hasInitialTrial
    );
  }

  get badgeType() {
    return this.hasAvailableFreeTrial ? 'tryForFree' : 'upgrade';
  }

  @action
  // @ts-expect-error
  updateDiscountConditions(text) {
    this.settings.discountConditions = text;
    this.segment.track(ONBOARDING_TRACKING_EVENTS.DISCOUNT_CONDITIONS_EDITED);
  }

  @action
  // @ts-expect-error
  updateLatePaymentPenalties(text) {
    this.settings.latePaymentPenalties = text;
    this.segment.track(ONBOARDING_TRACKING_EVENTS.LATE_PAYMENT_PENALTIES_EDITED);
  }

  @action
  // @ts-expect-error
  updateLegalFixedCompensation(text) {
    this.settings.legalFixedCompensation = text;
    this.segment.track(ONBOARDING_TRACKING_EVENTS.LEGAL_FIXED_COMPENSATION_EDITED);
  }

  @action
  handleSkipCTA() {
    // @ts-expect-error
    this.args.backToStep('summary');
  }

  @action togglePopup() {
    this.showPopup = !this.showPopup;
  }

  confirmTask = dropTask(async () => {
    try {
      await this.settings.save();
      this.onboardingState.substepStatusCompanyDetails = ONBOARDING_STEP_STATUS.CONFIRMED;
      await this.onboardingState.save();
      // @ts-expect-error
      this.args.backToStep('summary');
    } catch (error) {
      // @ts-expect-error
      if (error.status !== 422) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  onCtaClickTask = dropTask(async () => {
    try {
      let {
        recommended_recurrence: recurrence,
        recommended_product: { code },
      } = await this.upsellManager.getUpgradeRecommendation('ar_advanced_customization');

      this.args.context.recurrence = recurrence;
      this.args.context.addonCode = code;

      this.args.pushFlow('addon-change', 'addons');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::TermsAndConditions': typeof FlowsAccountReceivableOnboardingTermsAndConditions;
  }
}
