export default {
  "wrapper": "PN",
  "content-wrapper": "Pv",
  "no-fee": "Po",
  "concierge": "Pn",
  "no-fee-item": "Pi",
  "concierge-item": "PA",
  "no-fee-icon": "PY",
  "lounge-icon": "Ph",
  "insurance-conditions": "Pf",
  "description": "PK",
  "footer-detail": "PG",
  "title": "Pr",
  "footer-price": "Pb",
  "footer-content": "Py",
  "lounge-text": "PU",
  "lounge": "Pj",
  "lounge-images": "PW",
  "img": "Pu",
  "footer-sticky-bar": "lS",
  "visible": "lc",
  "content": "lq",
  "content-card": "lZ"
};
