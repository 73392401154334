/* import __COLOCATED_TEMPLATE__ from './team-supervisors-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { waitForQueue } from 'ember-concurrency';

import { SupervisorsSelectionPlaceholder } from 'qonto/react/components/budgets/create/supervisors-selection/placeholder';

interface FlowsBudgetsCreateBudgetTeamSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsBudgetsCreateBudgetTeamSelection extends Component<FlowsBudgetsCreateBudgetTeamSelectionSignature> {
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];

  @tracked selectedTeam = null;
  @tracked selectedMembers = [];
  @tracked teamError = null;

  supervisorsSelectionPlaceholder = SupervisorsSelectionPlaceholder;

  constructor(owner: unknown, args: FlowsBudgetsCreateBudgetTeamSelectionSignature['Args']) {
    super(owner, args);
    this.trackTeamSelectionRendered();
  }

  async trackTeamSelectionRendered() {
    await waitForQueue('afterRender');
    this.segment.track('budget-creation_team-selection_displayed');
  }

  @action
  // @ts-expect-error
  setSelectedTeam({ team, members }) {
    this.selectedTeam = team;
    this.selectedMembers = members || [];
    this.teamError = null;
  }

  @action
  nextStep() {
    if (!this.selectedTeam) {
      // @ts-expect-error
      this.teamError = this.intl.t('validations.errors.presence');
      return;
    }

    this.segment.track('budget-creation_team-selection_continue', {
      // @ts-expect-error
      team_managers: this.selectedTeam.team.teamManagers.length,
      budget_supervisors: this.selectedMembers.length,
      // @ts-expect-error
      team_id: this.selectedTeam.team.id,
      budget_supervisor_ids: this.selectedMembers,
    });

    // @ts-expect-error
    this.args.context.team = this.selectedTeam.team;
    // @ts-expect-error
    this.args.context.supervisors = this.selectedMembers;

    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::CreateBudget::TeamSupervisorsSelection': typeof FlowsBudgetsCreateBudgetTeamSelection;
  }
}
