/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ReviewSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Review = templateOnlyComponent<ReviewSignature>();

export default Review;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::Review': typeof Review;
  }
}
