/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';

import type { DataContext } from './data-context';

interface SubscriptionCloseStepsConfirmSignature {
  // The arguments accepted by the component
  Args: FlowStepArgs<DataContext>;
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionCloseStepsConfirmComponent extends Component<SubscriptionCloseStepsConfirmSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  get nextBillingDateFormatted() {
    return dateToken({
      date: this.args.context.nextBillingDate,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });
  }

  createDeactivationTask = dropTask(async () => {
    try {
      let {
        closingReasonCategory,
        closingReasonSubCategory,
        closingReasonDetails,
        closingReasonDetailsOther,
      } = this.args.context;
      let { organization } = this.organizationManager;
      let { due_date } = await organization.createDeactivation({
        closing_reason_category: closingReasonCategory?.key,
        closing_reason_sub_category: closingReasonSubCategory?.key || '',
        closing_reason_details: closingReasonDetails?.key || '',
        closing_reason_details_other: closingReasonDetailsOther || '',
      });

      this.args.context.deactivationDueDate = dayjs(due_date).toDate();
      this.segment.track('account_closing_requirements_clicked');
      this.args.transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::Confirm': typeof SubscriptionCloseStepsConfirmComponent;
  }
}
