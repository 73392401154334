/* import __COLOCATED_TEMPLATE__ from './bic.hbs'; */
import Component from '@glimmer/component';

import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';

interface BicSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class Bic extends Component<BicSignature> {
  CopyToClipboard = CopyToClipboard;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::Bic': typeof Bic;
  }
}
