export default {
  "dates-filter": "wA",
  "dates-wrapper": "wY",
  "date-interval": "wh",
  "date-field-wrapper": "wf",
  "dates-conditional": "wK",
  "placeholder": "wG",
  "error": "wr",
  "error-message": "wb body-2"
};
