export default {
  "wrapper": "xl",
  "legend": "xX",
  "operation-code": "xC",
  "table": "xk",
  "footer": "xJ",
  "footer-detraction": "xs",
  "footer-total-g": "xF",
  "footer-total-h": "xg",
  "footer-net-g-h": "xD"
};
