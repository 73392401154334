/* import __COLOCATED_TEMPLATE__ from './edit-role.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface EditRoleSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const EditRole = templateOnlyComponent<EditRoleSignature>();

export default EditRole;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::Success::EditRole': typeof EditRole;
  }
}
