/* import __COLOCATED_TEMPLATE__ from './details-review.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DetailsReviewSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DetailsReview = templateOnlyComponent<DetailsReviewSignature>();

export default DetailsReview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::MultiRequest::DetailsReview': typeof DetailsReview;
  }
}
