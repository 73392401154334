/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

import { PAYMENT_STATUSES } from 'qonto/constants/card-acquirer';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';

interface CardAcquirerPaymentsTableItemSignature {
  // The arguments accepted by the component
  Args: {
    isSelected?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class CardAcquirerPaymentsTableItem extends Component<CardAcquirerPaymentsTableItemSignature> {
  statusAvatar = StatusAvatar;

  badgeStatus = BadgeStatus;

  @service declare intl: Services['intl'];

  get isDeclined() {
    // @ts-expect-error
    return this.args.payment.status === PAYMENT_STATUSES.DECLINED;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardAcquirer::Payments::Table::Item': typeof CardAcquirerPaymentsTableItem;
  }
}
