/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { TablePlaceholder } from 'qonto/react/cards/components/table/placeholder';

interface CardTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardTable extends Component<CardTableSignature> {
  tablePlaceholder = TablePlaceholder;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Table': typeof CardTable;
  }
}
