export default {
  "tooltip": "Ho",
  "wrapper": "Hn",
  "collapsed": "Hi",
  "label": "HA body-1",
  "selected": "HY",
  "icon-wrapper": "Hh",
  "icon": "Hf",
  "locked-icon": "HK",
  "notification-count": "HG",
  "fade-in": "Hr",
  "responsive": "Hb"
};
