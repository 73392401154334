export default {
  "filter": "wL",
  "expression": "wT",
  "conditional-wrapper": "wz mr-8",
  "dropdown-property-select": "wa",
  "is-advanced-filters-mode": "wH",
  "dropdown-operator-select": "wO",
  "conditional-text": "wm",
  "property-select": "wp",
  "operator-select": "wx",
  "error-message": "ww",
  "values-field": "wN",
  "input-placeholder": "wv",
  "filter-actions": "wo",
  "label-color": "wn",
  "button-container": "wi"
};
