/* import __COLOCATED_TEMPLATE__ from './emitter.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { CalendarDate, parseDate } from '@internationalized/date';
import type { FormEvent } from 'react';
import type { DateValue } from 'react-aria';

import { FlowCheckEmitter } from 'qonto/react/components/flows/checks/emitter';

interface FlowsCheckEmitterSignature {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class FlowsCheckEmitterComponent extends Component<FlowsCheckEmitterSignature> {
  FlowCheckEmitter = FlowCheckEmitter;

  @service declare segment: Services['segment'];

  @tracked shouldHighlightEmitterName = false;
  @tracked shouldHighlightEmitterDate = false;
  @tracked isEmitterNameError = false;
  @tracked isEmittedDateError = false;

  get context() {
    return (this.args as any).context;
  }

  get emitterNameErrorMessage() {
    let { emitterName: emitterNameValidation } = this.context.check.validations.attrs;

    return emitterNameValidation.isInvalid && this.isEmitterNameError
      ? emitterNameValidation.message
      : undefined;
  }

  get emittedDate(): CalendarDate | undefined {
    let date: string | undefined | null = this.context.check.emittedDate;
    return date ? parseDate(date) : undefined;
  }

  get emittedDateErrorMessage(): string | undefined {
    let { emittedDate: emittedDateValidation } = this.context.check.validations.attrs;

    return emittedDateValidation.isInvalid && this.isEmittedDateError
      ? emittedDateValidation.message
      : undefined;
  }

  get hasError() {
    let { check } = this.context;

    let { emittedDate: emittedDateValidation, emitterName: emitterNameValidation } =
      check.validations.attrs;

    return !check.emittedDate || emitterNameValidation.isInvalid || emittedDateValidation.isInvalid;
  }

  @action
  onEmitterNameChange(value: string) {
    this.context.check.emitterName = value;
  }

  @action
  onEmitterNameKeyDown() {
    this.isEmitterNameError = false;
  }

  @action
  onEmitterNameFocusChange(isFocused: boolean) {
    if (isFocused) {
      this.shouldHighlightEmitterName = true;
    } else {
      this.shouldHighlightEmitterName = false;
      this.isEmitterNameError = true;
    }
  }

  @action
  onEmittedDateChange(value: DateValue | undefined) {
    this.context.check.emittedDate = value?.toString();
  }

  @action
  onEmittedDateFocusChange(isFocused: boolean) {
    this.shouldHighlightEmitterDate = isFocused;

    if (!isFocused && this.hasError) {
      this.isEmittedDateError = true;
    }
  }

  @action
  onFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let { emittedDate: emittedDateValidation, emitterName: emitterNameValidation } =
      this.context.check.validations.attrs;

    if (emittedDateValidation.isInvalid) {
      this.isEmittedDateError = true;
    }

    if (emitterNameValidation.isInvalid) {
      this.isEmitterNameError = true;
    }

    if (this.hasError) {
      return;
    }

    this.segment.track('checks_deposit_issuer_and_date_submitted');
    return (this.args as any).transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Check::Emitter': typeof FlowsCheckEmitterComponent;
  }
}
