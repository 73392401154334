/* import __COLOCATED_TEMPLATE__ from './consent.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask, waitForProperty, waitForQueue } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { BANK_CONNECTION_OUTCOME } from 'qonto/constants/connect';
import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface FlowsAccountsExternalImportConsentSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountsExternalImportConsentComponent extends Component<FlowsAccountsExternalImportConsentSignature> {
  lottiePlayer = LottiePlayer;

  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  containerId = `${guidFor(this)}-container`;

  constructor(owner: unknown, args: FlowsAccountsExternalImportConsentSignature['Args']) {
    super(owner, args);
    this.consentSetupTask.perform().catch(ignoreCancelation);
  }

  get shouldRedirectToConnectHub() {
    // @ts-expect-error
    return this.args.context.origin?.includes('connect-hub');
  }

  consentSetupTask = dropTask(async () => {
    await waitForQueue('afterRender');
    // @ts-expect-error
    await waitForProperty(this, 'router.currentRoute.queryParams');

    let { outcome } = this.router.currentRoute.queryParams;

    // @ts-expect-error
    if (Object.values(BANK_CONNECTION_OUTCOME).includes(outcome)) {
      // Redirect to proper outcome page when returning from webview
      // @ts-expect-error
      this.args.context.outcome = outcome;
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      this.scrollIntoView(this.containerId);
    }
  });

  @action
  // @ts-expect-error
  scrollIntoView(element) {
    scrollIntoView(element, { offsetTop: 32 });
  }

  @action
  onCancel() {
    this.segment.track('accounts_external-consent_cancel', {
      // @ts-expect-error
      bank: this.args.context.bank_name,
    });

    this.resetContext();

    if (!this.shouldRedirectToConnectHub) {
      // @ts-expect-error
      this.args.transitionToPrevious();
    }
  }

  resetContext() {
    // @ts-expect-error
    this.args.context.bank_id = null;
    // @ts-expect-error
    this.args.context.bank_name = null;
    // @ts-expect-error
    this.args.context.bank_logo = null;
    // @ts-expect-error
    this.args.context.bank_slug = null;
  }

  redirectToWebview = dropTask(async () => {
    // @ts-expect-error
    let { origin, bank_id, bank_name } = this.args.context;

    let name = 'external-account-import';
    let successURL = this.flowLinkManager.urlFor({
      name,
      stepId: 'consent',
      queryParams: {
        outcome: BANK_CONNECTION_OUTCOME.SUCCESS,
        origin,
      },
    });
    let errorURL = this.flowLinkManager.urlFor({
      name,
      stepId: 'consent',
      queryParams: {
        outcome: BANK_CONNECTION_OUTCOME.ERROR,
        origin,
      },
    });
    let data = {
      language: this.localeManager.locale,
      bank_id,
      success_url: `${window.location.origin}${successURL}`,
      error_url: `${window.location.origin}${errorURL}`,
    };

    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${apiNamespace}/account_aggregation/accounts/connect`,
        { method: 'POST', data }
      );

      this.segment.track('accounts_external-consent_allow', {
        bank: bank_name,
      });

      window.location.href = response.webview_url;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Accounts::External::Import::Consent': typeof FlowsAccountsExternalImportConsentComponent;
  }
}
