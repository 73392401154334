/* import __COLOCATED_TEMPLATE__ from './italian-province-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

// @ts-expect-error
import italianProvinces from 'qonto/constants/it-provinces';

interface ItalianProvinceSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ItalianProvinceSelector extends Component<ItalianProvinceSelectorSignature> {
  @service declare intl: Services['intl'];

  @cached
  get provinceOptions() {
    // @ts-expect-error
    return italianProvinces.map(key => ({
      key,
      label: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  get selectedProvinceOption() {
    // @ts-expect-error
    let { provinceCode } = this.args.eInvoiceActivation;

    // @ts-expect-error
    return this.provinceOptions.find(({ key }) => key === provinceCode);
  }

  @action
  // @ts-expect-error
  handleProvinceChange(provinceObject) {
    // @ts-expect-error
    this.args.eInvoiceActivation.provinceCode = provinceObject?.key;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::CompanyDetails::ItalianProvinceSelector': typeof ItalianProvinceSelector;
  }
}
