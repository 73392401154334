export default {
  "wrapper": "Qk",
  "core-information": "QJ",
  "iban": "Qs",
  "bic": "QF",
  "full-line": "Qg",
  "secondary-information": "QD",
  "title": "QV",
  "rib": "QL",
  "rib-copy": "QT",
  "flex-column": "Qz",
  "label": "Qa body-1",
  "value": "QH body-2",
  "right-spaced": "QO",
  "name": "Qm title-3",
  "disclaimer": "Qp"
};
