/* import __COLOCATED_TEMPLATE__ from './eligible-teams.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { compare } from '@ember/utils';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface BudgetsTeamsProviderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BudgetsTeamsProvider extends Component<BudgetsTeamsProviderSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];

  constructor(owner: unknown, args: BudgetsTeamsProviderSignature['Args']) {
    super(owner, args);
    this.fetchTeamsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      });
  }

  fetchTeamsTask = task(async () => {
    // @ts-expect-error
    let result = await this.store.adapterFor('budget').getEligibleTeams();

    // @ts-expect-error
    let eligibleTeamsIds = result.teams.map(team => team.id);

    let { organization } = this.organizationManager;

    await this.organizationManager.organization.loadTeams();

    let { teams } = this.organizationManager.organization;

    // @ts-expect-error
    let eligibleTeams = teams.filter(team => eligibleTeamsIds.includes(team.id));

    let managerIdsToLoad = eligibleTeams.reduce(
      // @ts-expect-error
      (acc, team) => acc.concat(team.hasMany('teamManagers').ids()),
      []
    );

    let memberships = [];
    if (managerIdsToLoad.length > 0) {
      // @ts-expect-error
      memberships = await this.store.query('membership', {
        organization_id: organization.id,
        filters: { ids: managerIdsToLoad },
        per_page: managerIdsToLoad.length,
      });
    }

    return (
      eligibleTeams
        // @ts-expect-error
        .sort((a, b) => compare(a.name, b.name))
        // @ts-expect-error
        .map(team => ({
          team,
          memberships: memberships.filter(
            membership => membership.belongsTo('team').id() === team.id
          ),
        }))
    );
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::Providers::EligibleTeams': typeof BudgetsTeamsProvider;
  }
}
