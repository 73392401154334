/* import __COLOCATED_TEMPLATE__ from './success-italy.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SuccessItalySignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SuccessItaly = templateOnlyComponent<SuccessItalySignature>();

export default SuccessItaly;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Trial::SuccessItaly': typeof SuccessItaly;
  }
}
