export default {
  "container": "Yr",
  "disabled-option": "Yb",
  "content": "Yy",
  "spinner": "YU",
  "subtitle": "Yj",
  "details-table": "YW",
  "caption": "Yu",
  "tooltip-icon": "hS",
  "tooltip-icon-right": "hc",
  "card-monthly-cost": "hq",
  "card-monthly-discounted-cost": "hZ",
  "strikethrough": "hR",
  "purple-text": "hQ",
  "budget-label": "he",
  "card-cost-label": "hB",
  "card-billing-account": "hE"
};
