export default {
  "wrapper": "uR",
  "confirm": "uQ",
  "plan-info": "ue",
  "plan-info-left": "uB",
  "info-title": "uE body-1",
  "initial-trial-content": "ud",
  "extra-fees-item": "uI body-2",
  "extra-fees-container": "ut",
  "bottom-wrapper": "uM",
  "total-price-container": "uP",
  "extra-fees-element": "ul body-1",
  "border": "uX",
  "subtitle": "uC body-2",
  "group-item": "uk",
  "amount": "uJ",
  "subscription-billing-summary": "us",
  "summary": "uF",
  "summary-list": "ug",
  "disclaimer": "uD",
  "disclaimer-revamp": "uV",
  "error": "uL",
  "error-link": "uT",
  "warning-wrapper": "uz",
  "warning-label": "ua body-1"
};
