/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

interface FlowsMandateReviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsMandateReviewComponent extends Component<FlowsMandateReviewSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @reads('args.context.mandate') model;
  // @ts-expect-error
  @reads('organizationManager.organization.hasMultipleActiveAccounts') showAccount;

  get recurrentLabel() {
    let recurrentType = this.model.recurrent ? 'recurrent' : 'single';
    let translationLookup = {
      single: 'mandates.new.type.single',
      recurrent: 'mandates.new.type.recurrent',
    };

    // @ts-expect-error
    return this.intl.t(translationLookup[recurrentType]);
  }

  saveNewMandateTask = dropTask(async () => {
    let mandate = this.model;
    this.segment.track('app_sdd_2FA_confirmed');

    await mandate
      .save()
      .then(() => {
        this.segment.track('app_sdd_creation_completed');
        // @ts-expect-error
        this.args.transitionToNext();
      })
      // @ts-expect-error
      .catch(error => {
        if (hasMFAError(error?.errors)) {
          throw error;
        }

        let message = '';
        if (error instanceof InvalidError) {
          // @ts-expect-error
          let { code, detail } = error.errors[0].detail;
          if (code === 'invalid_format') {
            message = this.intl.t('mandates.errors.creditor-identifier-invalid-format');
          } else if (code === 'umr_taken') {
            message = this.intl.t('mandates.errors.umr-taken');
          } else {
            message = detail;
          }
        } else {
          message = this.intl.t('errors.unexpected_error_occured');
        }
        this.toastFlashMessages.toastError(message);
      });
  });

  confirmMandateTask = dropTask(async () => {
    this.segment.track('app_sdd_details_reviewed', {
      // @ts-expect-error
      account_name: this.args.context.mandate.bankAccount.name,
    });
    await this.saveNewMandateTask.perform();
  });

  @action
  handleEditRedirect() {
    // @ts-expect-error
    this.args.backToStep('upload');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Mandate::Review': typeof FlowsMandateReviewComponent;
  }
}
