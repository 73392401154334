/* import __COLOCATED_TEMPLATE__ from './period-field.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PeriodFieldSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PeriodField = templateOnlyComponent<PeriodFieldSignature>();

export default PeriodField;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::PeriodField': typeof PeriodField;
  }
}
