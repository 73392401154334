/* import __COLOCATED_TEMPLATE__ from './beneficiary-form-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { dropTask, restartableTask, task, timeout, waitForProperty } from 'ember-concurrency';

import CURRENCIES from 'qonto/constants/currencies';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
// @ts-expect-error
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';

const SWITCH_DELAY = 600;

interface BeneficiaryFormModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BeneficiaryFormModal extends Component<BeneficiaryFormModalSignature> {
  spinner = Spinner;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare abilities: Services['abilities'];
  @service declare segment: Services['segment'];

  @tracked beneficiary = null;
  @tracked initialLabelsList = null;
  @tracked existingBeneficiary = null;
  @tracked isBeneficiaryUpdate = false;

  constructor(owner: unknown, args: BeneficiaryFormModalSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let { beneficiary } = this.args.data;

    this.beneficiary = beneficiary || this._createBeneficiaryRecord();
    // @ts-expect-error
    this.initialLabelsList = [...this.beneficiary.labels];
    this.isBeneficiaryUpdate = Boolean(beneficiary);
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    this.rollbackAttributes();
  }

  get isErrorShown() {
    return this.saveBeneficiaryTask.performCount > 0;
  }

  get isNameInvalid() {
    // @ts-expect-error
    return this.isErrorShown && this.beneficiary.validations.attrs.name.isInvalid;
  }

  get isIbanInvalid() {
    // @ts-expect-error
    return this.isErrorShown && this.beneficiary.validations.attrs.iban.isInvalid;
  }

  get isActivityTagInvalid() {
    // @ts-expect-error
    return this.isErrorShown && this.beneficiary.validations.attrs.activityTag.isInvalid;
  }

  get isEmailInvalid() {
    // @ts-expect-error
    return this.isErrorShown && this.beneficiary.validations.attrs.email.isInvalid;
  }

  get isSwitchingFormType() {
    return (
      this.switchToAddBeneficiaryTask.isRunning || this.switchToUpdateBeneficiaryTask.isRunning
    );
  }

  get confirmClickEventName() {
    return this.isBeneficiaryUpdate
      ? 'transfer_rolodex_save_beneficiary_confirmed'
      : 'transfer_rolodex_create_beneficiary_confirmed';
  }

  get hasDirtyLabels() {
    // @ts-expect-error
    if (this.initialLabelsList.length !== this.beneficiary.labels.length) {
      return true;
    }

    // @ts-expect-error
    return !this.initialLabelsList.every(({ id }) =>
      // @ts-expect-error
      this.beneficiary.labels.some(l => l.id === id)
    );
  }

  get saveDisabled() {
    let {
      switchToAddBeneficiaryTask,
      switchToUpdateBeneficiaryTask,
      isBeneficiaryUpdate: isEdition,
      beneficiary,
      hasDirtyLabels,
    } = this;

    let tasksRunning =
      switchToAddBeneficiaryTask.isRunning || switchToUpdateBeneficiaryTask.isRunning;
    // @ts-expect-error
    let hasChangesToSave = !isEdition || beneficiary.hasDirtyAttributes || hasDirtyLabels;

    return tasksRunning || !hasChangesToSave;
  }

  @action
  triggerTrackingEvents() {
    if (this.abilities.can('view bookkeeping')) {
      this.segment.track(this.confirmClickEventName, {
        // @ts-expect-error
        vat_rate_selected: this.beneficiary.vatRate === null ? false : true,
        // @ts-expect-error
        custom_labels_added: this.beneficiary.labels.length > 0,
      });
    } else {
      this.segment.track(this.confirmClickEventName);
    }
  }

  rollbackAttributes() {
    // @ts-expect-error
    if (!(this.beneficiary.isDestroyed || this.beneficiary.isDestroying)) {
      // @ts-expect-error
      this.beneficiary.rollbackAttributes();

      let { initialLabelsList, hasDirtyLabels } = this;

      if (hasDirtyLabels) {
        // @ts-expect-error
        this.beneficiary.set('labels', initialLabelsList);
      }
    }
  }

  @action
  // @ts-expect-error
  updateBeneficiary(attribute, value) {
    if (value === '') {
      // @ts-expect-error
      this.beneficiary[attribute] = null;
    } else {
      // @ts-expect-error
      this.beneficiary[attribute] = value;
    }
  }

  @action
  // @ts-expect-error
  updateVatRate(beneficiary, { vatRate }) {
    beneficiary.vatRate = vatRate;
  }

  @action
  // @ts-expect-error
  saveLabels(labelList, label) {
    // @ts-expect-error
    this.beneficiary.labels = getLabelsToUpdate(this.beneficiary.labels, labelList, label);
  }

  onIbanChangeTask = restartableTask(async value => {
    // @ts-expect-error
    this.beneficiary.iban = value;

    await timeout(DEBOUNCE_MS);
    await this.checkExistingIbanTask.perform(value);
  });

  switchToAddBeneficiaryTask = task(async () => {
    this.isBeneficiaryUpdate = false;
    await timeout(SWITCH_DELAY);

    // @ts-expect-error
    let { activityTag, email, name } = this.beneficiary;

    // @ts-expect-error
    this.beneficiary = this._createBeneficiaryRecord({ activityTag, email, name });
  });

  switchToUpdateBeneficiaryTask = task(async () => {
    this.isBeneficiaryUpdate = true;
    await timeout(SWITCH_DELAY);
    this.beneficiary = this.existingBeneficiary;
    this.existingBeneficiary = null;
  });

  saveBeneficiaryTask = dropTask(async () => {
    this.triggerTrackingEvents();

    try {
      if (await this.canSaveBeneficiaryTask.linked().perform()) {
        // @ts-expect-error
        let beneficiary = await this.beneficiary.save();
        // @ts-expect-error
        this.initialLabelsList = [...beneficiary.labels];
        // @ts-expect-error
        this.args.close();

        let message = this.isBeneficiaryUpdate
          ? this.intl.t('transfers.beneficiaries.edit-modal.success')
          : this.intl.t('transfers.beneficiaries.add-modal.success');

        this.toastFlashMessages.toastInfo(message);

        // @ts-expect-error
        this.args.data.refreshBeneficiariesList?.();
      }
    } catch (error) {
      // @ts-expect-error
      await this.handleBeneficiarySaveError(error);
    }
  });

  canSaveBeneficiaryTask = dropTask(async () => {
    // @ts-expect-error
    if (!this.beneficiary.validations.isValid) {
      return false;
    }

    if (!this.isBeneficiaryUpdate) {
      // BE validation for IBAN, throws exception if invalid
      // @ts-expect-error
      await this.beneficiary.confirm();
    }

    // make sure existing IBAN validation has run
    // @ts-expect-error
    await waitForProperty(this, 'checkExistingIbanTask.isIdle');
    return !this.existingBeneficiary;
  });

  async handleBeneficiarySaveError() {
    // check for ds errors on model returned from BE
    // @ts-expect-error
    await this.beneficiary.validate();
    // @ts-expect-error
    if (this.beneficiary.validations.isValid) {
      // if no model validation errors then show generic toast message
      this.toastFlashMessages.toastError(this.intl.t('transfers.errors.add-edit-beneficiary'));
    }
  }

  checkExistingIbanTask = dropTask(async value => {
    try {
      // @ts-expect-error
      let { transfer } = this.args.data;

      // we request the beneficiaries by IBAN which cannot be duplicated between several beneficiaries
      // so we always get an array with only one element or an empty array
      let beneficiaries = await this.store.query('beneficiary', {
        iban: value,
        organization_id: transfer.bankAccount.organization.id,
      });

      // @ts-expect-error
      this.existingBeneficiary = beneficiaries[0];
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('transfers.errors.add-edit-beneficiary'));
    }
  });

  _createBeneficiaryRecord(options = {}) {
    // @ts-expect-error
    let { transfer } = this.args.data;

    return this.store.createRecord('beneficiary', {
      currency: CURRENCIES.default,
      vatRate: null,
      organization: transfer.bankAccount.organization,
      ...options,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BeneficiaryFormModal: typeof BeneficiaryFormModal;
  }
}
