/* import __COLOCATED_TEMPLATE__ from './user-info.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DateField, RadioButtonControlled } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const MEMBERSHIP_FIELDS = ['nationality'];

const USER_FIELDS = ['gender', 'birthCountry', 'birthCity', 'phoneNumber', 'birthdate'];

interface KYCUserInfoControllerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class KYCUserInfoController extends Component<KYCUserInfoControllerSignature> {
  dateField = DateField;
  radioButtonControlled = RadioButtonControlled;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare userManager: Services['userManager'];
  @service declare router: Services['router'];
  @service declare homePage: Services['homePage'];
  @service declare sentry: Services['sentry'];

  @tracked enableValidation = false;

  get membership() {
    return this.organizationManager.membership;
  }

  get currentUser() {
    return this.userManager.currentUser;
  }

  get userBirthday() {
    // @ts-expect-error
    let birthdate = this.currentUser?.birthdate;
    return birthdate ? parseDate(birthdate) : birthdate;
  }

  get userHasBirthdate() {
    // @ts-expect-error
    return this.currentUser.birthdate && !this.currentUser.changedAttributes()['birthdate'];
  }

  confirmTask = dropTask(async () => {
    let { validations: membershipValidation } = await this.membership.validate({
      on: MEMBERSHIP_FIELDS,
    });

    // @ts-expect-error
    let { validations: userValidation } = await this.currentUser.validate({
      on: USER_FIELDS,
    });

    if (membershipValidation.isInvalid || userValidation.isInvalid) {
      this.enableValidation = true;
      return;
    }

    try {
      // @ts-expect-error
      await this.currentUser.save();
      // @ts-expect-error
      await this.membership.submitKYC(this.args.context.documents);

      this.router.transitionTo('kyc.start', {
        queryParams: {
          trackingEventOrigin: 'card_flow',
        },
      });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  // @ts-expect-error
  updateBirthdate(value) {
    if (value) {
      // @ts-expect-error
      this.currentUser.birthdate = value.toString();
    }
  }

  @action
  // @ts-expect-error
  handleGenderSelection(gender) {
    // @ts-expect-error
    this.currentUser.gender = gender;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Kyc::UserInfo': typeof KYCUserInfoController;
  }
}
