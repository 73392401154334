export default {
  "container": "vL",
  "company-details": "vT",
  "sideview": "vz",
  "form": "va",
  "french-company-details-fieldset": "vH",
  "footer": "vO",
  "pdf-preview": "vm",
  "de-layout": "vp",
  "de-pdf-preview": "vx",
  "german-preview": "vw"
};
