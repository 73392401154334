export default {
  "not-active": "BX",
  "title": "BC",
  "details": "Bk",
  "detail": "BJ",
  "text-secondary": "Bs",
  "text-primary-a": "BF",
  "amounts": "Bg",
  "contents-container": "BD",
  "contents": "BV",
  "menu-item": "BL",
  "archive": "BT",
  "amount-settled": "Bz",
  "shadow": "Ba",
  "name": "BH title-4",
  "tooltip": "BO"
};
