/* import __COLOCATED_TEMPLATE__ from './voucher-information-modal.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeHighlight } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface VoucherInformationModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class VoucherInformationModalComponent extends Component<VoucherInformationModalSignature> {
  badgeHighlight = BadgeHighlight;

  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @tracked pricePlanCondition;

  constructor(owner: unknown, args: VoucherInformationModalSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    if (this.args.data.voucherPricePlanIds?.length) {
      this.setPricePlanConditionTask.perform().catch(ignoreCancelation);
    }
  }

  get conditions() {
    // @ts-expect-error
    let { conditions = [] } = this.args.data;
    return [...conditions, this.minimumNumberCondition, this.pricePlanCondition].filter(Boolean);
  }

  get minimumNumberCondition() {
    // @ts-expect-error
    if (this.args.data.minimumRequiredActiveUsersCount) {
      return this.intl.t('invitation-flow.recap.modal.eligibility-criteria.item-1', {
        // @ts-expect-error
        minimumActiveUsers: this.args.data.minimumRequiredActiveUsersCount,
      });
    }
  }

  setPricePlanConditionTask = task(async () => {
    // @ts-expect-error
    let { voucherPricePlanIds } = this.args.data;

    if (voucherPricePlanIds.length === 1) {
      let { localName: planName } = await this.store.findRecord(
        'subscription-product',
        voucherPricePlanIds[0]
      );
      this.pricePlanCondition = this.intl.t(
        'invitation-flow.recap.modal.eligibility-criteria.item-2-single',
        { planName }
      );
    } else {
      let allPricePlans = await this.store.query('subscription-product', { type: 'core' });
      let planNames = voucherPricePlanIds
        // @ts-expect-error
        .map(pricePlanId => allPricePlans.find(({ id }) => id === pricePlanId)?.localName)
        .filter(Boolean)
        .join(', ');

      this.pricePlanCondition = this.intl.t(
        'invitation-flow.recap.modal.eligibility-criteria.item-2-multiple',
        { planNames }
      );
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::Review::VoucherInformationModal': typeof VoucherInformationModalComponent;
  }
}
