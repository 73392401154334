/* import __COLOCATED_TEMPLATE__ from './percentage-input.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { setCaretPosition } from 'qonto/utils/format-input';

interface PercentageInputSignature {
  // The arguments accepted by the component
  Args: {
    isOptional?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PercentageInput extends Component<PercentageInputSignature> {
  // @ts-expect-error
  argOrDefault(arg, defaultValue) {
    if (Object.keys(this.args).includes(arg)) {
      // @ts-expect-error
      return this.args[arg];
    }
    return defaultValue;
  }

  get numberOfDecimal() {
    return this.argOrDefault('numberOfDecimal', 2);
  }

  get placeholder() {
    return this.argOrDefault('placeholder', '0');
  }

  @action
  // @ts-expect-error
  onInput(e) {
    let { value } = e.target;
    let newValue = value.replace(/[^0-9.,]/g, '');
    setCaretPosition(e.target, value, newValue);
    // @ts-expect-error
    this.args.onInput?.(newValue);
  }

  @action
  // @ts-expect-error
  onChange(e) {
    let { value } = e.target;
    if (value) {
      let cappedValue = this._validateBoundaries(value.replace(/[,.]/g, '.'));
      let precisionFactor = Math.pow(10, this.numberOfDecimal);
      let roundedValue =
        Math.round(parseFloat(cappedValue) * precisionFactor * (1 + Number.EPSILON)) /
        precisionFactor;

      let normalizedValue = (roundedValue || 0).toFixed(this.numberOfDecimal);

      // for invoicing document the vat will display decimal only if different than 0
      // @ts-expect-error
      if (this.args.origin === 'invoicing-form') {
        normalizedValue = String(roundedValue || 0);
      }

      setCaretPosition(e.target, value, normalizedValue);
      // @ts-expect-error
      this.args.onChange?.(normalizedValue);
    }
  }

  // @ts-expect-error
  _validateBoundaries(value) {
    // @ts-expect-error
    if (this.args.max && value > this.args.max) {
      // @ts-expect-error
      return this.args.max;
    }
    // @ts-expect-error
    if (this.args.min && value < this.args.min) {
      // @ts-expect-error
      return this.args.min;
    }

    return value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::PercentageInput': typeof PercentageInput;
  }
}
