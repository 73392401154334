/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { AmountField, DatePicker, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsDirectDebitCollectionsSubscriptionDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsDirectDebitCollectionsSubscriptionDetailsComponent extends Component<FlowsDirectDebitCollectionsSubscriptionDetailsSignature> {
  amountField = AmountField;
  datePicker = DatePicker;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare directDebitCollectionsManager: Services['directDebitCollectionsManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];

  @tracked isValidationEnabled = false;
  @tracked dateValidationIsInvalid = false;
  @tracked dateValidationMessage = null;
  @tracked mandates = [];

  constructor(
    owner: unknown,
    args: FlowsDirectDebitCollectionsSubscriptionDetailsSignature['Args']
  ) {
    super(owner, args);

    this.directDebitCollectionsManager
      .loadMandates(this.subscription.client.id)
      .then(mandates => {
        if (mandates.length) {
          this.mandates = mandates;
        }

        // @ts-expect-error
        if (this.args.context.useExistingMandate) {
          this.subscription.mandate = this.mandates[0];
        }
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  // @ts-expect-error
  _parseDate(date) {
    let parsedDate = dayjs(date);
    return parsedDate.isValid() ? parseDate(parsedDate.format(DATE_PICKER_FIELD_FORMAT)) : null;
  }

  customDateValidationMessages = {
    // @ts-expect-error
    valueMissing: this.intl.t('validations.errors.blank'),
    // @ts-expect-error
    rangeUnderflow: this.intl.t('transfers.multi.datepicker.error.minimum-date', {
      // @ts-expect-error
      date: this.intl.formatDate(new Date(), {
        dateStyle: 'short',
        // @ts-expect-error
        locale: this.intl.primaryLocale === 'en' ? 'en-GB' : this.intl.primaryLocale, // ensure DMY format
      }),
    }),
  };

  get dateValue() {
    return this._parseDate(this.subscription.initialCollectionDate);
  }

  get minDate() {
    let tomorrow = new Date().setDate(new Date().getDate() + 1);
    return this._parseDate(tomorrow);
  }

  get subscription() {
    // @ts-expect-error
    return this.args.context.directDebitSubscription;
  }

  get bankAccounts() {
    return this.organizationManager.organization.bankAccounts;
  }

  @action
  // @ts-expect-error
  onAmountChange(amountValue) {
    this.subscription.setProperties({
      amount: { ...this.subscription.amount, value: amountValue },
    });
  }

  @action
  // @ts-expect-error
  onUpdateInitialCollectionDate(initialCollectionDate, isInvalid) {
    this.dateValidationIsInvalid = isInvalid;
    this.subscription.initialCollectionDate = initialCollectionDate;
  }

  @action
  // @ts-expect-error
  onUpdateFrequency({ scheduleType, scheduleCustomInterval, scheduleCustomUnit }) {
    this.subscription.setProperties({ scheduleType, scheduleCustomInterval, scheduleCustomUnit });
  }

  @action
  // @ts-expect-error
  onUpdateDuration({ scheduleTotalCollectionCount, isCustomDuration }) {
    this.subscription.setProperties({ scheduleTotalCollectionCount, isCustomDuration });
  }

  @action
  // @ts-expect-error
  onSelectMandate(mandate) {
    this.subscription.mandate = mandate;
    // @ts-expect-error
    this.args.context.useExistingMandate = Boolean(mandate);
  }

  @action
  onCTAClick() {
    this.isValidationEnabled = true;

    this.subscription.validate({}, false);

    if (this.subscription.validations.isValid && !this.dateValidationIsInvalid) {
      // @ts-expect-error
      this.args.transitionToNext();

      let { isOneOff, isRecurring, scheduleType, scheduleTotalCollectionCount } = this.subscription;

      this.segment.track('incoming-direct-debit-flow_collection-details_submitted', {
        collection_type: isOneOff ? 'one_off' : 'recurring',
        ...(isRecurring && { frequency: scheduleType }),
        ...(isRecurring && {
          duration: scheduleTotalCollectionCount ? 'number_payments' : 'no_limit',
        }),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::DirectDebitCollections::Subscription::Details': typeof FlowsDirectDebitCollectionsSubscriptionDetailsComponent;
  }
}
