/* import __COLOCATED_TEMPLATE__ from './de-freelancers-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import {
  EINZELUNTERNEHMER_LEGAL_CODE,
  FREIBERUFLER_LEGAL_CODE,
} from 'qonto/constants/company-types';

const DEFAULT_FILES_LIMIT = 1;

interface FlexKybDeFreelancersFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlexKybDeFreelancersFormComponent extends Component<FlexKybDeFreelancersFormSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  @service declare intl: Services['intl'];

  fileMaxFileSize = 30 * 1e6; // 30MB
  filesLimit = DEFAULT_FILES_LIMIT;

  files = new TrackedArray([]);

  @tracked legalNumber = null;
  @tracked legalNumberRequiredError = null;
  @tracked showFileRequiredError = false;

  constructor(owner: unknown, args: FlexKybDeFreelancersFormSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let { legalNumber } = this.args;
    if (legalNumber) {
      this.legalNumber = legalNumber;
    }
  }

  get fileExtensions() {
    return '.pdf,.jpg,.jpeg,.png';
  }

  get fileDropzoneLabel() {
    return this.intl.t('labels.upload-single-file-message', {
      maxSize: formatFileSize(this.intl, this.fileMaxFileSize),
    });
  }

  get fileLabel() {
    // @ts-expect-error
    let { legalCode } = this.args;
    if (legalCode === EINZELUNTERNEHMER_LEGAL_CODE) {
      return this.intl.t(
        'flex-kyb.de-freelancers-form.registration-certificate.einzelunternehmer.label',
        {
          htmlSafe: true,
        }
      );
    } else if (legalCode === FREIBERUFLER_LEGAL_CODE) {
      return this.intl.t(
        'flex-kyb.de-freelancers-form.registration-certificate.freiberufler.label',
        {
          htmlSafe: true,
        }
      );
    }
  }

  @action
  // @ts-expect-error
  handleLegalNumberUpdate(legalNumber) {
    this.legalNumberRequiredError = null;
    this.legalNumber = legalNumber;
    this.handleFormChange();
  }

  @action
  // @ts-expect-error
  handleFileUpload(file) {
    this.showFileRequiredError = false;
    // @ts-expect-error
    this.files.push(file);
    this.handleFormChange();
  }

  @action
  // @ts-expect-error
  handleDeleteFile(file) {
    file.deleteRecord();
    // @ts-expect-error
    this.files = this.files.filter(f => f !== file);
    this.handleFormChange();
  }

  @action
  handleFormChange() {
    // @ts-expect-error
    this.args.onChange?.({ legalNumber: this.legalNumber, files: this.files });
  }

  submitTask = dropTask(async () => {
    if (!this.legalNumber) {
      // @ts-expect-error
      this.legalNumberRequiredError = this.intl.t(
        'flex-kyb.de-freelancers-form.legal-number.required-error-message'
      );
    }
    if (!this.files.length) {
      this.showFileRequiredError = true;
    }

    if (!this.legalNumber || !this.files.length) {
      return;
    }

    // @ts-expect-error
    let { onFormSubmitTask } = this.args;
    await onFormSubmitTask.perform({
      legalNumber: this.legalNumber,
      // @ts-expect-error
      fileId: this.files[0].id,
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FlexKyb::DeFreelancersForm': typeof FlexKybDeFreelancersFormComponent;
  }
}
