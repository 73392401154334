/* import __COLOCATED_TEMPLATE__ from './stakeholders-list.hbs'; */
import { ForbiddenError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { map } from 'macro-decorators';

import colorSelector from 'qonto/utils/color-selector';

interface CapitalDepositLegalEntityStakeholdersListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositLegalEntityStakeholdersListComponent extends Component<CapitalDepositLegalEntityStakeholdersListSignature> {
  @service declare store: Services['store'];
  @service declare capitalIncreaseManager: Services['capitalIncreaseManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];

  @map('args.context.stakeholders', function (stakeholder) {
    let colorStyle = htmlSafe(`background-color: ${colorSelector(stakeholder.email)}`);
    return {
      colorStyle,
      stakeholder,
    };
  })
  // @ts-expect-error
  stakeholders;

  chooseStakeholderTask = dropTask(async selectedStakeholder => {
    let stakeholder = selectedStakeholder ?? this.createStakeholder();
    // @ts-expect-error
    this.args.context.stakeholder = stakeholder;

    if (!stakeholder.isNew) {
      try {
        // @ts-expect-error
        await this.args.context.saveLegalEntityTask.linked().perform(this.args.context);
        // @ts-expect-error
        this.args.transitionToNext();
      } catch (error) {
        if (error instanceof ForbiddenError) {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          this.sentry.captureException(error);
        }
      }
    } else {
      // @ts-expect-error
      this.args.transitionToNext();
    }
  });

  @action
  createStakeholder() {
    return this.store.createRecord('stakeholder', {
      organization: this.capitalIncreaseManager.organization,
      address: this.store.createRecord('address'),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CapitalDeposit::LegalEntity::StakeholdersList': typeof CapitalDepositLegalEntityStakeholdersListComponent;
  }
}
