/* import __COLOCATED_TEMPLATE__ from './contribution.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface CapitalDepositStakeholderContributionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositStakeholderContributionComponent extends Component<CapitalDepositStakeholderContributionSignature> {
  amountField = AmountField;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  @tracked showError = false;

  submitTask = dropTask(async stakeholder => {
    let { validations } = await stakeholder.validate({ on: ['capitalAmount'] });
    this.showError = true;

    if (validations.isValid) {
      try {
        this.showError = false;
        // @ts-expect-error
        await this.args.context.saveStakeholderTask.perform(stakeholder);
        this.segment.track('kdeposit_owner_capital_shares_added');
        // @ts-expect-error
        this.args.transitionToNext();
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  @action
  // @ts-expect-error
  updateCapitalAmount(value) {
    this.showError = false;
    // @ts-expect-error
    this.args.context.stakeholder.capitalAmount = parseFloat(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CapitalDeposit::Stakeholder::Contribution': typeof CapitalDepositStakeholderContributionComponent;
  }
}
