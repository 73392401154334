export default {
  "card": "Br",
  "header": "Bb",
  "budget-caption": "By caption",
  "amount-spent-section": "BU",
  "amount-spent-icon-container": "Bj",
  "amount-spent-title": "BW title-3",
  "amount-budgeted-section": "Bu",
  "amount-budgeted-title": "ES title-3"
};
