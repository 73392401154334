/* import __COLOCATED_TEMPLATE__ from './style-picker.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface GenerateLogoStylePickerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class GenerateLogoStylePickerComponent extends Component<GenerateLogoStylePickerSignature> {
  @service declare intl: Services['intl'];

  get styles() {
    return [
      {
        id: 'any',
        title: this.intl.t('generated-logo.modal.styles.options.any'),
        svg: '/illustrations/generate-logo/any.svg',
      },
      {
        id: 'minimalist',
        title: this.intl.t('generated-logo.modal.styles.options.minimalistic'),
        svg: '/illustrations/generate-logo/minimalist.svg',
      },
      {
        id: 'retro',
        title: this.intl.t('generated-logo.modal.styles.options.retro'),
        svg: '/illustrations/generate-logo/retro.svg',
      },
      {
        id: 'abstract',
        title: this.intl.t('generated-logo.modal.styles.options.abstract'),
        svg: '/illustrations/generate-logo/abstract.svg',
      },
      {
        id: 'playful',
        title: this.intl.t('generated-logo.modal.styles.options.playful'),
        svg: '/illustrations/generate-logo/playful.svg',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GenerateLogo::StylePicker': typeof GenerateLogoStylePickerComponent;
  }
}
