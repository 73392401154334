/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

interface SuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SuccessComponent extends Component<SuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: SuccessSignature['Args']) {
    super(owner, args);

    this.segment.track('onboarding_top_up_success');
    window.sessionStorage.removeItem('payment_activation_context');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::PaymentActivation::Success': typeof SuccessComponent;
  }
}
