export default {
  "sidebar": "kK",
  "sidebar-header": "kG",
  "close-button": "kr",
  "setup-mode-content": "kb",
  "edit-mode-content": "ky",
  "reminders-header": "kU",
  "reminders-title-container": "kj",
  "reminders-title": "kW",
  "reminders-subtitle": "ku",
  "reminders-list": "JS",
  "reminder": "Jc",
  "reminder-due-date": "Jq",
  "upsell-button-container": "JZ"
};
