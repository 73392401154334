export default {
  "cockpit-list": "VJ",
  "cockpit-item": "Vs",
  "cockpit-item-tile": "VF",
  "guarding-title": "Vg",
  "guarding-title-icon": "VD",
  "subtitle-link": "VV",
  "subtitle-wrapper": "VL",
  "subtitle-tooltip": "VT"
};
