/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { SearchField } from '@repo/design-system-kit';

import { COUNTERPARTIES_SORT_BYS } from 'qonto/constants/sort';

interface EmptyStatesSuppliersListDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesSuppliersListDummyDataComponent extends Component<EmptyStatesSuppliersListDummyDataSignature> {
  searchField = SearchField;

  sortBy = `${COUNTERPARTIES_SORT_BYS.NAME}:asc`;
  noop() {}
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::SuppliersList::DummyData': typeof EmptyStatesSuppliersListDummyDataComponent;
  }
}
