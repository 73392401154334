export default {
  "wrapper": "Lb",
  "header": "Ly",
  "status": "LU",
  "close-button": "Lj",
  "slug": "LW",
  "category": "Lu",
  "category-title": "TS",
  "activity-tag-select": "Tc",
  "footer": "Tq"
};
