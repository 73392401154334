/* import __COLOCATED_TEMPLATE__ from './success-page.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { ONBOARDING_TRACKING_EVENTS } from 'qonto/constants/receivable-invoice';

interface AccountReceivableOnboardingSuccessPageSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AccountReceivableOnboardingSuccessPage extends Component<AccountReceivableOnboardingSuccessPageSignature> {
  @service declare router: Services['router'];
  @service declare flow: Services['flow'];
  @service declare segment: Services['segment'];

  @action
  onCreateInvoice() {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.COMPLETE_CONFIRMED);
    this.router.transitionTo('receivable-invoices.new');
  }

  @action
  onClose() {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.COMPLETE_CONFIRMED);
    // @ts-expect-error
    if (this.flow.refererPage && this.flow.refererPage !== '/') {
      // @ts-expect-error
      return this.router.transitionTo(this.flow.refererPage);
    }
    return this.router.replaceWith('receivable-invoices.index');
  }

  get onboardingState() {
    // @ts-expect-error
    return this.args.context.onboardingState;
  }

  get organization() {
    return this.onboardingState.organization;
  }

  get exampleInvoice() {
    return {
      organization: {
        locale: this.organization.locale,
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        shortLegalForm: this.organization.shortLegalForm,
        legalCountry: this.organization.legalCountry,
        address: this.organization.address,
        contactEmail: this.organization.contactEmail,
        vatNumber: this.organization.vatNumber,
        taxNumber: this.organization.taxNumber,
      },
      bic: this.organization.mainAccount?.bic,
      iban: this.organization.mainAccount?.iban,
      beneficiaryName: this.organization.legalName,
    };
  }

  get logo() {
    return this.organization.isDefaultAvatar === false ? this.organization.picture : null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::SuccessPage': typeof AccountReceivableOnboardingSuccessPage;
  }
}
