/* import __COLOCATED_TEMPLATE__ from './labels.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LabelsSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Labels = templateOnlyComponent<LabelsSignature>();

export default Labels;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Sidebar::Labels': typeof Labels;
  }
}
