/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, LottiePlayer } from '@repo/design-system-kit';

import { CARD_LEVELS } from 'qonto/constants/cards';

interface FlowsRequestsCardsSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsRequestsCardsSuccessComponent extends Component<FlowsRequestsCardsSuccessSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  lottiePlayer = LottiePlayer;

  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];

  get displayKYCDisclaimer() {
    // @ts-expect-error
    let isFlash = this.args.context.cardLevel === CARD_LEVELS.FLASH;
    let { kycRefused } = this.organizationManager.membership;
    return kycRefused && isFlash;
  }

  get isVirtualCard() {
    // @ts-expect-error
    return this.args.context.cardLevel === CARD_LEVELS.VIRTUAL;
  }

  get isVirtualCardWithPendingKyc() {
    let { kycSubmitted, kycPending } = this.organizationManager.membership;

    return this.isVirtualCard && kycSubmitted && kycPending;
  }

  get title() {
    return this.isVirtualCardWithPendingKyc
      ? this.intl.t('kyc.on-demand.requests.success.title')
      : this.intl.t('requests.cards.steps.success.title');
  }

  get subtitle() {
    return this.isVirtualCardWithPendingKyc
      ? this.intl.t('kyc.on-demand.requests.success.text')
      : this.intl.t('requests.cards.steps.success.subtitle');
  }

  @action
  handleSuccessClick() {
    this.router.transitionTo('requests.landing', { queryParams: { origin: 'request_success' } });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Requests::Cards::Success': typeof FlowsRequestsCardsSuccessComponent;
  }
}
