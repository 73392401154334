/* import __COLOCATED_TEMPLATE__ from './others.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { ERROR_KEYS } from 'qonto/constants/f24';

const MAX_ITEMS_COUNT = 2;

const FIELD_NAME_KEYS = {
  institutionCode: 'f24.manual-declaration.module.others.field1',
  branchCode: 'f24.manual-declaration.module.others.field2',
  taxType: 'f24.manual-declaration.module.others.field3',
  positionCode: 'f24.manual-declaration.module.others.field4',
  referencePeriodFrom: 'f24.manual-declaration.module.others.field5',
  referencePeriodTo: 'f24.manual-declaration.module.others.field6',
  taxAmount: 'f24.manual-declaration.module.others.field7',
  compensationAmount: 'f24.manual-declaration.module.others.field8',
};

interface F24FormOthersSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class F24FormOthersComponent extends Component<F24FormOthersSignature> {
  fieldNameKeys = FIELD_NAME_KEYS;

  @action
  getErrorKey(code: keyof typeof ERROR_KEYS) {
    return ERROR_KEYS[code] ?? ERROR_KEYS.generic_error;
  }

  get isAddItemDisabled() {
    // @ts-expect-error
    return this.args.othersTax.taxList.length >= MAX_ITEMS_COUNT;
  }

  get totalTax() {
    return this.sumUp('taxAmount');
  }

  get totalCompensation() {
    return this.sumUp('compensationAmount');
  }

  get netTotal() {
    return Number(this.totalTax - this.totalCompensation).toFixed(2);
  }

  // @ts-expect-error
  sumUp(attributeName) {
    return (
      // @ts-expect-error
      this.args.othersTax?.taxList
        // @ts-expect-error
        .reduce((acc, item) => {
          let value = item[attributeName] || 0;
          return acc + Number(value);
        }, 0)
        .toFixed(2)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Form::Others': typeof F24FormOthersComponent;
  }
}
