export default {
  "upsell-cta": "yD",
  "discover-badge": "yV",
  "role-selection-item": "yL",
  "unavailable": "yT",
  "role-headline": "yz",
  "illustration": "ya",
  "role-description": "yH",
  "description-item": "yO",
  "description-icon": "ym",
  "description-text": "yp",
  "current-role": "yx",
  "icon-outlined": "yw"
};
