/* import __COLOCATED_TEMPLATE__ from './add-funds.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

interface AddFundsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AddFunds extends Component<AddFundsSignature> {
  amountField = AmountField;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('organization.activeSortedAccounts') activeSortedAccounts;

  @tracked amountErrorMessage = null;

  // @ts-expect-error
  @tracked accountToDebit = this.args.context.accountToDebit;
  @tracked amount = null;
  // @ts-expect-error
  @tracked accountToCredit = this.args.context.accountToCredit;

  get accountList() {
    // @ts-expect-error
    return this.activeSortedAccounts.filter(({ isRemunerated }) => !isRemunerated);
  }

  get selectedAccountBalance() {
    return this.accountToDebit.balance;
  }

  @action
  // @ts-expect-error
  handleSetAccount(account) {
    this.accountToDebit = account;

    this.validateAmount();
  }

  @action
  // @ts-expect-error
  handleUpdateAmount(value) {
    this.amount = value;
    this.validateAmount();
  }

  validateAmountExists() {
    if (!this.amount) {
      // @ts-expect-error
      this.amountErrorMessage = this.intl.t(
        'accounts.funding.remunerated.account.error.amount-empty'
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountAuthorizedBalance() {
    if (Number(this.amount) > this.selectedAccountBalance) {
      // @ts-expect-error
      this.amountErrorMessage = this.intl.t(
        'accounts.funding.remunerated.account.error.amount-above',
        { price: this._formatPrice(this.selectedAccountBalance) }
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountMinimum() {
    if (Number(this.amount) <= 0) {
      // @ts-expect-error
      this.amountErrorMessage = this.intl.t('validations.errors.transfer.amount');
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmount() {
    return (
      this.validateAmountExists() &&
      this.validateAmountAuthorizedBalance() &&
      this.validateAmountMinimum()
    );
  }

  // @ts-expect-error
  _formatPrice(price) {
    return this.intl.formatNumber(price, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }

  @action
  handleNext() {
    let validAmount = this.validateAmount();
    if (validAmount) {
      // @ts-expect-error
      this.args.context.accountToDebit = this.accountToDebit;
      // @ts-expect-error
      this.args.context.amount = this.amount;
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Accounts::Remunerated::Funding::AddFunds': typeof AddFunds;
  }
}
