/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

interface FlowsInvoicesProductsImportSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class FlowsInvoicesProductsImportSuccess extends Component<FlowsInvoicesProductsImportSuccessSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('args.context') context;

  button = Button;

  get importedProductsCount() {
    return this.context.xlsFile.rows.length;
  }

  @action
  goToImportClientsPrimaryCTA() {
    this.segment.track('items-import_success_clients-flow');

    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'invoice-clients-import',
      stepId: 'initial',
    });
  }

  @action
  goToProductList() {
    this.toastFlashMessages.toastInfo(
      this.intl.t('products.list.toasts.info.products-import', {
        count: this.importedProductsCount,
      })
    );
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Invoices::ProductsImport::Success': typeof FlowsInvoicesProductsImportSuccess;
  }
}
