/* import __COLOCATED_TEMPLATE__ from './amount-loading.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AmountLoadingSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AmountLoading = templateOnlyComponent<AmountLoadingSignature>();

export default AmountLoading;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::RequestMileage::RequestDetails::AmountLoading': typeof AmountLoading;
  }
}
