/* import __COLOCATED_TEMPLATE__ from './payment-collection.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { RadioButtonControlled, RadioGroup } from '@repo/design-system-kit';

interface DirectDebitCollectionsSubscriptionPaymentCollectionSignature {
  // The arguments accepted by the component
  Args: {
    isOneOff?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DirectDebitCollectionsSubscriptionPaymentCollectionComponent extends Component<DirectDebitCollectionsSubscriptionPaymentCollectionSignature> {
  radioButtonControlled = RadioButtonControlled;
  radioGroup = RadioGroup;

  @action
  // @ts-expect-error
  onChangePaymentOption(useExistingMandate) {
    // @ts-expect-error
    this.args.onSelectMandate(useExistingMandate ? this.args.mandates[0] : null);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Subscription::PaymentCollection': typeof DirectDebitCollectionsSubscriptionPaymentCollectionComponent;
  }
}
