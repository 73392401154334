export default {
  "container": "GY",
  "content": "Gh",
  "buttons": "Gf",
  "genders": "GK",
  "birth-details": "GG",
  "has-error": "Gr",
  "gender-option": "Gb",
  "radio-button": "Gy"
};
