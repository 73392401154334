/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { ErrorState } from '@repo/design-system-kit';

// @ts-expect-error
import { LAYOUT } from 'qonto/constants/empty-states/system';
import { Placeholder } from 'qonto/react/card-acquirer/components/payments/table/placeholder';

interface CardAcquirerPaymentsTableHeaderSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardAcquirerPaymentsTableHeader extends Component<CardAcquirerPaymentsTableHeaderSignature> {
  errorState = ErrorState;
  placeholder = Placeholder;

  @service declare intl: Services['intl'];

  @action
  // @ts-expect-error
  highlightItem(item) {
    // @ts-expect-error
    this.args.highlightItem(item);
  }

  get emptyStateOptions() {
    return {
      layout: LAYOUT.INFORM,
      title: this.intl.t('tap-to-pay.payments.empty-state.no-result.title'),
      subtitle: this.intl.t('tap-to-pay.payments.empty-state.no-result.subtitle'),
      lottieSrc: '/lotties/empty-state/search-no-result.json',
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardAcquirer::Payments::Table': typeof CardAcquirerPaymentsTableHeader;
  }
}
