export default {
  "wrapper": "Dy",
  "label": "DU",
  "input": "Dj",
  "icon-trash": "DW",
  "hidden": "Du",
  "icon-color": "VS",
  "icon-edit": "Vc",
  "icon-edit-save": "Vq",
  "button-container": "VZ"
};
