/* import __COLOCATED_TEMPLATE__ from './not-eligible-empty-state.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { EmptyStatesDiscover } from '@repo/domain-kit/pricing';

interface DirectDebitCollectionsNotEligibleEmptyStateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DirectDebitCollectionsNotEligibleEmptyState extends Component<DirectDebitCollectionsNotEligibleEmptyStateSignature> {
  emptyStatesDiscover = EmptyStatesDiscover;

  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: DirectDebitCollectionsNotEligibleEmptyStateSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let { emptyStateRevampOptions } = this.args;

    if (emptyStateRevampOptions) {
      let trackingData = emptyStateRevampOptions.tracking?.({
        isClickEvent: false,
        isEmptyState: true,
      });

      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::NotEligibleEmptyState': typeof DirectDebitCollectionsNotEligibleEmptyState;
  }
}
