/* import __COLOCATED_TEMPLATE__ from './iban.hbs'; */
import Component from '@glimmer/component';

import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';

interface IbanSignature {
  Element: null;
  Args: {
    isDisabled?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class Iban extends Component<IbanSignature> {
  CopyToClipboard = CopyToClipboard;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::Iban': typeof Iban;
  }
}
