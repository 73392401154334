/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from '@glimmer/component';

import { Slug } from 'qonto/react/components/transactions/sidebar/details/slug';

interface DirectDebitCollectionsSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DirectDebitCollectionsSidebarComponent extends Component<DirectDebitCollectionsSidebarSignature> {
  slug = Slug;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Sidebar': typeof DirectDebitCollectionsSidebarComponent;
  }
}
