/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error -- not typed
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Button } from '@repo/design-system-kit';
import { CheckoutHeader, SubscriptionHeader } from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error -- not typed
import { variation } from 'ember-launch-darkly';

import { Addons } from 'qonto/constants/addons';
import { apiBaseURL, billingNamespace } from 'qonto/constants/hosts';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import {
  AP_ADDON_MESSAGING_POPUP_FF,
  AP_ADDON_MESSAGING_POPUP_FF_MAP,
} from 'qonto/constants/supplier-invoice';
import type { Subscription } from 'qonto/models/organization-subscription';
import { ConfirmTotal } from 'qonto/react/components/flows/addon-change/confirm-total';
import { ConfirmUpgrade } from 'qonto/react/components/flows/addon-change/confirm-upgrade';
// @ts-expect-error -- not typed
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import { type ContextArg, type TargetSubscription, type TotalEstimate } from './dataContext.type';
export default class FlowsAddonChangeConfirmComponent extends Component<ContextArg> {
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  subscriptionHeader = SubscriptionHeader;
  checkoutHeader = CheckoutHeader;
  confirmTotal = ConfirmTotal;
  button = Button;
  confirmUpgrade = ConfirmUpgrade;

  get currentPlan() {
    return this.subscriptionManager?.currentPricePlan;
  }

  constructor() {
    // @ts-ignore
    super(...arguments);
    this.trackEventOnDisplay();
  }

  get addonIcon(): string {
    return `addon-${this.addon.product_code}-icon`;
  }

  get addon(): TargetSubscription {
    return this.args.context.targetAddon;
  }

  get pricePlan(): TargetSubscription | undefined {
    return this.args.context.targetPricePlan;
  }

  get hasInitialTrial(): boolean {
    return Boolean(this.subscriptionManager.currentSubscription?.hasInitialTrial);
  }

  get trialDate(): string {
    return this.intl.formatDate(
      this.isArAddonTrial
        ? this.subscriptionManager.currentSubscription?.arAddonTrial.endDate
        : this.subscriptionManager.currentSubscription?.activeTrial.end_date,
      {
        month: 'short',
        day: '2-digit',
      }
    );
  }

  get isUpsellOrUpgrade(): boolean {
    // TODO: remove this once the BE is ready
    return true;
  }

  get availableTrialEndDate(): string {
    return this.intl.formatDate(this.availableTrialForCurrentAddon?.endDate, {
      month: 'short',
      day: '2-digit',
    });
  }

  get total(): TotalEstimate {
    return this.args.context.total_estimate;
  }

  formatDateLong(date: Date): string {
    return this.intl.formatDate(date, { format: 'long' });
  }

  trackEventOnDisplay() {
    let { hasInsufficientFunds, targetPricePlan, tracking } = this.args.context;
    this.segment.track('checkout_initialized');
    this.subscriptionManager.setTrackingUserProperties();
    this.segment.track('checkout_displayed_success', {
      current_plan: tracking.current_plan,
      target_plan: tracking.target_plan,
      origin: tracking.origin,
      checkout_with_features_list: false,
      insufficient_funds: Boolean(hasInsufficientFunds),
      target_plan_recurrence: targetPricePlan?.recurrence || null,
      addon_code: tracking.addon_code,
      target_addon_recurrence: tracking.addon_recurrence,
    });
  }

  get addonTitle() {
    return this.intl.t('subscription.change.confirmation.addon.billing.title', {
      addonName: this.addon.productName,
      recurrence:
        this.addon.recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY
          ? this.intl.t('subscription.change.confirmation.addon.recurrence.monthly')
          : this.intl.t('subscription.change.confirmation.addon.recurrence.annually'),
    });
  }

  @action
  subtitle(
    recurrence: 'monthly' | 'annual',
    value: number,
    currency: string,
    isAddon = false
  ): string {
    if (this.isArAddonTrial && isAddon) {
      return recurrence === 'monthly'
        ? this.intl.t('subscription.change.confirmation.addon.trial.subtitle.month', {
            date: this.formatDateLong(
              this.subscriptionManager.currentSubscription?.arAddonTrial.endDate
            ),
            price: this.formattedPrice(value, currency),
          })
        : this.intl.t('subscription.change.confirmation.addon.trial.subtitle.annual', {
            date: this.formatDateLong(
              this.subscriptionManager.currentSubscription?.arAddonTrial.endDate
            ),
            price: this.formattedPrice(value, currency),
          });
    }
    if (this.hasInitialTrial || this.isAddonOnFreeTrial) {
      if (!isAddon && this.isAddonOnFreeTrial) {
        if (
          // We have just one case where addon is available for free trial and plan is not, when monthly plan free trial is canceled and is upsized to annual, and in this case plan amount will not be 0
          this.pricePlan?.prorated_amount?.value !== '0' ||
          // Orga is on Business 2023 -> Tries to buy addon available for free trial -> this requires switch to Business 2024 -> Addon will be free and Business 2024 plan will cost zero
          this.currentPlan.groupCode === this.pricePlan?.product_group_code
        ) {
          return recurrence === 'monthly'
            ? this.intl.t('subscription.change.confirmation.addon.billing.subtitle', {
                amount: this.formattedPrice(value, currency),
              })
            : this.intl.t('subscription.change.confirmation.subscription.upsizing.subtitle', {
                amount: this.formattedPrice(value, currency),
              });
        } else {
          return recurrence === 'monthly'
            ? this.intl.t(
                'subscription.change.confirmation.addon.billing.initial-trial.subtitle.monthly',
                { price: this.formattedPrice(value, currency) }
              )
            : this.intl.t(
                'subscription.change.confirmation.addon.billing.initial-trial.subtitle.annual',
                { price: this.formattedPrice(value, currency) }
              );
        }
      }
      return recurrence === 'monthly'
        ? this.intl.t(
            'subscription.change.confirmation.addon.billing.initial-trial.subtitle.monthly',
            { price: this.formattedPrice(value, currency) }
          )
        : this.intl.t(
            'subscription.change.confirmation.addon.billing.initial-trial.subtitle.annual',
            { price: this.formattedPrice(value, currency) }
          );
    }
    return recurrence === 'monthly'
      ? this.intl.t('subscription.change.confirmation.addon.billing.subtitle', {
          amount: this.formattedPrice(value, currency),
        })
      : this.intl.t('subscription.change.confirmation.subscription.upsizing.subtitle', {
          amount: this.formattedPrice(value, currency),
        });
  }

  get hasAddonUpsellCheckout() {
    return this.organizationManager.organization?.hasAddonUpsellCheckout;
  }

  formattedPrice(value: number, currency: string): string {
    return this.intl.formatNumber(value, {
      currency,
      style: 'currency',
    });
  }

  get availableTrialForCurrentAddon(): Subscription['availableTrials'] | undefined {
    return this.subscriptionManager.hasAvailableTrialProduct(
      this.addon.product_code,
      this.addon.recurrence
    );
  }

  get isAddonOnFreeTrial(): boolean {
    return (
      Boolean(this.availableTrialForCurrentAddon) && !this.isArAddonTrial && !this.hasInitialTrial
    );
  }

  get checkoutTitle(): string {
    if (this.isAddonOnFreeTrial) {
      let monthDuration = this.availableTrialForCurrentAddon?.monthDuration;

      return this.intl.t('subscription.change.confirmation.free-trial.title', { monthDuration });
    }
    return this.intl.t('subscription.change.confirmation.new.title');
  }

  get planTitle(): string {
    if (this.isAddonOnFreeTrial) {
      return this.intl.t('subscription.change.confirmation.plan.billing.title', {
        planName: this.pricePlan?.productName,
        recurrence:
          this.pricePlan?.recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY
            ? this.intl.t('subscription.change.confirmation.addon.recurrence.monthly')
            : this.intl.t('subscription.change.confirmation.addon.recurrence.annually'),
      });
    }
    return this.pricePlan?.recurrence === 'monthly'
      ? this.intl.t('subscription.change.confirmation.subscription.downsizing.title', {
          planName: this.pricePlan?.productName,
        })
      : this.intl.t('subscription.change.confirmation.subscription.upsizing.title', {
          planName: this.pricePlan?.productName,
        });
  }

  get checklistBullet(): string {
    return this.pricePlan && this.pricePlan.productName !== this.currentPlan.localName
      ? this.intl.t('subscription.change.confirmation.addon.trial.callout.bullet-4', {
          currentPlan: this.currentPlan.localName,
        })
      : this.intl.t('subscription.change.confirmation.addon.trial.callout.bullet-2');
  }

  get isArAddonTrial(): boolean {
    return (
      this.organizationManager.organization.hasArAddonForFree &&
      this.addon.product_code === Addons.AccountsReceivable &&
      Boolean(this.subscriptionManager.currentSubscription?.arAddonTrial)
    );
  }

  @action
  onConfirm() {
    this.segment.track('checkout_confirmation_cta_clicked', {
      ...this.args.context.tracking,
      ...(this.isAddonOnFreeTrial ? { free_trial: true } : {}),
    });

    let addOnMessagingPopupVariation = variation(
      'feature--string-addon-messaging-with-popups-in-ap'
    );
    if (
      addOnMessagingPopupVariation &&
      addOnMessagingPopupVariation !== AP_ADDON_MESSAGING_POPUP_FF.OFF
    ) {
      this.segment.track('suppliers_invoices_test_modal_converted', {
        variant: addOnMessagingPopupVariation,
        user_segment: AP_ADDON_MESSAGING_POPUP_FF_MAP[addOnMessagingPopupVariation],
      });
    }

    // @ts-expect-error
    this.sensitiveActions.runTask.perform(this.updateSubscriptionTask).catch(ignoreCancelation);
  }

  updateSubscriptionTask = dropTask(async () => {
    try {
      await this.networkManager.request(`${apiBaseURL}/${billingNamespace}/subscriptions`, {
        method: 'POST',
        data: {
          subscription: {
            organization_id: this.organizationManager.organization.organizationId,
            product_id: this.addon.product_id,
            recurrence: this.addon.recurrence,
          },
        },
      });
      await this.subscriptionManager.refresh();
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError((error as { errors?: any })?.errors)) {
        throw error;
      }
      if (variation('feature--boolean-mp-italy-checkout')) {
        let isItalyError = (error as { errors: { code: string }[] })?.errors?.some(
          err => err.code === 'product_change_not_available'
        );
        if (isItalyError) {
          this.args.context.isItalyError = true;
          this.args.transitionToNext();
          return;
        }
      }
      this.segment.track('checkout_confirmation_error');

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      let errorMessage = this.intl.t('toasts.errors.generic');
      this.toastFlashMessages.toastError(errorMessage);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AddonChange::Confirm': typeof FlowsAddonChangeConfirmComponent;
  }
}
