export default {
  "details": "jc",
  "disclaimer": "jq",
  "data": "jZ",
  "confirm": "jR",
  "form": "jQ",
  "textarea": "je",
  "preview": "jB",
  "form-title": "jE title1",
  "form-subtitle": "jd title2",
  "divider": "jI",
  "submit": "jt",
  "doc": "jM",
  "amount-input-error": "jP"
};
