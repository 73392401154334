/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
// @ts-expect-error
import { APPROVAL_WORKFLOW_REQUEST_TYPES } from '@repo/qonto-mirage/constants/approval-workflows.js';

interface ApprovalWorkflowsSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApprovalWorkflowsSuccess extends Component<ApprovalWorkflowsSuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare router: Services['router'];
  @service declare intl: Services['intl'];

  get subtitle() {
    // @ts-expect-error
    let { workflow } = this.args.context;
    return workflow.requestType === APPROVAL_WORKFLOW_REQUEST_TYPES.SUPPLIER_INVOICE
      ? this.intl.t('approval-workflows.success.subtitle-supplier-invoices')
      : this.intl.t('approval-workflows.success.subtitle');
  }

  @action
  goToWorkflow() {
    // @ts-expect-error
    let { context } = this.args;
    this.router.replaceWith('settings.approval-workflows', {
      queryParams: { highlight: context.workflow.id },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ApprovalWorkflows::Success': typeof ApprovalWorkflowsSuccess;
  }
}
