/* import __COLOCATED_TEMPLATE__ from './renew.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface CardRenewProviderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardRenewProvider extends Component<CardRenewProviderSignature> {
  @service declare cardsManager: Services['cardsManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  renewTask = dropTask(
    async (address, shipToBusiness, cardUpsellLevel, cardId, cardDesign, typeOfPrint) => {
      try {
        let response = await this.cardsManager.fetchCardsRenewTask.perform(
          this.organizationManager.organization.id,
          address,
          shipToBusiness,
          cardUpsellLevel,
          cardId,
          cardDesign,
          typeOfPrint
        );
        // @ts-expect-error
        await this.cardsManager.fetchCounters();
        return response;
      } catch (error) {
        // @ts-expect-error
        if (hasMFAError(error?.errors)) {
          throw error;
        }

        let errorInfo = ErrorInfo.for(error);

        // 404 error is legit: it means there is no card to renew
        if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 404) {
          this.sentry.captureException(error);
        }

        if (errorInfo.httpStatus === 404) {
          this.toastFlashMessages.toastError(this.intl.t('toasts.cards.no-card-to-renew'));
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        }
      }
    }
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Providers::Renew': typeof CardRenewProvider;
  }
}
