/* import __COLOCATED_TEMPLATE__ from './account-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BankAccountsSelectStep } from 'qonto/react/components/bank-accounts/select-step';

interface FlowsMandateAccountSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsMandateAccountSelection extends Component<FlowsMandateAccountSelectionSignature> {
  selectStep = BankAccountsSelectStep;

  @service declare segment: Services['segment'];

  @action
  transitionToNext() {
    this.segment.track('app_sdd_bank_account_chosen', {
      // @ts-expect-error
      account_name: this.args.context.mandate.bankAccount.name,
    });

    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Mandate::AccountSelection': typeof FlowsMandateAccountSelection;
  }
}
