export default {
  "wrapper": "WK",
  "wrapper-revamp": "WG",
  "container": "Wr",
  "container-revamp": "Wb",
  "box-wrapper": "Wy",
  "warning-label": "WU",
  "subtitle-container": "Wj",
  "info-container": "WW",
  "card": "Wu",
  "disclaimer": "uS",
  "list": "uc",
  "list-item": "uq body-2",
  "total": "uZ"
};
