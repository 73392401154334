/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface BeneficiaryUploadConfirmSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BeneficiaryUploadConfirmComponent extends Component<BeneficiaryUploadConfirmSignature> {
  @service declare router: Services['router'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];

  abortRoute = 'beneficiaries.multi.review';
  confirmRoute = 'transfers.new';

  submitTask = dropTask(async (multiBeneficiary, closeModal) => {
    await multiBeneficiary.submit();

    let message = this.intl.t('toasts.upload_beneficiaries_success', {
      count: multiBeneficiary.selectedBeneficiariesCount,
    });
    this.toastFlashMessages.toastSuccess(message);
    closeModal('submit');
    this.router.transitionTo(this.confirmRoute);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BeneficiaryUpload::Confirm': typeof BeneficiaryUploadConfirmComponent;
  }
}
