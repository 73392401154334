export default {
  "wrapper": "xZ",
  "subtitle": "xR body-2",
  "legend": "xQ",
  "table": "xe",
  "footer": "xB",
  "footer-codice-1": "xE",
  "footer-codice-2": "xd",
  "footer-total-a": "xI",
  "footer-total-b": "xt",
  "footer-balance": "xM",
  "errors": "xP"
};
