/* import __COLOCATED_TEMPLATE__ from './renewal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RenewalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Renewal = templateOnlyComponent<RenewalSignature>();

export default Renewal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Banners::Renewal': typeof Renewal;
  }
}
