/* import __COLOCATED_TEMPLATE__ from './flash-messages.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlashMessagesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlashMessagesComponent extends Component<FlashMessagesSignature> {
  @service declare bannerFlashMessages: Services['bannerFlashMessages'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FlashMessages: typeof FlashMessagesComponent;
  }
}
