/* import __COLOCATED_TEMPLATE__ from './create.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ACCOUNT_TYPE } from 'qonto/constants/bank-account';

interface BankAccountsCreateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BankAccountsCreateComponent extends Component<BankAccountsCreateSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @tracked account;
  @tracked value = null;
  @tracked error = null;

  createTask = dropTask(async () => {
    // @ts-expect-error
    let { account } = this.args.context;
    account.addIdempotencyHeader();

    // @ts-expect-error
    this.error = this.checkError();
    if (!this.error) {
      try {
        account.name = this.value;
        await account.save();
        this.segment.track('accounts_new_account_created', {
          account_type: ACCOUNT_TYPE.CURRENT,
        });
      } catch (error) {
        // @ts-expect-error
        let is403Error = error.errors.some(err => err.status === '403');
        let message = is403Error
          ? this.intl.t('toasts.errors.forbidden_error')
          : this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
        return;
      } finally {
        account.removeIdempotencyHeader();
      }
      await this.organizationManager.updateBankAccounts();
      // @ts-expect-error
      this.args.transitionToNext();
    }
  });

  @action
  // @ts-expect-error
  updateField(value) {
    this.value = value?.trim();
    // @ts-expect-error
    this.error = this.checkError();
  }

  checkError() {
    // @ts-expect-error
    return !this.value?.trim() ? this.intl.t('validations.errors.presence') : null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccounts::Create': typeof BankAccountsCreateComponent;
  }
}
