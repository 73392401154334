export default {
  "mandates": "mG",
  "mandates-list": "mr",
  "left-section": "mb",
  "header-search": "my",
  "header": "mU",
  "header-content": "mj",
  "search": "mW",
  "search-bar": "mu",
  "body": "pS",
  "list-title": "pc caption-bold",
  "details": "pq",
  "avatar": "pZ mr-16",
  "header-beneficiary": "pR",
  "header-beneficiary-activity": "pQ",
  "header-beneficiary-details": "pe",
  "header-beneficiary-details-recurring": "pB",
  "infos": "pE",
  "infos-title": "pd",
  "infos-empty": "pI",
  "infos-list": "pt",
  "infos-list-item": "pM",
  "infos-list-item-left": "pP",
  "infos-list-item-right": "pl",
  "infos-list-item-attachment": "pX"
};
