/* import __COLOCATED_TEMPLATE__ from './digital-card-kyc-intro.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlowsCardsKycDigitalCardKycIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsKycDigitalCardKycIntro extends Component<FlowsCardsKycDigitalCardKycIntroSignature> {
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: FlowsCardsKycDigitalCardKycIntroSignature['Args']) {
    super(owner, args);

    this.segment.track('submit_kyc_information_viewed', { origin: 'card_flow' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Kyc::DigitalCardKycIntro': typeof FlowsCardsKycDigitalCardKycIntro;
  }
}
