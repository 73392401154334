/* import __COLOCATED_TEMPLATE__ from './confirm-unrevoke-accountant.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { PRICE_PLAN_LINEUPS } from 'qonto/constants/price-plan';

interface ConfirmUnrevokeAccountantModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConfirmUnrevokeAccountantModal extends Component<ConfirmUnrevokeAccountantModalSignature> {
  lottiePlayer = LottiePlayer;

  @service declare errors: Services['errors'];
  @service declare segment: Services['segment'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare sensitiveActions: Services['sensitiveActions'];

  confirmTask = dropTask(async () => {
    try {
      // @ts-expect-error
      let { confirmTask, membership } = this.args.data;

      // @ts-expect-error
      await this.sensitiveActions.runTask.perform(confirmTask, membership);

      // @ts-expect-error
      this.args.close();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  @action
  reactivateMember() {
    this.segment.track('upsell_reactivate-paid-member_clicked', {
      // @ts-expect-error
      price_plan: this.args.data.pricePlanCode,
    });

    // @ts-expect-error
    this.args.close();

    this.flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'plans',
      queryParams: { lineup: PRICE_PLAN_LINEUPS.TEAMS },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Guest::Modal::ConfirmUnrevokeAccountant': typeof ConfirmUnrevokeAccountantModal;
  }
}
