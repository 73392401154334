export default {
  "guests-loading": "cRy",
  "header": "cRU",
  "body": "cRj",
  "members": "cRW",
  "title": "cRu caption-bold",
  "placeholder-container": "cQS",
  "icon": "cQc",
  "details": "cQq"
};
