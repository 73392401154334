/* import __COLOCATED_TEMPLATE__ from './placeholder-row.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PlaceholderRowSignature {
  Element: HTMLTableRowElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PlaceholderRow = templateOnlyComponent<PlaceholderRowSignature>();

export default PlaceholderRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::RequestsTable::PlaceholderRow': typeof PlaceholderRow;
  }
}
