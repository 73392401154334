/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

interface FlowsAccountsExternalImportConfirmationSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountsExternalImportConfirmationSuccessComponent extends Component<FlowsAccountsExternalImportConfirmationSuccessSignature> {
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  get isComingFromDirectDebitCollections() {
    // @ts-expect-error
    return this.args.context?.origin?.includes('direct-debit-collections');
  }

  get isBanklessPlan() {
    return !this.subscriptionManager.features.bankAccount;
  }

  get isComingFromInvoices() {
    return (
      variation('feature--boolean-aggregated-account-discoverability') &&
      // @ts-expect-error
      this.args.context?.origin?.includes('receivable-invoices')
    );
  }

  get isComingFromConnectHub() {
    // @ts-expect-error
    return this.args.context?.origin?.includes('connect-hub');
  }

  @action
  restartFlow() {
    // @ts-expect-error
    let { context, restartFlow } = this.args;

    this.segment.track('accounts_external-success_connect-another-account', {
      bank: context?.bank?.name || 'unknown',
    });

    restartFlow();
  }

  @action
  onPrimaryButtonClick() {
    // @ts-expect-error
    let { context, completeFlow } = this.args;

    if (this.isComingFromConnectHub) {
      this.segment.track('accounts_external-success_go-to-connected-accounts', {
        bank: context?.bank?.name || 'unknown',
      });
    }

    completeFlow();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Accounts::External::Import::Confirmation::Success': typeof FlowsAccountsExternalImportConfirmationSuccessComponent;
  }
}
