/* import __COLOCATED_TEMPLATE__ from './feedback.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { OnboardingFeedback } from 'qonto/react/payment-links/components/flows/onboarding';

interface PaymentLinksOnboardingFeedbackSignature {
  // The arguments accepted by the component
  Args: {
    transitionToNext: () => void;
    context: {
      origin: string;
      draftInvoiceId?: string;
      action?: string;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PaymentLinksOnboardingFeedbackComponent extends Component<PaymentLinksOnboardingFeedbackSignature> {
  OnboardingFeedback = OnboardingFeedback;

  @service declare flow: Services['flow'];
  @service declare mollie: Services['mollie'];
  @service declare router: Services['router'];

  get entryPoint() {
    if (this.args.context.draftInvoiceId) {
      return 'invoice';
    }
    if (this.args.context.origin === 'account-receivable-onboarding') {
      return 'account-receivable-onboarding';
    }
  }

  @action
  onCtaClick() {
    this.args.context.action = 'ctaClick';
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Onboarding::Feedback': typeof PaymentLinksOnboardingFeedbackComponent;
  }
}
