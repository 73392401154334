export default {
  "edit": "Ms",
  "upsell": "MF",
  "options": "Mg",
  "option": "MD body-2 mb-16",
  "option-line": "MV",
  "category-tags": "ML",
  "option-icons": "MT",
  "option-icon": "Mz",
  "valid": "Ma"
};
