/* import __COLOCATED_TEMPLATE__ from './capital-share-input.hbs'; */
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';

const CAPITAL_SHARE_LIMIT = 9999999999.99;

interface CapitalShareInputSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CapitalShareInputComponent extends Component<CapitalShareInputSignature> {
  amountField = AmountField;
  maxCapitalShare = CAPITAL_SHARE_LIMIT;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::CompanyDetails::CapitalShareInput': typeof CapitalShareInputComponent;
  }
}
