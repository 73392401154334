/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { IntroScreen } from 'qonto/react/components/cash-flow/components/first-time-experience/flow/intro-screen';

interface CashFlowFirstTimeExperienceIntro {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CashFlowFirstTimeExperienceIntroComponent extends Component<CashFlowFirstTimeExperienceIntro> {
  @service declare flow: Services['flow'];
  IntroScreen = IntroScreen;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::CashFlow::FirstTimeExperience::Intro': typeof CashFlowFirstTimeExperienceIntroComponent;
  }
}
