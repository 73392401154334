/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { ACCOUNT_ID_LOCAL_STORAGE } from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface FlowsAccountsRemuneratedCreationSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountsRemuneratedCreationSuccess extends Component<FlowsAccountsRemuneratedCreationSuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare segment: Services['segment'];

  @action
  handleNext() {
    safeLocalStorage.setItem(
      ACCOUNT_ID_LOCAL_STORAGE,
      // @ts-expect-error
      JSON.stringify(this.args.context.account.id)
    );
    this.segment.track('remunerated-account_fund_clicked', { origin: 'success_page' });

    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'remunerated-account-funding',
      stepId: 'add-funds',
      queryParams: {
        // @ts-expect-error
        origin: this.args.context.origin,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Accounts::Remunerated::Creation::Success': typeof FlowsAccountsRemuneratedCreationSuccess;
  }
}
