/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { PAGOPA_PAYMENT_RESULT_STATUS } from 'qonto/constants/pagopa';
import { Review } from 'qonto/react/components/flows/pagopa/review/review';

interface ReviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReviewComponent extends Component<ReviewSignature> {
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare sensitiveActions: Services['sensitiveActions'];

  get payment() {
    // @ts-expect-error
    return this.args.context.payment;
  }

  // @ts-expect-error
  setPaymentStatus = status => {
    // @ts-expect-error
    this.args.context.paymentStatus = status;
  };

  handleSubmitTask = dropTask(async () => {
    this.segment.track('pagopa_payment-flow_review_confirm-button_clicked');

    try {
      await this.payment.save();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      // @ts-expect-error
      if (error.status >= 500) {
        this.sentry.captureException(error);
      }

      this.segment.track('pagopa_payment-flow_failed');
      this.setPaymentStatus(PAGOPA_PAYMENT_RESULT_STATUS.ERROR);
    }

    // @ts-expect-error
    this.args.transitionToNext();
  });

  onConfirm = async () => {
    // @ts-expect-error
    await this.sensitiveActions.runTask.perform(this.handleSubmitTask);
  };

  get isSubmitting() {
    return this.handleSubmitTask.isRunning;
  }

  review = Review;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Pagopa::Review': typeof ReviewComponent;
  }
}
