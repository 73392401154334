/* import __COLOCATED_TEMPLATE__ from './confirmation-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ConfirmationModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ConfirmationModal = templateOnlyComponent<ConfirmationModalSignature>();

export default ConfirmationModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Banks::ConfirmationModal': typeof ConfirmationModal;
  }
}
