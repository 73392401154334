export default {
  "card": "Ia",
  "avatar": "IH",
  "owner": "IO",
  "content": "Im",
  "identity": "Ip",
  "fullname": "Ix",
  "type": "Iw",
  "menu-container": "IN",
  "dropdown-menu": "Iv",
  "menu-item": "Io body-2",
  "delete": "In"
};
