export default {
  "container": "Yl",
  "centered": "YX",
  "content": "YC",
  "spinner": "Yk",
  "holder-selector-wrapper": "YJ mb-16",
  "holder-selector-error": "Ys",
  "info-text": "YF",
  "error-text": "Yg",
  "lottie-illustration": "YD",
  "info-text-1": "YV",
  "info-text-2": "YL"
};
