/* import __COLOCATED_TEMPLATE__ from './upgrade-modal.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge, Button, LottiePlayer, type LottiePlayerProps } from '@repo/design-system-kit';

interface UpgradeModalSignature {
  // The arguments accepted by the component
  Args: {
    close: () => void;
    confirm: () => void;
    hasAvailableFreeTrial: boolean;
    lottieProps: LottiePlayerProps;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class UpgradeModalComponent extends Component<UpgradeModalSignature> {
  BADGE_TYPE = BADGE_TYPE;

  lottiePlayer = LottiePlayer;
  button = Button;
  badge = Badge;

  get lottieProps(): LottiePlayerProps {
    return {
      autoplay: true,
      loop: false,
      ...this.args.lottieProps, // Override defaults with passed values
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UpgradeModal: typeof UpgradeModalComponent;
  }
}
