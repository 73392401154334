/* import __COLOCATED_TEMPLATE__ from './suspend.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { CARD_LOCK_REASONS, ORIGIN } from 'qonto/constants/cards';
import { Suspend } from 'qonto/react/cards/components/modals/suspend';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface CardModalsSuspendSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardModalsSuspendComponent extends Component<CardModalsSuspendSignature> {
  @service declare abilities: Services['abilities'];
  @service declare cardsManager: Services['cardsManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  @tracked isReasonError = false;
  @tracked isSuccess = false;
  @tracked lockReason = null;

  // @ts-expect-error
  @reads('organizationManager.organization.isItalian') isItalian;

  button = Button;
  lottiePlayer = LottiePlayer;
  suspend = Suspend;

  get canRequest() {
    return this.abilities.can('create card request') && !this.isItalian;
  }

  get canReorderOrRequest() {
    return this.abilities.can('create card') || this.canRequest;
  }

  get shouldShowReasonError() {
    return this.isReasonError && !this.lockReason;
  }

  get title() {
    if (!this.isSuccess) {
      return this.intl.t('cards.modals.lost-stolen.title');
    }

    return this.lockReason === CARD_LOCK_REASONS.STOLEN
      ? this.intl.t('cards.modals.lost-stolen-success.title.stolen')
      : this.intl.t('cards.modals.lost-stolen-success.title.lost');
  }

  get description() {
    if (!this.isSuccess) {
      return this.intl.t('cards.modals.lost-stolen.desc');
    }

    return this.abilities.can('create card')
      ? this.intl.t('cards.modals.lost-stolen-success.subtitle.admin')
      : this.intl.t('cards.modals.lost-stolen-success.subtitle.non-admin');
  }

  @action
  linkToReorder() {
    let {
      // @ts-expect-error
      close,
      // @ts-expect-error
      data: { card },
    } = this.args;

    close();

    if (this.isItalian) {
      this.flowLinkManager.transitionTo({
        name: 'card-reorder',
        stepId: 'review',
        queryParams: { cardId: card.id },
      });
    } else {
      this.flowLinkManager.transitionTo({
        name: 'card-new-physical',
        stepId: 'choose-card',
        queryParams: { holderId: card.holder.id, origin: ORIGIN.CARD_REORDER },
      });
    }
  }

  @action
  linkToRequest() {
    // @ts-expect-error
    this.args.close();

    this.router.transitionTo('requests.landing', {
      queryParams: {
        origin: 'cards',
      },
    });
  }

  lockCardTask = dropTask(async () => {
    if (!this.lockReason) {
      this.isReasonError = true;
      return;
    }

    let {
      // @ts-expect-error
      close,
      // @ts-expect-error
      data: { card, shouldShowSuccess },
    } = this.args;

    try {
      await card.lock(this.lockReason);
      this.isSuccess = shouldShowSuccess;
      if (card.isMetal) {
        await this.cardsManager.updateMembershipFeatureFlagsTask.perform(card);
      }
      // TODO: legacy lost/stolen
      if (!shouldShowSuccess) {
        this.toastFlashMessages.toastInfo(this._getLockToastMessage(this.lockReason));
        close();
      }
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      close();
    } finally {
      this.segment.track(`card_${this.lockReason}_confirmed`);
    }
  });

  // @ts-expect-error
  _getLockToastMessage(reason) {
    return reason === CARD_LOCK_REASONS.STOLEN
      ? this.intl.t('toasts.cards.stolen')
      : this.intl.t('toasts.cards.lost');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::Suspend': typeof CardModalsSuspendComponent;
  }
}
