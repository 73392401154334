/* import __COLOCATED_TEMPLATE__ from './offer-card-loading.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface OfferCardLoadingSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const OfferCardLoading = templateOnlyComponent<OfferCardLoadingSignature>();

export default OfferCardLoading;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::OfferCardLoading': typeof OfferCardLoading;
  }
}
