export default {
  "sidebar": "gY",
  "scroll": "gh",
  "header": "gf",
  "close": "gK",
  "vat": "gG",
  "labels": "gr",
  "actions": "gb",
  "buttons-container": "gy"
};
