/* import __COLOCATED_TEMPLATE__ from './company-profile-container.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CompanyProfileContainerSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const CompanyProfileContainer = templateOnlyComponent<CompanyProfileContainerSignature>();

export default CompanyProfileContainer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CompanyProfileContainer: typeof CompanyProfileContainer;
  }
}
