/* import __COLOCATED_TEMPLATE__ from './additional-requirements.hbs'; */
import { setProperties } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { property } from 'es-toolkit/compat';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import {
  ERROR_CODE,
  ERROR_TRANSLATION_KEY,
  INVALID_ERROR_POINTER_PREFIX,
} from 'qonto/constants/international-out/beneficiary';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import type { DataContext } from 'qonto/routes/flows/setup/transfers/international-out/data-context';
import type { Requirements } from 'qonto/services/international-out/types';
import type { Validator } from 'qonto/utils/dynamic-form';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { extractFieldKeyFromPointer } from 'qonto/utils/international-out/error';
import { applyDefaultValues } from 'qonto/utils/international-out/requirements';

interface Signature {
  Args: FlowStepArgs<
    DataContext & {
      additionalRequirements: NonNullable<DataContext['additionalRequirements']>;
      beneficiary: NonNullable<DataContext['beneficiary']>;
      fees: NonNullable<DataContext['fees']>;
      quote: NonNullable<DataContext['quote']>;
    }
  >;
}

export default class FlowsTransfersInternationalOutNewAdditionalRequirementsComponent extends Component<Signature> {
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked requirements = this.args.context.additionalRequirements;
  @tracked validators: Record<string, Validator> = {};

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.#setRequirements(this.args.context.additionalRequirements);
  }

  get paymentMethods(): string[] {
    return this.requirements.map(({ type }) => type);
  }

  get subtitle(): string {
    if (
      this.requirements.every(
        paymentMethod => paymentMethod.type !== this.args.context.beneficiary.paymentType
      )
    ) {
      return this.intl.t('international-out.additional-requirements.subtitle.create');
    }

    return this.intl.t('international-out.additional-requirements.subtitle.update');
  }

  refreshRequirementsTask = dropTask(async ({ data: beneficiaryDetails }): Promise<void> => {
    try {
      const requirements = await this.internationalOutManager.getAdditionalBeneficiaryRequirements({
        quoteId: this.args.context.quote.id,
        beneficiary: this.args.context.beneficiary,
        details: beneficiaryDetails,
      });
      this.#setRequirements(requirements);
    } catch (error) {
      this.#handleError(error);
    }
  });

  updateBeneficiaryTask = dropTask(
    async ({ type: paymentMethod, data: beneficiaryDetails }): Promise<void> => {
      this.segment.track(EVENTS.BENEFICIARY_REQUIREMENTS_SUBMITTED);

      this.validators = {};

      const { context, transitionToNext } = this.args;
      const { beneficiary, quote } = context;

      try {
        const {
          beneficiary: updatedBeneficiary,
          fees,
          quote: patchedQuote,
          targetAccountId,
        } = await this.internationalOutManager.updateBeneficiary({
          quoteId: quote.id,
          beneficiaryId: beneficiary.id,
          currency: beneficiary.currency,
          type: paymentMethod,
          details: beneficiaryDetails,
        });

        this.segment.track(EVENTS.BENEFICIARY_REQUIREMENTS_ACCEPTED);

        setProperties(context, {
          beneficiary: updatedBeneficiary,
          fees,
          quote: patchedQuote,
          targetAccount: { id: targetAccountId },
        });

        transitionToNext();
      } catch (error) {
        // @ts-expect-error
        const { status, errors } = error;

        let isKnownErrorWithTranslation = false;

        if (status === 422) {
          // @ts-expect-error
          errors.forEach(({ code, source }) => {
            const isKnownError = Object.values(ERROR_CODE).includes(code);
            const translationKey = ERROR_TRANSLATION_KEY[code];

            if (isKnownError && translationKey) {
              isKnownErrorWithTranslation = true;

              const pointer = source?.pointer;

              if (pointer) {
                const fieldKey = extractFieldKeyFromPointer({
                  pointer,
                  prefix: INVALID_ERROR_POINTER_PREFIX,
                });
                const invalidValue = property(fieldKey)(beneficiaryDetails);
                this.validators = {
                  ...this.validators,
                  [fieldKey]: {
                    predicate: value => value !== invalidValue,
                    translationKey,
                  },
                };
              } else {
                this.toastFlashMessages.toastError(this.intl.t(translationKey));
              }
            }
          });
        }

        if (!isKnownErrorWithTranslation) {
          this.#handleError(error);
        }
      }
    }
  );

  // @ts-expect-error
  #handleError(error): void {
    const { httpStatus, shouldSendToSentry } = ErrorInfo.for(error);

    // We don't want to capture 400 errors as they correspond to unknown validation errors from the provider.
    // An example could be an account number that was black-listed by the provider.
    if (shouldSendToSentry && httpStatus !== 400) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }

  #setRequirements(requirements: Requirements[]): void {
    this.requirements = applyDefaultValues(requirements, {
      accountHolderName: this.args.context.invoice?.supplierName ?? null,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::New::AdditionalRequirements': typeof FlowsTransfersInternationalOutNewAdditionalRequirementsComponent;
  }
}
