/* import __COLOCATED_TEMPLATE__ from './vehicle-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';

interface VehicleDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class VehicleDetailsComponent extends Component<VehicleDetailsSignature> {
  checkbox = Checkbox;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare abilities: Services['abilities'];

  // @ts-expect-error
  @tracked selectedType = this.args.context.requestMileage.vehicle?.type;
  // @ts-expect-error
  @tracked selectedPower = this.args.context.requestMileage.vehicle?.power;
  // @ts-expect-error
  @tracked isVehicleElectric = this.args.context.requestMileage.vehicle?.electric;

  get selectedVehiclePowerOptions() {
    // @ts-expect-error
    return this.args.context.vehicles[this.selectedType]?.powerOptions;
  }

  get shouldDisplayElectricVehicleCheckbox() {
    return this.organizationManager.organization?.isFrench;
  }

  @action
  // @ts-expect-error
  setVehicleType(value) {
    this.selectedType = value;

    // Reset the power value when switching vehicle type
    this.selectedPower = null;
    // @ts-expect-error
    this.args.context.requestMileage.vehicle.power = null;

    // @ts-expect-error
    this.args.context.requestMileage.vehicle ||= {};
    // @ts-expect-error
    this.args.context.requestMileage.vehicle.type = value;
    if (this.selectedType === 'scooter') {
      // we delete the property `power` here because
      // backend doesn't accept it for scooters
      // @ts-expect-error
      delete this.args.context.requestMileage.vehicle.power;
    }
  }

  @action
  // @ts-expect-error
  setFiscalPower(value) {
    this.selectedPower = value;
    // @ts-expect-error
    this.args.context.requestMileage.vehicle.power = value;
  }

  @action
  toggleIsElectricVehicle() {
    this.isVehicleElectric = !this.isVehicleElectric;
  }

  @action
  transitionToNext() {
    // @ts-expect-error
    this.args.context.requestMileage.vehicle.electric = this.isVehicleElectric;

    this.segment.track('request_vehicle_selected', {
      origin: 'mileage_request',
    });

    // @ts-expect-error
    if (this.args.context.shouldGoToVehicleDetailsStep) {
      // We set this to false here because we don't want to go to the `vehicle-details` in this case
      // user should be redirect back to `request-details` step
      // @ts-expect-error
      this.args.context.shouldGoToVehicleDetailsStep = false;
      this.toastFlashMessages.toastSuccess(
        this.intl.t('requests.mileage.steps.request-details.success.vehicle-edited')
      );
    }

    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::RequestMileage::VehicleDetails': typeof VehicleDetailsComponent;
  }
}
