/* import __COLOCATED_TEMPLATE__ from './input.hbs'; */
import { assert } from '@ember/debug';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import isFunction from 'qonto/utils/is-function';

interface GoogleAutocompleteInputSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

/**
 * A text field. When the user fills it out, it will perform a search using
 * Google Places API, showing a dropdown with matching addresses. The user can
 * select one of these in order to complete the field.
 *
 * The component also supports recent addresses, which are shown in the dropdown
 * when the user clears the input field or user first focuses on the input field.
 *
 *
 * ```hbs
 * <GoogleAutocompleteInput
 *   @ariaDescribedBy={{this.ariaDescribedBy}}
 *   @placeholder="Enter an address"
 *   @required={{true}}
 *   @inputId="customId"
 *   @disabled={{false}}
 *   @select={{this.onResultSelected}}
 *   @value={{this.selectedAddress.formattedAddress}
 *   @recentAddresses={{this.RECENT_ADDRESSES.departures}}
 * />
 * ```
 *
 * @class GoogleAutocompleteInputComponent
 * @public
 **/
export default class GoogleAutocompleteInputComponent extends Component<GoogleAutocompleteInputSignature> {
  @service declare googleAutocomplete: Services['googleAutocomplete'];

  constructor(owner: unknown, args: GoogleAutocompleteInputSignature['Args']) {
    super(owner, args);
    assert('<GoogleAutocomplete::Input /> requires a `@select` callback', isFunction(this.select));
    this.googleAutocomplete.setup();
  }

  /**
   * Results from the google autocomplete service and the recent addresses
   *
   * @private
   * @property _places
   * @type [result]
   */
  // @ts-expect-error
  @tracked _places;

  /**
   * Boolean to show/hide recent addresses dropdown
   *
   * @property showRecentAddresses
   * @type boolean
   */
  @tracked showRecentAddresses = true;

  get placeholder() {
    // @ts-expect-error
    return this.args.placeholder ?? null;
  }

  get required() {
    // @ts-expect-error
    return this.args.required ?? false;
  }

  get disabled() {
    // @ts-expect-error
    return this.args.disabled ?? false;
  }

  get inputId() {
    // @ts-expect-error
    return this.args.inputId ?? null;
  }

  get select() {
    // @ts-expect-error
    return this.args.select ?? null;
  }

  get places() {
    // @ts-expect-error
    return this._places ? this._places : (this.args.recentAddresses ?? []);
  }

  set places(value) {
    this._places = value;
  }

  get customDropdownClass() {
    // @ts-expect-error
    return !this.places?.length > 0
      ? 'address-search-input-wormhole address-search-input--hide-dropdown'
      : 'address-search-input-wormhole';
  }

  get displayRecentAddresses() {
    // @ts-expect-error
    return this.args.recentAddresses?.length > 0 && this.showRecentAddresses;
  }

  handleSearch = restartableTask(async value => {
    await timeout(DEBOUNCE_MS);
    try {
      this.places = await this.googleAutocomplete.fetchAddresses(value);
      this.showRecentAddresses = false;
    } catch (error) {
      this.places = [];
      // @ts-expect-error
      this.args.onError?.(error);
    }
  });

  handleInput = restartableTask(async value => {
    // @ts-expect-error
    this.args.onInput?.(value);
    if (value.trim().length >= 3) {
      return await this.handleSearch.perform(value);
    }

    this.handleSearch.cancelAll();
    this.showRecentAddresses = true;
    // @ts-expect-error
    this.places = this.args.recentAddresses;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GoogleAutocomplete::Input': typeof GoogleAutocompleteInputComponent;
  }
}
