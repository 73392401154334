/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface SuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SuccessComponent extends Component<SuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  get isReviewer() {
    return this.abilities.can('review mileage request');
  }

  get titleLabel() {
    if (this.isReviewer) {
      return this.intl.t('requests.mileage.steps.success-admin.title');
    }

    return this.intl.t('requests.mileage.steps.success.title');
  }

  get subtitleLabel() {
    if (this.isReviewer) {
      return this.intl.t('requests.mileage.steps.success-admin.subtitle');
    }

    return this.intl.t('requests.mileage.steps.success.subtitle');
  }

  @action
  handleCloseClick() {
    // @ts-expect-error
    let { transitionToNext } = this.args;

    transitionToNext();
  }

  @action
  handleSuccessClick() {
    this.router.transitionTo('requests.landing', {
      queryParams: { origin: 'mileage_request' },
    });
  }

  @action
  handleReviewRequest() {
    this.segment.track('request_review_clicked', { request_type: 'mileage' });

    this.router.transitionTo('reimbursements.pending', {
      // @ts-expect-error
      queryParams: { highlight: this.args.context.requestMileage.id },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::RequestMileage::Success': typeof SuccessComponent;
  }
}
