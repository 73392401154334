/* import __COLOCATED_TEMPLATE__ from './document-selection.hbs'; */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';
import { TrackedArray } from 'tracked-built-ins';

interface FlowsKYCDocumentSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsKYCDocumentSelection extends Component<FlowsKYCDocumentSelectionSignature> {
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  // @ts-expect-error
  @reads('organizationManager.organization.legalCountry') legalCountry;

  @action
  // @ts-expect-error
  nextStep(documentType) {
    // @ts-expect-error
    if (this.args.context.documentType !== documentType) {
      // @ts-expect-error
      set(this.args.context, 'documents', new TrackedArray());
    }
    // @ts-expect-error
    set(this.args.context, 'documentType', documentType);
    this.segment.track('submit_kyc_information_poi_type_chosen', {
      poi_type: documentType,
    });
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Kyc::DocumentSelection': typeof FlowsKYCDocumentSelection;
  }
}
