export default {
  "row": "zR body-1",
  "active": "zQ",
  "cell": "ze body-2",
  "empty": "zB",
  "no-padding": "zE",
  "cell-content": "zd",
  "debitor": "zI",
  "avatar": "zt",
  "debitor-details": "zM",
  "debitor-name": "zP",
  "reference": "zl",
  "amount": "zX",
  "row--sidebar-open": "zC",
  "status": "zk"
};
