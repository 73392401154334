/* import __COLOCATED_TEMPLATE__ from './simple.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SimpleSignature {
  Element: HTMLButtonElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Simple = templateOnlyComponent<SimpleSignature>();

export default Simple;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BackButton::Simple': typeof Simple;
  }
}
