/* import __COLOCATED_TEMPLATE__ from './compare-roles.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge } from '@repo/design-system-kit';

import { Feature } from 'qonto/react/components/flows/member/compare-roles';

interface FlowsInviteCompareRolesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsInviteCompareRolesComponent extends Component<FlowsInviteCompareRolesSignature> {
  feature = Feature;

  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];

  @tracked isScrolling = false;

  badgeHighlight = Badge;
  BADGE_TYPE = BADGE_TYPE;

  get employeeRequestCopy() {
    if (this.abilities.can('submit expense request')) {
      return this.intl.t('invitation-flow.compare-roles.feature.only-submit');
    } else {
      return this.intl.t('invitation-flow.compare-roles.feature.on-their-own');
    }
  }

  featureGroups = [
    {
      // @ts-expect-error
      title: this.intl.t('invitation-flow.compare-roles.group.accounts.title'),
      features: [
        {
          // @ts-expect-error
          description: this.intl.t('invitation-flow.compare-roles.group.accounts.access'),
          roles: {
            // @ts-expect-error
            employee: this.intl.t('invitation-flow.compare-roles.feature.no-access'),
            // @ts-expect-error
            manager: this.intl.t('invitation-flow.compare-roles.feature.custom-access'),
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.custom-access'),
            // @ts-expect-error
            admin: this.intl.t('invitation-flow.compare-roles.feature.full-access'),
            // @ts-expect-error
            reporting: this.intl.t('invitation-flow.compare-roles.feature.full-access'),
          },
        },
        {
          // @ts-expect-error
          description: this.intl.t(
            'invitation-flow.compare-roles.group.accounts.financial-monitoring'
          ),
          roles: {
            employee: false,
            manager: false,
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: true,
          },
        },
      ],
    },
    {
      // @ts-expect-error
      title: this.intl.t('invitation-flow.compare-roles.group.transactions.title'),
      features: [
        {
          // @ts-expect-error
          description: this.intl.t('invitation-flow.compare-roles.group.transactions.view-edit'),
          roles: {
            // @ts-expect-error
            employee: this.intl.t('invitation-flow.compare-roles.feature.on-their-own'),
            // @ts-expect-error
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: true,
          },
        },
      ],
    },
    {
      // @ts-expect-error
      title: this.intl.t('invitation-flow.compare-roles.group.cards.title'),
      features: [
        {
          // @ts-expect-error
          description: this.intl.t('invitation-flow.compare-roles.group.cards.pay'),
          roles: {
            employee: true,
            manager: true,
            advanced_manager: true,
            admin: true,
            reporting: false,
          },
        },
      ],
    },
    {
      // @ts-expect-error
      title: this.intl.t('invitation-flow.compare-roles.group.expense-management.title'),
      features: [
        {
          // @ts-expect-error
          description: this.intl.t(
            'invitation-flow.compare-roles.group.expense-management.card-requests'
          ),
          roles: {
            employee: this.employeeRequestCopy,
            // @ts-expect-error
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
        {
          // @ts-expect-error
          description: this.intl.t(
            'invitation-flow.compare-roles.group.expense-management.transfer-requests'
          ),
          roles: {
            employee: this.employeeRequestCopy,
            // @ts-expect-error
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            // @ts-expect-error
            reporting: this.intl.t('invitation-flow.compare-roles.feature.only-submit'),
          },
        },
        // @ts-expect-error
        ...(this.abilities.can('use mileages request')
          ? [
              {
                // @ts-expect-error
                description: this.intl.t(
                  'invitation-flow.compare-roles.group.expense-management.expense-reports-mileage'
                ),
                roles: {
                  employee: this.employeeRequestCopy,
                  // @ts-expect-error
                  manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
                  // @ts-expect-error
                  advanced_manager: this.intl.t(
                    'invitation-flow.compare-roles.feature.customizable'
                  ),
                  admin: true,
                  reporting: false,
                },
              },
            ]
          : // @ts-expect-error
            this.abilities.can('use expense reports request')
            ? [
                {
                  // @ts-expect-error
                  description: this.intl.t(
                    'invitation-flow.compare-roles.group.expense-management.expense-reports'
                  ),
                  roles: {
                    // @ts-expect-error
                    employee: this.intl.t('invitation-flow.compare-roles.feature.only-submit'),
                    // @ts-expect-error
                    manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
                    // @ts-expect-error
                    advanced_manager: this.intl.t(
                      'invitation-flow.compare-roles.feature.customizable'
                    ),
                    admin: true,
                    reporting: false,
                  },
                },
              ]
            : []),
        {
          // @ts-expect-error
          description: this.intl.t(
            'invitation-flow.compare-roles.group.expense-management.supplier-invoices'
          ),
          roles: {
            employee: false,
            // @ts-expect-error
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
        {
          // @ts-expect-error
          description: this.intl.t(
            'invitation-flow.compare-roles.group.expense-management.direct-debits'
          ),
          roles: {
            employee: false,
            manager: false,
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
        {
          // @ts-expect-error
          description: this.intl.t('invitation-flow.compare-roles.group.expense-management.checks'),
          roles: {
            employee: false,
            manager: false,
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
      ],
    },
    {
      // @ts-expect-error
      title: this.intl.t('invitation-flow.compare-roles.group.team-management.title'),
      features: [
        {
          // @ts-expect-error
          description: this.intl.t('invitation-flow.compare-roles.group.team-management.manage'),
          roles: {
            employee: false,
            // @ts-expect-error
            manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: false,
          },
        },
      ],
    },
    {
      // @ts-expect-error
      title: this.intl.t('invitation-flow.compare-roles.group.bookkeeping.title'),
      features: [
        {
          // @ts-expect-error
          description: this.intl.t('invitation-flow.compare-roles.group.bookkeeping.organize'),
          roles: {
            employee: false,
            manager: false,
            // @ts-expect-error
            advanced_manager: this.intl.t('invitation-flow.compare-roles.feature.customizable'),
            admin: true,
            reporting: true,
          },
        },
      ],
    },
    {
      // @ts-expect-error
      title: this.intl.t('invitation-flow.compare-roles.group.qonto-connect.title'),
      features: [
        {
          // @ts-expect-error
          description: this.intl.t('invitation-flow.compare-roles.group.qonto-connect.connect'),
          roles: {
            employee: false,
            manager: false,
            advanced_manager: false,
            admin: true,
            reporting: true,
          },
        },
      ],
    },
    {
      // @ts-expect-error
      title: this.intl.t('invitation-flow.compare-roles.group.multi-accounts.title'),
      features: [
        {
          // @ts-expect-error
          description: this.intl.t(
            'invitation-flow.compare-roles.group.multi-accounts.open-accounts'
          ),
          roles: {
            employee: false,
            manager: false,
            advanced_manager: false,
            admin: true,
            reporting: false,
          },
        },
      ],
    },
  ];

  // @ts-expect-error
  @action updateScrolledTop(value) {
    this.isScrolling = !value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::CompareRoles': typeof FlowsInviteCompareRolesComponent;
  }
}
