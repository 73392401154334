/* import __COLOCATED_TEMPLATE__ from './show-pin.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Spinner } from '@repo/design-system-kit';
import { dropTask, race, rawTimeout } from 'ember-concurrency';

import ENV from 'qonto/config/environment';
// @ts-expect-error
import { SmartPinOnBehalfDelivery } from 'qonto/lib/smart-on-delivery';

const issuerReference = 'QONTO';
const language = 'en';
const PIN_MODAL_TIMEOUT = 10000;

class PinError extends Error {
  spinner = Spinner;

  // @ts-expect-error
  constructor(...params) {
    super(...params);
    this.name = 'PinError';
  }
}

interface CardSidebarShowPinSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLButtonElement;
}

export default class CardSidebarShowPinComponent extends Component<CardSidebarShowPinSignature> {
  spinner = Spinner;

  @service declare cardsManager: Services['cardsManager'];
  @service declare modals: Services['modals'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  #getPinFromProvider(data, signature) {
    let { demand_id, cardholder_identifier, provider_id, service_type, timestamp } = data;
    return new Promise((resolve, reject) => {
      // @ts-expect-error
      let onPinComplete = pinCode => resolve(pinCode);
      // @ts-expect-error
      let onPinError = error => reject(new PinError(`smart pin script error code ${error}`));

      SmartPinOnBehalfDelivery.GetPIN(
        // URL
        // @ts-expect-error
        ENV.smartPinURL,
        // CardholderIdentifier
        cardholder_identifier,
        // RequestReference
        demand_id,
        // IssuerReference
        issuerReference,
        // ProviderID
        provider_id,
        // Base64Signature
        signature,
        // ServiceType
        service_type,
        // Language
        language,
        // TIME
        timestamp.toString(),
        // OnErrorFunction
        onPinError,
        // OnCompleteFunction
        onPinComplete
      );
    });
  }

  pinApiTask = dropTask(async () => {
    // @ts-expect-error
    return await this.args.card.getPin();
  });

  // @ts-expect-error
  async #showPinModal(pinCode) {
    let modal = this.modals.open('card/modals/pin-code', {
      pinCode,
    });

    let modalClosedCondition = await race([modal, rawTimeout(PIN_MODAL_TIMEOUT)]);
    // only close the modal if the promise resolved is because of the rawTimeout
    // otherwise we expect the modal to be closed by a user action
    // @ts-expect-error
    if (modalClosedCondition !== 'modal-pin-code') {
      // @ts-expect-error
      modal.close();
    }
  }

  showEncryptedPinTask = dropTask(async () => {
    try {
      // @ts-expect-error
      let { card } = this.args;
      let pinCode = await this.cardsManager.getEncryptedPinTask.perform(card.id);
      this.segment.track('cards_side_panel_view_pin');

      // we don't want to await for the modal
      this.#showPinModal(pinCode);
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  showPinTask = dropTask(async () => {
    this.segment.track('cards_side_panel_view_pin');
    try {
      // @ts-expect-error
      await this.sensitiveActions.runTask.perform(this.pinApiTask);
      // cancelling the sca modal does not seem to cancel the task, so we need to check if it exists
      if (this.pinApiTask.lastSuccessful) {
        let { data, signature } = this.pinApiTask.lastSuccessful.value;
        let pinCode;
        if (data.demand_id === '_FAKE_' && data.cardholder_identifier === '_FAKE_') {
          pinCode = '0000';
        } else {
          pinCode = await this.#getPinFromProvider(data, signature);
        }

        // we don't want to await for the modal
        this.#showPinModal(pinCode);
      }
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      // @ts-expect-error
      let isUnprocessableError = error.status === 422;
      // @ts-expect-error
      let isNotReadyPinError = error.errors?.[0]?.detail?.code === 'pin_is_not_ready';

      let message = this.intl.t('toasts.errors.server_error');

      if (isUnprocessableError) {
        message = isNotReadyPinError
          ? this.intl.t('toasts.errors.pin-not-available')
          : this.intl.t('cards.errors.show-pin');
      }

      this.toastFlashMessages.toastError(message);

      if ((isUnprocessableError && !isNotReadyPinError) || error instanceof PinError) {
        /*
            422 errors in this case tend to mean that the request we're trying to make
            for this specific card was not accepted in some form by our provider, so we
            would like to track these cases to help us investigate and detect issues.

            Any error caused by the script will return with a specific error code, like `107`
            which we can also use to detecting issues.
          */
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::ShowPin': typeof CardSidebarShowPinComponent;
  }
}
