/* import __COLOCATED_TEMPLATE__ from './it-company-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { PLACEHOLDER_SECTIONS } from '@qonto/ui-kit/constants/pdf-preview';
import { dropTask } from 'ember-concurrency';

import {
  ONBOARDING_STEP_STATUS,
  ONBOARDING_TRACKING_EVENTS,
} from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsAccountReceivableOnboardingItCompanyDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountReceivableOnboardingItCompanyDetails extends Component<FlowsAccountReceivableOnboardingItCompanyDetailsSignature> {
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked displayValidations = false;

  constructor(
    owner: unknown,
    args: FlowsAccountReceivableOnboardingItCompanyDetailsSignature['Args']
  ) {
    super(owner, args);

    this.#prefillEinvoiceActivation();
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (this.settings && !this.settings.isDestroyed && !this.settings.isDestroying) {
      this.settings.rollbackAttributes();
    }

    if (
      this.eInvoiceActivation &&
      !this.eInvoiceActivation.isDestroyed &&
      !this.eInvoiceActivation.isDestroying
    ) {
      this.eInvoiceActivation.rollbackAttributes();
    }
  }

  get stepStatusCompanyDetails() {
    // @ts-expect-error
    return this.args.context.onboardingState.stepStatusCompanyDetails;
  }

  get prefillData() {
    return this.onboardingState?.prefillData;
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get eInvoiceActivation() {
    // @ts-expect-error
    return this.args.context.eInvoiceActivation;
  }

  get settings() {
    // @ts-expect-error
    return this.args.context.settings;
  }

  get onboardingState() {
    // @ts-expect-error
    return this.args.context.onboardingState;
  }

  #prefillEinvoiceActivation() {
    if (this.stepStatusCompanyDetails !== 'confirmed') {
      this.settings.setProperties({
        contactEmail: this.prefillData?.contactEmail || this.organization.contactEmail,
        vatNumber: this.prefillData?.vatNumber || this.organization.vatNumber,
      });

      this.eInvoiceActivation.setProperties({
        legalTvaNumber: this.prefillData?.vatNumber || this.organization.vatNumber,
      });
    }
  }

  get title() {
    let { organization } = this.organizationManager;
    if (organization.isAssociation) {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.association', {
        legalCountry: organization.legalCountry,
      });
    } else if (organization.isBusiness) {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.business', {
        legalCountry: organization.legalCountry,
      });
    } else {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.company', {
        legalCountry: organization.legalCountry,
      });
    }
  }

  get placeholderConfig() {
    return {
      enablePlaceholders: true,
      activeSections: [
        this.exampleInvoice.number && PLACEHOLDER_SECTIONS.NUMBER,
        PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION,
      ],
    };
  }

  get exampleInvoice() {
    return {
      number: this.settings?.invoiceNextNumberFormatted,
      organization: {
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        address: this.organization.address,
        vatNumber: this.settings.vatNumber,
      },
      contactEmail: this.settings.contactEmail,
    };
  }

  @action
  // @ts-expect-error
  handleProvinceChange(provinceCode) {
    this.eInvoiceActivation.provinceCode = provinceCode;
  }

  @action
  // @ts-expect-error
  handleTaxRegimeChange(taxRegime) {
    this.eInvoiceActivation.taxRegime = taxRegime;
  }

  @action
  // @ts-expect-error
  handleEmailUpdate(email) {
    this.eInvoiceActivation.contactEmail = email;
    this.settings.contactEmail = email;
  }

  @action
  // @ts-expect-error
  handleFiscalCodeUpdate(fiscalCode) {
    this.eInvoiceActivation.fiscalCode = fiscalCode;
  }

  @action
  // @ts-expect-error
  handleLegalTvaNumberUpdate(legalTvaNumber) {
    this.eInvoiceActivation.legalTvaNumber = legalTvaNumber;
    this.settings.vatNumber = legalTvaNumber;
  }

  @action
  toggleTermsAndConditions() {
    this.eInvoiceActivation.eInvoicingTermsAndConditionsAccepted =
      !this.eInvoiceActivation.eInvoicingTermsAndConditionsAccepted;
  }

  confirmTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_COMPLETED, {
      // @ts-expect-error
      source: this.args.context.source,
      step: 'company-details',
    });
    try {
      await this.eInvoiceActivation.validate();
      this.displayValidations = true;
      if (this.eInvoiceActivation.validations.isValid) {
        // Update settings
        await this.settings.save();
        await this.eInvoiceActivation.save();

        // Update onboarding status
        this.onboardingState.stepStatusCompanyDetails = ONBOARDING_STEP_STATUS.CONFIRMED;
        this.onboardingState.substepStatusCompanyDetails = ONBOARDING_STEP_STATUS.CONFIRMED;
        await this.onboardingState.save();

        // @ts-expect-error
        this.args.transitionToNext();
      }
    } catch (error) {
      // @ts-expect-error
      if (error.status === 422) {
        // @ts-expect-error
        let normalizedErrors = this._normalizeErrors(error.errors);
        // @ts-expect-error
        this.networkManager.errorModelInjector(this.settings, normalizedErrors, error);
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  // @ts-expect-error
  _normalizeErrors(errors) {
    return (
      errors
        // @ts-expect-error
        .filter(e => Boolean(e.source?.pointer))
        // @ts-expect-error
        .reduce((errs, { source, detail }) => {
          let attribute = source.pointer.split('/').pop();
          //camelize did not work here for some reason so we are temporarily going with this solution
          switch (attribute) {
            case 'contactemail':
              attribute = 'contactEmail';
              break;
            case 'vatnumber':
              attribute = 'vatNumber';
              break;
          }
          return {
            [attribute]: detail,
            ...errs,
          };
        }, {})
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::ItCompanyDetails': typeof FlowsAccountReceivableOnboardingItCompanyDetails;
  }
}
