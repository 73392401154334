/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SectionSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Section = templateOnlyComponent<SectionSignature>();

export default Section;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::Section': typeof Section;
  }
}
