/* import __COLOCATED_TEMPLATE__ from './cancel-confirmation-modal.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { getOwner } from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface CancelConfirmationModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CancelConfirmationModal extends Component<CancelConfirmationModalSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  getErrorToastTranslation(isInvalid) {
    if (isInvalid) {
      return this.intl.t('f24.archive.cancel.error.cutoff');
    }

    return this.intl.t('f24.archive.cancel.error.generic');
  }

  f24PaymentCancelTask = dropTask(async () => {
    try {
      // @ts-expect-error
      let { f24Order, closeSidebar } = this.args.data;
      await f24Order.cancelF24();
      // @ts-expect-error
      let route = getOwner(this).lookup('route:f24.list.upcoming');
      // @ts-expect-error
      await route.refresh();
      closeSidebar();
      // @ts-expect-error
      this.args.close();
      this.toastFlashMessages.toastSuccess(this.intl.t('f24.archive.cancel.success'));
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      let errorTranslation = this.getErrorToastTranslation(error instanceof InvalidError);
      this.toastFlashMessages.toastError(errorTranslation);
      // @ts-expect-error
      this.args.close();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::CancelConfirmationModal': typeof CancelConfirmationModal;
  }
}
