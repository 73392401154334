export default {
  "container": "oi",
  "summary": "oA",
  "sideview": "oY",
  "steps-container": "oh",
  "invoice-upload-container": "of",
  "pdf-preview": "oK",
  "uploader": "oG",
  "template-text": "or",
  "file-dropzone": "ob",
  "overlay": "oy",
  "footer": "oU",
  "upload-prompt": "oj",
  "step-selector": "oW",
  "disclaimer": "ou"
};
