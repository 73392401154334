/* import __COLOCATED_TEMPLATE__ from './qonto-pilot.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

// @ts-expect-error
import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';
import {
  HOTKEYS,
  modifierKey,
  QUICK_ACTIONS_DISCOVERABILITY_STORAGE_KEY,
  // @ts-expect-error
} from 'qonto/constants/qonto-pilot';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
// @ts-expect-error
import { isMac } from 'qonto/utils/is-mac';

interface DualNavQontoPilotMenuSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLIElement;
}

export default class DualNavQontoPilotMenuComponent extends Component<DualNavQontoPilotMenuSignature> {
  InstructionalTooltip = InstructionalTooltip;

  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare bannerFlashMessages: Services['bannerFlashMessages'];
  @service declare qontoPilotService: Services['qontoPilotService'];

  @tracked isInstructionalTooltipDismissed = false;

  hotkey = HOTKEYS;
  modifierKey = modifierKey(isMac());

  get hasTopBanner() {
    // @ts-expect-error
    return !this.bannerFlashMessages.isEmpty;
  }

  constructor(owner: unknown, args: DualNavQontoPilotMenuSignature['Args']) {
    super(owner, args);
    this.setEvents();
    this.router.on('routeWillChange', this.handleRouteChange);
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    this.router.off('routeWillChange', this.handleRouteChange);
  }

  @action
  // @ts-expect-error
  handleRouteChange(transition) {
    if (transition.to) {
      this.handleDismissInstructionalTooltip(false);
    }
  }

  get shouldDisplayInstructionalTooltip() {
    // @ts-expect-error
    if (this.args.state === 'collapsed') return false;
    if (!variation('feature--boolean-quick-actions-tooltip-discoverability')) {
      return false;
    }

    let storageValue = safeLocalStorage.getItem(QUICK_ACTIONS_DISCOVERABILITY_STORAGE_KEY);

    return storageValue !== 'true';
  }

  @action
  handleDismissInstructionalTooltip(shouldTrack = true) {
    if (shouldTrack) {
      this.segment.track('quick_actions-tooltip_dismiss');
    }
    // @ts-expect-error
    safeLocalStorage.setItem(QUICK_ACTIONS_DISCOVERABILITY_STORAGE_KEY, true);
    this.isInstructionalTooltipDismissed = true;
  }

  /**
   * Listener that opens the Qonto Pilot modal if the user presses T
   */
  setEvents() {
    window.addEventListener('keydown', event => {
      // @ts-expect-error
      let isEditable = document.activeElement.isContentEditable;

      if ((isMac() && event.metaKey) || (!isMac() && event.ctrlKey)) {
        if (
          Object.keys(HOTKEYS).includes(event.key.toUpperCase()) &&
          !isEditable &&
          document.activeElement === document.body &&
          (!this.qontoPilotService.modal || this.qontoPilotService.modal?.isClosing) // prevents the opening of a second modal
        ) {
          // prevents overriding unrelated native shortcut on Linux/Firefox
          if (!event.shiftKey) {
            event.preventDefault();
          }
          this.openQontoPilotModal(HOTKEYS[event.key.toUpperCase()]);
        }
      }
    });
  }

  @action
  // @ts-expect-error
  openQontoPilotModal(actionValue) {
    this.segment.track('qontopilot_modal_opened');
    this.qontoPilotService.modal = this.modals.open(
      'qonto-pilot/modal',
      { action: actionValue },
      {
        className: 'top-modal qonto-pilot',
      }
    );
  }

  get shouldShowItemDetails() {
    // @ts-expect-error
    return this.args.state === DUAL_NAV_STATES.EXPANDED;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::QontoPilot': typeof DualNavQontoPilotMenuComponent;
  }
}
