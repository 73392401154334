export default {
  "two-column-layout": "WV",
  "points-list": "WL",
  "point-title": "WT",
  "point-content": "Wz",
  "old-container": "Wa",
  "columns": "WH",
  "left-column": "WO",
  "right-column": "Wm",
  "video-wrapper": "Wp",
  "selling-points-wrapper": "Wx",
  "actions": "Ww"
};
