/* import __COLOCATED_TEMPLATE__ from './request-flash-card-kyc-intro.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlowsCardsKycFlashCardIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsKycFlashCardIntro extends Component<FlowsCardsKycFlashCardIntroSignature> {
  @service declare flowLinkManager: Services['flowLinkManager'];

  @action
  handleTransitionToNext() {
    // @ts-expect-error
    this.args.pushForwardFlow('management-kyc', 'user-info');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Requests::Cards::RequestFlashCardKycIntro': typeof FlowsCardsKycFlashCardIntro;
  }
}
