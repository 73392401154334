/* import __COLOCATED_TEMPLATE__ from './file-preview-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface FilePreviewModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FilePreviewModalComponent extends Component<FilePreviewModalSignature> {
  @action
  // @ts-expect-error
  handleRemoveFile(model, docType, file) {
    // @ts-expect-error
    this.args.data.remove(model, docType, file);
    // @ts-expect-error
    this.args.close('confirm');
  }

  get fileIndex() {
    // @ts-expect-error
    return this.args.selectedFileIndex || 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FilePreviewModal: typeof FilePreviewModalComponent;
  }
}
