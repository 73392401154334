/* import __COLOCATED_TEMPLATE__ from './integer-text-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

// @ts-expect-error
const removeTrailingSpaces = value => value?.replace(/\s/g, '');

const UTILITY_KEYS = [
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
  'Delete',
  'Home',
  'End',
  'Enter',
  'Escape',
];

interface FormFieldsIntegerTextFieldSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FormFieldsIntegerTextField extends Component<FormFieldsIntegerTextFieldSignature> {
  get currentValue() {
    // @ts-expect-error
    if (this.args.value) {
      // @ts-expect-error
      let value = typeof this.args.value === 'number' ? String(this.args.value) : this.args.value;

      // double tap on space key produces a dot in input, to be on the safe side
      return value.replace(/\./g, '');
    } else {
      // in case the default value is zero (or any falsy value), we return null
      return null;
    }
  }

  get hasError() {
    // @ts-expect-error
    if (typeof this.args.hasError === 'boolean') {
      // @ts-expect-error
      return this.args.hasError;
    }

    // @ts-expect-error
    let { errorMessage, errorLevel } = this.args;
    let hasMessage = false;

    // @message can potentially be a SafeString object when using the `htmlSafe=true` option in `intl.t()`
    // eg: `@message={{t "my.key" htmlSafe=true}}`
    if (errorMessage) {
      hasMessage =
        typeof errorMessage === 'string'
          ? errorMessage.length > 0
          : errorMessage.toString().length > 0;
    }

    return errorLevel === 'error' && hasMessage;
  }

  @action
  // @ts-expect-error
  onKeyDown(event) {
    let isIntegerKey = /^[0-9]$/.test(event.key);
    let isUtilityKey = UTILITY_KEYS.includes(event.key);

    // @ts-expect-error
    let canBeZero = this.args.canBeZero ?? true;
    let isEmpty = !event.target.value;
    let isZero = isIntegerKey ? event.key === '0' : false;
    let shouldPreventPressedKey = !canBeZero && isEmpty && isZero;

    if ((!isIntegerKey && !isUtilityKey) || shouldPreventPressedKey) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  @action
  // @ts-expect-error
  onInput(event) {
    let sanitizedValue = removeTrailingSpaces(event.target.value);

    // @ts-expect-error
    this.args.onUpdate(sanitizedValue || null);
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::IntegerTextField': typeof FormFieldsIntegerTextField;
  }
}
