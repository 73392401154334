/* import __COLOCATED_TEMPLATE__ from './pagopa-notice-number-field.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { isHTMLSafe } from '@ember/template';
import Component from '@glimmer/component';

import { formatPagopaNoticeNumber, handleFormatPagopaNoticeNumber } from 'qonto/utils/format-input';

interface PagopaNoticeNumberFieldSignature {
  // The arguments accepted by the component
  Args: {
    isOptional?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PagopaNoticeNumberField extends Component<PagopaNoticeNumberFieldSignature> {
  elementId = guidFor(this);
  inputId = `${this.elementId}-input`;
  messageId = `${this.elementId}-msg`;

  get noticeInfoValue() {
    // @ts-expect-error
    let { value } = this.args;
    return formatPagopaNoticeNumber(value);
  }

  get hasError() {
    // @ts-expect-error
    let { message, messageLevel } = this.args;
    // @message can potentially be a SafeString object when using the `htmlSafe=true` option in `intl.t()`
    // eg: `@message={{t "my.key" htmlSafe=true}}`
    return (
      // The SafeString object exposes a `toString` method
      // Native string
      messageLevel === 'error' &&
      (message?.length > 0 || (message && isHTMLSafe(message) && message.toString?.().length > 0))
    );
  }

  @action
  // @ts-expect-error
  handleInput({ target }) {
    handleFormatPagopaNoticeNumber(target);
    // @ts-expect-error
    this.args.update(target.value.replace(/[^a-zA-Z0-9]/g, ''));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::PagopaNoticeNumberField': typeof PagopaNoticeNumberField;
  }
}
