/* import __COLOCATED_TEMPLATE__ from './invite-card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_FLOWS_TRACKING_ORIGINS } from 'qonto/constants/cards';

interface FlowsInviteCardSuccessInviteSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsInviteCardSuccessInviteComponent extends Component<FlowsInviteCardSuccessInviteSignature> {
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];
  @service declare flow: Services['flow'];

  constructor(owner: unknown, args: FlowsInviteCardSuccessInviteSignature['Args']) {
    super(owner, args);

    this.segment.track('invitation-success_displayed', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION,
    });
  }

  @action primaryAction() {
    this.segment.track('cards_order_clicked', {
      origin: 'invitation-success_user-access',
    });

    // @ts-expect-error
    let { invitedMember } = this.args.context;
    this.flow.reset();
    this.flowLinkManager.transitionTo({
      name: 'card-new-invitee',
      queryParams: { inviteeMembershipId: invitedMember.recipientId },
    });
  }

  @action secondaryAction() {
    this.segment.track('team_invite_selected', {
      origin: 'invitation-success_user-access',
    });

    // @ts-expect-error
    this.args.restartFlow({ initialStepId: 'role' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::Success::InviteCard': typeof FlowsInviteCardSuccessInviteComponent;
  }
}
