/* import __COLOCATED_TEMPLATE__ from './success-renewal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CardImage } from 'qonto/react/cards/components/card-image';

interface FlowsCardsSuccessRenewalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsSuccessRenewal extends Component<FlowsCardsSuccessRenewalSignature> {
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];

  cardImage = CardImage;

  @action handleOpenEducationalModal() {
    this.modals.open('card/modals/digital-first', {
      isFullScreenModal: true,
    });
    this.segment.track('cards_renewal_digital_first_education_opened');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::SuccessRenewal': typeof FlowsCardsSuccessRenewal;
  }
}
