export default {
  "sub-menu": "Ov",
  "collapsed": "Oo",
  "collapse-left": "On",
  "responsive": "Oi",
  "expanded": "OA",
  "no-slide": "OY",
  "visible": "Oh",
  "hidden": "Of",
  "group": "OK",
  "promo-box": "OG",
  "title": "Or",
  "quick-access-padding": "Ob",
  "scrolled-top": "Oy",
  "scrollable-content": "OU"
};
