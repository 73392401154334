/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

import { FAST_TRACK_DEPOSIT_CAPITAL_STATUS } from 'qonto/constants/organization';

let {
  COMPANY_VERIFICATION,
  DEPOSIT_REQUEST_SENT,
  DEPOSIT_REQUEST_SIGNED,
  DEPOSIT_CERTIFICATE_SIGNED,
  KBIS_SUBMITTED,
  DEPOSIT_RELEASE_REQUESTED,
} = FAST_TRACK_DEPOSIT_CAPITAL_STATUS;

interface CapitalDepositFastTrackStatusSignature {
  // The arguments accepted by the component
  Args: {
    isOnboardingPartnerAccountant?: boolean;
    legalPlaceUrl?: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositFastTrackStatusComponent extends Component<CapitalDepositFastTrackStatusSignature> {
  lottiePlayer = LottiePlayer;

  @service declare intl: Services['intl'];
  @service declare featuresManager: Services['featuresManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare organizationManager: Services['organizationManager'];

  get isCompanyCreationFromScratch() {
    return this.organizationManager.organization.companyCreationFromScratch;
  }

  fastTrackStatuses = {
    [COMPANY_VERIFICATION]: this.companyVerificationCopies,

    [DEPOSIT_REQUEST_SENT]: this.isQontoNotaryEnabled
      ? {
          // @ts-expect-error
          title: this.intl.t('on_boarding.deposit-certificate-step.title', {
            // @ts-expect-error
            legal_tech_partner_name: this.args.onboardingPartner,
          }),
          // @ts-expect-error
          subtitle: this.intl.t('on_boarding.deposit-certificate-step.subtitle'),
          image: '/illustrations/ill-cp-onboarding-capital-deposit-sent.svg',
        }
      : {
          // @ts-expect-error
          title: this.intl.t('on_boarding.notary-step.title'),
          // @ts-expect-error
          subtitle: this.intl.t('on_boarding.notary-step.subtitle'),
          image: '/illustrations/ill-cp-onboarding-capital-deposit-notary-review.svg',
        },

    [DEPOSIT_REQUEST_SIGNED]: {
      // @ts-expect-error
      title: this.intl.t('on_boarding.notary-step.title'),
      // @ts-expect-error
      subtitle: this.intl.t('on_boarding.notary-step.subtitle'),
      image: '/illustrations/ill-cp-onboarding-capital-deposit-notary-review.svg',
    },

    [DEPOSIT_CERTIFICATE_SIGNED]: this.depositCertificateSignedCopies,

    [KBIS_SUBMITTED]: this.kbisSumbittedCopies,

    [DEPOSIT_RELEASE_REQUESTED]: {
      // @ts-expect-error
      title: this.intl.t('on_boarding.account-open-step.title'),
      // @ts-expect-error
      subtitle: this.intl.t('on_boarding.account-open-step.subtitle'),
    },
  };

  get fastTrackStatusImage() {
    // @ts-expect-error
    return this.fastTrackStatus.image;
  }

  get fastTrackStatusTitle() {
    // @ts-expect-error
    return this.fastTrackStatus.title;
  }

  get fastTrackStatusSubtitle() {
    // @ts-expect-error
    return this.fastTrackStatus.subtitle;
  }

  get fastTrackStatus() {
    return (
      // @ts-expect-error
      this.fastTrackStatuses[this.args.status] ??
      this.fastTrackStatuses[FAST_TRACK_DEPOSIT_CAPITAL_STATUS.COMPANY_VERIFICATION]
    );
  }

  get companyVerificationCopies() {
    if (this.isCompanyCreationFromScratch) {
      return {
        title: this.intl.t('on_boarding.company-verification.fr-full-cc.title'),
        subtitle: this.intl.t('on_boarding.company-verification.fr-full-cc.subtitle'),
        image: '/illustrations/ill-cp-onboarding-company-verification.svg',
      };
    } else if (this.args.isOnboardingPartnerAccountant) {
      return {
        title: this.intl.t('on_boarding.company-verification.accountant.title'),
        subtitle: this.intl.t('on_boarding.company-verification.accountant.subtitle'),
        image: '/illustrations/ill-cp-onboarding-company-verification.svg',
      };
    } else {
      return {
        title: this.intl.t('on_boarding.company-verification.title', {
          // @ts-expect-error
          legal_tech_partner_name: this.args.onboardingPartner,
        }),
        subtitle: this.intl.t('on_boarding.company-verification.subtitle'),
        image: '/illustrations/ill-cp-onboarding-company-verification.svg',
      };
    }
  }

  get depositCertificateSignedCopies() {
    if (this.isCompanyCreationFromScratch) {
      return {
        title: this.intl.t('on_boarding.deposit-certificate-step.fr-full-cc.title'),
        subtitle: this.intl.t('on_boarding.deposit-certificate-step.fr-full-cc.subtitle'),
        image: '/illustrations/ill-cp-onboarding-capital-deposit-sent.svg',
      };
    }
    if (this.args.isOnboardingPartnerAccountant) {
      return {
        title: this.intl.t('on_boarding.deposit-certificate-step.accountant.title'),
        subtitle: this.intl.t('on_boarding.deposit-certificate-step.accountant.subtitle'),
        image: '/illustrations/ill-cp-onboarding-capital-deposit-sent.svg',
      };
    } else {
      return {
        title: this.intl.t('on_boarding.deposit-certificate-step.title', {
          // @ts-expect-error
          legal_tech_partner_name: this.args.onboardingPartner,
        }),
        subtitle: this.intl.t('on_boarding.deposit-certificate-step.subtitle'),
        image: '/illustrations/ill-cp-onboarding-capital-deposit-sent.svg',
      };
    }
  }

  get kbisSumbittedCopies() {
    if (this.isCompanyCreationFromScratch) {
      return {
        title: this.intl.t('on_boarding.registration-proof-step.fr-full-cc.title'),
        subtitle: this.intl.t('on_boarding.registration-proof-step.fr-full-cc.subtitle'),
        image: '/illustrations/ill-cp-onboarding-kbis-check.svg',
      };
    }
    if (this.args.isOnboardingPartnerAccountant) {
      return {
        title: this.intl.t('on_boarding.registration-proof-step.accountant.title'),
        subtitle: this.intl.t('on_boarding.registration-proof-step.accountant.subtitle'),
        image: '/illustrations/ill-cp-onboarding-kbis-check.svg',
      };
    } else {
      return {
        title: this.intl.t('on_boarding.registration-proof-step.title', {
          // @ts-expect-error
          legal_tech_partner_name: this.args.onboardingPartner,
        }),
        subtitle: this.intl.t('on_boarding.registration-proof-step.subtitle'),
        image: '/illustrations/ill-cp-onboarding-kbis-check.svg',
      };
    }
  }

  get isDepositReleaseRequestedStatus() {
    // @ts-expect-error
    return this.args.status === FAST_TRACK_DEPOSIT_CAPITAL_STATUS.DEPOSIT_RELEASE_REQUESTED;
  }

  get isQontoNotaryEnabled() {
    return this.featuresManager.isEnabled('qontoNotary');
  }

  @action trackProgressInlegalPlace() {
    let { legalPlaceUrl } = this.args;
    if (legalPlaceUrl) {
      window.location.href = legalPlaceUrl;
    }
  }

  @action chooseCard() {
    this.flowLinkManager.transitionTo({
      name: 'card-onboarding',
      stepId: 'choose-card',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CapitalDeposit::FastTrack::Status': typeof CapitalDepositFastTrackStatusComponent;
  }
}
