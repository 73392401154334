/* import __COLOCATED_TEMPLATE__ from './account-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { BankAccountsSelectStep } from 'qonto/react/components/bank-accounts/select-step';

interface AccountSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AccountSelectionComponent extends Component<AccountSelectionSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  selectStep = BankAccountsSelectStep;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('args.context.payment') payment;

  get amount() {
    return this.intl.formatNumber(this.payment.amount.value, {
      currency: this.payment.amount.currency,
      style: 'currency',
    });
  }

  get feeAmountFormatted() {
    if (parseFloat(this.payment.feeEstimateAmount?.value) > 0) {
      return this.intl.formatNumber(this.payment.feeEstimateAmount.value, {
        currency: this.payment.feeEstimateAmount.currency,
        style: 'currency',
      });
    }
  }

  get creditorName() {
    return this.payment.creditorName;
  }

  get paymentDisclaimer() {
    return this.feeAmountFormatted
      ? this.intl.t('pagopa.payment-creation.account-selection.disclaimer-fee', {
          amount: this.amount,
          beneficiary: this.creditorName,
          feeAmount: this.feeAmountFormatted,
        })
      : this.intl.t('pagopa.payment-creation.account-selection.disclaimer', {
          amount: this.amount,
          beneficiary: this.creditorName,
        });
  }

  @action
  // @ts-expect-error
  setBankAccount(bankAccount) {
    this.segment.track('pagopa_payment-flow_account_select-button_clicked');
    this.payment.bankAccount = bankAccount;
  }

  @action
  // @ts-expect-error
  isBankAccountEnabled({ authorizedBalance }) {
    let amount = Number(this.payment.amount.value);
    return authorizedBalance >= amount;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Pagopa::AccountSelection': typeof AccountSelectionComponent;
  }
}
