/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { Header } from 'qonto/react/connections/components/table/header';

interface TableSignature {
  Element: null;
  Args: {
    isSidebarOpen?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class Table extends Component<TableSignature> {
  header = Header;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Apps::Table': typeof Table;
  }
}
