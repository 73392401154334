/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import Component from '@glimmer/component';

import { Success } from 'qonto/react/components/flows/addon-change/success';
interface SubscriptionChangeSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionChangeSuccessComponent extends Component<SubscriptionChangeSuccessSignature> {
  success = Success;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AddonChange::Success': typeof SubscriptionChangeSuccessComponent;
  }
}
