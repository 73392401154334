/* import __COLOCATED_TEMPLATE__ from './amount-limit.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, waitForQueue } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface CardSettingsAmountLimitSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class CardSettingsAmountLimitComponent extends Component<CardSettingsAmountLimitSignature> {
  @service declare sentry: Services['sentry'];

  constructor(owner: unknown, args: CardSettingsAmountLimitSignature['Args']) {
    super(owner, args);
    this.setAutoFocusTask.perform().catch(ignoreCancelation);
  }

  setAutoFocusTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');
      // @ts-expect-error
      if (!this.args.autofocusDisabled) {
        let inputElement = document.getElementById('amount-input');
        // @ts-expect-error
        next(() => inputElement.focus());
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  // @ts-expect-error
  onChange(value) {
    let amountLimit;

    if (value === '') {
      amountLimit = null;
    } else {
      amountLimit = Number(value);
    }

    // @ts-expect-error
    if (amountLimit >= 0) {
      // @ts-expect-error
      this.args.onChange(amountLimit);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::AmountLimit': typeof CardSettingsAmountLimitComponent;
  }
}
