/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

interface FlowsCardsOptionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsOptions extends Component<FlowsCardsOptionsSignature> {
  @service declare abilities: Services['abilities'];
  @service declare segment: Services['segment'];

  get trackingParams() {
    // @ts-expect-error
    let { card, flowTrackingOrigin, isUserCardHolder } = this.args.context;
    let { atmOption, nfcOption, onlineOption, foreignOption, cardLevel } = card;

    return {
      card_type: CARD_LEVELS_TRACKING[cardLevel],
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      withdrawals: atmOption,
      contactless: nfcOption,
      online: onlineOption,
      international: foreignOption,
      origin: flowTrackingOrigin,
    };
  }

  @action
  continueToNextStep() {
    this.segment.track('cards_order_settings_submitted', this.trackingParams);
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Options': typeof FlowsCardsOptions;
  }
}
