/* import __COLOCATED_TEMPLATE__ from './cards-grid.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CardsGridSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const CardsGrid = templateOnlyComponent<CardsGridSignature>();

export default CardsGrid;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::CardsGrid': typeof CardsGrid;
  }
}
