export default {
  "wrapper": "cRc",
  "close-button": "cRq",
  "content-wrapper": "cRZ",
  "main": "cRR",
  "main-content": "cRQ",
  "disclaimer": "cRe",
  "description": "cRB",
  "mt-26": "cRE",
  "mt-62": "cRd",
  "user-prompt": "cRI",
  "user-prompt-inappropriate-warning": "cRt",
  "logo-preview": "cRM",
  "static-loader": "cRP",
  "loading-state-illustration": "cRl",
  "sticky-panel": "cRX"
};
