/* import __COLOCATED_TEMPLATE__ from './discover-modal.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface CounterpartiesDiscoverModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CounterpartiesDiscoverModalComponent extends Component<CounterpartiesDiscoverModalSignature> {
  @service declare organizationManager: Services['organizationManager'];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::DiscoverModal': typeof CounterpartiesDiscoverModalComponent;
  }
}
