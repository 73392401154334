/* import __COLOCATED_TEMPLATE__ from './details-header.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

interface DirectDebitCollectionsDetailsHeaderSignature {
  // The arguments accepted by the component
  Args: {
    isPastDueDateDisclaimerShown?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class DirectDebitCollectionsDetailsHeaderComponent extends Component<DirectDebitCollectionsDetailsHeaderSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  get shouldAddNoteFullStop() {
    // @ts-expect-error
    return this.args.multiRequest?.note && this.args.multiRequest.note.slice(-1) !== '.';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::MultiRequest::DetailsHeader': typeof DirectDebitCollectionsDetailsHeaderComponent;
  }
}
