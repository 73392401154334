/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

interface PreviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PreviewComponent extends Component<PreviewSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: PreviewSignature['Args']) {
    super(owner, args);
    this.segment.track('nrc_summary_opened');
  }

  get amount() {
    // @ts-expect-error
    return this.args.context.nrcPayment.amount;
  }

  get receiptCode() {
    // @ts-expect-error
    return this.args.context.nrcPayment.receiptCode;
  }

  get selectedModelo() {
    // @ts-expect-error
    return `Modelo ${Number(this.args.context.selectedModelo.tax_code)}`;
  }

  get bankAccount() {
    // @ts-expect-error
    return this.args.context.nrcPayment.bankAccount;
  }

  get legalName() {
    // @ts-expect-error
    return this.args.context.nrcPayment.legalName;
  }

  get documentId() {
    // @ts-expect-error
    return this.args.context.nrcPayment.documentId;
  }

  get fiscalYear() {
    // @ts-expect-error
    return this.args.context.nrcPayment.fiscalYear;
  }

  get period() {
    // @ts-expect-error
    let period = this.args.context.nrcPayment.period;
    if (!period) return null;

    let match = period.match(/(\d+|0)([MTPA])/);
    if (!match) return period;

    let [, number, periodType] = match;

    switch (periodType) {
      case 'M':
        return this.intl.t('aeat.pay.field.period.mensual', {
          number: number.padStart(2, '0'),
        });
      case 'T':
        return this.intl.t('aeat.pay.field.period.trimestre', {
          number: number.padStart(2, '0'),
        });
      case 'P':
        return this.intl.t('aeat.pay.field.period.periodo', {
          number: number.padStart(2, '0'),
        });
      case 'A':
        return this.intl.t('aeat.pay.field.period.anual');
      default:
        return period;
    }
  }

  get isPaymentInInstallments() {
    // @ts-expect-error
    return this.args.context.nrcPayment.isPaymentInInstallments;
  }

  get paymentYear() {
    // @ts-expect-error
    return Number(this.args.context.nrcPayment.fiscalYear) + 1;
  }

  get currentPayment() {
    if (!this.isPaymentInInstallments || !this.amount) return null;
    return {
      value: this.amount.value * 0.6,
      currency: this.amount.currency,
    };
  }

  get futurePayment() {
    if (!this.isPaymentInInstallments || !this.amount) return null;
    return {
      value: this.amount.value * 0.4,
      currency: this.amount.currency,
    };
  }

  proceedToPayment = dropTask(async () => {
    try {
      // @ts-expect-error
      let response = await this.args.context.nrcPayment.createPayment();
      // @ts-expect-error
      this.args.context.requestId = response.request_id;
      this.segment.track('nrc_summary_completed');
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      } else {
        this.segment.track('nrc_summary_completed');
      }

      // @ts-expect-error
      if (error?.status === 422) {
        // @ts-expect-error
        this.args.context.showErrorScreen = true;
        // @ts-expect-error
        this.args.transitionToNext();
        this.sentry.captureException(error);
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::NrcPayment::Preview': typeof PreviewComponent;
  }
}
