/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface F24ManualDeclarationOrdinarioFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24ManualDeclarationOrdinarioFormComponent extends Component<F24ManualDeclarationOrdinarioFormSignature> {
  @service declare segment: Services['segment'];
  @service declare errors: Services['errors'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  validateOrderTask = task(async () => {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let { f24Order } = context;

    try {
      f24Order.documentAmount = String(f24Order.totalAmount);
      await f24Order.validate();
      this.segment.track('f24_create-flow_step1-form-submitted');
      transitionToNext();
    } catch (error) {
      let isInvalidError = f24Order.isInvalidErrorWithPointers(error);
      // @ts-expect-error
      let isServiceUnavailableError = error.status === 503;

      if (!isInvalidError && ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // @ts-expect-error
      let genericMessage = this.errors.messageForStatus(error);
      if (genericMessage) {
        this.toastFlashMessages.toastError(genericMessage);
      } else if (isServiceUnavailableError) {
        this.toastFlashMessages.toastError(
          this.intl.t('toasts.errors.server_error', { htmlSafe: true })
        );
      } else {
        this.toastFlashMessages.toastError(
          this.intl.t('f24.manual-declaration.module.generic-error')
        );
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::F24::ManualDeclaration::Ordinario::Form': typeof F24ManualDeclarationOrdinarioFormComponent;
  }
}
