/* import __COLOCATED_TEMPLATE__ from './bank-details.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { DEFAULT_LOCALE } from '@repo/shared-config/constants/locales';

interface BankDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BankDetailsComponent extends Component<BankDetailsSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare organizationManager: Services['organizationManager'];
  @service declare userManager: Services['userManager'];
  @service declare intl: Services['intl'];

  get recommendedPrice() {
    // @ts-expect-error
    return this.args.context.recommendedPrice;
  }

  get bankAccount() {
    return this.organizationManager.organization.mainAccount;
  }

  get currentUserLocale() {
    return (
      // @ts-expect-error
      this.intl.getLocaleCodes.find(
        // @ts-expect-error
        code => code === this.userManager.currentUser.localeObject.code
      ) || DEFAULT_LOCALE
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::PaymentActivation::BankDetails': typeof BankDetailsComponent;
  }
}
