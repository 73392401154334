export default {
  "container": "uH",
  "has-background": "uO",
  "wrapper": "um",
  "error-wrapper": "up",
  "bottom-btns": "ux",
  "bottom-wrapper": "uw",
  "tab-nav": "uN",
  "tab-nav-small": "uv",
  "btn-clear": "uo",
  "error-illustration": "un",
  "error-title": "ui title-1",
  "disclaimer-wrapper": "uA",
  "disclaimer": "uY",
  "error-description": "uh body-2",
  "table-wrapper": "uf",
  "title": "uK title-1",
  "lineup-toggle": "uG",
  "description": "ur body-2",
  "loading-wrapper": "ub",
  "card": "uy",
  "plan": "uU",
  "placeholder-header": "uj",
  "disclaimer-info": "uW",
  "spinner": "uu"
};
