/* import __COLOCATED_TEMPLATE__ from './choose-alert.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface QontoHubNotificationsCreateStepsChooseAlertSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QontoHubNotificationsCreateStepsChooseAlertComponent extends Component<QontoHubNotificationsCreateStepsChooseAlertSignature> {
  @tracked hasBalanceAlert;
  @tracked hasTransactionAlert;

  constructor(owner: unknown, args: QontoHubNotificationsCreateStepsChooseAlertSignature['Args']) {
    super(owner, args);
    this.hasBalanceAlert = this.findRuleByTrigger('balance');
    this.hasTransactionAlert = this.findRuleByTrigger('transaction');
  }

  // @ts-expect-error
  findRuleByTrigger(trigger) {
    // @ts-expect-error
    let { rules } = this.args.context.hubConnection;
    // @ts-expect-error
    return rules.find(rule => !rule.isNew && rule.trigger === trigger);
  }

  get channelUrl() {
    let reg = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/;
    // @ts-expect-error
    let { configuration_url, channel } = this.args.context.hubConnection.params;

    // @ts-expect-error
    let domainName = reg.exec(configuration_url)[0];
    let channelName = channel.replace('#', '');

    return `${domainName}/app_redirect?channel=${channelName}`;
  }

  @action
  // @ts-expect-error
  handleNext(trigger) {
    let isBalance = trigger === 'balance';

    // @ts-expect-error
    this.args.context.hubNotificationRule.setProperties({
      trigger,
      field: isBalance ? 'authorized_balance_cents' : 'amount_cents',
      filter: isBalance ? 'lte' : 'gte',
    });
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Connect::Applications::Activation::ChooseAlert': typeof QontoHubNotificationsCreateStepsChooseAlertComponent;
  }
}
