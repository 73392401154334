export default {
  "container": "Na",
  "header-wrapper": "NH mb-16",
  "header": "NO",
  "logo": "Nm mr-16",
  "title": "Np title-4",
  "description": "Nx body-2",
  "conditions": "Nw",
  "label": "NN caption mb-4",
  "tags": "Nv"
};
