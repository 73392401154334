/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface FlowsKYCIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsKYCIntro extends Component<FlowsKYCIntroSignature> {
  lottiePlayer = LottiePlayer;

  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  animationReady = false;
  defaultLottieSrc = '/lotties/fourthline/intro.json';

  get originTrackingParam() {
    // @ts-expect-error
    switch (this.args.origin) {
      case 'cards':
        return 'card_flow';
      case 'transfers':
        return 'transfer_flow';
      default:
        return null;
    }
  }

  @action
  handleNext() {
    let { personalInfoProvided } = this.organizationManager.membership;

    if (personalInfoProvided) {
      this.router.transitionTo('kyc.start', this.organizationManager.organization.slug, {
        queryParams: {
          trackingEventOrigin: this.originTrackingParam,
        },
      });
    } else {
      this.segment.track('submit_kyc_information_start', {
        origin: this.originTrackingParam,
      });

      // @ts-expect-error
      this.args.transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Kyc::Intro': typeof FlowsKYCIntro;
  }
}
