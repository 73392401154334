/* import __COLOCATED_TEMPLATE__ from './files-download-list.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FilesDownloadListSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const FilesDownloadList = templateOnlyComponent<FilesDownloadListSignature>();

export default FilesDownloadList;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FilesDownloadList: typeof FilesDownloadList;
  }
}
