/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ConnectFiltersSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ConnectFiltersComponent extends Component<ConnectFiltersSignature> {
  get hasFilters() {
    // @ts-expect-error
    return this.args.selectedCategory || this.args.selectedStakeholder;
  }

  @action
  // @ts-expect-error
  onCategorySelect(value) {
    // @ts-expect-error
    this.args.onCategorySelect?.(value);
  }

  @action
  // @ts-expect-error
  onStakeholderSelect(value) {
    // @ts-expect-error
    this.args.onStakeholderSelect?.(value);
  }

  @action
  onFilterReset() {
    // @ts-expect-error
    this.args.onFilterReset?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::Filters': typeof ConnectFiltersComponent;
  }
}
