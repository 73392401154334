export default {
  "wrapper": "HP",
  "header": "Hl",
  "no-separator": "HX",
  "tabs": "HC",
  "tab": "Hk body-1 overlay",
  "selected": "HJ",
  "organization-list": "Hs",
  "content": "HF",
  "empty-state": "Hg",
  "illustration": "HD",
  "body": "HV body-1",
  "cta": "HL btn btn--tertiary"
};
