/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

interface CardSidebarFooterSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardSidebarFooterComponent extends Component<CardSidebarFooterSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare modals: Services['modals'];

  unlockTask = dropTask(async card => {
    try {
      await card.unlock();
      let message = this.intl.t('toasts.cards.unlock');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  @action handleLostOrStolen() {
    this.modals.open('card/modals/suspend', {
      // @ts-expect-error
      card: this.args.card,
      shouldShowSuccess: true,
    });

    this.segment.track('card_lost_stolen_clicked');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::Footer': typeof CardSidebarFooterComponent;
  }
}
