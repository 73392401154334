export default {
  "tile-layout": "Qr",
  "focus": "Qb",
  "no-shadow": "Qy",
  "tile-layout--start-verification": "QU",
  "header": "Qj",
  "headings": "QW",
  "management": "Qu",
  "logo": "eS",
  "title": "ec title-4",
  "name": "eq title-4",
  "loading": "eZ",
  "menu-item": "eR body-2",
  "close-account": "eQ",
  "content": "ee"
};
