/* import __COLOCATED_TEMPLATE__ from './permissions-details.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PermissionsDetailsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PermissionsDetails = templateOnlyComponent<PermissionsDetailsSignature>();

export default PermissionsDetails;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::PermissionsDetails': typeof PermissionsDetails;
  }
}
