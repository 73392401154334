/* import __COLOCATED_TEMPLATE__ from './permission-toggle-card.hbs'; */
import Component from '@glimmer/component';

import { Toggle } from '@repo/design-system-kit';

interface FlowsMemberOtherPermissionsPermissionToggleCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsMemberOtherPermissionsPermissionToggleCardComponent extends Component<FlowsMemberOtherPermissionsPermissionToggleCardSignature> {
  toggle = Toggle;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::OtherPermissions::PermissionToggleCard': typeof FlowsMemberOtherPermissionsPermissionToggleCardComponent;
  }
}
