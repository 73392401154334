export default {
  "container": "WP",
  "form-core": "Wl",
  "label": "WX",
  "request-description": "WC",
  "radio-option": "Wk",
  "radio-button": "WJ",
  "inline-inputs": "Ws",
  "placeholder-label": "WF",
  "placeholder-input": "Wg",
  "placeholder-checkbox": "WD"
};
