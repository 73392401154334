/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { task, timeout } from 'ember-concurrency';

import { FUNDING_DEBOUNCE_MS } from 'qonto/constants/bank-account';

interface FlowsAccountsRemuneratedFundingSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountsRemuneratedFundingSuccess extends Component<FlowsAccountsRemuneratedFundingSuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare router: Services['router'];

  redirectAccountsTask = task(async () => {
    await timeout(FUNDING_DEBOUNCE_MS);
    // @ts-expect-error
    this.router.replaceWith(this.args.context.origin);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Accounts::Remunerated::Funding::Success': typeof FlowsAccountsRemuneratedFundingSuccess;
  }
}
