export default {
  "step-container": "KK",
  "content": "KG",
  "section": "Kr",
  "section-content": "Kb",
  "section-content-text": "Ky",
  "section-illustration": "KU",
  "close-form-cta": "Kj",
  "bank-account-select": "KW"
};
