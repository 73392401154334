export default {
  "cell": "EB caption-bold",
  "empty": "EE",
  "transaction": "Ed",
  "member": "EI",
  "method": "Et",
  "operation-date": "EM",
  "amount": "EP",
  "label": "El caption-bold",
  "active": "EX"
};
