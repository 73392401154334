/* import __COLOCATED_TEMPLATE__ from './handler.hbs'; */
import Component from '@glimmer/component';

interface CardBannersHandlerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardBannersHandler extends Component<CardBannersHandlerSignature> {
  get countPhysicalCardsToRenew() {
    // @ts-expect-error
    let { currentUserPhysicalCards } = this.args;
    // @ts-expect-error
    return currentUserPhysicalCards?.filter(card => card.isRenewable).length || 0;
  }

  get hasCardsEligibleForRenewal() {
    return this.countPhysicalCardsToRenew > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Banners::Handler': typeof CardBannersHandler;
  }
}
