/* import __COLOCATED_TEMPLATE__ from './initial.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ONBOARDING_STATUS, ONBOARDING_TRACKING_EVENTS } from 'qonto/constants/receivable-invoice';
import { NextStep } from 'qonto/react/components/flows/invoices/next-step';

interface AccountReceivableOnboardingSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AccountReceivableOnboardingSuccess extends Component<AccountReceivableOnboardingSuccessSignature> {
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  // @ts-expect-error
  @reads('args.context.onboardingState') onboardingState;

  nextStepComponent = NextStep;

  @action
  continueWithOnboarding() {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.GUIDED_SETUP);
    // @ts-expect-error
    this.args.transitionToNext();
  }

  goToInvoiceCreation = dropTask(async () => {
    try {
      this.segment.track(ONBOARDING_TRACKING_EVENTS.MANUAL_SETUP);
      this.onboardingState.status = ONBOARDING_STATUS.SKIPPED;
      await this.onboardingState.save();
      this.router.transitionTo('receivable-invoices.new');
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::Initial': typeof AccountReceivableOnboardingSuccess;
  }
}
