/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { TablePlaceholder } from 'qonto/react/components/budget/details/table/placeholder';

interface PaginatesTableSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PaginatesTableComponent extends Component<PaginatesTableSignature> {
  tablePlaceholder = TablePlaceholder;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budget::Details::Table': typeof PaginatesTableComponent;
  }
}
