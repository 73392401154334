/* import __COLOCATED_TEMPLATE__ from './e-invoicing-opt-in-pop-up.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Checkbox } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

import {
  LAST_DISMISSED_DATE_LOCAL_STORAGE_KEY,
  SEGMENTATION_LEVELS,
} from 'qonto/constants/e-invoicing-fr';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface EInvoicingOptInPopUpSignature {
  Args: {
    data?: {
      origin?: string;
      segmentation?: string;
    };
  };
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class EInvoicingOptInPopUpComponent extends Component<EInvoicingOptInPopUpSignature> {
  @service declare segment: Services['segment'];
  @service declare homePage: Services['homePage'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];

  get origin() {
    return this.args?.data?.origin ?? 'standard';
  }

  constructor(owner: unknown, args: EInvoicingOptInPopUpSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let { segmentation } = this.args.data;
    this.segment.track('einvoicing-consent_opened', { segmentation, origin: this.origin });
  }
  checkbox = Checkbox;
  badge = Badge;

  @tracked einvoicingConsent = false;
  @tracked isConfirmClicked = false;

  get isConfirmedWithoutConsent() {
    return this.isConfirmClicked && !this.einvoicingConsent;
  }

  setDismissDate() {
    let now = new Date();
    safeLocalStorage.setItem(LAST_DISMISSED_DATE_LOCAL_STORAGE_KEY, now.toISOString());
  }

  @action
  // @ts-expect-error
  handleOnClose(cta) {
    // @ts-expect-error
    let { segmentation } = this.args.data;

    if (cta === 'dismiss-button') {
      this.segment.track('einvoicing-consent_later_clicked', { segmentation, origin: this.origin });
    } else if (cta === 'close-button') {
      this.segment.track('einvoicing-consent_close_clicked', { segmentation, origin: this.origin });
    }

    this.setDismissDate();
    // @ts-expect-error
    return this.args.close();
  }

  @action
  toggleEinvoicingConsent() {
    this.einvoicingConsent = !this.einvoicingConsent;

    if (this.einvoicingConsent) {
      this.isConfirmClicked = false;
    }
  }

  get segmentationConsentTitle() {
    // @ts-expect-error
    let { segmentation } = this.args.data;
    // @ts-expect-error
    const key = SEGMENTATION_LEVELS[segmentation].title;
    return this.intl.t(key);
  }

  get segmentationConsentSubtitleKey() {
    // @ts-expect-error
    let { segmentation } = this.args.data;
    // @ts-expect-error
    return SEGMENTATION_LEVELS[segmentation].subtitle;
  }

  saveSettingsTask = task(async () => {
    // @ts-expect-error
    let { segmentation } = this.args.data;

    if (this.einvoicingConsent) {
      try {
        this.segment.track('einvoicing-consent_enable_clicked', {
          segmentation,
          origin: this.origin,
        });

        let organizationId = this.organizationManager.organization.id;
        let settings = this.store.peekRecord('einvoicing-fr-settings', organizationId);

        if (!settings) {
          throw new Error('French E-Invoicing settings not found');
        }

        await settings.activate();

        this.toastFlashMessages.toastSuccess(this.intl.t('einvoicing-consent.success-toast'));
      } catch (error) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      } finally {
        // @ts-expect-error
        this.handleOnClose();
      }
    } else {
      this.isConfirmClicked = true;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    EInvoicingOptInPopUp: typeof EInvoicingOptInPopUpComponent;
  }
}
