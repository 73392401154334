/* import __COLOCATED_TEMPLATE__ from './in-progress.hbs'; */
import Component from '@glimmer/component';

import { EmptyStatesInform } from '@repo/domain-kit/pricing';

interface FinancingPayLaterCockpitEmptyStateInProgressSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FinancingPayLaterCockpitEmptyStateInProgressComponent extends Component<FinancingPayLaterCockpitEmptyStateInProgressSignature> {
  emptyStatesInform = EmptyStatesInform;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::PayLater::Cockpit::EmptyState::InProgress': typeof FinancingPayLaterCockpitEmptyStateInProgressComponent;
  }
}
