/* import __COLOCATED_TEMPLATE__ from './einvoice-activation.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface EInvoiceActivationControllerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EInvoiceActivationController extends Component<EInvoiceActivationControllerSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];

  @tracked eInvoiceActivation = null;

  constructor(owner: unknown, args: EInvoiceActivationControllerSignature['Args']) {
    super(owner, args);
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }

  fetchDataTask = dropTask(async () => {
    // @ts-expect-error
    this.eInvoiceActivation = await this.store.findRecord(
      'e-invoice-activation',
      this.organizationManager.organization.id
    );
  });

  confirmTask = dropTask(async () => {
    try {
      // @ts-expect-error
      await this.eInvoiceActivation.save();
      await this.organizationManager.organization.reload();

      this.segment.track('invoice_einvoicing-activation_continue');
      // @ts-expect-error
      this.args.completeFlow();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::InvoiceOnboarding::EinvoiceActivation': typeof EInvoiceActivationController;
  }
}
