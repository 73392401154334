/* import __COLOCATED_TEMPLATE__ from './expense-permissions.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask, restartableTask } from 'ember-concurrency';

// @ts-expect-error
import { MEMBER_EDIT_FLOW_NAMES } from 'qonto/constants/member-edit-flow';
import { ROLES } from 'qonto/constants/membership';
// @ts-expect-error
import { SPEND_LIMITS } from 'qonto/constants/spend-limit';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const TRACKER_PER_TRANSFER_AMOUNT_SELECTED = 'manager-invitation_per-transfer-amount-selected';
const TRACKER_MONTHLY_AMOUNT_SELECTED = 'manager-invitation_monthly-amount-selected';

interface MemberInviteExpensePermissionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class MemberInviteExpensePermissionComponent extends Component<MemberInviteExpensePermissionSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare sentry: Services['sentry'];
  @service declare abilities: Services['abilities'];
  @service declare flow: Services['flow'];
  @service declare organizationManager: Services['organizationManager'];

  get isAdvancedManager() {
    return this.member.role === ROLES.ADVANCED_MANAGER;
  }

  get hasRestrictedPermissions() {
    if (this.isEditingMember) {
      return this.abilities.can('access advanced manager role feature members');
    }

    return this.isAdvancedManager;
  }

  isValid = true;

  permissions = {
    cardsOld: {
      key: 'create_cards_and_review_request_cards',
      // @ts-expect-error
      title: this.intl.t(
        'invitation-flow.expense-permissions.create-cards-and-review-request-cards'
      ),
      image: '/illustrations/ill-sspot-roles-and-permissions-expense-management.svg',
      checked: this.contextCustomPermissions.groups?.create_cards_and_review_request_cards,
    },
    cards: {
      key: ['create_cards', 'review_request_cards'],
      // @ts-expect-error
      title: this.intl.t(
        'invitation-flow.expense-permissions.create-cards-and-review-request-cards'
      ),
      image: '/illustrations/ill-mspot-cards-cards-front-back.svg',
      checked:
        this.contextCustomPermissions.groups?.review_request_cards &&
        this.contextCustomPermissions.groups?.create_cards,
    },
    transfers: {
      key: 'create_transfers',
      // @ts-expect-error
      title: this.abilities.can('use mileages request')
        ? // @ts-expect-error
          this.intl.t('invitation-flow.expense-permissions.transfers.create.title-mileage')
        : // @ts-expect-error
          this.intl.t('invitation-flow.expense-permissions.transfers.create.title'),
      image: '/illustrations/ill-sspot-transfers-external-transfer.svg',
      checked: this.contextCustomPermissions.groups?.create_transfers,
    },
    transfersOld: {
      key: 'create_transfers_and_review_request_transfers',
      // @ts-expect-error
      title: this.abilities.can('use mileages request')
        ? // @ts-expect-error
          this.intl.t('invitation-flow.expense-permissions.transfers.title-mileage')
        : // @ts-expect-error
          this.intl.t('invitation-flow.expense-permissions.transfers.title'),
      // @ts-expect-error
      subtitle: this.intl.t('invitation-flow.expense-permissions.transfers.subtitle'),
      image: '/illustrations/ill-lspot-onboarding-bank-transfer.svg',
      checked: this.contextCustomPermissions.groups?.create_transfers_and_review_request_transfers,
    },
    supplierInvoices: {
      key: this.scope === 'team' ? 'manage_team_supplier_invoices' : 'manage_supplier_invoices',
      title:
        this.scope === 'team'
          ? // @ts-expect-error
            this.intl.t('invitation-flow.permissions.expense.team-manager.supplier-invoices')
          : // @ts-expect-error
            this.intl.t('invitation-flow.permissions.expense.supplier-invoices'),
      image: '/illustrations/ill-sspot-supplier-invoices-supplier-invoice.svg',
      checked:
        this.scope === 'team'
          ? this.contextCustomPermissions.groups?.manage_team_supplier_invoices
          : this.contextCustomPermissions.groups?.manage_supplier_invoices,
    },
    debit: {
      key: 'access_direct_debits',
      // @ts-expect-error
      title: this.intl.t('invitation-flow.expense-permissions.access-direct-debits'),
      image: '/illustrations/ill-sspot-transfers-direct-debit-mandate.svg',
      checked: this.contextCustomPermissions.groups?.access_direct_debits,
    },
    checks: {
      key: 'access_checks',
      // @ts-expect-error
      title: this.intl.t('invitation-flow.expense-permissions.access-checks'),
      image: '/illustrations/ill-sspot-transfers-deposit-check.svg',
      checked: this.contextCustomPermissions.groups?.access_checks,
    },
  };

  @tracked showNoPermissionsDisclaimer =
    !this.contextCustomPermissions.groups?.create_cards_and_review_request_cards &&
    !this.contextCustomPermissions.groups?.create_cards &&
    !this.contextCustomPermissions.groups?.review_request_cards &&
    !this.contextCustomPermissions.groups?.create_transfers &&
    !this.contextCustomPermissions.groups?.create_transfers_and_review_request_transfers &&
    !this.contextCustomPermissions.groups?.manage_supplier_invoices &&
    !this.contextCustomPermissions.groups?.access_direct_debits &&
    !this.contextCustomPermissions.groups?.access_checks;

  get isEditingMember() {
    // @ts-expect-error
    return this.args.context.isEditingMember;
  }

  preDefMonthlyLimits = [
    {
      value: SPEND_LIMITS.UNLIMITED,
      tracker: TRACKER_MONTHLY_AMOUNT_SELECTED,
    },
    { value: SPEND_LIMITS.VALUE_500, tracker: TRACKER_MONTHLY_AMOUNT_SELECTED },
    { value: SPEND_LIMITS.VALUE_1000, tracker: TRACKER_MONTHLY_AMOUNT_SELECTED },
    { value: SPEND_LIMITS.VALUE_5000, tracker: TRACKER_MONTHLY_AMOUNT_SELECTED },
    { value: SPEND_LIMITS.VALUE_10000, tracker: TRACKER_MONTHLY_AMOUNT_SELECTED },
  ];
  preDefPerTransferLimits = [
    {
      value: SPEND_LIMITS.UNLIMITED,
      tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED,
    },
    { value: SPEND_LIMITS.VALUE_50, tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED },
    { value: SPEND_LIMITS.VALUE_100, tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED },
    { value: SPEND_LIMITS.VALUE_500, tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED },
    { value: SPEND_LIMITS.VALUE_2000, tracker: TRACKER_PER_TRANSFER_AMOUNT_SELECTED },
  ];

  @action
  // @ts-expect-error
  updatePermission(shortKey) {
    // @ts-expect-error
    let localPermission = this.permissions[shortKey];
    set(localPermission, 'checked', !localPermission.checked);

    if (Array.isArray(localPermission.key)) {
      this.contextCustomPermissions.groups = {
        ...this.contextCustomPermissions.groups,
        // @ts-expect-error
        ...Object.fromEntries(localPermission.key.map(key => [key, localPermission.checked])),
      };
    } else {
      this.contextCustomPermissions.groups = {
        ...this.contextCustomPermissions.groups,
        [localPermission.key]: localPermission.checked,
      };
    }

    if (shortKey === 'transfersOld' || shortKey === 'transfers') {
      if (this.isEditingMember) {
        this.segment.track(localPermission.checked ? 'transfer_limits_on' : 'transfer_limits_off');
      } else {
        this.segment.track(
          localPermission.checked
            ? 'manager-invitation_transfer-permission_on'
            : 'manager-invitation_transfer-permission_off'
        );
      }
    }

    this.calculateNoPermissionsDisclaimerVisibility();
  }

  calculateNoPermissionsDisclaimerVisibility() {
    this.showNoPermissionsDisclaimer =
      !this.transfersPermission?.checked &&
      !this.cardsPermissionOld?.checked &&
      !this.cardsPermissions.checked &&
      !this.supplierInvoicesPermission?.checked &&
      !this.debitPermission?.checked &&
      !this.checksPermission?.checked;
  }

  get scope() {
    return this.contextCustomPermissions.scope;
  }

  get contextCustomPermissions() {
    // @ts-expect-error
    return this.args.context.member.customPermissions;
  }

  get cardsPermissionOld() {
    return this.permissions.cardsOld;
  }

  get cardsPermissions() {
    return this.permissions.cards;
  }

  get transfersPermission() {
    return this.permissions.transfers;
  }

  get transfersPermissionOld() {
    return this.permissions.transfersOld;
  }

  get supplierInvoicesPermission() {
    return this.permissions.supplierInvoices;
  }

  get debitPermission() {
    return this.permissions.debit;
  }

  get checksPermission() {
    return this.permissions.checks;
  }

  get fullName() {
    return this.member.fullName;
  }

  get member() {
    // @ts-expect-error
    return this.args.context.member;
  }

  validateTask = restartableTask(async () => {
    let { validations } = await this.member.validate({ on: ['monthlyLimit', 'perTransferLimit'] });

    this.isValid = validations.isValid;
  });

  get monthlyLimitAmount() {
    return this.member.monthlyTransferLimit?.value;
  }

  get perTransferLimitAmount() {
    return this.member.perTransferLimit?.value;
  }

  updateMonthlyLimitAmountTask = restartableTask(async newAmount => {
    this.member.monthlyTransferLimit ??= { value: null };
    set(this.member, 'monthlyTransferLimit.value', newAmount?.trim() === '' ? null : newAmount);
    this.member.monthlyTransferLimit = { ...this.member.monthlyTransferLimit };

    await this.validateTask.perform();
  });

  updatePerTransferLimitAmountTask = restartableTask(async newAmount => {
    this.member.perTransferLimit ??= { value: null };
    set(this.member, 'perTransferLimit.value', newAmount?.trim() === '' ? null : newAmount);
    this.member.perTransferLimit = { ...this.member.perTransferLimit };

    await this.validateTask.perform();
  });

  confirmTask = dropTask(async () => {
    if (this.isValid) {
      if (!this.isEditingMember) {
        this.segment.track('manager-invitation_expense-permission_continue');
      }

      if (this.flow.flowName === MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_EXPENSE_PERMISSIONS) {
        // @ts-expect-error
        await this.sensitiveActions.runTask.linked().perform(this.saveTask);
      } else {
        // @ts-expect-error
        this.args.transitionToNext();
      }
    }
  });

  saveTask = dropTask(async () => {
    // @ts-expect-error
    let { context } = this.args;

    try {
      await context.member.updateRole();

      if (this.abilities.can('update by role transfer-limit', context.member.role)) {
        await context.member.updateSpendLimits();
      }

      this.segment.track('member_permissions-update_confirmed');

      if (
        Boolean(context.currentUserSIpermission) !==
        context.member.customPermissions.groups.manage_supplier_invoices
      ) {
        let trackMessage = context.currentUserSIpermission
          ? 'manager-invitation_supplier-invoices-permission_off'
          : 'manager-invitation_supplier-invoices-permission_on';
        this.segment.track(trackMessage);
      }

      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // We abort the flow and go back to the members.active.member page
      context.member.rollbackAttributes();
      this.router.transitionTo('members.active.member', context.member.id);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::ExpensePermissions': typeof MemberInviteExpensePermissionComponent;
  }
}
