/* import __COLOCATED_TEMPLATE__ from './numbering.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import {
  ONBOARDING_STEP_STATUS,
  ONBOARDING_TRACKING_EVENTS,
} from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { getNextInvoiceNumberPreview } from 'qonto/utils/invoices/preview';

interface FlowsAccountReceivableOnboardingNumberingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountReceivableOnboardingNumbering extends Component<FlowsAccountReceivableOnboardingNumberingSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];

  constructor(owner: unknown, args: FlowsAccountReceivableOnboardingNumberingSignature['Args']) {
    super(owner, args);

    this.#prefillSettings();
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (!this.settings || this.settings.isDestroyed || this.settings.isDestroying) return;

    this.settings.rollbackAttributes();
  }

  get settings() {
    // @ts-expect-error
    return this.args.context.settings;
  }

  get onboardingState() {
    // @ts-expect-error
    return this.args.context.onboardingState;
  }

  get invoiceNumberingPattern() {
    return this.settings?.invoiceNumberingPattern;
  }

  get invoiceNextNumber() {
    return this.settings.invoiceNextNumber;
  }

  get errors() {
    return this.settings.errors ?? {};
  }

  get nextInvoiceNumberPreview() {
    return getNextInvoiceNumberPreview(this.settings);
  }

  get placeholderConfig() {
    return {
      enablePlaceholders: true,
      activeSections: ['number'],
    };
  }

  get exampleInvoice() {
    // @ts-expect-error
    if (!this.args.context.isGermanOrganization) {
      return {
        number: this.nextInvoiceNumberPreview,
      };
    }

    return {
      number: this.nextInvoiceNumberPreview,
      issueDate: true,
      performanceDate: true,
      purchaseOrder: true,
      quote: {
        number: true,
      },
      customerSnapshot: {
        vatNumber: true,
        tinNumber: true,
      },
    };
  }

  @action
  // @ts-expect-error
  updateInvoiceNumberingPattern(value) {
    this.settings.invoiceNumberingPattern = value;
  }

  /**
   * Our input only allows numbers, but FormFields::TextField doesnt support pattern
   * @param {*} number
   */
  @action
  // @ts-expect-error
  updateInvoiceNextNumber(number) {
    this.settings.invoiceNextNumber = number;
    this.settings.invoiceNextNumber = number?.replace(/[^0-9]/g, '');
  }

  confirmTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_COMPLETED, {
      // @ts-expect-error
      source: this.args.context.source,
      step: 'numbering',
    });
    try {
      // Update settings
      await this.settings.save();

      // Update onboarding status
      this.onboardingState.stepStatusNumbering = ONBOARDING_STEP_STATUS.CONFIRMED;
      this.onboardingState.substepStatusInvoiceNumbering = ONBOARDING_STEP_STATUS.CONFIRMED;
      await this.onboardingState.save();

      // @ts-expect-error
      this.args.backToStep('summary');
    } catch (error) {
      // @ts-expect-error
      if (error.status !== 422) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  #prefillSettings() {
    if (this.onboardingState.stepStatusNumbering === ONBOARDING_STEP_STATUS.PREFILLED) {
      this.settings.setProperties({
        invoiceNumberingPattern: this.onboardingState.prefillData.invoiceNumberingPattern,
        invoiceNextNumber: this.onboardingState.prefillData.invoiceNextNumber,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::Numbering': typeof FlowsAccountReceivableOnboardingNumbering;
  }
}
