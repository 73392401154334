export default {
  "row": "tP",
  "active": "tl",
  "cell": "tX body-2",
  "empty": "tC",
  "no-padding": "tk",
  "declined": "tJ",
  "not-declined": "ts",
  "item-amount-declined": "tF",
  "cell-content": "tg",
  "item-card-name": "tD",
  "item-card-name-text": "tV",
  "item-amount": "tL body-1"
};
