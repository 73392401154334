/* eslint-disable unicorn/catch-error-name */
/* eslint-disable unicorn/prefer-optional-catch-binding */
/* eslint-disable no-var */
/* eslint-disable wrap-iife */
import Component from '@glimmer/component';

import { isTesting, macroCondition } from '@embroider/macros';
import { didCancel, dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
// @ts-expect-error
import { ExternalScriptError, loadScript } from 'qonto/utils/load-script';

interface DidomiSDKSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

// Component based on React implementation from official Didomi repo
// https://github.com/didomi/react
export default class DidomiSDKComponent extends Component<DidomiSDKSignature> {
  isTestEnvironment = macroCondition(isTesting()) ? true : false;
  // @ts-expect-error
  didomi = ENV.didomi ?? {};

  constructor(owner: unknown, args: DidomiSDKSignature['Args']) {
    super(owner, args);
    this.setEvents();

    if (macroCondition(!isTesting())) {
      this.initSDKTask.perform().catch(error => {
        if (!(didCancel(error) || error instanceof ExternalScriptError)) throw error;
      });
    }
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    this.teardownEvents();
  }

  setEvents() {
    // @ts-expect-error
    if (this.args.onReady) {
      // @ts-expect-error
      window.didomiOnReady = window.didomiOnReady || [];
      // @ts-expect-error
      window.didomiOnReady.push(this.args.onReady);
    }

    // @ts-expect-error
    window.didomiEventListeners = window.didomiEventListeners || [];

    // @ts-expect-error
    if (this.args.onConsentChanged) {
      // @ts-expect-error
      window.didomiEventListeners.push({
        event: 'consent.changed',
        // @ts-expect-error
        listener: e => this.args.onConsentChanged(e),
      });
    }

    // @ts-expect-error
    if (this.args.onPreferencesClickSaveChoices) {
      // @ts-expect-error
      window.didomiEventListeners.push({
        event: 'preferences.clicksavechoices',
        // @ts-expect-error
        listener: () => this.args.onPreferencesClickSaveChoices(),
      });
    }
  }

  teardownEvents() {
    // @ts-expect-error
    delete window.didomiOnReady;
    // @ts-expect-error
    delete window.didomiEventListeners;
  }

  /**
   * Initialize the SDK, set the config object and insert the loader.js into the DOM
   */
  initSDKTask = dropTask(async () => {
    // @ts-expect-error
    window.didomiConfig = this.config;
    // @ts-expect-error
    window.didomiConfig.sdkPath = this.sdkPath;
    // @ts-expect-error
    window.gdprAppliesGlobally = true;

    // Embed the TCF stub
    if (this.embedTCFStub) {
      // TCF v2
      // prettier-ignore
      // @ts-expect-error
      (function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");})();
    }

    let scriptId = 'spcloader';
    let spcloaderScript = document.getElementById(scriptId);
    if (spcloaderScript) {
      return;
    }

    let loaderParams = `target_type=notice&target=${this.noticeId}`;
    let scriptSrc = this.sdkPath + this.apiKey + '/loader.js?' + loaderParams;

    await loadScript(scriptSrc, { scriptId, insertBeforeAllTags: true });
  });

  get noticeId() {
    // @ts-expect-error
    return this.isTestEnvironment ? this.didomi.noticeId : this.args.noticeId;
  }

  get sdkPath() {
    // @ts-expect-error
    return this.isTestEnvironment ? this.didomi.sdkPath : this.args.sdkPath;
  }

  get apiKey() {
    // @ts-expect-error
    return this.isTestEnvironment ? this.didomi.apiKey : this.args.apiKey;
  }

  get embedTCFStub() {
    // @ts-expect-error
    return this.args.embedTCFStub ?? true;
  }

  get config() {
    let testConfig = {
      notice: { enable: false },
    };
    // @ts-expect-error
    let config = this.args.config ?? {};
    return this.isTestEnvironment ? testConfig : config;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DidomiSdk: typeof DidomiSDKComponent;
  }
}
