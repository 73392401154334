/* import __COLOCATED_TEMPLATE__ from './invite-manager.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';

interface FlowsBudgetsInviteManagerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsBudgetsInviteManager extends Component<FlowsBudgetsInviteManagerSignature> {
  @tracked enableInviteValidations = false;

  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked emailErrorMessage;
  // @ts-expect-error
  @tracked existingMember;
  @tracked member;

  MANAGER_ROLE = {
    key: ROLES.MANAGER,
    // @ts-expect-error
    name: this.intl.t('roles.manager'),
    available: true,
    currentRole: false,
    disabled: false,
  };

  constructor(owner: unknown, args: FlowsBudgetsInviteManagerSignature['Args']) {
    super(owner, args);

    this.member = this.store.createRecord('invite', {
      organization: this.organizationManager.organization,
      // @ts-expect-error
      team: this.args.context.team,
      role: ROLES.MANAGER,
    });
  }

  get teams() {
    // @ts-expect-error
    return [this.args.context.team.name];
  }

  get emailDisclaimerText() {
    let name = this.existingMember.fullName;

    switch (this.existingMember.status) {
      case MEMBER_STATUS.REVOKED:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.revoked.main', {
          name,
        });
      case MEMBER_STATUS.PENDING:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.pending.main', {
          name,
        });
      default:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.active.main', {
          name,
        });
    }
  }

  get emailDisclaimerLinkText() {
    switch (this.existingMember.status) {
      case MEMBER_STATUS.REVOKED:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.revoked.link');
      case MEMBER_STATUS.PENDING:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.pending.link');
      default:
        return this.intl.t('invitation-flow.personal-info.email.disclaimer.active.link');
    }
  }

  @action
  // @ts-expect-error
  updateContextMemberEmail(email) {
    this.member.email = email;
    this.emailErrorMessage = null;
    this.existingMember = null;
  }

  continueTask = dropTask(async () => {
    let { validations } = await this.member.validate({
      on: ['firstName', 'lastName', 'email'],
    });

    // @ts-expect-error
    let hasEmailErrors = Boolean(validations.errors.find(error => error.attribute === 'email'));

    this.enableInviteValidations = true;
    this.emailErrorMessage = null;

    let isEmailFree = true;

    if (!hasEmailErrors) {
      isEmailFree = await this.validateEmailTask.perform();
    }

    if (!isEmailFree) {
      this.emailErrorMessage = this.intl.t('invitation-flow.personal-info.email.already-in-use');
      return;
    }

    if (validations.isValid) {
      this.segment.track('budget-creation_invite-member-confirm_clicked');
      // @ts-expect-error
      set(this.args.context, 'member', this.member);
      // @ts-expect-error
      set(this.args.context, 'role', this.MANAGER_ROLE);
      // @ts-expect-error
      set(this.args.context, 'showComparison', false);
      // @ts-expect-error
      this.args.pushForwardFlow('member-invite', 'scope');
    }
  });

  validateEmailTask = dropTask(async () => {
    let result = await this.store.query('membership', {
      organization_id: this.organizationManager.organization.id,
      filters: {
        email: this.member.email,
      },
    });

    this.existingMember = null;

    // @ts-expect-error
    if (result.length > 0 && result[0].id !== this.member.id) {
      // @ts-expect-error
      this.existingMember = result[0];
    }

    return !this.existingMember;
  });

  @action
  skipStep() {
    this.segment.track('budget-creation_invite-member-cancel_clicked');
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::CreateBudget::InviteManager': typeof FlowsBudgetsInviteManager;
  }
}
