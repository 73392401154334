export default {
  "card-sidebar-header": "tx",
  "content": "tw",
  "type": "tN",
  "type-container": "tv",
  "type-close": "to",
  "type-status-awaiting": "tn",
  "type-status-live": "ti",
  "type-status-paused": "tA",
  "type-status-ghost": "tY",
  "actions-panel": "th",
  "actions-panel-header": "tf",
  "action-btn": "tK",
  "disclaimer": "tG",
  "infobox": "tr",
  "btn-panel": "tb",
  "required-action-btn": "ty",
  "kyc-disclaimer": "tU"
};
