/* import __COLOCATED_TEMPLATE__ from './text-field.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

import { F24FormElementsLabel } from 'qonto/react/f24/components/form-elements';

interface F24TextFieldSignature {
  // The arguments accepted by the component
  Args: {
    isDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class F24TextField extends Component<F24TextFieldSignature> {
  F24FormElementsLabel = F24FormElementsLabel;

  elementId = guidFor(this);
  inputId = `${this.elementId}-input`;
  messageId = `${this.elementId}-msg`;

  @action
  // @ts-expect-error
  handleInput(event) {
    let { value } = event.target;
    // @ts-expect-error
    this.args.onUpdate(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::FormFields::TextField': typeof F24TextField;
  }
}
