/* import __COLOCATED_TEMPLATE__ from './virtual-card-provide-kyc-success.hbs'; */
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface FlowsCardsKycVirtualCardProvideKycSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsCardsKycVirtualCardProvideKycSuccessComponent extends Component<FlowsCardsKycVirtualCardProvideKycSuccessSignature> {
  lottiePlayer = LottiePlayer;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Kyc::VirtualCardProvideKycSuccess': typeof FlowsCardsKycVirtualCardProvideKycSuccessComponent;
  }
}
