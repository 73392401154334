/* import __COLOCATED_TEMPLATE__ from './reason-category.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { shuffleArray } from 'qonto/utils/shuffle-array';

import type { DataContext } from './data-context';

interface SubscriptionCloseStepsReasonSignature {
  // The arguments accepted by the component
  Args: FlowStepArgs<DataContext>;
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionCloseStepsReasonComponent extends Component<SubscriptionCloseStepsReasonSignature> {
  @service declare segment: Services['segment'];
  @service declare flow: Services['flow'];

  reasonList = [];

  constructor(owner: unknown, args: SubscriptionCloseStepsReasonSignature['Args']) {
    super(owner, args);
    this.flow.dataContext.continueClosure = false;

    let { context } = this.args;
    context.bannerType = null;

    // the wizard component now uses ensure-safe-component
    // which requires a check before calling next()
    // to avoid triggering an infinite loop which causes
    // tests to timeout
    if (
      context.closingReasonCategory !== null ||
      context.closingReasonSubCategory !== null ||
      context.closingReasonDetails !== null ||
      context.closingReasonDetailsOther !== null
    ) {
      // without next throws re-validation error
      next(() => {
        context.closingReasonCategory = null;
        context.closingReasonSubCategory = null;
        context.closingReasonDetails = null;
        context.closingReasonDetailsOther = null;
      });
    }

    if (variation('feature--account-closing-ab-test')) {
      // @ts-expect-error
      this.reasonList = shuffleArray(context.reasonList);
      this.segment.track('account-closing_reasons-screen_opened', {
        reasons_list: 'randomised',
      });
    } else {
      // @ts-expect-error
      this.reasonList = context.reasonList;
      this.segment.track('account-closing_reasons-screen_opened', {
        reasons_list: 'standard',
      });
    }
  }

  @action selectReasonCategory(item: { key: string } | null) {
    this.args.context.closingReasonCategory = item;
    this.segment.track('account_closing_category_selected', {
      category: item?.key,
    });
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::ReasonCategory': typeof SubscriptionCloseStepsReasonComponent;
  }
}
