/* import __COLOCATED_TEMPLATE__ from './customization-logo.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import TEMPLATE_VALUES from 'qonto/constants/env-helper';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_ERROR_STATUSES = [422];

interface FlowsAccountReceivableOnboardingCustomizationLogoSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountReceivableOnboardingCustomizationLogo extends Component<FlowsAccountReceivableOnboardingCustomizationLogoSignature> {
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare flow: Services['flow'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @tracked formattedMaxSize = formatFileSize(this.intl, TEMPLATE_VALUES.avatarMaxSize);
  @tracked avatarUploaded = false;

  avatarRollbackAttributes;

  constructor(
    owner: unknown,
    args: FlowsAccountReceivableOnboardingCustomizationLogoSignature['Args']
  ) {
    super(owner, args);

    let { isDefaultAvatar, avatar } = this.organization;
    this.avatarRollbackAttributes = { isDefaultAvatar, avatar };
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (this.organization.isDestroying || this.organization.isDestroyed) return;

    this.rollbackAttributesOnClose();
  }

  get hasDefaultAvatar() {
    return this.organization.isDefaultAvatar ?? true;
  }

  get logo() {
    return this.organization.get('isDefaultAvatar') === false ? this.organization.picture : null;
  }

  @action
  // @ts-expect-error
  handleAvatarUpload(file) {
    this.organization.reload();
    this.organization.set('avatar', file);
    this.organization.set('isDefaultAvatar', false);

    this.avatarUploaded = true;
  }

  @action
  handleDropAvatar() {
    this.organization.reload();
    this.organization.set('avatar', null);
    this.organization.set('isDefaultAvatar', true);

    this.avatarUploaded = true;
  }

  @action
  rollbackAttributesOnClose() {
    this.organization.set('isDefaultAvatar', this.avatarRollbackAttributes.isDefaultAvatar);
    this.organization.set('avatar', this.avatarRollbackAttributes.avatar);
  }

  @action
  handleDoItLater() {
    this.rollbackAttributesOnClose();
    // @ts-expect-error
    this.args.transitionToNext();
  }

  handleConfirmTask = dropTask(async () => {
    try {
      if (this.avatarUploaded) {
        await this.organization.save();
      }

      // @ts-expect-error
      this.flow.dataContext.onboardingState.substepStatusLogo = 'confirmed';
      // @ts-expect-error
      this.flow.dataContext.onboardingState.stepStatusCustomization = 'confirmed';
      // @ts-expect-error
      await this.flow.dataContext.onboardingState.save();

      this.organization.getAvatar();

      let { isDefaultAvatar, avatar } = this.organization;
      this.avatarRollbackAttributes = { isDefaultAvatar, avatar };

      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      if (
        ErrorInfo.for(error).shouldSendToSentry &&
        // @ts-expect-error
        !SENTRY_IGNORE_ERROR_STATUSES.includes(error.status)
      ) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::CustomizationLogo': typeof FlowsAccountReceivableOnboardingCustomizationLogo;
  }
}
