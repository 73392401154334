/* import __COLOCATED_TEMPLATE__ from './cancel-subscription-modal.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

interface DirectDebitCollectionsModalsCancelSubscriptionModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DirectDebitCollectionsModalsCancelSubscriptionModalComponent extends Component<DirectDebitCollectionsModalsCancelSubscriptionModalSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare intl: Services['intl'];

  title;
  description;
  disclaimer;
  confirmBtnText;

  constructor(
    owner: unknown,
    args: DirectDebitCollectionsModalsCancelSubscriptionModalSignature['Args']
  ) {
    super(owner, args);

    if (this.subscription.isPendingMandateSignature) {
      this.title = this.intl.t('sdd-collections.modals.cancel.pending-mandate.title');
      this.description = this.intl.t('sdd-collections.modals.cancel.pending-mandate.description');
      this.confirmBtnText = this.intl.t('sdd-collections.btn.cancel.pending-mandate');
    } else if (this.subscription.isOneOff) {
      this.title = this.intl.t('sdd-collections.modals.cancel.one-off.title');
      this.description = this.intl.t('sdd-collections.modals.cancel.one-off.description');
      this.confirmBtnText = this.intl.t('sdd-collections.btn.cancel.one-off');
    } else if (this.subscription.isRecurring) {
      this.title = this.intl.t('sdd-collections.modals.cancel.recurring.title');
      this.description = this.intl.t('sdd-collections.modals.cancel.recurring.description');
      this.confirmBtnText = this.intl.t('sdd-collections.btn.recurring.cancel');
      this.disclaimer = this.intl.t('sdd-collections.modals.cancel.recurring.disclaimer');
    }
  }

  get subscription() {
    // @ts-expect-error
    return this.args.data.subscription;
  }

  get popupData() {
    return {
      title: this.title,
      description: this.description,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Modals::CancelSubscriptionModal': typeof DirectDebitCollectionsModalsCancelSubscriptionModalComponent;
  }
}
