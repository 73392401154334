export default {
  "content": "eB",
  "balance": "eE",
  "balance-failed": "ed",
  "bank-infos": "eI",
  "bank-name": "et",
  "bank-identifier": "eM",
  "bank-iban": "eP",
  "footer-container": "el",
  "links": "eX",
  "links--start-verification": "eC",
  "link": "ek",
  "start-verification-link": "eJ",
  "last-sync": "es"
};
