/* import __COLOCATED_TEMPLATE__ from './application-card-loading.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

interface ApplicationCardLoadingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApplicationCardLoading extends Component<ApplicationCardLoadingSignature> {
  placeholderBlock = SkeletonLoader.Block;
  placeholderLine = SkeletonLoader.Line;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::ApplicationCardLoading': typeof ApplicationCardLoading;
  }
}
