/* import __COLOCATED_TEMPLATE__ from './contribuente.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { ERROR_KEYS } from 'qonto/constants/f24';
import { F24FormFieldsCheckboxField } from 'qonto/react/f24/components/checkbox-field';

const INDEPENDENT_CONTRIBUENTE_LEGAL_FORMS = [
  'libero professionista',
  'libero professionista/lavoratore autonomo',
  'imprenditore individuale',
  'impresa individuale',
];

const PAYER_FIELDS_KEYS = {
  fiscalCode: 'f24.manual-declaration.module.contribuente.section1.fiscal-code',
  lastName: 'f24.manual-declaration.module.contribuente.section2.surname',
  firstName: 'f24.manual-declaration.module.contribuente.section2.name',
  birthDate: 'f24.manual-declaration.module.contribuente.section2.birth-date',
  sex: 'f24.manual-declaration.module.contribuente.section2.sex',
  birthPlace: 'f24.manual-declaration.module.contribuente.section2.birth-city',
  birthProvince: 'f24.manual-declaration.module.contribuente.section2.birth-province',
  city: 'f24.manual-declaration.module.contribuente.section3.address-city',
  province: 'f24.manual-declaration.module.contribuente.section3.address-province',
  streetAddress: 'f24.manual-declaration.module.contribuente.section3.address-street',
};

const PAYER_AGENT_FIELDS_KEYS = {
  fiscalCode: 'f24.manual-declaration.module.contribuente.section-payer-agent.fiscal-code',
  type: 'f24.manual-declaration.module.contribuente.section-payer-agent.type',
};

interface F24FormContribuenteSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class F24FormContribuenteComponent extends Component<F24FormContribuenteSignature> {
  f24FormFieldsCheckboxField = F24FormFieldsCheckboxField;

  @service declare intl: Services['intl'];

  payerAgentFieldsKeys = PAYER_AGENT_FIELDS_KEYS;
  payerFieldsKeys = PAYER_FIELDS_KEYS;

  @action
  getErrorKey(code: keyof typeof ERROR_KEYS) {
    return ERROR_KEYS[code] ?? ERROR_KEYS.generic_error;
  }

  get sexOptions() {
    return [
      this.intl.t('f24.manual-declaration.module.contribuente.section2.sexM'),
      this.intl.t('f24.manual-declaration.module.contribuente.section2.sexF'),
    ];
  }

  get isContribuentePersonalDataNeeded() {
    // @ts-expect-error
    let legalForm = this.args.legalForm || '';

    return INDEPENDENT_CONTRIBUENTE_LEGAL_FORMS.includes(legalForm.toLowerCase());
  }

  get hasPersonalNameErrors() {
    // @ts-expect-error
    let { errors } = this.args.payer;

    return errors.get('firstName') && errors.get('lastName');
  }

  get personalNameErrors() {
    // @ts-expect-error
    let { errors } = this.args.payer;
    let lastNameErrors = errors.get('lastName');
    let firstNameErrors = errors.get('firstName');

    return [...lastNameErrors, ...firstNameErrors];
  }

  get hasBirthInfoErrors() {
    // @ts-expect-error
    let { errors } = this.args.payer;
    let birthDateErrors = errors.get('birthDate');
    let sexErrors = errors.get('sex');
    let birthPlaceErrors = errors.get('birthPlace');
    let birthProvinceErrors = errors.get('birthProvince');

    return birthDateErrors && sexErrors && birthPlaceErrors && birthProvinceErrors;
  }

  get birthInfoErrors() {
    // @ts-expect-error
    let { errors } = this.args.payer;
    let birthDateErrors = errors.get('birthDate');
    let sexErrors = errors.get('sex');
    let birthPlaceErrors = errors.get('birthPlace');
    let birthProvinceErrors = errors.get('birthProvince');

    return [...birthDateErrors, ...sexErrors, ...birthPlaceErrors, ...birthProvinceErrors];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Form::Contribuente': typeof F24FormContribuenteComponent;
  }
}
