export default {
  "step-container": "yn",
  "subtitle": "yi",
  "step-container-content": "yA",
  "continue-button": "yY",
  "period-select": "yh",
  "period-dropdown": "yf",
  "readonly": "yK",
  "period-error": "yG",
  "toggle-options-container": "yr",
  "receipt-code-toggle-input": "yb"
};
