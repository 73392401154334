/* import __COLOCATED_TEMPLATE__ from './infos.hbs'; */
import { ForbiddenError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

const FIELDS = ['legalName', 'legalNumber', 'capitalAmount'];

interface CapitalDepositLegalEntityInfosSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositLegalEntityInfosComponent extends Component<CapitalDepositLegalEntityInfosSignature> {
  amountField = AmountField;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  @tracked isLegalNameValidationEnabled = false;
  @tracked isLegalNumberValidationEnabled = false;
  @tracked isAmountValidationEnabled = false;

  componentStepName = 'legalEntityInfos';

  nextStepTask = dropTask(async legalEntity => {
    let { validations } = await legalEntity.validate({ on: FIELDS });

    if (validations.isValid) {
      this.isLegalNameValidationEnabled = false;
      this.isLegalNumberValidationEnabled = false;
      this.isAmountValidationEnabled = false;

      legalEntity.set('didValidate', false);

      // @ts-expect-error
      if (this.args.context.submitOnStep === this.componentStepName) {
        try {
          // @ts-expect-error
          await this.args.context.saveLegalEntityTask.linked().perform(this.args.context);
          this.segment.track('kdeposit_owner_capital_shares_added');
          // @ts-expect-error
          this.args.transitionToNext();
        } catch (error) {
          if (error instanceof ForbiddenError) {
            this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
          } else {
            this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
            this.sentry.captureException(error);
          }
        }
      } else {
        // @ts-expect-error
        this.args.transitionToNext();
      }
    } else {
      this.isLegalNameValidationEnabled = true;
      this.isLegalNumberValidationEnabled = true;

      // Verify that the capitalAmount is in error state to properly display the input in error state
      this.isAmountValidationEnabled = validations.errors.some(
        // @ts-expect-error
        item => item.attribute === 'capitalAmount'
      );
      legalEntity.set('didValidate', true);
    }
  });

  @action
  // @ts-expect-error
  updateCapitalAmount(value) {
    this.isAmountValidationEnabled = false;
    // @ts-expect-error
    this.args.context.legalEntity.capitalAmount = parseFloat(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CapitalDeposit::LegalEntity::Infos': typeof CapitalDepositLegalEntityInfosComponent;
  }
}
