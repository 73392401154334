/* import __COLOCATED_TEMPLATE__ from './bank-account.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';
import { BankAccountsSelectStep } from 'qonto/react/components/bank-accounts/select-step';

interface FlowsCardsBankAccountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsBankAccount extends Component<FlowsCardsBankAccountSignature> {
  selectStep = BankAccountsSelectStep;

  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];

  @action
  handleNext() {
    let {
      // @ts-expect-error
      context: { card, flowTrackingOrigin },
      // @ts-expect-error
      transitionToNext,
    } = this.args;

    let isMainAccount = card.bankAccount.main;
    this.segment.track('card_account_type_chosen', {
      account_type: isMainAccount ? 'main' : 'secondary',
      origin: flowTrackingOrigin,
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
    });

    transitionToNext();
  }

  get accountsCount() {
    let { activeAccounts } = this.organizationManager.organization;
    return activeAccounts?.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::BankAccount': typeof FlowsCardsBankAccount;
  }
}
