/* import __COLOCATED_TEMPLATE__ from './confirm-revoke-accountant.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ConfirmRevokeAccountantSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ConfirmRevokeAccountant = templateOnlyComponent<ConfirmRevokeAccountantSignature>();

export default ConfirmRevokeAccountant;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Guest::Modal::ConfirmRevokeAccountant': typeof ConfirmRevokeAccountant;
  }
}
