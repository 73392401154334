/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { ObfuscatedIban } from 'qonto/react/components/direct-debit-collections';

interface DirectDebitCollectionsSidebarHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DirectDebitCollectionsSidebarHeaderComponent extends Component<DirectDebitCollectionsSidebarHeaderSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  ObfuscatedIban = ObfuscatedIban;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Sidebar::Header': typeof DirectDebitCollectionsSidebarHeaderComponent;
  }
}
