export default {
  "budget-creation-form": "Bw",
  "period-label": "BN",
  "periods": "Bv",
  "yearly": "Bo",
  "yearly-toggle": "Bn",
  "toggle-tooltip": "Bi",
  "tooltip": "BA",
  "year": "BY",
  "total-budget": "Bh",
  "total-budget-value": "Bf",
  "divider": "BK"
};
