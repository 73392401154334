/* import __COLOCATED_TEMPLATE__ from './role-button.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FlowsInviteRoleButtonSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsInviteRoleButtonComponent extends Component<FlowsInviteRoleButtonSignature> {
  @service declare intl: Services['intl'];
  @service declare memberManager: Services['memberManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare organizationManager: Services['organizationManager'];

  @action
  // @ts-expect-error
  handleSelectRole(context, role, transitionToNext, pushFlow) {
    this.handleSelectRoleTask
      .perform(context, role, transitionToNext, pushFlow)
      .catch(ignoreCancelation);
  }

  handleSelectRoleTask = dropTask(async (context, role, transitionToNext, pushFlow) => {
    this.segment.track('team-invite_role-chosen_clicked');

    await this.memberManager.handleSelectRoleTask
      .perform(context, role, transitionToNext, pushFlow, 'role-details')
      .catch(error => {
        if (error.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::RoleButton': typeof FlowsInviteRoleButtonComponent;
  }
}
