/* import __COLOCATED_TEMPLATE__ from './completed.hbs'; */
import Component from '@glimmer/component';

import { EmptyStatesInform } from '@repo/domain-kit/pricing';

interface FinancingPayLaterCockpitEmptyStateCompletedSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FinancingPayLaterCockpitEmptyStateCompletedComponent extends Component<FinancingPayLaterCockpitEmptyStateCompletedSignature> {
  emptyStatesInform = EmptyStatesInform;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::PayLater::Cockpit::EmptyState::Completed': typeof FinancingPayLaterCockpitEmptyStateCompletedComponent;
  }
}
