/* import __COLOCATED_TEMPLATE__ from './quick-access.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface DualNavSubMenuQuickAccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DualNavSubMenuQuickAccessComponent extends Component<DualNavSubMenuQuickAccessSignature> {
  constructor(owner: unknown, args: DualNavSubMenuQuickAccessSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { items } = this.args;

    assert('<DualNav::SubMenu::QuickAccess /> must have @items', items);
  }

  @action
  // @ts-expect-error
  handleClick(item, event) {
    // @ts-expect-error
    let { onClick } = this.args;

    item.link.transitionTo?.(event);
    onClick?.(item);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::SubMenu::QuickAccess': typeof DualNavSubMenuQuickAccessComponent;
  }
}
