/* import __COLOCATED_TEMPLATE__ from './customization-upsell.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface FlowsCardsCustomizationUpsellSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsCustomizationUpsell extends Component<FlowsCardsCustomizationUpsellSignature> {
  @action
  // @ts-expect-error
  onColorChange(design) {
    // @ts-expect-error
    this.args.context.cardUpsellDesign = design;
  }

  @action
  // @ts-expect-error
  onTypeOfPrintChange(typeOfPrint) {
    // @ts-expect-error
    this.args.context.cardUpsellTypeOfPrint = typeOfPrint;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::CustomizationUpsell': typeof FlowsCardsCustomizationUpsell;
  }
}
