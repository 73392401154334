/* import __COLOCATED_TEMPLATE__ from './avatar.hbs'; */
import Component from '@glimmer/component';

import { QONTO_DEFAULT_LOGO } from 'qonto/constants/bank-account';

interface BankAccountAvatarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BankAccountAvatarComponent extends Component<BankAccountAvatarSignature> {
  get bankLogo() {
    // @ts-expect-error
    if (!this.args.bankAccount?.logo) return QONTO_DEFAULT_LOGO;

    // @ts-expect-error
    let { size } = this.args;
    // @ts-expect-error
    return this.args.bankAccount.logo?.[size || 'small'];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::Avatar': typeof BankAccountAvatarComponent;
  }
}
