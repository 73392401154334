/* import __COLOCATED_TEMPLATE__ from './choose-card-invitee.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_FLOWS_TRACKING_ORIGINS, CARD_LEVELS, ORIGIN } from 'qonto/constants/cards';
import { ChooseCardInviteePrice } from 'qonto/react/components/flows/cards/choose-card-invitee-price';
import { getDefaultDesignByLevel } from 'qonto/utils/cards/design';

interface FlowsCardsChooseCardInviteeSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsChooseCardInvitee extends Component<FlowsCardsChooseCardInviteeSignature> {
  chooseCardInviteePrice = ChooseCardInviteePrice;

  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];
  @service declare router: Services['router'];

  showExcludingVatLabel = Boolean(this.physicalOneCardPrice || this.virtualCardPrice);

  get physicalOneCardPrice() {
    // @ts-expect-error
    return this.args.context.estimates.standard.monthly_cost;
  }

  get virtualCardPrice() {
    // @ts-expect-error
    return this.args.context.estimates.virtual.monthly_cost;
  }

  @action
  handlePhysicalOneCardClick() {
    this.segment.track('cards_order_physical_type_chosen', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION_CARD_SELECTION,
    });

    // @ts-expect-error
    this.args.context.card = this._createCardRecord(CARD_LEVELS.STANDARD);

    // @ts-expect-error
    let { hasMultipleActiveAccounts } = this.args.context;

    // @ts-expect-error
    this.args.pushForwardFlow(
      'card-new-invitee-physical-one',
      hasMultipleActiveAccounts ? 'bank-account' : 'payment-limits'
    );
  }

  @action
  handleVirtualCardClick() {
    this.segment.track('cards_order_virtual_type_chosen', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION_CARD_SELECTION,
    });

    // @ts-expect-error
    this.args.context.card = this._createCardRecord(CARD_LEVELS.VIRTUAL);

    // @ts-expect-error
    let { hasMultipleActiveAccounts } = this.args.context;

    // @ts-expect-error
    this.args.pushForwardFlow(
      'card-new-invitee-virtual',
      hasMultipleActiveAccounts ? 'bank-account' : 'payment-limits'
    );
  }

  @action handleOtherCardsClick() {
    this.segment.track('cards_order_clicked', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION_CARD_SELECTION,
    });

    // @ts-expect-error
    let { cardHolderId } = this.args.context;

    return this.router.transitionTo('cards.physical-or-virtual', {
      queryParams: { origin: ORIGIN.MEMBER_INVITATION, inviteeMembershipId: cardHolderId },
    });
  }

  // @ts-expect-error
  _createCardRecord(cardLevel) {
    // @ts-expect-error
    let { mainAccount, cardHolder, cardsLimits } = this.args.context;

    let cardLevelOptions = {
      [CARD_LEVELS.STANDARD]: {
        nfcOption: false,
        atmOption: false,
      },
    };

    let cardDesign = getDefaultDesignByLevel(cardLevel);

    let card = this.store.createRecord('card', {
      ...cardLevelOptions[cardLevel],
      bankAccount: mainAccount,
      cardLevel,
      cardDesign,
      organization: this.organizationManager.organization,
      holder: cardHolder,
      paymentMonthlyLimit: cardsLimits[cardLevel].payment_monthly_limit_maximum,
      atmMonthlyLimit: cardsLimits[cardLevel].atm_monthly_limit_maximum,
    });

    card.addIdempotencyKey();

    return card;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCardInvitee': typeof FlowsCardsChooseCardInvitee;
  }
}
