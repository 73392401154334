/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { SearchField } from '@repo/design-system-kit';

interface EmptyStatesSuppliersDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesSuppliersDummyDataComponent extends Component<EmptyStatesSuppliersDummyDataSignature> {
  searchField = SearchField;

  sortBy = 'supplier:asc';
  noop() {}
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::Suppliers::DummyData': typeof EmptyStatesSuppliersDummyDataComponent;
  }
}
