/* import __COLOCATED_TEMPLATE__ from './kyc-pending-sdd-creation-blocked-popup.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface KycPendingSddCreationBlockedPopupSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const KycPendingSddCreationBlockedPopup =
  templateOnlyComponent<KycPendingSddCreationBlockedPopupSignature>();

export default KycPendingSddCreationBlockedPopup;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::KycPendingSddCreationBlockedPopup': typeof KycPendingSddCreationBlockedPopup;
  }
}
