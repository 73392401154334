/* import __COLOCATED_TEMPLATE__ from './iban-field.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { formatIban, handleFormatIban } from 'qonto/utils/format-input';

interface IbanFieldSignature {
  // The arguments accepted by the component
  Args: {
    isIBANObfuscated?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class IbanField extends Component<IbanFieldSignature> {
  @service declare organizationManager: Services['organizationManager'];
  // @ts-expect-error
  @reads('organizationManager.organization.legalCountry') legalCountry;

  get iban() {
    // @ts-expect-error
    let { isIBANObfuscated, value } = this.args;
    return formatIban(value, Boolean(isIBANObfuscated));
  }

  @action
  // @ts-expect-error
  handleOnInput(event) {
    let { target } = event;
    // @ts-expect-error
    handleFormatIban(target, this.args.isIBANObfuscated);
    // @ts-expect-error
    this.args.update(target.value.replace(/[^a-zA-Z0-9]/g, ''));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::IbanField': typeof IbanField;
  }
}
