/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ListSignature {
  Element: HTMLDivElement;
  Args: {
    isWealthAccountsList?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const List = templateOnlyComponent<ListSignature>();

export default List;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccounts::List': typeof List;
  }
}
