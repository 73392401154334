/* import __COLOCATED_TEMPLATE__ from './dynamic-section.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DynamicSectionSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DynamicSection = templateOnlyComponent<DynamicSectionSignature>();

export default DynamicSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::DynamicSection': typeof DynamicSection;
  }
}
