/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox, Spinner, TextAreaField, TextField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { apiBaseURL, mollieV2Namespace } from 'qonto/constants/hosts';

interface PaymentLinksOnboardingFormSignature {
  // The arguments accepted by the component
  Args: {
    transitionToNext: () => void;
    context: {
      userBankAccountId?: string;
      userPhoneNumber?: string;
      userWebsiteUrl?: string;
      businessDescription?: string;
      tosAccepted: boolean;
    };
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PaymentLinksOnboardingFormComponent extends Component<PaymentLinksOnboardingFormSignature> {
  textField = TextField;
  checkbox = Checkbox;
  spinner = Spinner;
  textAreaField = TextAreaField;

  @service declare organizationManager: Services['organizationManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toast-flash-messages'];
  @service declare router: Services['router'];

  @tracked errors: { source: { pointer: string }; code: string }[] = [];
  @tracked tosAcceptedError: string | null = null;

  @action
  updateBankAccount(bankAccount: { id: string }): void {
    this.args.context.userBankAccountId = bankAccount.id;
  }

  get selectedBankAccount() {
    return this.organizationManager.accounts.find(
      ({ id }) => id === this.args.context.userBankAccountId
    );
  }

  @action
  updatePhoneNumber(phoneNumber: string): void {
    this.args.context.userPhoneNumber = phoneNumber;
  }

  get phoneNumberError(): string | null {
    let error = this.errors.find(({ source }) => source?.pointer === 'user_phone_number');
    switch (error?.code) {
      case 'required':
        return this.intl.t('payment-links.onboarding.form.phone.error.missing');
      case 'e164':
        return this.intl.t('payment-links.onboarding.form.phone.error.invalid');
      default:
        return null;
    }
  }

  @action
  updateWebsiteUrl(websiteUrl: string): void {
    this.args.context.userWebsiteUrl = websiteUrl;
  }

  get websiteUrlError(): string | null {
    let error = this.errors.find(({ source }) => source?.pointer === 'user_website_url');

    switch (error?.code) {
      case 'url_validation':
      case 'required':
        return this.intl.t('payment-links.onboarding.form.website.error.invalid');
      default:
        return null;
    }
  }

  get hasWebsiteUrlError(): boolean {
    return Boolean(this.websiteUrlError);
  }

  @action
  updateBusinessDescription(businessDescription: string): void {
    this.args.context.businessDescription = businessDescription;
  }

  get businessDescriptionError(): string | null {
    let error = this.errors.find(({ source }) => source?.pointer === 'business_description');
    return error?.code === 'min' || error?.code === 'required'
      ? this.intl.t('payment-links.onboarding.form.business-description.error.invalid')
      : null;
  }

  get hasBusinessDescriptionError(): boolean {
    return Boolean(this.businessDescriptionError);
  }

  @action
  toggleTosAccepted(): void {
    this.args.context.tosAccepted = !this.args.context.tosAccepted;
  }

  submitTask = dropTask(
    waitFor(async () => {
      this.segment.track('payment-link_activation_confirmed');

      this.errors = [];
      this.tosAcceptedError = null;
      if (!this.args.context.tosAccepted) {
        this.tosAcceptedError = this.intl.t('payment-links.onboarding.form.legal.error.missing');
        return;
      }

      try {
        let response = await this.networkManager.request(
          `${apiBaseURL}/${mollieV2Namespace}/connections`,
          {
            method: 'POST',
            data: JSON.stringify({
              data: {
                type: 'mollie_connections',
                attributes: {
                  user_bank_account_id: this.args.context.userBankAccountId,
                  user_phone_number: this.args.context.userPhoneNumber,
                  user_website_url: this.args.context.userWebsiteUrl,
                  business_description: this.args.context.businessDescription,
                  organization_id: this.organizationManager.organization.id,
                  membership_id: this.organizationManager.membership.id,
                  origin: window.location.origin + this.router.currentURL,
                },
              },
            }),
          }
        );
        window.location.replace(response.data.attributes.mollie_connection_location);
      } catch (error: any) {
        if (error.status === 422 && error.responseJSON?.errors) {
          this.errors = error.responseJSON.errors;
          if (this.errors.find(({ code }) => code === 'multiple_connection_not_allowed')) {
            this.toastFlashMessages.toastError(
              this.intl.t('payment-links.onboarding.form.organization.error.invalid')
            );
          }
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
        }
      }
    })
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Onboarding::Form': typeof PaymentLinksOnboardingFormComponent;
  }
}
