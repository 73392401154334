/* import __COLOCATED_TEMPLATE__ from './select-exercise.hbs'; */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { PERIOD_TYPES } from 'qonto/constants/budget';

interface FlowsBudgetsSelectExerciseSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsBudgetsSelectExercise extends Component<FlowsBudgetsSelectExerciseSignature> {
  @service declare segment: Services['segment'];

  periodTypes = PERIOD_TYPES;

  constructor(owner: unknown, args: FlowsBudgetsSelectExerciseSignature['Args']) {
    super(owner, args);
    this.segment.track('edit-budget_select-budget-period_displayed');
  }

  get budget() {
    // @ts-expect-error
    return this.args.context.budget;
  }

  get currentExercise() {
    if (this.budget?.currentExercise && !this.budget.currentExercise.isNew) {
      return this.budget.currentExercise;
    }
  }

  get nextExercise() {
    if (this.budget?.nextExercise && !this.budget.nextExercise.isNew) {
      return this.budget.nextExercise;
    }
  }

  @action
  // @ts-expect-error
  nextStep(periodType, exercise) {
    // @ts-expect-error
    set(this.args.context, 'type', periodType);
    // @ts-expect-error
    set(this.args.context, 'exerciseId', exercise?.id ?? null);

    this.segment.track(`edit-budget_${periodType}_selected`);

    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::EditBudget::SelectExercise': typeof FlowsBudgetsSelectExercise;
  }
}
