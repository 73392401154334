/* import __COLOCATED_TEMPLATE__ from './modelo-select.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, LottiePlayer, SearchField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface NrcModeloSelectSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class NrcModeloSelectComponent extends Component<NrcModeloSelectSignature> {
  searchField = SearchField;

  @service declare networkManager: Services['networkManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];

  constructor(owner: unknown, args: NrcModeloSelectSignature['Args']) {
    super(owner, args);
    this.getModelosTask.perform().catch(ignoreCancelation);

    // @ts-expect-error
    this.args.context.nrcPayment = this.store.createRecord('nrc-payment');
  }

  @tracked filteredModelos = [];
  // @ts-expect-error
  @tracked searchQuery;
  @tracked isError = false;

  getModelosTask = dropTask(async () => {
    this.isError = false;

    try {
      let response = await this.networkManager.request(`${apiBaseURL}/v1/nrc/modelos`, {
        method: 'GET',
      });

      //we add a searchable string to the modelo objects
      // @ts-expect-error
      let modelosWithSearchString = response.modelos.map(item => ({
        ...item,
        search_string: this._normalizeSearchString(
          `${this.intl.t('aeat.pay.form-selection.card-title-prefix')} ${this._formatTaxCode(item.tax_code)} ${item.tax_description}`
        ),
      }));

      // @ts-expect-error
      this.args.context.modelos = modelosWithSearchString;
      this.filteredModelos = modelosWithSearchString;
      this.segment.track('nrc_form-list_loaded');
    } catch (error) {
      this.isError = true;

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get showErrorState() {
    return this.isError;
  }

  get showEmptySearchResults() {
    return !this.isError && this.filteredModelos.length === 0 && this.searchQuery;
  }

  @action
  // @ts-expect-error
  selectorTitle(option) {
    return `${this.intl.t('aeat.pay.form-selection.card-title-prefix')} ${this._formatTaxCode(option.tax_code)}`;
  }

  @action
  // @ts-expect-error
  selectorToolTip(option) {
    return `${this.intl.t('aeat.pay.form-selection.card-title-prefix')} ${this._formatTaxCode(option.tax_code)} - ${option.tax_description}`;
  }

  @action
  // @ts-expect-error
  updateModeloList(query) {
    // @ts-expect-error
    this.filteredModelos = this.args.context.modelos.filter(modelo =>
      modelo.search_string.includes(this._normalizeSearchString(query))
    );
    this.searchQuery = query;
  }

  @action
  // @ts-expect-error
  selectModelo(option) {
    this.segment.track('nrc_form-list_selected');

    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    context.selectedModelo = option;
    context.nrcPayment.taxCode = option.tax_code;

    transitionToNext();
  }

  // @ts-expect-error
  _normalizeSearchString(string) {
    return string
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  // @ts-expect-error
  _formatTaxCode(taxCode) {
    return parseInt(taxCode, 10).toString();
  }

  button = Button;
  lottiePlayer = LottiePlayer;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::NrcPayment::ModeloSelect': typeof NrcModeloSelectComponent;
  }
}
