export default {
  "dual-nav": "aW",
  "header": "au",
  "expanded": "HS",
  "collapsed": "Hc",
  "responsive": "Hq",
  "overlay": "HZ",
  "visible": "HR",
  "footer": "HQ",
  "scrolled-top": "He",
  "scrollable-content": "HB",
  "safari": "HE"
};
