/* import __COLOCATED_TEMPLATE__ from './beneficiaries.hbs'; */
import { action, setProperties } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, restartableTask, task, type TaskForAsyncTaskFunction } from 'ember-concurrency';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { BeneficiaryCardLoader } from 'qonto/react/components/transfers/international-out/beneficiary-card/loader';
import { State } from 'qonto/react/components/transfers/international-out/state';
import type { DataContext } from 'qonto/routes/flows/setup/transfers/international-out/data-context';
import type { Beneficiary } from 'qonto/services/international-out/types';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface Signature {
  // The transfer quote cannot be `null`, as this step is only accessible once a quote has been created
  Args: FlowStepArgs<DataContext & { quote: NonNullable<DataContext['quote']> }>;
}

export default class FlowsTransfersInternationalOutNewBeneficiariesComponent extends Component<Signature> {
  beneficiaryCardLoader = BeneficiaryCardLoader;
  state = State;

  @service declare clipboardManager: Services['clipboardManager'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked allBeneficiaries: Beneficiary[] = [];
  @tracked isError = false;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.loadBeneficiariesTask.perform().catch(ignoreCancelation);
  }

  get beneficiaries(): Beneficiary[] {
    return this.allBeneficiaries.filter(
      beneficiary => !this.matchingBeneficiaries.includes(beneficiary)
    );
  }

  get hasInvoice(): boolean {
    return Boolean(this.args.context.invoice);
  }

  get matchingBeneficiaries(): Beneficiary[] {
    const { invoice } = this.args.context;

    if (invoice) {
      return this.allBeneficiaries.filter(
        beneficiary => beneficiary.accountIdentifier === invoice.supplierAccountIdentifier
      );
    }

    return [];
  }

  get subtitle(): string {
    const { invoice, quote } = this.args.context;

    if (invoice) {
      return this.intl.t('international-out.beneficiaries.subtitle.invoice');
    }

    return this.intl.t('international-out.beneficiaries.subtitle.default', {
      targetCurrency: quote.targetCurrency,
    });
  }

  @action
  deleteBeneficiary(beneficiary: Beneficiary): void {
    this.#cancelTasks([this.selectBeneficiaryTask]);
    this.modals.open('popup/destructive', {
      title: this.intl.t('international-out.beneficiaries.delete.title'),
      description: this.intl.t('international-out.beneficiaries.delete.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('international-out.beneficiaries.delete.confirm'),
      confirmTask: this.deleteBeneficiaryTask,
      beneficiary,
    });
  }

  transitionToCreationFlowTask = dropTask(async (): Promise<void> => {
    this.segment.track(EVENTS.NEW_BENEFICIARY);

    this.args.context.additionalRequirements = null;

    const supplierAccountIdentifier = this.args.context.invoice?.supplierAccountIdentifier;
    if (supplierAccountIdentifier) {
      await this.clipboardManager.copyTask.perform({
        clipboardText: supplierAccountIdentifier,
        infoLabel: this.intl.t(
          'international-out.beneficiaries.new.toast.account-identifier-copied'
        ),
      });
    }

    this.args.pushForwardFlow('international-out-beneficiary', 'create');
  });

  @action
  viewBeneficiary(beneficiary: Beneficiary): void {
    this.#cancelTasks([this.selectBeneficiaryTask]);
    this.segment.track(EVENTS.VIEW_BENEFICIARY);
    this.modals.open('transfers/international-out/modals/beneficiary', {
      beneficiary,
    });
  }

  deleteBeneficiaryTask = task(
    async (close: () => void, { beneficiary }: { beneficiary: Beneficiary }): Promise<void> => {
      try {
        await this.internationalOutManager.deleteBeneficiary(beneficiary);
        this.allBeneficiaries = this.allBeneficiaries.filter(({ id }) => id !== beneficiary.id);
        this.toastFlashMessages.toastInfo(
          this.intl.t('international-out.beneficiaries.delete.success')
        );
        this.segment.track(EVENTS.DELETE_BENEFICIARY);
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      } finally {
        close();
      }
    }
  );

  loadBeneficiariesTask = task(async (): Promise<void> => {
    this.isError = false;

    try {
      this.allBeneficiaries = (
        await this.internationalOutManager.getBeneficiaries(this.args.context.quote.targetCurrency)
      ).sort(this.#sortByName);
    } catch {
      this.isError = true;
    }
  });

  selectBeneficiaryTask = restartableTask(async (beneficiary: Beneficiary): Promise<void> => {
    this.#cancelTasks([this.deleteBeneficiaryTask]);

    const { context, transitionToNext } = this.args;
    const { quote } = context;

    this.segment.track(EVENTS.SELECT_BENEFICIARY);

    context.additionalRequirements = null;

    try {
      const additionalRequirements =
        await this.internationalOutManager.getAdditionalBeneficiaryRequirements({
          quoteId: quote.id,
          beneficiary,
        });

      context.additionalRequirements = additionalRequirements.length
        ? additionalRequirements
        : null;

      if (!context.additionalRequirements) {
        const {
          fees,
          quote: patchedQuote,
          targetAccountId,
        } = await this.internationalOutManager.updateBeneficiary({
          quoteId: quote.id,
          beneficiaryId: beneficiary.id,
          currency: beneficiary.currency,
          type: beneficiary.paymentType,
        });

        setProperties(context, {
          fees,
          quote: patchedQuote,
          targetAccount: { id: targetAccountId },
        });
      }

      context.beneficiary = beneficiary;

      transitionToNext();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  #cancelTasks = (
    taskObjects: TaskForAsyncTaskFunction<unknown, (...args: unknown[]) => Promise<void>>[]
  ) => {
    for (const task of taskObjects) {
      if (!task.isRunning) {
        continue;
      }

      task.cancelAll();
    }
  };

  #sortByName = (firstBeneficiary: Beneficiary, secondBeneficiary: Beneficiary): number =>
    firstBeneficiary.name
      .trim()
      .toUpperCase()
      .localeCompare(secondBeneficiary.name.trim().toUpperCase());
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::New::Beneficiaries': typeof FlowsTransfersInternationalOutNewBeneficiariesComponent;
  }
}
