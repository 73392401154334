/* import __COLOCATED_TEMPLATE__ from './accountant-access-upgrade.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';
import window from 'ember-window-mock';

interface AccountReceivableOnboardingAccountantAccessUpgradeSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AccountReceivableOnboardingAccountantAccessUpgrade extends Component<AccountReceivableOnboardingAccountantAccessUpgradeSignature> {
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare router: Services['router'];
  @service declare flow: Services['flow'];

  button = Button;

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get hasAvailableFreeTrial() {
    return this.upsellManager.hasAvailableFreeTrial('accountant_access') || this.hasInitialTrial;
  }

  get lottieProps() {
    return {
      relativePath: '/freemium-upgrade/upsell-invite-accountant.json',
      locale: this.intl.primaryLocale,
    };
  }

  @action
  goBack() {
    this.flow.back();
  }

  @action
  upsell() {
    this.upsellManager.upsell({ feature: 'accountant_access' });
  }
}
