/* import __COLOCATED_TEMPLATE__ from './voucher.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';

import type { DataContext } from './data-context';

interface SubscriptionCloseVoucherSignature {
  // The arguments accepted by the component
  Args: FlowStepArgs<DataContext>;
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionCloseVoucherComponent extends Component<SubscriptionCloseVoucherSignature> {
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare flow: Services['flow'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];

  @tracked continueClosure = null;

  constructor(owner: unknown, args: SubscriptionCloseVoucherSignature['Args']) {
    super(owner, args);
    this.segment.track('account-closing_voucher-offer_displayed');
  }

  get nextBillingDateFormatted() {
    return dateToken({
      // @ts-expect-error
      date: dayjs(this.args.context.nextBillingDate).add(1, 'day').toDate(),
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan?.localName;
  }

  get planAmount() {
    return this.intl.formatNumber(
      Number(this.subscriptionManager.currentPricePlan?.monthlyPrice?.value),
      {
        currency: this.subscriptionManager.currentPricePlan?.monthlyPrice?.currency,
        style: 'currency',
        currencyDisplay: 'symbol',
        notation: 'compact',
        // @ts-expect-error
        minimumFractionDigits: '2',
      }
    );
  }

  get planAmountHalved() {
    return this.intl.formatNumber(
      Number(this.subscriptionManager.currentPricePlan?.monthlyPrice?.value / 2),
      {
        currency: this.subscriptionManager.currentPricePlan?.monthlyPrice?.currency,
        style: 'currency',
        currencyDisplay: 'symbol',
        notation: 'compact',
        // @ts-expect-error
        minimumFractionDigits: '2',
      }
    );
  }

  get formattedFinishOn() {
    return dateToken({
      // @ts-expect-error
      date: this.args.context.finishOn,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });
  }

  @action
  onContinueClosure() {
    this.flow.dataContext.continueClosure = true;
    this.segment.track('account-closing_voucher-offer_continue-closure', {
      current_plan: this.planName,
    });

    this.args.transitionToNext();
  }

  redeemVoucherTask = dropTask(async () => {
    try {
      await this.organizationManager.organization.redeemVoucher();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      throw error;
    }
  });

  @action
  onSubmit(e: { preventDefault: () => void }) {
    e.preventDefault();
    this.segment.track('account-closing_voucher-offer_claim-clicked', {
      current_plan: this.planName,
    });

    this.redeemVoucherTask
      .perform()
      .then(() => {
        this.args.transitionToNext();
      })
      .catch(() => {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::Voucher': typeof SubscriptionCloseVoucherComponent;
  }
}
