/* import __COLOCATED_TEMPLATE__ from './supplier-invoice-archive-promo-box.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { PromotionalCard } from '@repo/design-system-kit';
import { dropTask, restartableTask } from 'ember-concurrency';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface DualNavSupplierInvoiceArchivePromoBoxSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DualNavSupplierInvoiceArchivePromoBoxComponent extends Component<DualNavSupplierInvoiceArchivePromoBoxSignature> {
  promotionalCard = PromotionalCard;

  newBadgeType = BADGE_TYPE.NEW_FEATURE;

  @service declare abilities: Services['abilities'];
  @service declare errors: Services['errors'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare networkManager: Services['networkManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare supplierInvoicesInsights: Services['supplierInvoicesInsights'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get overdueInvoices() {
    // @ts-expect-error
    return this.supplierInvoicesInsights.currentInsights?.total_overdue_counter;
  }

  get shouldDisplay() {
    return (
      this.abilities.can('update supplierInvoice') &&
      this.overdueInvoices > 14 &&
      this.router.currentRouteName === 'supplier-invoices.list.index'
    );
  }

  get archiveCTA() {
    return this.intl.t('supplier-invoices.bulk-archive.cta', {
      count: this.overdueInvoices,
    });
  }

  @action
  showBulkArchivePopup() {
    this.segment.track('supplier-invoices_archive_clicked', { action_type: 'bulk_suggested' });

    this.modals.open('popup/confirmation', {
      title: this.intl.t('supplier-invoices.imported.bulk.popup.archive.title', {
        count: this.overdueInvoices,
      }),
      description: this.intl.t('supplier-invoices.imported.bulk.popup.archive.subtitle', {
        count: this.overdueInvoices,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('supplier-invoices.imported.bulk.popup.archive.confirm', {
        count: this.overdueInvoices,
      }),
      confirmTask: this.bulkArchiveTask,
    });
  }

  @action
  fetchAllSupplierInvoices() {
    return this.fetchSupplierInvoicesTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.clearToastMessages();
        this.errors.handleError(error);
      });
  }

  fetchSupplierInvoicesTask = restartableTask(async () => {
    let invoices = [];
    // @ts-expect-error
    invoices = await this.store.query('supplier-invoice', {
      page: 1,
      per_page: 100,
      filter: {
        status: [INVOICE_STATUSES.toReview],
        due_date: 'past_and_today',
      },
    });

    // @ts-expect-error
    let pages = invoices?.meta?.total_pages || 1;

    for (let page = 2; page <= pages; page++) {
      invoices = [
        ...invoices,
        // @ts-expect-error
        ...(await this.store.query('supplier-invoice', {
          page,
          per_page: 100,
          filter: {
            status: [INVOICE_STATUSES.toReview],
            due_date: 'past_and_today',
          },
        })),
      ];
    }

    return invoices;
  });

  get peekedOverdueSupplierInvoices() {
    let allInvoices = this.fetchSupplierInvoicesTask.lastSuccessful?.value || [];
    // @ts-expect-error
    let idsArray = [];

    let overdueInvoices = allInvoices.filter(invoice => invoice.totalAmount !== null);

    overdueInvoices.map(invoice => idsArray.push(invoice.id));

    // @ts-expect-error
    return idsArray;
  }

  bulkArchiveTask = dropTask(async close => {
    await this.fetchAllSupplierInvoices();

    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_archive`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: this.peekedOverdueSupplierInvoices,
          }),
        }
      );

      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-invoices.imported.bulk.archive.toast.success', {
          count: this.overdueInvoices,
        })
      );
      this.router.replaceWith('supplier-invoices.index');
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      close();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::SupplierInvoiceArchivePromoBox': typeof DualNavSupplierInvoiceArchivePromoBoxComponent;
  }
}
