/* import __COLOCATED_TEMPLATE__ from './schedule.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { task } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface F24ManualDeclarationScheduleSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24ManualDeclarationScheduleComponent extends Component<F24ManualDeclarationScheduleSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare errors: Services['errors'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked selectedDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);
  @tracked errorMessage = false;

  constructor(owner: unknown, args: F24ManualDeclarationScheduleSignature['Args']) {
    super(owner, args);

    this.getAvailableDates();
  }

  async getAvailableDates() {
    // @ts-expect-error
    let { context } = this.args;
    let { f24Order } = context;

    try {
      let result = await f24Order.fetchAvailableDates();

      // @ts-expect-error
      this.disabledDates = result.dates.filter(data => !data.is_enabled).map(data => data.date);
      // @ts-expect-error
      this.minDate = result.start_date;
      // @ts-expect-error
      this.maxDate = result.end_date;

      // @ts-expect-error
      let firstEnabled = result.dates.find(data => data.is_enabled);
      // @ts-expect-error
      this.setDate(firstEnabled?.date ?? this.minDate);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  @action
  // @ts-expect-error
  setDate(newDate) {
    // @ts-expect-error
    this.args.context.f24Order.expirationDate = newDate ? dayjs(newDate).toDate() : null;
    this.selectedDate = newDate;
  }

  validateOrderTask = task(async () => {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let { f24Order } = context;

    try {
      await f24Order.validate();
      this.segment.track('f24_create-flow_step2-date-picked');
      transitionToNext();
    } catch (error) {
      // @ts-expect-error
      let isServiceUnavailableError = error.status === 503;
      if (isServiceUnavailableError) {
        this.toastFlashMessages.toastError(
          this.intl.t('toasts.errors.server_error', { htmlSafe: true })
        );
      } else if (!this.errors.handleError(error)) {
        // @ts-expect-error
        this.errorMessage = this.intl.t('f24.manual-declaration.date-selection.error');
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::F24::ManualDeclaration::Schedule': typeof F24ManualDeclarationScheduleComponent;
  }
}
