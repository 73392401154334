/* import __COLOCATED_TEMPLATE__ from './online-ad-features.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface OnlineAdFeaturesSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const OnlineAdFeatures = templateOnlyComponent<OnlineAdFeaturesSignature>();

export default OnlineAdFeatures;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::OnlineAdFeatures': typeof OnlineAdFeatures;
  }
}
