export default {
  "rule-card": "Fc",
  "rule-content": "Fq",
  "rule-options": "FZ",
  "visible": "FR",
  "dropdown-btn": "FQ",
  "delete-btn": "Fe",
  "btn--tertiary": "FB",
  "delete-rule-btn": "FE",
  "transaction-estimate": "Fd"
};
