/* import __COLOCATED_TEMPLATE__ from './amounts-card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

// @ts-expect-error
import { METER_AMOUNTS_THRESHOLDS } from 'qonto/constants/budget';
import { Meter } from 'qonto/react/components/meter';

interface BudgetDetailsAmountsCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BudgetDetailsAmountsCardComponent extends Component<BudgetDetailsAmountsCardSignature> {
  @service declare intl: Services['intl'];

  amountsThresholds = METER_AMOUNTS_THRESHOLDS;
  meter = Meter;

  get currentPeriod() {
    // @ts-expect-error
    let { periodId, budget } = this.args;

    return (
      budget.exercises
        // @ts-expect-error
        .reduce((periods, exercise) => [...periods, ...exercise.periods], [])
        // @ts-expect-error
        .find(period => period.id === periodId)
    );
  }

  get amountBudgeted() {
    let { value, currency } = this.currentPeriod.amountBudgeted;

    return this.intl.formatNumber(value, {
      currency,
      style: 'currency',
      maximumFractionDigits: Number.isInteger(parseFloat(value)) ? 0 : 2,
    });
  }

  get amountRemaining() {
    let {
      amountRemainingValue,
      amountBudgeted: { currency },
    } = this.currentPeriod;

    if (amountRemainingValue > 0) {
      let amountRemaining = this.intl.formatNumber(amountRemainingValue, {
        currency,
        style: 'currency',
        maximumFractionDigits: Number.isInteger(amountRemainingValue) ? 0 : 2,
      });

      return this.intl.t('team-budgets.detail.progress-card.remaining', {
        amount: amountRemaining,
      });
    }

    return this.intl.t('team-budgets.detail.progress-card.no-budget-remaining');
  }

  get amountSettled() {
    let { amountBudgeted, computedAmounts } = this.currentPeriod;

    if (!amountBudgeted?.value) {
      return 0;
    }

    return computedAmounts.amountSettled.value;
  }

  get shouldDisableMeter() {
    return dayjs().isAfter(this.currentPeriod.endDate);
  }

  get percent() {
    let { computedAmounts, amountBudgeted } = this.currentPeriod;

    let percent = (computedAmounts.amountSettled.value / amountBudgeted.value) * 100;

    return Math.max(0, Math.min(100, percent));
  }

  get overLow() {
    return this.percent >= this.amountsThresholds.LOW && this.percent < this.amountsThresholds.HIGH;
  }

  get overHigh() {
    return this.percent >= this.amountsThresholds.HIGH;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budget::Details::AmountsCard': typeof BudgetDetailsAmountsCardComponent;
  }
}
