/* import __COLOCATED_TEMPLATE__ from './frequency-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  FREQUENCY_CUSTOM_UNITS,
} from 'qonto/constants/direct-debit-subscriptions';
import {
  getLabelForFrequencyCustomUnit,
  getScheduleTypeLabel,
} from 'qonto/models/direct-debit-subscription';

interface DirectDebitCollectionsSubscriptionFrequencySelectionSignature {
  // The arguments accepted by the component
  Args: {
    isDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DirectDebitCollectionsSubscriptionFrequencySelectionComponent extends Component<DirectDebitCollectionsSubscriptionFrequencySelectionSignature> {
  @service declare intl: Services['intl'];

  scheduleTypeOptions = Object.values(DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES)
    .filter(value => value !== DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF)
    .map(value => ({
      value,
      label: getScheduleTypeLabel(value, this.intl),
      clearable: value === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM,
    }));

  scheduleCustomUnitOptions = Object.values(FREQUENCY_CUSTOM_UNITS).map(value => ({
    value,
    label: getLabelForFrequencyCustomUnit(value, this.intl),
  }));

  get selectedScheduleTypeOption() {
    return this.scheduleTypeOptions.find(
      // @ts-expect-error
      frequency => frequency.value === this.args.frequency.scheduleType
    );
  }

  get selectedScheduleCustomUnitOption() {
    return this.scheduleCustomUnitOptions.find(
      // @ts-expect-error
      customUnit => customUnit.value === this.args.frequency.scheduleCustomUnit
    );
  }

  @action
  // @ts-expect-error
  onUpdateScheduleType(scheduleTypeOption) {
    // @ts-expect-error
    this.args.onUpdateFrequency({
      scheduleType: scheduleTypeOption?.value || DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY,
      scheduleCustomInterval: null,
      scheduleCustomUnit:
        scheduleTypeOption?.value === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM
          ? FREQUENCY_CUSTOM_UNITS.MONTHS
          : null,
    });
  }

  @action
  // @ts-expect-error
  onUpdateScheduleCustomInterval(scheduleCustomInterval) {
    // @ts-expect-error
    this.args.onUpdateFrequency({
      scheduleType: DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM,
      scheduleCustomInterval: Number(scheduleCustomInterval),
      // @ts-expect-error
      scheduleCustomUnit: this.args.frequency.scheduleCustomUnit,
    });
  }

  @action
  // @ts-expect-error
  onUpdateScheduleCustomUnit(scheduleCustomUnit) {
    // @ts-expect-error
    this.args.onUpdateFrequency({
      scheduleType: DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM,
      // @ts-expect-error
      scheduleCustomInterval: this.args.frequency.scheduleCustomInterval,
      scheduleCustomUnit: scheduleCustomUnit.value,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Subscription::FrequencySelection': typeof DirectDebitCollectionsSubscriptionFrequencySelectionComponent;
  }
}
