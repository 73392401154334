/* import __COLOCATED_TEMPLATE__ from './integer-text-field-with-unit.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface IntegerTextFieldWithUnitSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const IntegerTextFieldWithUnit = templateOnlyComponent<IntegerTextFieldWithUnitSignature>();

export default IntegerTextFieldWithUnit;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::IntegerTextFieldWithUnit': typeof IntegerTextFieldWithUnit;
  }
}
