/* import __COLOCATED_TEMPLATE__ from './transfer-requirements.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SkeletonLoader } from '@repo/design-system-kit';
import { dropTask, type TaskForAsyncTaskFunction } from 'ember-concurrency';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { State } from 'qonto/react/components/transfers/international-out/state';
import type { DataContext } from 'qonto/routes/flows/setup/transfers/international-out/data-context';
import type { Requirements } from 'qonto/services/international-out/types';
import { formatAmountToNumber } from 'qonto/utils/amount';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { applyDefaultValues } from 'qonto/utils/international-out/requirements';

interface Signature {
  Args: FlowStepArgs<
    DataContext & {
      beneficiary: NonNullable<DataContext['beneficiary']>;
      fees: NonNullable<DataContext['fees']>;
      quote: NonNullable<DataContext['quote']>;
      targetAccount: NonNullable<DataContext['targetAccount']>;
    }
  >;
}

export default class FlowsTransfersInternationalOutNewTransferRequirementsComponent extends Component<Signature> {
  skeletonLoaderLine: typeof SkeletonLoader.Line = SkeletonLoader.Line;
  state = State;

  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked requirements: Requirements[] = [];
  @tracked isError = false;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.loadInitialRequirementsTask.perform().catch(ignoreCancelation);
  }

  get #hasRequiredFields(): boolean {
    return Boolean(
      this.requirements[0]?.fields
        .map(({ group }) => group)
        .flat()
        .some(({ required }) => required)
    );
  }

  get subtitle(): string {
    if (this.#hasRequiredFields) {
      return this.intl.t('international-out.transfer-requirements.subtitle.required');
    }

    return this.intl.t('international-out.transfer-requirements.subtitle.optional');
  }

  loadInitialRequirementsTask = dropTask(async (): Promise<void> => {
    this.isError = false;

    try {
      const requirements = await this.internationalOutManager.getTransferRequirements({
        quoteId: this.args.context.quote.id,
        targetAccountId: this.args.context.targetAccount.id,
      });
      this.#setRequirements(requirements);
    } catch {
      this.isError = true;
    }
  });

  onSubmitTask = dropTask(
    async (
      confirmTask: TaskForAsyncTaskFunction<unknown, () => Promise<boolean>>,
      { data: transferDetails }
    ): Promise<void> => {
      const { context, transitionToNext } = this.args;
      const { fees, invoice, quote, settings } = context;

      context.transferDetails = transferDetails;

      const totalAmount = formatAmountToNumber({
        amount: quote.sourceAmount + fees.total,
        precision: getAllowedDecimalPlaces(quote.sourceCurrency),
      });

      const shouldUploadAttachment = invoice
        ? false
        : totalAmount >= settings.attachment.maximumAmountWithout;

      if (shouldUploadAttachment || (await confirmTask.perform())) {
        this.segment.track(EVENTS.TRANSFER_REQUIREMENTS_SUBMITTED);
        transitionToNext();
      }
    }
  );

  refreshRequirementsTask = dropTask(async ({ data: transferDetails }): Promise<void> => {
    try {
      const requirements = await this.internationalOutManager.getTransferRequirements({
        quoteId: this.args.context.quote.id,
        targetAccountId: this.args.context.targetAccount.id,
        details: transferDetails,
      });
      this.#setRequirements(requirements);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  #setRequirements(requirements: Requirements[]): void {
    this.requirements = applyDefaultValues(requirements, {
      reference: this.args.context.invoice?.number ?? null,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::New::TransferRequirements': typeof FlowsTransfersInternationalOutNewTransferRequirementsComponent;
  }
}
