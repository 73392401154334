/* import __COLOCATED_TEMPLATE__ from './payment-link-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PaymentLinkModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PaymentLinkModal = templateOnlyComponent<PaymentLinkModalSignature>();

export default PaymentLinkModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::PaymentLinkModal': typeof PaymentLinkModal;
  }
}
