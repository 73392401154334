/* import __COLOCATED_TEMPLATE__ from './edit-scope.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface EditScopeSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const EditScope = templateOnlyComponent<EditScopeSignature>();

export default EditScope;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::Success::EditScope': typeof EditScope;
  }
}
