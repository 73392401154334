/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface CounterpartiesSidebarDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CounterpartiesSidebarDetailsComponent extends Component<CounterpartiesSidebarDetailsSignature> {
  @service declare intl: Services['intl'];

  @tracked readMoreIsOpen = false;
  @tracked showActionBtns = false;

  activities = {
    // @ts-expect-error
    atm: this.intl.t('activities.atm'),
    // @ts-expect-error
    fees: this.intl.t('activities.fees'),
    // @ts-expect-error
    finance: this.intl.t('activities.finance'),
    // @ts-expect-error
    food_and_grocery: this.intl.t('activities.food_and_grocery'),
    // @ts-expect-error
    gas_station: this.intl.t('activities.gas_station'),
    // @ts-expect-error
    hardware_and_equipment: this.intl.t('activities.hardware_and_equipment'),
    // @ts-expect-error
    hotel_and_lodging: this.intl.t('activities.hotel_and_lodging'),
    // @ts-expect-error
    insurance: this.intl.t('activities.insurance'),
    // @ts-expect-error
    it_and_electronics: this.intl.t('activities.it_and_electronics'),
    // @ts-expect-error
    legal_and_accounting: this.intl.t('activities.legal_and_accounting'),
    // @ts-expect-error
    logistics: this.intl.t('activities.logistics'),
    // @ts-expect-error
    manufacturing: this.intl.t('activities.manufacturing'),
    // @ts-expect-error
    marketing: this.intl.t('activities.marketing'),
    // @ts-expect-error
    office_rental: this.intl.t('activities.office_rental'),
    // @ts-expect-error
    office_supply: this.intl.t('activities.office_supply'),
    // @ts-expect-error
    online_service: this.intl.t('activities.online_service'),
    // @ts-expect-error
    other_expense: this.intl.t('activities.other_expense'),
    // @ts-expect-error
    other_income: this.intl.t('activities.other_income'),
    // @ts-expect-error
    other_service: this.intl.t('activities.other_service'),
    // @ts-expect-error
    refund: this.intl.t('activities.refund'),
    // @ts-expect-error
    restaurant_and_bar: this.intl.t('activities.restaurant_and_bar'),
    // @ts-expect-error
    salary: this.intl.t('activities.salary'),
    // @ts-expect-error
    sales: this.intl.t('activities.sales'),
    // @ts-expect-error
    subscription: this.intl.t('activities.subscription'),
    // @ts-expect-error
    tax: this.intl.t('activities.tax'),
    // @ts-expect-error
    transport: this.intl.t('activities.transport'),
    // @ts-expect-error
    treasury_and_interco: this.intl.t('activities.treasury_and_interco'),
    // @ts-expect-error
    utility: this.intl.t('activities.utility'),
    // @ts-expect-error
    voucher: this.intl.t('activities.voucher'),
  };

  get activityLabel() {
    // @ts-expect-error
    return this.activities[this.args.counterparty.activityTag] || '';
  }

  @action onMouseEnter() {
    this.showActionBtns = true;
  }

  @action onMouseLeave() {
    this.showActionBtns = false;
  }

  get activityIconThemedAsset() {
    // @ts-expect-error
    return this.args.counterparty.activityTag + '-xs';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Sidebar::Details': typeof CounterpartiesSidebarDetailsComponent;
  }
}
