export default {
  "container": "IP",
  "title": "Il",
  "subtitle": "IX",
  "form": "IC",
  "uploader": "Ik",
  "submit-button": "IJ",
  "error-message": "Is",
  "inputs": "IF"
};
