/* import __COLOCATED_TEMPLATE__ from './compact-header.hbs'; */
import Component from '@glimmer/component';

import { ApplyCta } from 'qonto/react/financing/components/partners/offer-details/apply-cta';

interface FinancingOfferDetailsCompactHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FinancingOfferDetailsCompactHeaderComponent extends Component<FinancingOfferDetailsCompactHeaderSignature> {
  ApplyCta = ApplyCta;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::OfferDetails::CompactHeader': typeof FinancingOfferDetailsCompactHeaderComponent;
  }
}
