export default {
  "wrapper": "cZR input-field mb-4",
  "error": "cZQ",
  "inactive": "cZe",
  "hidden-value": "cZB",
  "percentage-sign": "cZE",
  "black": "cZd",
  "input-field": "cZI",
  "disabled": "cZt"
};
