/* import __COLOCATED_TEMPLATE__ from './verification-code.hbs'; */
// Do not convert to TS, see commit description for more details
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { authBaseURL } from 'qonto/constants/hosts';

export default class FormFieldsVerificationCodeComponent extends Component {
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service networkManager;
  @service otpManager;
  @service userManager;

  /**
   * When the user wants to change his phone number but he is not KYC validated yet using the
   * `/v1/mfas/sms/trigger` fails in 403. Hence when we want to update the phone number with the
   * following endpoint: `/v4/user/phone_number`.
   * https://qonto.slack.com/archives/C03T1S54C3A/p1661938546396059?thread_ts=1661839251.754939&cid=C03T1S54C3A
   */
  triggerSMSTask = dropTask(async () => {
    let { triggerSmsToken, shouldChangePhoneNumber = false, actionType } = this.args;

    if (shouldChangePhoneNumber) {
      try {
        await this._resendChangePhoneNumber(this.userManager.currentUser.pendingPhoneNumber);
        this.toastFlashMessages.toastSuccess(this.intl.t('otp.toast-success'));
      } catch (error) {
        this.toastFlashMessages.toastError(
          this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
        );
      }
    } else {
      if (actionType === 'email.update' || actionType === 'email.update.migration') {
        try {
          await this.resendSmsOTP(actionType);
          this.toastFlashMessages.toastSuccess(this.intl.t('otp.toast-success'));
        } catch {
          await this.modals.open('popup/security/sca/generic-error');
        }
      } else {
        let context = this.userManager.currentUser?.mfaEnabled ? 'action' : 'setup';
        await this.otpManager.triggerSms({ context, triggerSmsToken, actionType });
        this.toastFlashMessages.toastSuccess(this.intl.t('otp.toast-success'));
      }
    }
  });

  _resendChangePhoneNumber(phoneNumber) {
    return this.networkManager.request(`${authBaseURL}/v5/user/phone_number`, {
      method: 'PUT',
      data: JSON.stringify({
        user: {
          phone_number: phoneNumber,
        },
      }),
    });
  }

  resendSmsOTP(actionType) {
    let endpoint = `${authBaseURL}${'/v5/user/email_update/resend_sms_otp'}`;

    let response = this.networkManager.request(endpoint, {
      method: 'POST',
      data: JSON.stringify({
        action_type: actionType,
      }),
    });
    return response;
  }
}
