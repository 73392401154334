/* import __COLOCATED_TEMPLATE__ from './initial.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { NextStep } from 'qonto/react/components/flows/invoices/next-step';

interface FlowsInvoicesClientsImportInitialSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class FlowsInvoicesClientsImportInitial extends Component<FlowsInvoicesClientsImportInitialSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('args.context') context;

  nextStepComponent = NextStep;

  // @ts-expect-error
  goToStep = stepId => {
    // @ts-expect-error
    this.args.context.nextStepId = stepId;

    if (stepId === 'upload') {
      this.segment.track('client-import_manual-flow_selected');
    } else {
      this.segment.track('client-import_connect-flow_selected');
    }

    // @ts-expect-error
    this.args.transitionToNext();
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Invoices::ClientsImport::Initial': typeof FlowsInvoicesClientsImportInitial;
  }
}
