/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { Header } from 'qonto/react/components/checks/table/header';
import { ChecksTableItem } from 'qonto/react/components/checks/table/item';
import { ChecksTablePlaceholder } from 'qonto/react/components/checks/table/placeholder';

interface TableSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class Table extends Component<TableSignature> {
  header = Header;
  placeholder = ChecksTablePlaceholder;
  item = ChecksTableItem;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checks::Table': typeof Table;
  }
}
