/* import __COLOCATED_TEMPLATE__ from './promotion.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { getPromotionRecommendationsConfig } from 'qonto/constants/promotion';
import { SUBSCRIPTION_STATUS } from 'qonto/constants/subscriptions';

interface PromotionRecommendationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PromotionRecommendationComponent extends Component<PromotionRecommendationSignature> {
  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  // @ts-expect-error
  supportedRoutes = Object.keys(getPromotionRecommendationsConfig(this.intl));

  get shouldRender() {
    // @ts-expect-error
    let { activeSubMenuItem, groups } = this.args;
    let activeSubMenuItemId = activeSubMenuItem?.id;
    if (
      this.subscriptionManager.currentSubscription?.status !== SUBSCRIPTION_STATUS.ACTIVE ||
      !groups ||
      !activeSubMenuItemId ||
      !this.supportedRoutes.includes(activeSubMenuItemId) ||
      !this.abilities.can('update subscription')
    ) {
      return false;
    }

    // @ts-expect-error
    let isCurrentSubMenu = groups.find(group =>
      // @ts-expect-error
      group.items.some(item => item.id === activeSubMenuItemId)
    );

    return isCurrentSubMenu && Boolean(this.routeConfig);
  }

  get routeConfig() {
    // @ts-expect-error
    let subMenuId = this.args.activeSubMenuItem?.id;
    return subMenuId && getPromotionRecommendationsConfig(this.intl)[subMenuId];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::Promotion': typeof PromotionRecommendationComponent;
  }
}
