/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DummyDataSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DummyData = templateOnlyComponent<DummyDataSignature>();

export default DummyData;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::DirectDebitCollections::DummyData': typeof DummyData;
  }
}
