export default {
  "animated": "Az",
  "container": "Aa",
  "header": "AH",
  "footer": "AO",
  "skip-cta": "Am",
  "pricing-caption": "Ap",
  "asset-x": "Ax",
  "asset-plus": "Aw",
  "asset-one": "AN",
  "show": "Av",
  "selector": "Ao",
  "selectorFadeIn": "An",
  "fadeIn": "Ai",
  "onePlusAssetFadeIn": "AA",
  "assetMoveUp": "AY",
  "xAssetFadein": "Ah",
  "feature": "Af",
  "highlighted": "AK"
};
