/* import __COLOCATED_TEMPLATE__ from './balance-amount.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

interface QontoHubNotificationsCreateStepsBalanceAmountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QontoHubNotificationsCreateStepsBalanceAmountComponent extends Component<QontoHubNotificationsCreateStepsBalanceAmountSignature> {
  amountField = AmountField;

  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get amountInEUR() {
    // @ts-expect-error
    return this.args.context.hubNotificationRule.value / 100;
  }

  get errorMessage() {
    // @ts-expect-error
    if (this.args.context.hubNotificationRule.value <= 0) {
      return this.intl.t('validations.errors.blank');
    }

    return null;
  }

  @action
  // @ts-expect-error
  changeValue(value) {
    let amount = parseInt(value, 10);

    // @ts-expect-error
    this.args.context.hubNotificationRule.set('value', Number.isNaN(amount) ? 0 : amount * 100);
  }

  confirmAmountTask = task(async () => {
    try {
      // @ts-expect-error
      await this.args.context.hubNotificationRule.save();

      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      // @ts-expect-error
      if (!error.isAdapterError) {
        throw error;
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Connect::Applications::Activation::BalanceAmount': typeof QontoHubNotificationsCreateStepsBalanceAmountComponent;
  }
}
