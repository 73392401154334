/* import __COLOCATED_TEMPLATE__ from './limit-input.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';

interface MemberInviteExpensePermissionLimitInputSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

/**
 * @typedef expensePermissionLimitPreDefType
 * @property {string} label
 * @property {number|null} value
 */
/**
 * @param {string|null} amount
 * @param {EmberConcurrencyTask} updateAmountTask
 * @param {string} title
 * @param {expensePermissionLimitPreDefType[]} preDefValues
 */
export default class MemberInviteExpensePermissionLimitInputComponent extends Component<MemberInviteExpensePermissionLimitInputSignature> {
  @service declare intl: Services['intl'];

  amountField = AmountField;

  @tracked isFocused = false;

  get isUnlimited() {
    return this.amount === null;
  }

  get amount() {
    // @ts-expect-error
    return this.args.amount ?? null;
  }

  @action
  // @ts-expect-error
  generateLabel(value) {
    return value
      ? `€${this.intl.formatNumber(value)}`
      : this.intl.t('invitation-flow.expense-permissions.transfers.limits.none-chip');
  }

  @action
  // @ts-expect-error
  onFocusChange(isFocused) {
    this.isFocused = isFocused;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::ExpensePermissions::LimitInput': typeof MemberInviteExpensePermissionLimitInputComponent;
  }
}
