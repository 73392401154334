export default {
  "row": "zH",
  "icon": "zO",
  "cell": "zm",
  "empty": "zp",
  "no-padding": "zx",
  "cell-content": "zw",
  "right": "zN",
  "cube": "zv"
};
