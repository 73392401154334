/* import __COLOCATED_TEMPLATE__ from './application-card.hbs'; */
import { getOwner } from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { HrisConnection } from 'qonto/models/hris-integration';
import { ApplicationCard } from 'qonto/react/components/flows/invoices/import-clients/application-card';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ApplicationCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApplicationCardComponent extends Component<ApplicationCardSignature> {
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare router: Services['router'];
  @service declare connectManager: Services['connectManager'];
  @service declare organizationManager: Services['organizationManager'];

  applicationCard = ApplicationCard;

  get isIntegration() {
    // @ts-expect-error
    let { integration_type, tray_solution_id, kombo_connect_provider_name } = this.args.application;

    return (
      integration_type === 'internal_integration' || tray_solution_id || kombo_connect_provider_name
    );
  }

  installApplicationTask = task(async () => {
    // @ts-expect-error
    let solutionId = this.args.application.tray_solution_id;
    // @ts-expect-error
    let applicationSlug = this.args.application.slug;
    // @ts-expect-error
    let komboConnectProviderName = this.args.application.kombo_connect_provider_name;

    try {
      // @ts-expect-error
      let [hubConnection] = this.store.query('hub-connection', {
        // @ts-expect-error
        organization_id: this.args.organization.id,
        filters: {
          external_service: applicationSlug,
        },
      });

      // @ts-expect-error
      let [solutionInstance] = solutionId
        ? this.store.query('solution-instance', {
            // @ts-expect-error
            organization_id: this.args.organization.id,
            solution_id: solutionId,
          })
        : [];

      // @ts-expect-error
      let [hubApplication] = this.store.query('hub-application', {
        // @ts-expect-error
        organization_id: this.args.organization.id,
        filters: { slug: applicationSlug },
      });

      let hrisConnection =
        komboConnectProviderName && HrisConnection.create(getOwner(this), komboConnectProviderName);

      let model = {
        hubApplication,
        hubConnection,
        // @ts-expect-error
        integration: this.args.application,
        solutionInstance,
        hrisConnection,
      };
      await this.connectManager.installApplicationTask.perform(model, applicationSlug);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.router.transitionTo(
        'settings.connect-hub.applications.hub-application.details.index',
        this.organizationManager.organization.slug,
        // @ts-expect-error
        this.args.application.slug
      );
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Invoices::ClientsImport::Integrations::ApplicationCard': typeof ApplicationCardComponent;
  }
}
