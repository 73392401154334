/* import __COLOCATED_TEMPLATE__ from './loading.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LoadingScreen } from 'qonto/react/components/cash-flow/components/first-time-experience/flow/loading-screen';

interface CashFlowFirstTimeExperienceLoadingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CashFlowFirstTimeExperienceLoadingComponent extends Component<CashFlowFirstTimeExperienceLoadingSignature> {
  loadingScreen = LoadingScreen;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::CashFlow::FirstTimeExperience::Loading': typeof CashFlowFirstTimeExperienceLoadingComponent;
  }
}
