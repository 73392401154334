/* import __COLOCATED_TEMPLATE__ from './custom-period.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';

interface BookkeepingPeriodSelectorCustomPeriodSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BookkeepingPeriodSelectorCustomPeriodComponent extends Component<BookkeepingPeriodSelectorCustomPeriodSignature> {
  datePicker = DatePicker;
  // @ts-expect-error
  @tracked startDate = this.args?.startDate ? parseDate(this.args.startDate) : null;
  // @ts-expect-error
  @tracked endDate = this.args?.endDate ? parseDate(this.args.endDate) : null;

  get maxDate() {
    // @ts-expect-error
    if (this.args?.maxDate) {
      // @ts-expect-error
      let date = dayjs(this.args.maxDate).format('YYYY-MM-DD');
      return parseDate(date);
    }
    return null;
  }

  @action
  // @ts-expect-error
  updateStartDate(newValue) {
    this.startDate = newValue ? parseDate(newValue.toString()) : null;
    // @ts-expect-error
    if (this.startDate && this.endDate && !dayjs(this.startDate).isBefore(this.endDate)) {
      // @ts-expect-error
      this.updateEndDate(dayjs(this.startDate).add(1, 'day').format('YYYY-MM-DD'));
    }
  }

  @action
  // @ts-expect-error
  updateEndDate(newValue) {
    this.endDate = newValue ? parseDate(newValue.toString()) : null;
  }

  @action
  // @ts-expect-error
  handleSubmit(e) {
    e.preventDefault();
    // @ts-expect-error
    this.args.onUpdate({
      // @ts-expect-error
      startDate: this.startDate.toString(),
      // @ts-expect-error
      endDate: this.endDate.toString(),
    });
  }

  get canSubmit() {
    if (!this.startDate || !this.endDate) {
      return false;
    }
    if (
      // @ts-expect-error
      this.startDate.toString() === this.args.startDate &&
      // @ts-expect-error
      this.endDate.toString() === this.args.endDate
    ) {
      return false;
    }
    return true;
  }

  get minEndDate() {
    if (this.startDate) {
      // @ts-expect-error
      return parseDate(dayjs(this.startDate).add(1, 'day').format('YYYY-MM-DD'));
    } else {
      return null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Bookkeeping::PeriodSelector::CustomPeriod': typeof BookkeepingPeriodSelectorCustomPeriodComponent;
  }
}
