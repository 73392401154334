/* import __COLOCATED_TEMPLATE__ from './link-tile.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LinkTileSignature {
  Element: HTMLAnchorElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const LinkTile = templateOnlyComponent<LinkTileSignature>();

export default LinkTile;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::LinkTile': typeof LinkTile;
  }
}
