/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, task, timeout } from 'ember-concurrency';
import { alias, bool, or } from 'macro-decorators';

import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface BeneficiaryUploadReviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BeneficiaryUploadReviewComponent extends Component<BeneficiaryUploadReviewSignature> {
  @service declare router: Services['router'];

  abortRoute = 'transfers.new';
  confirmRoute = 'beneficiaries.multi.review.confirm';

  // @ts-expect-error
  @alias('args.multiBeneficiary.beneficiaries') beneficiaries;
  // @ts-expect-error
  @or('hasMultiBeneficiaryError', 'hasBeneficiariesErrors') hasErrors;
  // @ts-expect-error
  @bool('args.multiBeneficiary.errors.length') hasMultiBeneficiaryError;

  @tracked multiBeneficiary = null;

  get hasBeneficiariesErrors() {
    let invalidBeneficiaries = this.beneficiaries.filter(
      // @ts-expect-error
      beneficiary => beneficiary.selected && beneficiary.get('errors.length') > 0
    );

    return invalidBeneficiaries.length > 0;
  }

  scrollToErrorTask = task(async () => {
    await timeout(500);

    scrollIntoView('.has-danger');
  });

  confirmFlightTask = dropTask(async multiBeneficiary => {
    try {
      await multiBeneficiary.confirmFlight();
      this.router.transitionTo(this.confirmRoute, multiBeneficiary);
    } catch {
      this.scrollToErrorTask.perform().catch(ignoreCancelation);
    }
  });

  @action
  handleGoBack() {
    this.router.transitionTo(this.abortRoute);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BeneficiaryUpload::Review': typeof BeneficiaryUploadReviewComponent;
  }
}
