/* import __COLOCATED_TEMPLATE__ from './hear-from-you.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { HearFromYouScreen } from 'qonto/react/components/flows/subscription/hear-from-you/index.ts';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface SubscriptionHearFromYouSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionHearFromYouComponent extends Component<SubscriptionHearFromYouSignature> {
  @service declare flow: Services['flow'];
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare zendeskWidget: Services['zendeskWidget'];

  hearFromYouScreen = HearFromYouScreen;

  constructor(owner: unknown, args: SubscriptionHearFromYouSignature['Args']) {
    super(owner, args);
    this.zendeskWidget.hide();
    this.segment.track('account-closing_cs-callback_displayed', {
      pricePlan: this.subscriptionManager.currentPricePlan?.code,
      closingReasonCategory: this.flow.dataContext.closingReasonCategory?.key,
      entry:
        this.flow.dataContext.closingReasonCategory?.key === 'service_quality'
          ? 'screen'
          : 'banner',
    });
  }

  @action onClose() {
    this.flow.dataContext.bookCall = false;
    this.segment.track('account-closing_cs-callback_continue-closure', {
      pricePlan: this.subscriptionManager.currentPricePlan?.code,
      closingReasonCategory: this.flow.dataContext.closingReasonCategory?.key,
      entry:
        this.flow.dataContext.closingReasonCategory?.key === 'service_quality'
          ? 'screen'
          : 'banner',
    });
    this.flow.next();
  }

  @action onBookCall({ slotPreference }: { slotPreference: string }) {
    this.flow.dataContext.bookCall = true;
    this.segment.track('account-closing_cs-callback_call-requested', {
      pricePlan: this.subscriptionManager.currentPricePlan.code,
      closingReasonCategory: this.flow.dataContext.closingReasonCategory?.key,
      entry:
        this.flow.dataContext.closingReasonCategory?.key === 'service_quality'
          ? 'screen'
          : 'banner',
    });
    this.onBookCallTask
      // @ts-expect-error
      .perform({ slotPreference })
      .then(() => {
        this.flow.next();
      })
      // @ts-expect-error
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        let status = errorInfo.httpStatus;
        if (status === 409) {
          this.toastFlashMessages.toastError(
            this.intl.t('subscription.account-closing.book-a-call.error-toast2')
          );
        } else {
          this.toastFlashMessages.toastError(
            this.intl.t('subscription.account-closing.book-a-call.error-toast')
          );
        }
      });
  }

  // @ts-expect-error
  onBookCallTask = dropTask(this, async ({ slotPreference }) => {
    let { organization } = this.organizationManager;
    let {
      closingReasonCategory,
      closingReasonSubCategory,
      closingReasonDetails,
      closingReasonDetailsOther,
    } = this.flow.dataContext;
    return await this.networkManager.request(
      `${apiBaseURL}/v3/organizations/${organization.id}/deactivation/cs_callback`,
      {
        method: 'POST',
        body: JSON.stringify({
          deactivation_reason: {
            closing_reason_category: closingReasonCategory?.key,
            closing_reason_sub_category: closingReasonSubCategory?.key,
            closing_reason_details: closingReasonDetails?.key,
            closing_reason_details_other: closingReasonDetailsOther ?? undefined,
          },
          slot_preference: slotPreference,
        }),
      }
    );
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::HearFromYou::HearFromYou': typeof SubscriptionHearFromYouComponent;
  }
}
