/* import __COLOCATED_TEMPLATE__ from './application-card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

import { ApplicationCardContent } from 'qonto/react/components/connect/application-card-content';

interface ApplicationCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApplicationCardComponent extends Component<ApplicationCardSignature> {
  BADGE_TYPE = BADGE_TYPE;

  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];

  applicationData = null;
  applicationCardContent = ApplicationCardContent;

  constructor(owner: unknown, args: ApplicationCardSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.applicationData = this.args.application?.data;
  }

  @action fireTrackingEvent() {
    // @ts-expect-error
    let { filteredBy, appliedFilters, organization } = this.args;
    // @ts-expect-error
    let { name, integration_type, tray_solution_id } = this.applicationData;

    this.segment.track('connect_app_card_clicked', {
      legalCountry: organization.legalCountry,
      integrationName: name,
      integrationType: integration_type,
      solutionId: tray_solution_id,
      filteredBy,
      ...(appliedFilters && {
        category: appliedFilters.category?.slug,
        type: appliedFilters.type?.slug,
        designedFor: appliedFilters.stakeholder?.slug,
      }),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::ApplicationCard': typeof ApplicationCardComponent;
  }
}
