/* import __COLOCATED_TEMPLATE__ from './date-range-selector.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

const NUM_MONTHS = 12;

interface FlowsBudgetsSelectExerciseSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class FlowsBudgetsSelectExercise extends Component<FlowsBudgetsSelectExerciseSignature> {
  @cached
  get startDates() {
    // @ts-expect-error
    return this.generateMonths(this.args.startDate).map(date =>
      date.startOf('month').format(DATE_PICKER_FIELD_FORMAT)
    );
  }

  @cached
  get endDates() {
    // @ts-expect-error
    let initialDate = this.selectedStartDate ?? this.args.startDate;
    return this.generateMonths(initialDate).map(date =>
      date.endOf('month').format(DATE_PICKER_FIELD_FORMAT)
    );
  }

  // @ts-expect-error
  generateMonths(initialDate, num = NUM_MONTHS) {
    let months = [dayjs(initialDate)];
    for (let i = 1; i < num; i++) {
      months.push(dayjs(initialDate).add(i, 'month'));
    }
    return months;
  }

  get selectedStartDate() {
    // @ts-expect-error
    if (this.args.selectedStartDate === undefined) {
      return this.startDates.at(0);
    }
    // @ts-expect-error
    return this.args.selectedStartDate;
  }

  get selectedEndDate() {
    // @ts-expect-error
    if (this.args.selectedEndDate === undefined) {
      return this.endDates.at(-1);
    }
    // @ts-expect-error
    return this.args.selectedEndDate;
  }

  // @ts-expect-error
  @action selectEndDate(endDate) {
    // @ts-expect-error
    this.args?.onDatesSelected({
      endDate,
      startDate: this.selectedStartDate,
    });
  }

  // @ts-expect-error
  @action selectStartDate(startDate) {
    let endDate = this.selectedEndDate;

    if (dayjs(startDate).isAfter(endDate)) {
      endDate = null;
    }

    if (dayjs(endDate).diff(startDate, 'month') >= NUM_MONTHS) {
      endDate = null;
    }

    // @ts-expect-error
    this.args.onDatesSelected?.({ endDate, startDate });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budget::DateRangeSelector': typeof FlowsBudgetsSelectExercise;
  }
}
