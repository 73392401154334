/* import __COLOCATED_TEMPLATE__ from './save.hbs'; */
import { set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { sensitiveAction } from '@repo/sca';
import { dropTask, task } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { ORIGIN } from 'qonto/constants/cards';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import isFunction from 'qonto/utils/is-function';

interface CardSaveProviderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardSaveProvider extends Component<CardSaveProviderSignature> {
  @service declare cardsManager: Services['cardsManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  @service declare userManager: Services['userManager'];
  @service declare networkManager: Services['networkManager'];

  // @ts-expect-error
  DEFAULT_ERROR_TOAST_MSG = this.intl.t('toasts.errors.server_error');

  saveCardTask = dropTask(async (context, onSuccess) => {
    try {
      let { card } = context;

      card.addIdempotencyHeader();

      let isQcp = await this.cardsManager.getIsQcpCardTask.perform(card.cardLevel);
      card.isQcp = isQcp;

      let data: unknown;

      if (isQcp) {
        data =
          card.isPhysical && card.pin
            ? await this.cardsManager.buildPayloadWithEncryptedPin(card)
            : this.cardsManager.buildPayloadWithoutPin(card);
      }

      if (variation('feature--boolean-sca-migration-react')) {
        await sensitiveAction({
          action: () =>
            this._saveTask.perform({
              card,
              context,
              data,
              onSuccess,
            }),
          onScaSuccess: params => {
            this.networkManager.scaHeader = params;
          },
          hostFaqService: this.zendeskLocalization,
          user: this.userManager?.currentUser || {},
        });
      } else {
        // @ts-expect-error
        await this.sensitiveActions.runTask.perform(this._saveTask, {
          card,
          context,
          data,
          onSuccess,
        });
      }
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  _saveTask = task(async ({ card, context, data, onSuccess }) => {
    try {
      if (card.isQcp) {
        let response = await this.cardsManager.postCardWithEncryptedPinTask.perform(data);
        this.store.pushPayload('card', response);
        // hacks because the card is not saved through ember-data
        // the card model in the context and in the response are not reconciled
        card.isQcpSaved = true;
        set(context, 'cardId', response.card.id);
      } else {
        await card.save();
      }

      card.removeIdempotencyHeader();

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      // @ts-expect-error
      let errors = error?.errors;
      if (hasMFAError(errors)) {
        throw error;
      }

      let errorMsg = this.DEFAULT_ERROR_TOAST_MSG;

      // errors about card saving must be caught here, because they are not thrown above sensitiveActions.runTask.perform()
      if (errors) {
        // @ts-expect-error
        errors.forEach(errorItem => {
          switch (errorItem.detail.code) {
            case 'too_many_digital_cards_for_holder_recently':
            case 'too_many_digital_cards_for_holder':
              errorMsg = this.intl.t('toasts.errors.too-many-digital-cards');
              break;
            case 'too_many_physical_cards_holder':
              if (context.origin === ORIGIN.CARD_REORDER) {
                errorMsg = this.intl.t('toasts.errors.physical-cards-limit');
              }
              break;
            default:
              break;
          }
        });
      }

      this.toastFlashMessages.toastError(errorMsg);

      let errorInfo = ErrorInfo.for(error);
      // business error mustn't be sent to sentry
      if (errorInfo.shouldSendToSentry && errorMsg === this.DEFAULT_ERROR_TOAST_MSG) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Providers::Save': typeof CardSaveProvider;
  }
}
