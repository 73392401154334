/* import __COLOCATED_TEMPLATE__ from './comparison.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS, COMPARE_TAB_ID } from 'qonto/constants/cards';

const TABS = [
  {
    value: CARD_LEVELS.STANDARD,
    title: 'cards.name.standard',
    usePremiumTheme: false,
  },
  {
    value: CARD_LEVELS.PLUS,
    title: 'cards.name.plus',
    usePremiumTheme: false,
  },
  {
    value: CARD_LEVELS.METAL,
    title: 'cards.name.metal',
    usePremiumTheme: true,
  },
  {
    value: COMPARE_TAB_ID,
    usePremiumTheme: false,
  },
];

interface CardComparisonModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardComparisonModal extends Component<CardComparisonModalSignature> {
  @service declare segment: Services['segment'];
  @tracked activeTabId;
  @tracked isAnimated = false;

  detailsTabs = TABS.slice(0, 3);

  constructor(owner: unknown, args: CardComparisonModalSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { initialTab } = this.args.data;
    this.activeTabId = initialTab;
  }

  get activeTab() {
    return TABS.find(tab => tab.value === this.activeTabId);
  }

  get usePremiumTheme() {
    // @ts-expect-error
    return this.activeTab.usePremiumTheme;
  }

  @action
  // @ts-expect-error
  onTabClick(activeTabId) {
    let switchingTabsCardLevels = [this.activeTabId, activeTabId];
    let isSwitchingWithMetal = switchingTabsCardLevels.includes(CARD_LEVELS.METAL);
    this.isAnimated = isSwitchingWithMetal;

    this.segment.track('cards_comparison_switch', {
      initial_page: this.activeTabId,
      landing_page: activeTabId,
    });

    this.activeTabId = activeTabId;
  }

  chooseCardTask = dropTask(async cardLevel => {
    // @ts-expect-error
    await this.args.data.handleChooseCardTask.perform(cardLevel);
    // @ts-expect-error
    this.args.close();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::Comparison': typeof CardComparisonModal;
  }
}
