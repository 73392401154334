/* import __COLOCATED_TEMPLATE__ from './account-aggregation-promo-box.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

import { DEFAULT_ORIGIN_URL } from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const LOCAL_STORAGE_KEY = 'account-aggregation-promo-box-dismissed';

interface DualNavAccountAggregationPromoBoxSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DualNavAccountAggregationPromoBoxComponent extends Component<DualNavAccountAggregationPromoBoxSignature> {
  badge = Badge;

  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  get shouldDisplay() {
    // do not show the promo box for bankless customers
    if (!this.subscriptionManager.features.bankAccount) {
      return false;
    }
    // @ts-expect-error
    return this.args.shouldDisplay && !safeLocalStorage.getItem(LOCAL_STORAGE_KEY);
  }

  get origin() {
    return this.router.currentRoute.name || DEFAULT_ORIGIN_URL;
  }

  @action
  handleOnClick() {
    // @ts-expect-error
    safeLocalStorage.setItem(LOCAL_STORAGE_KEY, Date.now());
    this.segment.track('account-aggregation-promo-box_cta_click');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::AccountAggregationPromoBox': typeof DualNavAccountAggregationPromoBoxComponent;
  }
}
