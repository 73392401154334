/* import __COLOCATED_TEMPLATE__ from './uploader.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { reads } from 'macro-decorators';

import { AiGlow } from 'qonto/react/components/account-receivable/summary';

interface accountReceivableOnboardingSummaryUploaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class accountReceivableOnboardingSummaryUploaderComponent extends Component<accountReceivableOnboardingSummaryUploaderSignature> {
  @service declare intl: Services['intl'];
  @service
  declare accountReceivableOnboardingUploadManager: Services['accountReceivableOnboardingUploadManager'];
  // @ts-expect-error
  @reads('accountReceivableOnboardingUploadManager.files') files;

  formattedMaxSize = formatFileSize(
    // @ts-expect-error
    this.intl,
    // @ts-expect-error
    this.accountReceivableOnboardingUploadManager.maxSize
  );

  constructor(owner: unknown, args: accountReceivableOnboardingSummaryUploaderSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.accountReceivableOnboardingUploadManager.shouldToastOnInvalidFile = false;
  }

  get file() {
    return this.files.at(-1);
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    // @ts-expect-error
    this.accountReceivableOnboardingUploadManager.resetState();
  }

  AiGlow = AiGlow;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::Summary::Uploader': typeof accountReceivableOnboardingSummaryUploaderComponent;
  }
}
