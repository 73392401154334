export default {
  "color-option": "cZD",
  "color-dot": "cZV",
  "any": "cZL",
  "red": "cZT",
  "orange": "cZz",
  "yellow": "cZa",
  "green": "cZH",
  "blue": "cZO",
  "purple": "cZm",
  "pink": "cZp",
  "black": "cZx",
  "grey": "cZw",
  "brown": "cZN",
  "color-name": "cZv"
};
