/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached, tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import provinces from 'qonto/constants/it-provinces';
// @ts-expect-error
import taxRegimes from 'qonto/constants/it-tax-regimes';

interface EinvoiceActivationFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EinvoiceActivationFormComponent extends Component<EinvoiceActivationFormSignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  @service declare intl: Services['intl'];

  @tracked displayValidations = false;

  confirmTask = dropTask(async () => {
    // @ts-expect-error
    await this.args.eInvoiceActivation.validate();
    this.displayValidations = true;
    // @ts-expect-error
    if (this.args.eInvoiceActivation.validations.isValid) {
      // @ts-expect-error
      await this.args.handleConfirmTask.perform();
    }
  });

  get data() {
    return {
      title: this.intl.t('receivable-invoices.italy.activation.title'),
      description: this.intl.t('receivable-invoices.italy.activation.subtitle'),
    };
  }

  get isReadonlyLegalTvaNumber() {
    let { legalTvaNumber: legalTvaNumberChanges } =
      // @ts-expect-error
      this.args.eInvoiceActivation.changedAttributes();
    // @ts-expect-error
    return !isEmpty(this.args.eInvoiceActivation.legalTvaNumber) && isEmpty(legalTvaNumberChanges);
  }

  get isReadonlyFiscalCode() {
    // @ts-expect-error
    let { fiscalCode: fiscalCodeChanges } = this.args.eInvoiceActivation.changedAttributes();
    // @ts-expect-error
    return !isEmpty(this.args.eInvoiceActivation.fiscalCode) && isEmpty(fiscalCodeChanges);
  }

  get selectedTaxRegimeOption() {
    return this.taxRegimeOptions.find(
      // @ts-expect-error
      option => option.key === this.args.eInvoiceActivation.taxRegime
    );
  }

  get selectedProvinceOption() {
    return this.provinceOptions.find(
      // @ts-expect-error
      option => option.key === this.args.eInvoiceActivation.provinceCode
    );
  }

  @cached
  get taxRegimeOptions() {
    return Object.entries(taxRegimes).map(([key, value]) => {
      return { key, value: `${key} - ${value}` };
    });
  }

  // @ts-expect-error
  @action updateTaxRegimeOption({ key }) {
    // @ts-expect-error
    this.args.eInvoiceActivation.taxRegime = key;
  }

  @cached
  get provinceOptions() {
    // @ts-expect-error
    return provinces.map(key => ({
      key,
      value: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  // @ts-expect-error
  @action updateProvinceOption({ key }) {
    // @ts-expect-error
    this.args.eInvoiceActivation.provinceCode = key;
  }

  @action handleClose() {
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EinvoiceActivation::Form': typeof EinvoiceActivationFormComponent;
  }
}
