export default {
  "budget-edit-form": "Ev",
  "period-label": "Eo",
  "periods": "En",
  "yearly": "Ei",
  "tooltips": "EA",
  "year": "EY",
  "total-budget": "Eh",
  "total-budget-value": "Ef",
  "divider": "EK",
  "buttons": "EG"
};
