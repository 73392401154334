/* import __COLOCATED_TEMPLATE__ from './periodic-update-banner.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { type LocaleCode } from '@repo/shared-config/constants/locales';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { type RiskClass } from 'qonto/constants/kyc-kyb-update-process';
import { getCompanyType } from 'qonto/utils/organization';
import { getKycbTypeformLink, hasSddEddTypeformFeature } from 'qonto/utils/typeform';

interface PeriodicUpdateBannerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PeriodicUpdateBanner extends Component<PeriodicUpdateBannerSignature> {
  @service declare intl: Services['intl'];
  @service('segment') declare segment: Services['segment'];

  get typeformLink() {
    // @ts-expect-error
    let { id, legalName, legalForm, legalCode } = this.args.organization;
    // @ts-expect-error
    let { userId, firstName } = this.args.membership;
    // @ts-expect-error
    let { riskClass } = this.args.updateProcess;

    let companyType = getCompanyType({
      legalForm,
      legalCode,
    });
    let href = getKycbTypeformLink(
      {
        organizationId: id,
        membershipUserId: userId,
        membershipFirstName: firstName,
        organizationLegalName: legalName,
      },
      riskClass as RiskClass,
      this.intl.primaryLocale as LocaleCode,
      companyType
    );

    return href;
  }

  get shouldDisplaySddEddTypeform() {
    return hasSddEddTypeformFeature(
      // @ts-expect-error
      this.args.organization,
      // @ts-expect-error
      this.args.updateProcess,
      variation('feature--boolean-improve-comms-in-it')
    );
  }

  get title() {
    if (this.shouldDisplaySddEddTypeform) {
      return this.intl.t('organizations.profile.company_profile.manual-validation.title-italy');
    }

    return this.intl.t('organizations.profile.company_profile.manual-validation.title');
  }

  get subtitle() {
    if (this.shouldDisplaySddEddTypeform) {
      return this.intl.t('organizations.profile.company_profile.manual-validation.subtitle-italy');
    }

    return this.intl.t('organizations.profile.company_profile.manual-validation.subtitle');
  }

  get ctaText() {
    if (this.shouldDisplaySddEddTypeform) {
      return this.intl.t('organizations.profile.company_profile.manual-validation.cta-italy');
    }

    return null;
  }

  get illustration() {
    if (this.shouldDisplaySddEddTypeform) {
      return '/illustrations/company-profile/fill-out-form.svg';
    }

    return '/illustrations/ill-cp-company-profile-review-validation.svg';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::PeriodicUpdateBanner': typeof PeriodicUpdateBanner;
  }
}
