export default {
  "row": "Lt body-1",
  "cell": "LM",
  "empty": "LP",
  "amount": "Ll",
  "review": "LX",
  "cell-content": "LC",
  "icon": "Lk",
  "name": "LJ",
  "name-text": "Ls",
  "quick-actions": "LF",
  "account-select": "Lg"
};
