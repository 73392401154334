/* import __COLOCATED_TEMPLATE__ from './set-budget.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { EXERCISE_KINDS } from 'qonto/constants/budget';

interface FlowsBudgetsSetBudgetSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsBudgetsSetBudget extends Component<FlowsBudgetsSetBudgetSignature> {
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: FlowsBudgetsSetBudgetSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.budget = this._createBudget(this.args.context.team);
    // @ts-expect-error
    this.exercise = this.budget.exercises.at(0);
    // @ts-expect-error
    this.exercise.initPeriods();
    this.segment.track('budget-creation_set-budget_displayed');
  }

  // @ts-expect-error
  _createBudget(assignedTo) {
    let params = {
      name: assignedTo.name,
      exerciseKind: EXERCISE_KINDS.MONTHLY,
      assignedTo,
    };
    // @ts-expect-error
    if (this.args.context.supervisors) {
      // @ts-expect-error
      params.supervisorIds = this.args.context.supervisors;
    }
    let budget = this.store.createRecord('budget', params);
    this.store.createRecord('exercise', {
      budget,
      // @ts-expect-error
      startDate: this.args.context.selectedStartDate,
      // @ts-expect-error
      endDate: this.args.context.selectedEndDate,
    });
    return budget;
  }

  submitTask = dropTask(async () => {
    // @ts-expect-error
    await this.budget.save();
    this.segment.track('budget-creation_budget-confirmation_clicked');
    // @ts-expect-error
    this.args.context.budget_id = this.budget.id;
    // @ts-expect-error
    this.args.transitionToNext();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::CreateBudget::SetBudget': typeof FlowsBudgetsSetBudget;
  }
}
