/* import __COLOCATED_TEMPLATE__ from './subscriptions-table.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SubscriptionsTableSignature {
  Element: null;
  Args: {
    isLoading?: boolean;
    isSidebarOpen?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const SubscriptionsTable = templateOnlyComponent<SubscriptionsTableSignature>();

export default SubscriptionsTable;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::SubscriptionsTable': typeof SubscriptionsTable;
  }
}
