export default {
  "header-cell": "wc caption-bold",
  "sortable": "wq",
  "animated": "wZ",
  "fadein-item": "wR",
  "animated-cell": "wQ",
  "col1": "we",
  "header-content": "wB caption-bold",
  "active": "wE"
};
