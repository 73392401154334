/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';
import { SortIcon } from 'qonto/react/components/paginated-table';

interface CardTableHeaderSignature {
  // The arguments accepted by the component
  Args: {
    isShrinked?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class CardTableHeader extends Component<CardTableHeaderSignature> {
  sortIcon = SortIcon;

  get sortField() {
    // @ts-expect-error
    let { sortBy } = this.args;
    return sortBy?.split(':')[0];
  }

  get sortOrder() {
    // @ts-expect-error
    let { sortBy } = this.args;
    return sortBy ? sortBy.split(':')[1] : DEFAULT_SORT_ORDER;
  }

  @action
  // @ts-expect-error
  handleSortBy(name) {
    // @ts-expect-error
    let { handleSortBy } = this.args;
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      // @ts-expect-error
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    return handleSortBy?.(`${name}:${sortOrder}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Table::Header': typeof CardTableHeader;
  }
}
