export default {
  "header-cell": "Tv",
  "empty": "To",
  "right": "Tn",
  "debitor": "Ti",
  "frequency": "TA",
  "date": "TY",
  "status": "Th",
  "amount": "Tf",
  "header-content": "TK caption-bold",
  "active": "TG",
  "row--sidebar-open": "Tr"
};
