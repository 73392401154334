export default {
  "container": "nB",
  "customize-field": "nE",
  "content": "nd",
  "sideview": "nI",
  "form": "nt",
  "footer": "nM",
  "layout": "nP",
  "pdf-preview": "nl",
  "german-preview": "nX"
};
