/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

// @ts-expect-error
import { METER_AMOUNTS_THRESHOLDS, NUM_OF_NAMES_TO_DISPLAY } from 'qonto/constants/budget';
import { Meter } from 'qonto/react/components/meter';

interface TeamCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TeamCardComponent extends Component<TeamCardSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  amountsThresholds = METER_AMOUNTS_THRESHOLDS;
  meter = Meter;

  get supervisorList() {
    // @ts-expect-error
    let managedBy = this.args.managedBy || [];
    let count = managedBy.meta?.total_count || 0;
    let translationVars = {
      count,
      managerNameOne: managedBy[0]?.fullName,
      managerNameTwo: managedBy[1]?.fullName,
      remaining: count - NUM_OF_NAMES_TO_DISPLAY,
    };

    if (count === 2) {
      return this.intl.t('team-budgets.section.card.two-supervisors.label', translationVars);
    }

    return this.intl.t('team-budgets.section.card.supervisors.label', translationVars);
  }

  get isActive() {
    // @ts-expect-error
    return this.args.budget.activePeriod?.amountBudgetedValue;
  }

  @action
  // @ts-expect-error
  formatAmount(amount, currency = 'eur') {
    let integer = parseInt(amount, 10);
    let difference = Number(amount) - integer;
    let numberToFormat = difference > 0 ? amount : integer;

    return this.intl.formatNumber(numberToFormat, {
      minimumFractionDigits: difference > 0 ? 2 : 0,
      currency,
      style: 'currency',
    });
  }

  get percent() {
    // @ts-expect-error
    let { budget } = this.args;
    let percent =
      (budget.activePeriod.amountSettledValue / budget.activePeriod.amountBudgetedValue) * 100;
    return Math.max(0, Math.min(100, percent));
  }

  get overLow() {
    return (
      this.percent >= METER_AMOUNTS_THRESHOLDS.LOW && this.percent < METER_AMOUNTS_THRESHOLDS.HIGH
    );
  }

  get overHigh() {
    return this.percent >= METER_AMOUNTS_THRESHOLDS.HIGH;
  }

  get encouragement() {
    // @ts-expect-error
    let { budget } = this.args;
    if (budget.isActive) return '';
    if (budget.activePeriod) {
      if (this.abilities.can('update budget')) {
        return this.intl.t('team-budgets.section.card.no-budget-monthly', {
          month: budget.activePeriod.name,
        });
      } else {
        return this.intl.t('team-budgets.section.card.no-budget-manager-monthly', {
          month: budget.activePeriod.name,
        });
      }
    } else if (budget.nextExercise) {
      return this.intl.t('team-budgets.section.card.no-budget-next-period', {
        date: dateToken({
          date: budget.nextExercise.startDate,
          // @ts-expect-error
          locale: this.intl.primaryLocale,
          token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
        }),
      });
    } else if (this.abilities.can('update budget')) {
      return this.intl.t('team-budgets.section.card.no-budget-period-ended');
    } else {
      return this.intl.t('team-budgets.section.card.no-budget-manager-period-ended');
    }
  }

  get amountRemainingDisclaimer() {
    // @ts-expect-error
    let { budget } = this.args;

    if (budget.activePeriod.amountRemainingValue > 0) {
      let amountRemaining = this.formatAmount(
        budget.activePeriod.amountRemainingValue,
        budget.activePeriod.amountBudgeted.currency
      );
      return this.intl.t('team-budgets.section.card.remaining', { amountRemaining });
    }

    return this.intl.t('team-budgets.section.card.remaining-no-budget');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budget::Card': typeof TeamCardComponent;
  }
}
