/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import {
  CARD_FEES,
  CARD_LEVELS,
  CARD_LEVELS_TRACKING,
  FORMAT_PRICE_OPTIONS,
  PLUS_CARD_WITHDRAWALS_INCLUDED,
} from 'qonto/constants/cards';
// @ts-expect-error
import { ORIGIN } from 'qonto/routes/flows/setup/cards/upsell';
import { getMonthlyCost, getUpsellFreeUntil } from 'qonto/utils/cards/card-costs';
import {
  getAtmMonthlyLimitsMaximum,
  getExtendedPaymentMonthlyLimitsMaximum,
} from 'qonto/utils/cards/card-limits';
import { getCardShortName } from 'qonto/utils/cards/card-names';

interface FlowsCardsUpsellSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsUpsell extends Component<FlowsCardsUpsellSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  @tracked selectedUpsellLevel = CARD_LEVELS.METAL;

  // @ts-expect-error
  @reads('args.context.cardsLimits') cardsLimits;
  // @ts-expect-error
  @reads('args.context.card.cardLevel') currentCardLevel;

  // These benefits depend on only the viewed upsell level (switch shows Plus or X)
  upsellLevelBenefits = {
    plus: [
      // @ts-expect-error
      this.intl.t('cards.steps.upsell.features.print-type.plus'),
      // @ts-expect-error
      this.intl.t('cards.steps.upsell.features.insurance.plus'),
      // @ts-expect-error
      this.intl.t('cards.steps.upsell.features.free-delivery'),
    ],
    metal: [
      // @ts-expect-error
      ...(this.currentCardLevel === CARD_LEVELS.PLUS
        ? []
        : // @ts-expect-error
          [this.intl.t('cards.steps.upsell.features.print-type.metal')]),
      // @ts-expect-error
      this.intl.t('cards.steps.upsell.features.insurance.metal'),
      // @ts-expect-error
      this.intl.t('cards.steps.upsell.features.free-delivery'),
    ],
  };

  fxRateBenefits = {
    // @ts-expect-error
    plus: this.intl.t('cards.steps.upsell.features.fx-rates.plus', {
      // @ts-expect-error
      percentage: this.intl.formatNumber(CARD_FEES.plus.foreignFees, { style: 'percent' }),
    }),
    // @ts-expect-error
    metal: this.intl.t('cards.steps.upsell.features.fx-rates.metal'),
  };

  withdrawalFeeBenefits = {
    // @ts-expect-error
    plus: this.intl.t('cards.steps.upsell.features.free-withdrawals.plus', {
      number: PLUS_CARD_WITHDRAWALS_INCLUDED,
    }),
    // @ts-expect-error
    metal: this.intl.t('cards.steps.upsell.features.free-withdrawals.metal'),
  };

  get oldCardName() {
    return getCardShortName(this.currentCardLevel, this.intl);
  }

  get paymentBenefit() {
    let { amount, multiple, oldCardName } = this._getBenefitsIntlOptions(true);

    if (this.isCurrentCardStandard) {
      return {
        plus: this.intl.t('cards.steps.upsell.features.payment.plus', {
          amount,
          multiple,
          oldCardName,
        }),
        metal: this.intl.t('cards.steps.upsell.features.payment.metal.metal-or-plus', { amount }),
      };
    } else {
      return {
        metal: this.intl.t('cards.steps.upsell.features.payment.metal.metal-only', {
          amount,
          multiple,
          oldCardName,
        }),
      };
    }
  }

  get withdrawalBenefit() {
    let { amount, multiple, oldCardName } = this._getBenefitsIntlOptions(false);

    if (this.isCurrentCardStandard) {
      return {
        plus: this.intl.t('cards.steps.upsell.features.withdrawal.plus', {
          amount,
          multiple,
          oldCardName,
        }),
        metal: this.intl.t('cards.steps.upsell.features.withdrawal.metal.metal-or-plus', {
          amount,
        }),
      };
    } else {
      return {
        metal: this.intl.t('cards.steps.upsell.features.withdrawal.metal.metal-only', {
          amount,
          multiple,
          oldCardName,
        }),
      };
    }
  }

  get limitsBenefits() {
    return {
      plus: [this.paymentBenefit.plus, this.withdrawalBenefit.plus],
      metal: [this.paymentBenefit.metal, this.withdrawalBenefit.metal],
    };
  }

  get benefits() {
    const orderedBenefits = (level: 'plus' | 'metal') => {
      // @ts-expect-error
      switch (this.args.context.origin) {
        case ORIGIN.FX_PAYMENT_FEES:
          return [
            this.fxRateBenefits[level],
            ...this.limitsBenefits[level],
            this.withdrawalFeeBenefits[level],
            ...this.upsellLevelBenefits[level],
          ];
        case ORIGIN.WITHDRAWAL_FEES:
          return [
            this.withdrawalFeeBenefits[level],
            ...this.limitsBenefits[level],
            this.fxRateBenefits[level],
            ...this.upsellLevelBenefits[level],
          ];
        case ORIGIN.WITHDRAWAL_LIMITS:
          return [
            this.limitsBenefits[level][1], // Withdrawal benefit first
            this.limitsBenefits[level][0], // Payment benefit second
            this.withdrawalFeeBenefits[level],
            this.fxRateBenefits[level],
            ...this.upsellLevelBenefits[level],
          ];
        default:
          return [
            ...this.limitsBenefits[level],
            this.withdrawalFeeBenefits[level],
            this.fxRateBenefits[level],
            ...this.upsellLevelBenefits[level],
          ];
      }
    };

    return {
      plus: orderedBenefits('plus'),
      metal: orderedBenefits('metal'),
    };
  }

  get title() {
    // @ts-expect-error
    switch (this.args.context.origin) {
      case ORIGIN.PAYMENT_LIMITS:
        return this.isCurrentCardStandard
          ? this.intl.t('cards.steps.upsell.title.metal-or-plus.from-payment-limits')
          : this.intl.t('cards.steps.upsell.title.metal-only.from-payment-limits');
      case ORIGIN.WITHDRAWAL_LIMITS:
        return this.isCurrentCardStandard
          ? this.intl.t('cards.steps.upsell.title.metal-or-plus.from-withdrawal-limits')
          : this.intl.t('cards.steps.upsell.title.metal-only.from-withdrawal-limits');
      case ORIGIN.FX_PAYMENT_FEES:
        return this.isCurrentCardStandard
          ? this.intl.t('cards.steps.upsell.title.metal-or-plus.from-fx-payment-fees')
          : this.intl.t('cards.steps.upsell.title.metal-only.from-fx-payment-fees');
      case ORIGIN.WITHDRAWAL_FEES:
        return this.isCurrentCardStandard
          ? this.intl.t('cards.steps.upsell.title.metal-or-plus.from-withdrawal-fees')
          : this.intl.t('cards.steps.upsell.title.metal-only.from-withdrawal-fees');
      default:
        return this.isCurrentCardStandard
          ? this.intl.t('cards.steps.upsell.title.metal-or-plus.from-promotional')
          : this.intl.t('cards.steps.upsell.title.metal-only.from-promotional');
    }
  }

  get subtitle() {
    if (!this.isCurrentCardStandard) {
      return this.intl.t('cards.steps.upsell.subtitle.metal-only');
    }

    // @ts-expect-error
    switch (this.args.context.origin) {
      case ORIGIN.PAYMENT_LIMITS:
        return this.intl.t('cards.steps.upsell.subtitle.metal-or-plus.from-payment-limits');
      case ORIGIN.WITHDRAWAL_LIMITS:
        return this.intl.t('cards.steps.upsell.subtitle.metal-or-plus.from-withdrawal-limits');
      case ORIGIN.FX_PAYMENT_FEES:
        return this.intl.t('cards.steps.upsell.subtitle.metal-or-plus.from-fx-payment-fees');
      case ORIGIN.WITHDRAWAL_FEES:
        return this.intl.t('cards.steps.upsell.subtitle.metal-or-plus.from-withdrawal-fees');
      default:
        return this.intl.t('cards.steps.upsell.subtitle.metal-or-plus.from-promotional');
    }
  }

  get isCurrentCardStandard() {
    return this.currentCardLevel === CARD_LEVELS.STANDARD;
  }

  get monthlyCost() {
    // @ts-expect-error
    return this._formatPrice(getMonthlyCost(this.selectedUpsellLevel, this.args.context.estimates));
  }

  get freeUntil() {
    // @ts-expect-error
    return getUpsellFreeUntil(this.selectedUpsellLevel, this.args.context.estimates);
  }

  @action
  confirmUpsellLevel() {
    // @ts-expect-error
    set(this.args.context, 'cardUpsellLevel', this.selectedUpsellLevel);

    // Customization options for Plus card needs to be reset
    // e.g. when choosing a Plus card, but then going back and choosing a Metal card instead
    // @ts-expect-error
    set(this.args.context, 'cardUpsellDesign', null);
    // @ts-expect-error
    set(this.args.context, 'cardUpsellTypeOfPrint', null);

    // @ts-expect-error
    let { origin } = this.args.context;
    this.segment.track('cards_upsell_card_selected', {
      card_level_origin: CARD_LEVELS_TRACKING[this.currentCardLevel],
      card_level_upsell: CARD_LEVELS_TRACKING[this.selectedUpsellLevel],
      trigger: origin === ORIGIN.PROMOTIONAL ? '' : origin,
    });

    // @ts-expect-error
    this.args.transitionToNext();
  }

  @action
  // @ts-expect-error
  selectUpsellLevel(value) {
    this.selectedUpsellLevel = value;
  }

  /* "for payment" being a boolean opposed to "for withdrawal" */
  // @ts-expect-error
  _getBenefitsIntlOptions(forPayment) {
    let currentLevelMonthlyLimit = forPayment
      ? getExtendedPaymentMonthlyLimitsMaximum(this.currentCardLevel, this.cardsLimits)
      : getAtmMonthlyLimitsMaximum(this.currentCardLevel, this.cardsLimits);
    let selectedLevelMonthlyLimit = forPayment
      ? getExtendedPaymentMonthlyLimitsMaximum(this.selectedUpsellLevel, this.cardsLimits)
      : getAtmMonthlyLimitsMaximum(this.selectedUpsellLevel, this.cardsLimits);
    return {
      amount: this._formatPrice(selectedLevelMonthlyLimit),
      multiple: this._nearestHalf(selectedLevelMonthlyLimit / currentLevelMonthlyLimit),
      oldCardName: this.oldCardName,
    };
  }

  // @ts-expect-error
  _formatPrice(price) {
    // @ts-expect-error
    return this.intl.formatNumber(price, FORMAT_PRICE_OPTIONS);
  }

  // @ts-expect-error
  _nearestHalf(num) {
    return Math.round(num * 2) / 2;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Upsell': typeof FlowsCardsUpsell;
  }
}
