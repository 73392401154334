/* import __COLOCATED_TEMPLATE__ from './edit-beneficiary.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

interface FlowsTransfersSepaBeneficiaryEditBeneficiarySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaBeneficiaryEditBeneficiaryComponent extends Component<FlowsTransfersSepaBeneficiaryEditBeneficiarySignature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    // @ts-expect-error
    let { beneficiaryToEdit } = this.args.context;

    if (!beneficiaryToEdit || beneficiaryToEdit.isDestroyed || beneficiaryToEdit.isDestroying) {
      return;
    }

    beneficiaryToEdit.rollbackAttributes();
  }

  get beneficiary() {
    // @ts-expect-error
    return this.args.context.beneficiaryToEdit;
  }

  get shouldShowInvalidInvoiceDisclaimer() {
    return (
      // @ts-expect-error
      this.args.context.invoice?.isGermanEInvoice &&
      // @ts-expect-error
      this.args.context.invoice?.isAttachmentNonFinancial
    );
  }

  @action
  // @ts-expect-error
  transitionToNext(editedBeneficiary) {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    context.beneficiary = editedBeneficiary;
    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::Beneficiary::EditBeneficiary': typeof FlowsTransfersSepaBeneficiaryEditBeneficiaryComponent;
  }
}
