/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import colorSelector from 'qonto/utils/color-selector';

interface CapitalDepositStakeholderCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositStakeholderCard extends Component<CapitalDepositStakeholderCardSignature> {
  get avatarStyle() {
    // @ts-expect-error
    let color = colorSelector(this.args.stakeholder.email);
    return htmlSafe(`background-color: ${color};`);
  }

  @action
  // @ts-expect-error
  handleEdit(stakeholder, toggle) {
    // @ts-expect-error
    this.args.onEdit(stakeholder);
    toggle();
  }

  @action
  // @ts-expect-error
  handleDelete(stakeholder, toggle) {
    // @ts-expect-error
    this.args.onDelete(stakeholder);
    toggle();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CapitalDeposit::Stakeholder::Card': typeof CapitalDepositStakeholderCard;
  }
}
