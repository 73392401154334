/* import __COLOCATED_TEMPLATE__ from './payment-lifespan-limit.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlowsCardsPaymentLifespanLimitSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsPaymentLifespanLimit extends Component<FlowsCardsPaymentLifespanLimitSignature> {
  @service declare segment: Services['segment'];

  @action
  handleNext() {
    let {
      // @ts-expect-error
      context: { card, flowTrackingOrigin },
      // @ts-expect-error
      transitionToNext,
    } = this.args;

    this.segment.track('cards_flash_budget_set', {
      origin: flowTrackingOrigin,
      payment_lifespan_limit: card.paymentLifespanLimit,
    });

    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::PaymentLifespanLimit': typeof FlowsCardsPaymentLifespanLimit;
  }
}
