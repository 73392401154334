/* import __COLOCATED_TEMPLATE__ from './language-select.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { sortByOrgLocale } from 'qonto/utils/sorters';

// @ts-expect-error
const mapLanguagesToObject = intl => code => ({
  code,
  label: intl.t(`bank_accounts.share.download.link-${code}`),
});

interface LanguageSelectSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class LanguageSelectComponent extends Component<LanguageSelectSignature> {
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare bankAccount: Services['bankAccount'];

  @tracked language = null;

  constructor(owner: unknown, args: LanguageSelectSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.options = this.intl.getLocaleCodes
      .map(mapLanguagesToObject(this.intl))
      // @ts-expect-error
      .sort((a, b) => sortByOrgLocale(a, b, this.organizationLocale));
  }

  get organizationLocale() {
    // @ts-expect-error
    return this.args.bankAccount.organization.legalCountry.toLowerCase();
  }

  get selectedLanguage() {
    // @ts-expect-error
    let languageCode = this.language || this.args.defaultLocale;
    // @ts-expect-error
    return this.options.find(option => option.code === languageCode);
  }

  // @ts-expect-error
  trackLocaleDownload(locale) {
    if (locale === this.organizationLocale) {
      this.segment.track('account_details_download_local');
    } else {
      this.segment.track(`account_details_download_${locale}`);
    }
  }

  @action
  // @ts-expect-error
  handleUpdate(language) {
    this.language = language.code;
  }

  handleIbanDownloadTask = dropTask(async locale => {
    this.trackLocaleDownload(locale);
    // @ts-expect-error
    await this.bankAccount.downloadIbanPdf(this.args.bankAccount.id, locale);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::DetailsDownload::LanguageSelect': typeof LanguageSelectComponent;
  }
}
