export default {
  "row": "DH",
  "active": "DO",
  "cell": "Dm",
  "empty-cell": "Dp",
  "counterparty": "Dx",
  "counterparty-info": "Dw",
  "name": "DN body-1",
  "qonto": "Dv",
  "currency": "Do",
  "account-number": "Dn caption",
  "custom-labels": "Di",
  "label": "DA"
};
