/* import __COLOCATED_TEMPLATE__ from './set-name.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface F24ManualDeclarationSetNameSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24ManualDeclarationSetNameComponent extends Component<F24ManualDeclarationSetNameSignature> {
  @service declare segment: Services['segment'];

  @tracked showError = false;

  // @ts-expect-error
  @action setName(value) {
    this.showError = false;
    // @ts-expect-error
    this.args.context.f24Order.name = value;
  }

  @action onSubmit() {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let { f24Order } = context;

    if (isBlank(f24Order.name)) {
      this.showError = true;
    } else {
      this.segment.track('f24_create-flow_step3-name-assigned');
      transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::F24::ManualDeclaration::SetName': typeof F24ManualDeclarationSetNameComponent;
  }
}
