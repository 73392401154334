export default {
  "wrapper": "OP",
  "active": "Ol",
  "expanded": "OX",
  "fade-in": "OC",
  "hidden": "Ok",
  "visible": "OJ",
  "item-list": "Os",
  "item-list-slide-down": "OF",
  "dropdown-trigger": "Og overlay",
  "settings-dropdown-slide-down": "OD",
  "responsive": "OV",
  "is-selected": "OL",
  "is-active": "OT",
  "dropdown": "Oz"
};
