/* import __COLOCATED_TEMPLATE__ from './mandate-settings-button.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ChecksMandateSettingsButtonSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ChecksMandateSettingsButton extends Component<ChecksMandateSettingsButtonSignature> {
  @service declare router: Services['router'];

  @action
  goToMandateSettings() {
    if (!this.args.isLoading) {
      this.router.transitionTo('checks.mandate-settings');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checks::MandateSettingsButton': typeof ChecksMandateSettingsButton;
  }
}
