/* import __COLOCATED_TEMPLATE__ from './payment-method.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface PaymentMethodSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PaymentMethodComponent extends Component<PaymentMethodSignature> {
  @service declare segment: Services['segment'];

  PAYMENT_METHOD_STEP_MAP = {
    BANK_DETAILS: 'bank-details',
    CARD_DETAILS: 'card-details',
  };

  @action
  // @ts-expect-error
  goToStep(nextStepId) {
    // @ts-expect-error
    this.args.context.chosenPaymentStep = nextStepId;

    if (nextStepId === this.PAYMENT_METHOD_STEP_MAP.BANK_DETAILS) {
      this.segment.track('onboarding_top_up_transfer_selected');
    } else {
      this.segment.track('onboarding_top_up_card_selected');
    }

    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::PaymentActivation::PaymentMethod': typeof PaymentMethodComponent;
  }
}
