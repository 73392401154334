/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import fade from 'ember-animated/transitions/fade';

interface CounterpartiesFiltersSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CounterpartiesFiltersComponent extends Component<CounterpartiesFiltersSignature> {
  fadeTransition = fade;

  get missingInformationSelected() {
    // @ts-expect-error
    let { missingVAT, missingLabels } = this.args;

    return missingVAT && missingLabels;
  }

  get showClearFilters() {
    // @ts-expect-error
    let { appliedFiltersCount, counterpartiesFound, searchQuery } = this.args;
    return (appliedFiltersCount || searchQuery) && !isNaN(counterpartiesFound);
  }

  @action
  toggleMissingInformationFilters() {
    let value = !this.missingInformationSelected;

    // @ts-expect-error
    this.args.updateFilters({
      missingVAT: value,
      missingLabels: value,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Filters': typeof CounterpartiesFiltersComponent;
  }
}
