/* import __COLOCATED_TEMPLATE__ from './benefits-list.hbs'; */
import Component from '@glimmer/component';

import { BenefitsList } from 'qonto/react/components/flows/addon-change/benefits-list';

interface FlowsAddonChangeBenefitsListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsAddonChangeBenefitsListComponent extends Component<FlowsAddonChangeBenefitsListSignature> {
  benefitsList = BenefitsList;

  get benefits() {
    // @ts-expect-error
    let benefits = this.args.context.targetAddon?.benefit_groups[0]?.benefits || [];

    // @ts-expect-error
    return benefits.filter(benefit => benefit.is_included).map(benefit => benefit.description);
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AddonChange::BenefitsList': typeof FlowsAddonChangeBenefitsListComponent;
  }
}
