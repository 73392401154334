export default {
  "label-wrapper": "hh body-1",
  "container": "hf",
  "transaction-info": "hK",
  "secondary": "hG",
  "amount-container": "hr",
  "amount": "hb",
  "disabled": "hy",
  "checkbox": "hU",
  "disclaimer": "hj"
};
