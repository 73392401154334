/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface GenerateLogoColorPickerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class GenerateLogoColorPickerComponent extends Component<GenerateLogoColorPickerSignature> {
  @service declare intl: Services['intl'];

  get colors() {
    return [
      { id: 'any', title: this.intl.t('generated-logo.modal.color.options.any') },
      { id: 'red', title: this.intl.t('generated-logo.modal.color.options.red') },
      { id: 'orange', title: this.intl.t('generated-logo.modal.color.options.orange') },
      { id: 'yellow', title: this.intl.t('generated-logo.modal.color.options.yellow') },
      { id: 'green', title: this.intl.t('generated-logo.modal.color.options.green') },
      { id: 'blue', title: this.intl.t('generated-logo.modal.color.options.blue') },
      { id: 'purple', title: this.intl.t('generated-logo.modal.color.options.purple') },
      { id: 'pink', title: this.intl.t('generated-logo.modal.color.options.pink') },
      { id: 'brown', title: this.intl.t('generated-logo.modal.color.options.brown') },
      { id: 'grey', title: this.intl.t('generated-logo.modal.color.options.grey') },
      { id: 'black', title: this.intl.t('generated-logo.modal.color.options.black') },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GenerateLogo::ColorPicker': typeof GenerateLogoColorPickerComponent;
  }
}
