/* import __COLOCATED_TEMPLATE__ from './pay-later-promo-box.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  CONTRACT_STATUS,
  PAY_LATER_ELIGIBILITY_STATUSES,
  PAY_LATER_FLOW_ORIGIN,
  PAY_LATER_PROMO_BOX_LOCAL_STORAGE_KEY,
} from 'qonto/constants/financing';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface DualNavPayLaterPromoBoxSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DualNavPayLaterPromoBoxComponent extends Component<DualNavPayLaterPromoBoxSignature> {
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];
  @service declare financing: Services['financing'];
  @service declare abilities: Services['abilities'];

  @tracked eligibilityResponse = null;

  constructor(owner: unknown, args: DualNavPayLaterPromoBoxSignature['Args']) {
    super(owner, args);

    this.handleFetchEligibility();
  }

  async handleFetchEligibility() {
    if (
      this.abilities.cannot('create pay later transfer in financing') ||
      safeLocalStorage.getItem(PAY_LATER_PROMO_BOX_LOCAL_STORAGE_KEY)
    )
      return;

    try {
      this.eligibilityResponse = await this.financing.getLastPayLaterEligibility();
    } catch {
      this.eligibilityResponse = null;
    }
  }

  get shouldDisplayPayLaterPromoBox() {
    if (!this.eligibilityResponse) return false;

    let { eligibility, contractStatus } = this.eligibilityResponse;

    return (
      eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE &&
      contractStatus === CONTRACT_STATUS.SIGNED
    );
  }

  @action
  redirectToDedicatedFlow() {
    this.segment.track('pay-later-promotional-nav');
    this.flowLinkManager.transitionTo({
      name: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        origin: PAY_LATER_FLOW_ORIGIN.PROMOTIONAL_CARD,
      },
    });
    // @ts-expect-error
    safeLocalStorage.setItem(PAY_LATER_PROMO_BOX_LOCAL_STORAGE_KEY, true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::PayLaterPromoBox': typeof DualNavPayLaterPromoBoxComponent;
  }
}
