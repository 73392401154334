export default {
  "wrapper": "mT",
  "content": "mz",
  "compact": "ma",
  "full-width": "mH",
  "left": "mO",
  "right-image": "mm",
  "right-lottie": "mp",
  "badge": "mx",
  "badge-icon": "mw",
  "animation": "mN",
  "hidden": "mv"
};
