/* import __COLOCATED_TEMPLATE__ from './integrations.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

interface FlowsInvoicesClientsImportIntegrationsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class FlowsInvoicesClientsImportIntegrations extends Component<FlowsInvoicesClientsImportIntegrationsSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare connectManager: Services['connectManager'];

  // @ts-expect-error
  @reads('args.context') context;
  // @ts-expect-error
  @reads('args.context.integrations') integrations;
  // @ts-expect-error
  @reads('organizationManager.organization') organization;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Invoices::ClientsImport::Integrations': typeof FlowsInvoicesClientsImportIntegrations;
  }
}
