export default {
  "separator": "ccY",
  "vat-accounting": "cch",
  "labels": "ccf",
  "label-title": "ccK",
  "amount-and-reference-wrapper": "ccG",
  "amount-wrapper": "ccr",
  "payable-amount-tooltip": "ccb",
  "sticky-panel": "ccy"
};
