/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

interface HelpCenterFaqLinkSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLIElement;
}

export default class HelpCenterFaqLinkComponent extends Component<HelpCenterFaqLinkSignature> {
  @service declare segment: Services['segment'];

  @action
  trackLinkOpeningEvent() {
    this.segment.track('transaction-helper_faq-page_clicked', {
      page_url: window.location.href,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::FaqLink::Index': typeof HelpCenterFaqLinkComponent;
  }
}
