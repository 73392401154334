/* import __COLOCATED_TEMPLATE__ from './allowed-bank-accounts.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AllowedBankAccountsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AllowedBankAccounts = templateOnlyComponent<AllowedBankAccountsSignature>();

export default AllowedBankAccounts;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::Error::AllowedBankAccounts': typeof AllowedBankAccounts;
  }
}
