/* import __COLOCATED_TEMPLATE__ from './integration-compact-header.hbs'; */
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';

import { INTEGRATION_SOURCES } from 'qonto/constants/connect';

interface ConnectIntegrationCompactHeaderSignature {
  // The arguments accepted by the component
  Args: {
    isVisible?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ConnectIntegrationCompactHeaderComponent extends Component<ConnectIntegrationCompactHeaderSignature> {
  source = INTEGRATION_SOURCES.MARKETPLACE;

  badgeHighlight = BadgeHighlight;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::IntegrationCompactHeader': typeof ConnectIntegrationCompactHeaderComponent;
  }
}
