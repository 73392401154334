/* import __COLOCATED_TEMPLATE__ from './kyc-success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlowsRequestsCardsKycSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsRequestsCardsKycSuccessComponent extends Component<FlowsRequestsCardsKycSuccessSignature> {
  @service declare segment: Services['segment'];

  @action
  handleClick() {
    // @ts-expect-error
    let { transitionToNext } = this.args;

    this.segment.track('request_creation_virtual_card_started');

    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Requests::Cards::KycSuccess': typeof FlowsRequestsCardsKycSuccessComponent;
  }
}
