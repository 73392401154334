export default {
  "wrapper": "xi",
  "legend": "xA",
  "input": "xY",
  "table": "xh",
  "footer": "xf",
  "footer-total-m": "xK",
  "footer-total-n": "xG",
  "footer-balance": "xr"
};
