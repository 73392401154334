/* import __COLOCATED_TEMPLATE__ from './account-close-error-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AccountCloseErrorModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AccountCloseErrorModal = templateOnlyComponent<AccountCloseErrorModalSignature>();

export default AccountCloseErrorModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccounts::AccountCloseErrorModal': typeof AccountCloseErrorModal;
  }
}
