/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

interface BackLinkSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BackLink extends Component<BackLinkSignature> {
  @action
  onBackButtonClick() {
    window.history.back();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BackLink::Index': typeof BackLink;
  }
}
