/* import __COLOCATED_TEMPLATE__ from './signature-warning.hbs'; */
import Component from '@glimmer/component';

import { SignatureWarning } from 'qonto/react/financing/components/flows/pay-later/transfer/pay-later-application/signature-warning';

interface FlowsTransfersPayLaterApplicationSignatureWarningSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersPayLaterApplicationSignatureWarningComponent extends Component<FlowsTransfersPayLaterApplicationSignatureWarningSignature> {
  signatureWarning = SignatureWarning;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::PayLaterApplication::SignatureWarning': typeof FlowsTransfersPayLaterApplicationSignatureWarningComponent;
  }
}
