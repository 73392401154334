export default {
  "container": "oC",
  "numbering": "ok",
  "sideview": "oJ",
  "form": "os",
  "numbering-fields": "oF",
  "row": "og",
  "label-tooltip": "oD",
  "tooltip-icon": "oV",
  "legend": "oL",
  "next-invoice-number": "oT",
  "pdf-preview": "oz",
  "de-layout": "oa",
  "de-pdf-preview": "oH"
};
