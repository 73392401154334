/* import __COLOCATED_TEMPLATE__ from './date-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DateField } from '@repo/design-system-kit';

interface F24DateFieldSignature {
  // The arguments accepted by the component
  Args: {
    isDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class F24DateField extends Component<F24DateFieldSignature> {
  dateField = DateField;

  get birthdate() {
    // @ts-expect-error
    let birthdate = this.args.value;
    return birthdate ? parseDate(birthdate) : birthdate;
  }

  get localClass() {
    // @ts-expect-error
    if (this.args.hasError) {
      return 'field error';
    }
    return 'field';
  }

  @action
  // @ts-expect-error
  updateBirthDate(value) {
    if (value) {
      // @ts-expect-error
      this.args.onUpdate(value.toString());
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::FormFields::DateField': typeof F24DateField;
  }
}
