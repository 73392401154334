/* import __COLOCATED_TEMPLATE__ from './review-upsell.hbs'; */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { CARD_LEVELS, CARD_LEVELS_TRACKING, FORMAT_PRICE_OPTIONS } from 'qonto/constants/cards';
import {
  getMonthlyCost,
  getUpsellFreeUntil,
  getUpsellNextBillingRecurrence,
} from 'qonto/utils/cards/card-costs';
import { getCardShortName } from 'qonto/utils/cards/card-names';

interface FlowsCardsReviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsReview extends Component<FlowsCardsReviewSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get cardPanelInfo() {
    // @ts-expect-error
    let { context } = this.args;

    return {
      icon: `card-${context.cardUpsellDesign}`,
      displayNameKey: context.cardUpsellDesign,
      displayTypeKey: context.cardUpsellLevel,
      displayTypeOfPrint: context.cardUpsellTypeOfPrint,
    };
  }

  get upsellMonthlyCost() {
    return this.intl.formatNumber(
      // @ts-expect-error
      getMonthlyCost(this.args.context.cardUpsellLevel, this.args.context.estimates),
      // @ts-expect-error
      FORMAT_PRICE_OPTIONS
    );
  }

  get upsellFreeUntilDate() {
    // @ts-expect-error
    return getUpsellFreeUntil(this.args.context.cardUpsellLevel, this.args.context.estimates);
  }

  get payingInfo() {
    // Variables to pass down to translations
    // @ts-expect-error
    let { card, cardUpsellLevel, estimates } = this.args.context;
    let oldCardName = getCardShortName(card.cardLevel, this.intl);
    let newCardName = getCardShortName(cardUpsellLevel, this.intl);
    let willStartPayingAt = dateToken({
      // @ts-expect-error
      date: getUpsellNextBillingRecurrence(cardUpsellLevel, estimates),
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });

    // Translation key will be different for a free One card
    let isCurrentCardStandard = card.cardLevel === CARD_LEVELS.STANDARD;

    return isCurrentCardStandard
      ? this.intl.t('cards.steps.review.upsell.details.paying-infos.one-upsold-card', {
          oldCardName,
          newCardName,
          date: willStartPayingAt,
        })
      : this.intl.t('cards.steps.review.upsell.details.paying-infos.charged-upsold-card', {
          oldCardName,
          newCardName,
          date: willStartPayingAt,
        });
  }

  @action
  // @ts-expect-error
  confirmUpsell(cardUpsellId) {
    // @ts-expect-error
    let { card, cardUpsellLevel } = this.args.context;
    this.segment.track('cards_upsell_confirmed', {
      card_level_origin: CARD_LEVELS_TRACKING[card.cardLevel],
      card_level_upsell: CARD_LEVELS_TRACKING[cardUpsellLevel],
    });

    // @ts-expect-error
    set(this.args.context, 'upsellCard.id', cardUpsellId);
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ReviewUpsell': typeof FlowsCardsReview;
  }
}
