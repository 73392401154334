export default {
  "content": "cSD",
  "error-message": "cSV",
  "wrapper": "cSL",
  "banner": "cST",
  "banner-content": "cSz",
  "banner-text": "cSa",
  "banner-image": "cSH",
  "banner-title": "cSO",
  "banner-description": "cSm body-2",
  "link": "cSp",
  "plans-comparison": "cSx",
  "plan-box": "cSw",
  "arrow": "cSN",
  "arrow-icon": "cSv",
  "link-downgrade": "cSo",
  "stroke-text": "cSn"
};
