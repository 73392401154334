/* import __COLOCATED_TEMPLATE__ from './scope-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import {
  DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION,
  DEFAULT_CUSTOM_PERMISSIONS_TEAM,
} from 'qonto/constants/membership';

interface ScopeSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ScopeSelectionComponent extends Component<ScopeSelectionSignature> {
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];
  @service declare abilities: Services['abilities'];

  // @ts-expect-error
  @reads('args.context.member.team.name') team;

  @action
  // @ts-expect-error
  handleSelectScope(scope) {
    // @ts-expect-error
    if (!this.args.context.isEditingMember) {
      // In the creation mode we reset the allowed bank accounts ids when the scope changes
      this.resetAllowedBankAccountsIds();
    }

    let defaultPermissions = {
      team: DEFAULT_CUSTOM_PERMISSIONS_TEAM,
      organization: DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION,
    };

    // @ts-expect-error
    this.args.context.member.customPermissions = {
      scope,
      groups: scope === 'team' ? defaultPermissions.team : defaultPermissions.organization,
    };

    // @ts-expect-error
    if (!this.args.context.isEditingMember) {
      this.segment.track('team_invite_manager-scope_set');
    }
    // @ts-expect-error
    this.args.transitionToNext();
  }

  resetAllowedBankAccountsIds() {
    // @ts-expect-error
    if (this.args.context && this.args.context.member) {
      // @ts-expect-error
      this.args.context.member.allowedBankAccountsIds = undefined;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::ScopeSelection': typeof ScopeSelectionComponent;
  }
}
