/* import __COLOCATED_TEMPLATE__ from './base.hbs'; */
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

// @ts-expect-error
import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';

interface DualNavMainMenuBaseSignature {
  // The arguments accepted by the component
  Args: {
    isSelected?: boolean;
    isLocked?: boolean;
    isNew?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DualNavMainMenuBaseComponent extends Component<DualNavMainMenuBaseSignature> {
  badge = Badge;

  get shouldShowItemDetails() {
    // @ts-expect-error
    return this.args.state === DUAL_NAV_STATES.EXPANDED;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::MainMenu::Base': typeof DualNavMainMenuBaseComponent;
  }
}
