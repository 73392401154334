/* import __COLOCATED_TEMPLATE__ from './supervisors-selector.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

import { SupervisorSelector } from 'qonto/react/components/budgets/create/supervisors-selection/supervisor-selector';

interface BudgetsSupervisorsSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BudgetsSupervisorsSelectorComponent extends Component<BudgetsSupervisorsSelectorSignature> {
  checkbox = Checkbox;
  supervisorSelector = SupervisorSelector;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budgets::SupervisorsSelector': typeof BudgetsSupervisorsSelectorComponent;
  }
}
