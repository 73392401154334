/* import __COLOCATED_TEMPLATE__ from './choose-card-physical.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task, waitForQueue } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  CARD_FLOWS_TRACKING_ORIGINS,
  CARD_LEVELS,
  CARD_LEVELS_TRACKING,
  COMPARE_TAB_ID,
  CUSTOMIZABLE_CARDS_LEVELS,
} from 'qonto/constants/cards';
import { getDefaultDesignByLevel } from 'qonto/utils/cards/design';

interface FlowsCardsChooseCardPhysicalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsChooseCardPhysical extends Component<FlowsCardsChooseCardPhysicalSignature> {
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('args.context.cardsLimits') cardsLimits;
  // @ts-expect-error
  @reads('args.context.estimates') estimates;
  // @ts-expect-error
  @reads('args.context.hasOneCardsLeft') hasOneCardsLeft;
  // @ts-expect-error
  @reads('args.context.isEstimateLoaded') isEstimateLoaded;
  // @ts-expect-error
  @reads('args.context.organization') organization;

  COMPARE_TAB_ID = COMPARE_TAB_ID;

  constructor(owner: unknown, args: FlowsCardsChooseCardPhysicalSignature['Args']) {
    super(owner, args);
    this.launchMetalCardDetailsModal();
  }

  get isOneCardIncluded() {
    return this.estimates && this.isEstimateLoaded && this.hasOneCardsLeft;
  }

  chooseCardTask = task(async cardLevel => {
    await this._proceedWithCard(cardLevel);
  });

  // @ts-expect-error
  _proceedWithCard(cardLevel) {
    let {
      // @ts-expect-error
      context: { abTestingVariation, card, cardsLimits, flowTrackingOrigin },
      // @ts-expect-error
      transitionToNext,
    } = this.args;

    this.segment.track('cards_order_physical_type_chosen', {
      origin: flowTrackingOrigin,
      card_type: CARD_LEVELS_TRACKING[cardLevel],
      ...(abTestingVariation && flowTrackingOrigin === CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR
        ? { testVariant: abTestingVariation }
        : {}),
    });

    let { payment_monthly_limit_maximum, atm_monthly_limit_maximum } = cardsLimits[cardLevel];

    card.setProperties({
      atmMonthlyLimit: atm_monthly_limit_maximum,
      cardLevel,
      paymentMonthlyLimit: payment_monthly_limit_maximum,
    });

    // @ts-expect-error
    this.args.context.showCardCustomization = CUSTOMIZABLE_CARDS_LEVELS.includes(cardLevel);
    // @ts-expect-error
    let cardDesign = this.args.context.showCardCustomization
      ? null
      : getDefaultDesignByLevel(cardLevel);

    card.setProperties({
      cardDesign,
      typeOfPrint: null,
    });

    transitionToNext();
  }

  @action
  // @ts-expect-error
  handleOpenCardComparisonModal(tabId) {
    this.modals.open('card/modals/comparison', {
      initialTab: tabId,
      isFullScreenModal: true,
      pricing: this.estimates,
      cardsMaxLimits: this.cardsLimits,
      isOneCardIncluded: this.isOneCardIncluded,
      handleChooseCardTask: this.chooseCardTask,
    });

    // @ts-expect-error
    this.args.context.showMetalCardDetailsModal = false;

    // @ts-expect-error
    let { flowTrackingOrigin } = this.args.context;

    let trackingEventOptions =
      tabId === CARD_LEVELS.METAL
        ? {
            type: CARD_LEVELS_TRACKING[CARD_LEVELS.METAL],
            origin: flowTrackingOrigin,
          }
        : {
            origin: flowTrackingOrigin,
          };
    this.segment.track('cards_view_details', trackingEventOptions);
  }

  async launchMetalCardDetailsModal() {
    await waitForQueue('afterRender');

    // @ts-expect-error
    if (this.args.context.showMetalCardDetailsModal) {
      this.handleOpenCardComparisonModal(CARD_LEVELS.METAL);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCardPhysical': typeof FlowsCardsChooseCardPhysical;
  }
}
