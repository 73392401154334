/* import __COLOCATED_TEMPLATE__ from './notice-info.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

const FIELD_ERRORS = ['invalid_notice_number', 'invalid_fiscal_code'];
const UNREACHABLE_COUNTERPARTY = 'unreachable_counterparty';
const NOTICE_ALREADY_PAID = 'notice_already_paid';

interface NoticeInfoSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class NoticeInfoComponent extends Component<NoticeInfoSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  @tracked focusedField = null;

  // @ts-expect-error
  @reads('args.context.payment') payment;

  // @ts-expect-error
  getToastErrorMessage(errorObject) {
    try {
      let error = errorObject.errors[0];
      if (error.code === UNREACHABLE_COUNTERPARTY) {
        return this.intl.t('pagopa.payment-creation.notice-info.errors.unreachable-counterparty');
      }

      if (error.code === NOTICE_ALREADY_PAID) {
        let detail = error.detail;
        let date = dayjs(detail);

        if (date.isValid()) {
          let paymentDate = dateToken({
            date: detail,
            // @ts-expect-error
            locale: this.intl.primaryLocale,
            token: DATE_FORMAT_TOKENS.DATE_TIME_S,
          });

          return this.intl.t('pagopa.payment-creation.notice-info.errors.notice-already-paid', {
            paymentDate,
          });
        }
      }

      return this.intl.t('pagopa.payment-creation.notice-info.generic-error-toast-message');
    } catch {
      return this.intl.t('pagopa.payment-creation.notice-info.generic-error-toast-message');
    }
  }

  handleSubmitTask = dropTask(async () => {
    this.segment.track('pagopa_payment-flow_notice_confirm-button_clicked');

    try {
      await this.payment.verify();
    } catch (error) {
      let showToast =
        // @ts-expect-error
        error.errors && error.errors.filter(e => !FIELD_ERRORS.includes(e.code)).length > 0;

      if (showToast) {
        this.toastFlashMessages.toastError(this.getToastErrorMessage(error));
      }

      return;
    }

    // @ts-expect-error
    this.args.transitionToNext();
  });

  @action
  // @ts-expect-error
  handleFieldUpdate(attribute, value) {
    if (this.paymentHasError(attribute)) {
      this.payment.errors.remove(attribute);
    }
    this.payment[attribute] = value;
  }

  @action
  // @ts-expect-error
  handleOnFocus(attribute, isFocused) {
    this.focusedField = isFocused ? attribute : null;
  }

  // @ts-expect-error
  paymentHasError(attribute) {
    return this.payment?.errors.has(attribute);
  }

  get noticeNumberMessageOpt() {
    if (this.paymentHasError('noticeNumber')) {
      return {
        message: this.intl.t(
          'pagopa.payment-creation.notice-info.notice-number-field.format-error',
          { htmlSafe: true }
        ),
        messageLevel: 'error',
      };
    } else if (this.focusedField === 'noticeNumber') {
      return {
        message: this.intl.t('pagopa.payment-creation.notice-info.notice-number-field.hint'),
        messageLevel: 'hint',
      };
    }
  }

  get fiscalCodeMessageOpt() {
    if (this.paymentHasError('fiscalCode')) {
      return {
        message: this.intl.t(
          'pagopa.payment-creation.notice-info.creditor-code-field.format-error',
          { htmlSafe: true }
        ),
        messageLevel: 'error',
      };
    } else if (this.focusedField === 'fiscalCode') {
      return {
        message: this.intl.t('pagopa.payment-creation.notice-info.creditor-code-field.hint'),
        messageLevel: 'hint',
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Pagopa::NoticeInfo': typeof NoticeInfoComponent;
  }
}
