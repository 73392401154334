export default {
  "row": "Fr body-1",
  "active": "Fb",
  "cell": "Fy",
  "empty": "FU",
  "connection": "Fj",
  "connection-avatar": "FW",
  "connection-name": "Fu",
  "member-name": "gS body-2",
  "member-team": "gc caption",
  "status": "gq",
  "failure": "gZ",
  "error": "gR",
  "hidden": "gQ"
};
