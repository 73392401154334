/* import __COLOCATED_TEMPLATE__ from './upload.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

interface FlowsMandateUploadSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsMandateUploadComponent extends Component<FlowsMandateUploadSignature> {
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare attachmentsManager: Services['attachmentsManager'];

  @tracked selectedFileIndex = 0;

  @tracked error = null;

  // @ts-expect-error
  @reads('args.context.mandate') model;
  // @ts-expect-error
  @reads('model.attachments') attachments;

  @action
  // @ts-expect-error
  handleRemoveAttachment(file) {
    this.attachmentsManager.removeAttachment(false, this.model, file);

    // close the modal if there are no more attachments to display
    if (this.attachments.length === 0) {
      // @ts-expect-error
      this.previewModal?.close();
    } else if (this.selectedFileIndex > 0) {
      this.selectedFileIndex--;
    }
  }

  @action
  // @ts-expect-error
  handleDownloadAttachment(downloadUrl) {
    window.open(downloadUrl, '_blank');
  }

  @action
  // @ts-expect-error
  handleShowAttachment(file) {
    let selectedAttachment = this.attachmentsManager.getAttachmentByFile(this.model, file);
    this.selectedFileIndex = this.attachments.indexOf(selectedAttachment);

    // @ts-expect-error
    this.previewModal = this.modals.open('mandate-upload-preview-modal', {
      isFullScreenModal: true,
      files: this.model.attachmentsFiles,
      parent: this,
      onDownload: this.handleDownloadAttachment,
      onRemove: this.handleRemoveAttachment,
    });
  }

  @action
  // @ts-expect-error
  handleCancelUploadAttachment(file) {
    this.attachmentsManager.cancelUploadAttachment(this.model, file);
  }

  attachMandateTask = task(async file => {
    await this.attachmentsManager.addAttachmentTask.perform(false, this.model, file);
    // @ts-expect-error
    this.error = this.checkError();
  });

  @action
  handleContinue() {
    // @ts-expect-error
    this.error = this.checkError();
    if (this.error) return;

    this.segment.track('app_sdd_mandate_uploaded');
    // @ts-expect-error
    this.args.transitionToNext();
  }

  checkError() {
    return this.attachments.length === 0 || this.attachmentsManager.addAttachmentTask.isRunning
      ? this.intl.t('validations.errors.upload_by_laws_error')
      : null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Mandate::Upload': typeof FlowsMandateUploadComponent;
  }
}
