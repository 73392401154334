/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SearchFieldWithDebounce } from '@repo/design-system-kit';

import { CARD_STATUSES, CARD_TYPES } from 'qonto/constants/cards';

interface CardFiltersSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardFiltersComponent extends Component<CardFiltersSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  searchField = SearchFieldWithDebounce;

  cardTypeOptions = Object.values(this.cardTypeFilters);

  get cardStatusFilters() {
    let { PENDING, LIVE, PAUSED, STOLEN, LOST, PIN_BLOCKED, DELETED, PRE_EXPIRED, EXPIRED } =
      CARD_STATUSES;
    // @ts-expect-error
    if (this.args.activeTab === 'archived') {
      return [
        { label: this.intl.t('cards.status.expired'), value: [EXPIRED, PRE_EXPIRED] },
        { label: this.intl.t('cards.status.deleted'), value: [DELETED] },
        { label: this.intl.t('cards.status.lost'), value: [LOST] },
        { label: this.intl.t('cards.status.stolen'), value: [STOLEN] },
      ];
    } else {
      return [
        { label: this.intl.t('cards.status.live'), value: [LIVE] },
        { label: this.intl.t('cards.status.kyc.pending'), value: [PENDING] },
        { label: this.intl.t('cards.status.paused'), value: [PAUSED, PIN_BLOCKED] },
      ];
    }
  }

  get cardTypeFilters() {
    return [
      {
        type: 'physical',
        label: this.intl.t('cards.list.filters.physical-cards'),
        value: CARD_TYPES.PHYSICALS,
      },
      {
        type: 'virtual',
        label: this.intl.t('cards.list.filters.virtual-cards'),
        value: CARD_TYPES.VIRTUALS,
      },
    ];
  }

  get cardTypeValue() {
    // @ts-expect-error
    let cardLevels = this.args.filters?.card_levels;
    if (!cardLevels?.length) {
      return null;
    }

    // @ts-expect-error
    if (cardLevels.every(level => CARD_TYPES.PHYSICALS.includes(level))) {
      return this.cardTypeOptions.find(option => option.type === 'physical');
    }
    return this.cardTypeOptions.find(option => option.type === 'virtual');
  }

  get hasTeamsFilter() {
    // @ts-expect-error
    return this.args.hasTeamsFilter && this.abilities.can('filter team');
  }

  get teamValue() {
    // @ts-expect-error
    let teamIds = this.args.filters?.team_ids;
    // @ts-expect-error
    return teamIds && this.teams.find(team => team.id === teamIds[0]);
  }

  get teams() {
    return this.organizationManager.organization.teams;
  }

  get cardStatusValue() {
    // @ts-expect-error
    let cardStatus = this.args.filters?.statuses;
    return (
      cardStatus?.length && this.cardStatusFilters.find(status => status.value[0] === cardStatus[0])
    );
  }

  @action
  // @ts-expect-error
  handleTeamSelect(selectedTeam) {
    if (selectedTeam) {
      // @ts-expect-error
      this.args.triggerFilter('team_ids', [selectedTeam.id]);
      this.segment.track('cards_filter_team');
    } else {
      // @ts-expect-error
      this.args.triggerFilter('team_ids', []);
    }
  }

  @action
  // @ts-expect-error
  handleSelectCardType(cardTypeOption) {
    // @ts-expect-error
    this.args.triggerFilter('card_levels', cardTypeOption?.value || []);
    if (cardTypeOption?.type === 'physical') {
      this.segment.track('cards_filter_physical');
    } else {
      this.segment.track('cards_filter_virtual');
    }
  }

  @action
  // @ts-expect-error
  handleCardStatusSelect(selectedStatus) {
    if (selectedStatus) {
      // @ts-expect-error
      this.args.triggerFilter('statuses', selectedStatus.value);
      this.segment.track('cards_filter_status');
    } else {
      // @ts-expect-error
      this.args.triggerFilter('statuses', []);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Filters': typeof CardFiltersComponent;
  }
}
