/* import __COLOCATED_TEMPLATE__ from './digital-first.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

interface CardModalsDigitalFirstSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardModalsDigitalFirst extends Component<CardModalsDigitalFirstSignature> {
  badge = Badge;

  @service declare intl: Services['intl'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  get description3() {
    return this.intl.t('cards.modals.digital-first.description-3', {
      htmlSafe: true,
      // keep link text on the same line as <a></a> to avoid extra spaces around the link text
      // @ts-expect-error
      link: htmlSafe(
        `<a
            href=${this.zendeskLocalization.getLocalizedArticle(6952381)}
            target="_blank"
            rel="noopener noreferrer"
            class="body-link"
          >${this.intl.t('cards.modals.digital-first.description-3-link-text')}</a>`
      ),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::DigitalFirst': typeof CardModalsDigitalFirst;
  }
}
