export default {
  "container": "PL",
  "content": "PT",
  "table": "Pz",
  "table-body": "Pa",
  "title": "PH",
  "title-with-badge": "PO",
  "metal-card-title": "Pm",
  "subtitle": "Pp",
  "card-image": "Px",
  "button-container": "Pw"
};
