/* import __COLOCATED_TEMPLATE__ from './time-frame.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

interface FlowsBudgetsTimeFrameSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsBudgetsTimeFrame extends Component<FlowsBudgetsTimeFrameSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare flow: Services['flow'];
  @service declare modals: Services['modals'];

  get exercise() {
    // @ts-expect-error
    if (this.args.context.exerciseId) {
      // @ts-expect-error
      return this.store.peekRecord('exercise', this.args.context.exerciseId);
    }
  }

  get isNew() {
    return !this.exercise;
  }

  get budget() {
    // @ts-expect-error
    return this.args.context.budget;
  }

  get startDate() {
    if (this.exercise?.startDate) {
      return this.exercise.startDate;
    } else if (this.budget.exercisesEndingInTheFuture.at(-1)) {
      return dayjs(this.budget.exercisesEndingInTheFuture.at(-1).endDate)
        .add(1, 'day')
        .format(DATE_PICKER_FIELD_FORMAT); // next available date
    } else {
      // @ts-expect-error
      return new dayjs().format(DATE_PICKER_FIELD_FORMAT);
    }
  }

  // @ts-expect-error
  @tracked error;
  @tracked selectedDates = {
    endDate:
      // @ts-expect-error
      this.args.context.selectedEndDate ??
      this.exercise?.endDate ??
      dayjs(this.startDate).add(11, 'month').endOf('month').format(DATE_PICKER_FIELD_FORMAT),
    startDate:
      // @ts-expect-error
      this.args.context.selectedStartDate ??
      dayjs(this.startDate).startOf('month').format(DATE_PICKER_FIELD_FORMAT),
  };

  // @ts-expect-error
  @action handleDatesSelected(selectedDates) {
    this.selectedDates = selectedDates;
    this.error = null;
  }

  @action submit() {
    this.segment.track('budget-creation_budget-period-definition_clicked');
    if (!this.selectedDates.endDate) {
      this.error = this.intl.t('validations.errors.presence');
    } else {
      // @ts-expect-error
      this.args.context.selectedEndDate = this.selectedDates.endDate;
      // @ts-expect-error
      this.args.context.selectedStartDate = this.selectedDates.startDate;
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }

  deleteTask = dropTask(async () => {
    this.segment.track('edit-budget_delete-period_clicked');

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.title'),
      description: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.button'),
    });

    // @ts-expect-error
    if (result === 'confirm') {
      this.segment.track('edit-budget_delete-period-confirmation_clicked');
      // @ts-expect-error
      await this.exercise.deleteRecord();
      // @ts-expect-error
      await this.exercise.save();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('team-budgets.edit.customize.toast.budget.success')
      );
      this.flow.complete();
    } else {
      this.segment.track('edit-budget_delete-period-confirmation-back_clicked');
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::EditBudget::TimeFrame': typeof FlowsBudgetsTimeFrame;
  }
}
