/* import __COLOCATED_TEMPLATE__ from './terms-and-conditions.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL, chargbackClaimsNamespace } from 'qonto/constants/hosts';
import { StepLayout } from 'qonto/react/components/flows/chargeback-claim/step-layout/step-layout';
import { TermsAndConditions } from 'qonto/react/components/flows/chargeback-claim/terms-and-conditions';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsChargebackClaimTermsAndConditionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsChargebackClaimTermsAndConditionsComponent extends Component<FlowsChargebackClaimTermsAndConditionsSignature> {
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  termsAndConditions = TermsAndConditions;
  stepLayout = StepLayout;

  submitTask = dropTask(async isAccepted => {
    try {
      let { claimType, selectedTransactions, files, description, hadCardDuringFraud } =
        // @ts-expect-error
        this.args.context;

      let payload = {
        data: {
          type: 'claims',
          attributes: {
            type: claimType,
            description,
            user_had_card_during_fraud: hadCardDuringFraud,
            user_accepted_tos: isAccepted,
          },
          relationships: {
            transactions: {
              // @ts-expect-error
              data: selectedTransactions.map(transaction => ({
                type: 'transactions',
                id: transaction.id,
              })),
            },
            documents: {
              // @ts-expect-error
              data: files.flat().map(file => ({ id: file.id, type: 'documents' })),
            },
          },
        },
      };

      this.segment.track('cards-chargeback_submit-dispute-cta_clicked');
      await this.networkManager.request(`${apiBaseURL}/${chargbackClaimsNamespace}/claims`, {
        method: 'POST',
        data: payload,
      });

      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::TermsAndConditions': typeof FlowsChargebackClaimTermsAndConditionsComponent;
  }
}
