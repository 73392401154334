/* import __COLOCATED_TEMPLATE__ from './cta.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  ALLOWED_ROUTES,
  ATTEMPTS_LIMIT,
  GENERATED_LOGO_MODEL,
  LOGO_STATUS,
  TRACKING_EVENTS,
  // @ts-expect-error
} from 'qonto/constants/generated-logo';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface CtaSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CtaComponent extends Component<CtaSignature> {
  @service declare capitalIncreaseManager: Services['capitalIncreaseManager'];
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  // @ts-expect-error
  @tracked logos;

  constructor(owner: unknown, args: CtaSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.router.on('routeDidChange', this, this.#fetchLogos);

    this.#fetchLogos();
  }

  willDestroy() {
    super.willDestroy();
    // @ts-expect-error
    this.router.off('routeDidChange', this, this.#fetchLogos);
  }

  @action
  openModal() {
    this.modals.open('generate-logo/modal', {
      isFullScreenModal: true,
    });

    this.trackLogoCreationEntryPointEvent();
  }

  doesMatchRoutes() {
    let allowedRoutes = [
      ALLOWED_ROUTES.CAPITAL.CERTIFICATE,
      ALLOWED_ROUTES.CAPITAL.KBIS_INFO,
      ALLOWED_ROUTES.CAPITAL.UPLOAD,
    ];

    // @ts-expect-error
    let { currentRouteName } = this.args;

    let isDepositRequestSigned =
      currentRouteName === ALLOWED_ROUTES.CAPITAL.REVIEW_INDEX && this.isDepositRequestSigned;

    return isDepositRequestSigned || allowedRoutes.includes(currentRouteName);
  }

  get isDepositRequestSigned() {
    return this.capitalIncreaseManager.isDepositRequestSigned;
  }

  get showGenerateLogoCta() {
    return this.doesMatchRoutes();
  }

  get isCollapsed() {
    // @ts-expect-error
    let { state } = this.args;
    return state !== 'expanded';
  }

  trackLogoCreationEntryPointEvent() {
    this.segment.track(TRACKING_EVENTS.ENTRY_POINT_CLICKED, {
      // @ts-expect-error
      step: this.args.currentRouteName,
    });
  }

  fetchLogosTask = dropTask(async () => {
    this.logos = await this.store.findAll(GENERATED_LOGO_MODEL);
  });

  get generatedLogos() {
    return this.logos?.filter(
      // @ts-expect-error
      logo =>
        !logo.isNew &&
        logo.status === LOGO_STATUS.GENERATED &&
        logo.organization?.id === this.organization.id
    );
  }

  get hasReachedLogoLimit() {
    return this.generatedLogos?.length >= ATTEMPTS_LIMIT;
  }

  get ctaButtonText() {
    if (this.hasReachedLogoLimit) {
      return this.intl.t('generated-logo.entry-point.cta.limit-reached');
    } else {
      return this.intl.t('generated-logo.entry-point.cta.create');
    }
  }

  get ctaTitle() {
    if (this.hasReachedLogoLimit) {
      return this.intl.t('generated-logo.entry-point.title.limit-reached');
    } else {
      return this.intl.t('generated-logo.entry-point.title.create');
    }
  }

  get ctaDescription() {
    if (this.hasReachedLogoLimit) {
      return this.intl.t('generated-logo.entry-point.description.limit-reached');
    } else {
      return this.intl.t('generated-logo.entry-point.description.create');
    }
  }

  #fetchLogos() {
    if (this.showGenerateLogoCta) {
      this.fetchLogosTask.perform().catch(ignoreCancelation);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GenerateLogo::Cta': typeof CtaComponent;
  }
}
