export default {
  "row": "XX",
  "active": "XC",
  "status": "Xk",
  "align-right": "XJ",
  "animated": "Xs",
  "fadein-item": "XF",
  "cell": "Xg body-2",
  "cell-content": "XD",
  "amount": "XV body-1",
  "subtitle": "XL caption",
  "no-padding": "XT",
  "note": "Xz"
};
