/* import __COLOCATED_TEMPLATE__ from './transactions-item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { CLAIM_TYPES } from 'qonto/react/constants';

interface FlowsCardChargebackTransactionsBulkSelectionTransactionsItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardChargebackTransactionsBulkSelectionTransactionsItemComponent extends Component<FlowsCardChargebackTransactionsBulkSelectionTransactionsItemSignature> {
  statusAvatar = StatusAvatar;

  checkbox = Checkbox;

  @service declare intl: Services['intl'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  get isNotEligible() {
    // @ts-expect-error
    let { transaction, type } = this.args;
    if (!transaction) return false;

    const isPendingTransactionClaimAllowed = type === CLAIM_TYPES.fraud;
    return (
      (!isPendingTransactionClaimAllowed && transaction.pending) ||
      transaction.canceled ||
      transaction.declined ||
      transaction.reversed ||
      transaction.chargebackDisputedTypes.includes(type) ||
      transaction.chargebackDisputingTypes.length > 0
    );
  }

  get isSelected() {
    // @ts-expect-error
    let { selectedTransactions, transaction } = this.args;
    let selected = selectedTransactions.includes(transaction);
    return selected;
  }

  toggleSelection = () => {
    // @ts-expect-error
    let { selectedTransactions, transaction } = this.args;

    if (this.isSelected) {
      let index = selectedTransactions.indexOf(transaction);
      selectedTransactions.splice(index, 1);
    } else {
      selectedTransactions.push(transaction);
    }
  };

  get disclaimer() {
    // @ts-expect-error
    let { transaction, type } = this.args;
    if (!transaction) return;

    let learnMoreLink = this.intl.t(
      'dispute-flow.transactions-selection.bulk-selection.learn-more'
    );
    switch (true) {
      // transaction is in dispute
      case transaction.chargebackDisputingTypes.length > 0:
        return {
          key: 'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputing.body',
          body: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputing.body',
            {
              learnMoreLink,
            }
          ),
          url: this.zendeskLocalization.getLocalizedArticle(9015327),
        };

      // transaction has been disputed for same type
      case transaction.chargebackDisputedTypes.includes(type):
        return {
          key: 'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputed.body',
          body: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.disputed.body',
            {
              learnMoreLink,
            }
          ),
          url: this.zendeskLocalization.getLocalizedArticle(9015327),
        };
      case transaction.pending:
        return {
          key: 'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.processing.body',
          body: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.processing.body',
            { learnMoreLink }
          ),
          url: this.zendeskLocalization.getLocalizedArticle(9015327),
        };
      case transaction.canceled || transaction.declined || transaction.reversed:
        return {
          key: 'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.body',
          body: this.intl.t(
            'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.body',
            {
              learnMoreLink,
              placeholder: this.placeholderText(transaction),
            }
          ),
          url: this.zendeskLocalization.getLocalizedArticle(9015327),
        };

      default:
        return null;
    }
  }

  // @ts-expect-error
  placeholderText(transaction) {
    if (transaction.canceled) {
      return this.intl.t(
        'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.status.canceled'
      );
    } else if (transaction.declined) {
      return this.intl.t(
        'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.status.declined'
      );
    } else if (transaction.reversed) {
      return this.intl.t(
        'dispute-flow.transactions-selection.bulk-selection.transaction-disclaimer.fail-statuses.status.reverted'
      );
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::TransactionsBulkSelection::TransactionsItem': typeof FlowsCardChargebackTransactionsBulkSelectionTransactionsItemComponent;
  }
}
