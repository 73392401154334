/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { UpsellAssets } from '@repo/cards-kit/assets';
import { Badges } from '@repo/cards-kit/components';
import { ToggleButton } from '@repo/design-system-kit';

import { CARD_LEVELS } from 'qonto/constants/cards';

interface CardUpsellSignature {
  // The arguments accepted by the component
  Args: {
    isCurrentCardStandard?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardUpsellComponent extends Component<CardUpsellSignature> {
  metal = Badges.Metal;
  recycled = Badges.Recycled;

  toggleButton = ToggleButton;

  @service declare deviceManager: Services['deviceManager'];
  @service declare intl: Services['intl'];

  CARD_LEVELS = CARD_LEVELS;

  get cardBrand() {
    // @ts-expect-error
    let { selectedUpsellLevel } = this.args;

    if (selectedUpsellLevel === CARD_LEVELS.PLUS) {
      return this.intl.t('cards.steps.renewal-upsell.brand.plus');
    } else if (selectedUpsellLevel === CARD_LEVELS.METAL) {
      return this.intl.t('cards.steps.renewal-upsell.brand.metal');
    }
  }

  get videoUrl() {
    // @ts-expect-error
    return this.args.selectedUpsellLevel === CARD_LEVELS.PLUS
      ? '/videos/cards/upsell-plus-video.webm'
      : '/videos/cards/upsell-x-video.webm';
  }

  get imageUrl() {
    // @ts-expect-error
    return this.args.selectedUpsellLevel === CARD_LEVELS.PLUS
      ? UpsellAssets.UpsellPlusFallback
      : UpsellAssets.UpsellXFallback;
  }

  get wrapperStyles() {
    let localClass = ['container'];
    // @ts-expect-error
    if (this.args.selectedUpsellLevel === CARD_LEVELS.METAL) {
      localClass.push('container-metal');
    }
    return localClass.join(' ');
  }

  @action
  // @ts-expect-error
  selectUpsellLevel(value) {
    // @ts-expect-error
    this.args.onSelectUpsellLevel(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Upsell': typeof CardUpsellComponent;
  }
}
