/* import __COLOCATED_TEMPLATE__ from './logo.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import { canAnimate } from 'qonto/utils/chart';

interface DualNavLogoSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DualNavLogoComponent extends Component<DualNavLogoSignature> {
  lottiePlayer = LottiePlayer;
  staticThemedAsset = StaticThemedAsset;

  @service declare localeManager: Services['localeManager'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  isChrome = /Chrome/.test(navigator.userAgent);

  get shouldShowStaticLogo() {
    // @ts-expect-error
    return this.args.state === 'responsive' || !canAnimate();
  }

  get isQontoInvoicing() {
    return this.subscriptionManager.isQontoInvoicing;
  }

  get qontoInvoicingLogoSrc() {
    return `logo-qonto-invoicing-${this.localeManager.locale || 'en'}`;
  }

  @action
  // @ts-expect-error
  handleError(error) {
    // Here the error is a LottieError so we cannot use shouldSendToSentry
    if (Boolean(error.status) && error.status >= 400 && error.status < 500) {
      this.sentry.captureException(error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::Logo': typeof DualNavLogoComponent;
  }
}
