/* import __COLOCATED_TEMPLATE__ from './restrictions.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

interface FlowsCardsRestrictionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsRestrictions extends Component<FlowsCardsRestrictionsSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get subtitle() {
    if (this.abilities.can('view restrictive categories card')) {
      return this.intl.t('cards.steps.restrictions.subtitle-days-and-categories');
    }
    return this.intl.t('cards.steps.restrictions.subtitle');
  }

  get trackingParams() {
    // @ts-expect-error
    let { card, flowTrackingOrigin, isUserCardHolder } = this.args.context;
    let { cardLevel, activeDaysOption, hasCategoryTags } = card;

    return {
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      card_type: CARD_LEVELS_TRACKING[cardLevel],
      days: activeDaysOption,
      categories: hasCategoryTags,
      origin: flowTrackingOrigin,
    };
  }

  @action
  continueToNextStep() {
    this.segment.track('cards_order_advanced_settings_submitted', this.trackingParams);
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Restrictions': typeof FlowsCardsRestrictions;
  }
}
