/* import __COLOCATED_TEMPLATE__ from './loading-state.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

// @ts-expect-error
import styles from './loading-state.module.css';

interface LoadingStateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class LoadingStateComponent extends Component<LoadingStateSignature> {
  styles = styles;
  placeholderBlock = SkeletonLoader.Block;
  placeholderLine = SkeletonLoader.Line;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::LoadingState': typeof LoadingStateComponent;
  }
}
