export default {
  "voucher-wrapper": "cSU",
  "left-side": "cSj",
  "left-side__wrapper": "cSW",
  "voucher-illustration": "cSu",
  "left-side__wrapper-title": "ccS",
  "voucher-list": "ccc",
  "left-side__wrapper-details": "ccq",
  "right-side": "ccZ",
  "multi-accounts": "ccR",
  "right-side__wrapper": "ccQ"
};
