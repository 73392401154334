/* import __COLOCATED_TEMPLATE__ from './limit-section.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LimitSectionSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const LimitSection = templateOnlyComponent<LimitSectionSignature>();

export default LimitSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::LimitSection': typeof LimitSection;
  }
}
