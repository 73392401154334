/* import __COLOCATED_TEMPLATE__ from './tile-layout.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeStatus } from '@repo/design-system-kit';

import { ACCOUNT_STATUS, ACCOUNT_TYPE } from 'qonto/constants/bank-account';

interface BankAccountTileLayoutSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class BankAccountTileLayoutComponent extends Component<BankAccountTileLayoutSignature> {
  badgeStatus = BadgeStatus;

  @service declare keyboardFocus: Services['keyboardFocus'];

  @tracked focused = false;

  get isRemuneratedWaiting() {
    return (
      // @ts-expect-error
      this.args.account.accountType === ACCOUNT_TYPE.REMUNERATED &&
      // @ts-expect-error
      this.args.account.status === ACCOUNT_STATUS.WAITING
    );
  }

  @action
  // @ts-expect-error
  setFocused(focused) {
    this.focused = focused;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::TileLayout': typeof BankAccountTileLayoutComponent;
  }
}
