/* import __COLOCATED_TEMPLATE__ from './category.hbs'; */
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

interface CardSettingsRestrictionCategorySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardSettingsRestrictionCategoryComponent extends Component<CardSettingsRestrictionCategorySignature> {
  checkbox = Checkbox;

  get isChecked() {
    // @ts-expect-error
    let { checkedValues = [], value } = this.args;
    return checkedValues?.includes(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::Restrictions::Category': typeof CardSettingsRestrictionCategoryComponent;
  }
}
