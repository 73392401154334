/* import __COLOCATED_TEMPLATE__ from './note.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS } from 'qonto/constants/cards';

interface FlowsRequestCardsNoteSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsRequestCardsNoteComponent extends Component<FlowsRequestCardsNoteSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  textAreaField = TextAreaField;

  @tracked note = '';
  @tracked errorMessage = null;

  constructor(owner: unknown, args: FlowsRequestCardsNoteSignature['Args']) {
    super(owner, args);
    this.sendTrackingEvent();
  }

  sendTrackingEvent() {
    this.segment.track('request_creation_note_displayed', {
      // @ts-expect-error
      request_type: `${this.args.context.cardLevel} card`,
    });
  }

  @action
  // @ts-expect-error
  onNoteChange(value) {
    this.note = value;
    this.errorMessage = null;
  }

  continueTask = dropTask(async () => {
    if (!this.note || !this.note.trim()) {
      // @ts-expect-error
      this.errorMessage = this.intl.t('validations.errors.presence');
      return;
    }

    // @ts-expect-error
    this.args.context.entity.note = this.note;

    try {
      // @ts-expect-error
      await this._saveRequestTask.perform(this.args.context.entity);

      // @ts-expect-error
      this.args.transitionToNext();
    } catch {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
    }
  });

  _saveRequestTask = dropTask(async entity => {
    let card = await entity.save();
    this.segment.track('request_created', {
      request_id: card.id,
      request_type: card.requestType.replace('_', ' '),
    });
  });

  get inputPlaceholder() {
    // @ts-expect-error
    if (this.args.context.cardLevel === CARD_LEVELS.FLASH) {
      return this.intl.t('requests.cards.steps.note.placeholder.flash');
    }
    return this.intl.t('requests.cards.steps.note.placeholder.virtual');
  }

  get buttonLabel() {
    // @ts-expect-error
    if (this.args.context.cardLevel === CARD_LEVELS.FLASH) {
      return this.intl.t('requests.cards.steps.note.button-label.flash');
    }
    return this.intl.t('requests.cards.steps.note.button-label.virtual');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Requests::Cards::Note': typeof FlowsRequestCardsNoteComponent;
  }
}
