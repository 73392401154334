/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface EmptyStateSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const EmptyState = templateOnlyComponent<EmptyStateSignature>();

export default EmptyState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::SearchResults::EmptyState': typeof EmptyState;
  }
}
