/* import __COLOCATED_TEMPLATE__ from './bank-of-italy-restrictions.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BankOfItalyRestrictionsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const BankOfItalyRestrictions = templateOnlyComponent<BankOfItalyRestrictionsSignature>();

export default BankOfItalyRestrictions;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::BankOfItalyRestrictions': typeof BankOfItalyRestrictions;
  }
}
