/* import __COLOCATED_TEMPLATE__ from './upload-conversation.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface UploadConversationSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const UploadConversation = templateOnlyComponent<UploadConversationSignature>();

export default UploadConversation;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::UploadConversation': typeof UploadConversation;
  }
}
