/* import __COLOCATED_TEMPLATE__ from './account-rename-modal.hbs'; */
/* eslint-disable @qonto/no-async-action */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

interface AccountRenameModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AccountRenameModal extends Component<AccountRenameModalSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  // @ts-expect-error
  @tracked name = this.args.data.account.name;

  externalAccountNameRegex = new RegExp('[A-Za-z0-9 ¡-ʯ&(){}\\[\\].:,;?!+\\-_@$#~^€]*');

  get isNameValid() {
    return this.name.trim().length;
  }

  saveTask = dropTask(async account => {
    try {
      await account.save();
    } catch (error) {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
      throw error;
    }

    let successMessage = this.intl.t('bank_accounts.rename-modal.success-toast');
    this.toastFlashMessages.toastSuccess(successMessage);
  });

  @action
  // @ts-expect-error
  onUpdate(name) {
    // @ts-expect-error
    if (this.args.data.account.isExternalAccount) {
      let [match] = name.match(this.externalAccountNameRegex);
      this.name = match;
    } else {
      this.name = name;
    }
  }

  @action
  async renameAccount() {
    // @ts-expect-error
    let { close, data } = this.args;
    let { account } = data;

    account.set('name', this.name);
    await this.saveTask.perform(account);

    close();
  }

  @action
  cancel() {
    if (this.saveTask.isRunning) {
      return;
    }
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccounts::AccountRenameModal': typeof AccountRenameModal;
  }
}
