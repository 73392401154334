export default {
  "header-cell": "zJ",
  "empty": "zs",
  "right": "zF",
  "debitor": "zg",
  "date": "zD",
  "status": "zV",
  "amount": "zL",
  "header-amount": "zT",
  "header-content": "zz caption-bold",
  "active": "za"
};
