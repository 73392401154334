export default {
  "details": "gU",
  "top-details": "gj",
  "name": "gW",
  "account-infobox": "gu",
  "row": "DS",
  "icon": "Dc",
  "account-number": "Dq",
  "currency": "DZ",
  "activity-tag": "DR",
  "activity-icon": "DQ",
  "edit-btn": "De",
  "action-buttons-container": "DB"
};
