/* import __COLOCATED_TEMPLATE__ from './group.hbs'; */
import { assert } from '@ember/debug';
import Component from '@glimmer/component';

interface DualNavSubMenuGroupSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class DualNavSubMenuGroupComponent extends Component<DualNavSubMenuGroupSignature> {
  constructor(owner: unknown, args: DualNavSubMenuGroupSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { items } = this.args;

    assert('<DualNav::SubMenu::Group /> must have @items', items);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::SubMenu::Group': typeof DualNavSubMenuGroupComponent;
  }
}
