/* import __COLOCATED_TEMPLATE__ from './card-details.hbs'; */
/* eslint-disable @qonto/no-async-action */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

// @ts-expect-error
import { CHECKOUT_REDIRECTION } from 'qonto/constants/payment-activation';
import { CardPaymentLoading } from 'qonto/react/shared/components/card-payment-loading';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const HARD_ERROR_CODE_REGEX = /^[3][0-9]*$/gm;

const DATA_CONTEXT = 'payment_activation_context';

interface TopUpSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopUpComponent extends Component<TopUpSignature> {
  cardPaymentLoading = CardPaymentLoading;

  @service declare organizationManager: Services['organizationManager'];
  @service declare localeManager: Services['localeManager'];
  @service declare payment: Services['payment'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  @tracked checkoutFramesLibraryReady = false;
  @tracked gpayLibraryLoaded = false;

  @tracked showGooglePaySection = false;

  @tracked threeDSAuthenticationError = null;
  @tracked googlePayError = null;

  // @ts-expect-error
  @tracked ckoSessionId = this.router.currentRoute?.queryParams['cko-session-id'];
  @tracked provider;

  constructor(owner: unknown, args: TopUpSignature['Args']) {
    super(owner, args);

    if (window.sessionStorage.getItem(DATA_CONTEXT)) {
      // @ts-expect-error
      let { provider } = JSON.parse(window.sessionStorage.getItem(DATA_CONTEXT));
      this.provider = provider;

      window.sessionStorage.removeItem(DATA_CONTEXT);
    }

    if (this.ckoSessionId && this.provider) {
      // @ts-expect-error
      this.args.context.nextTransitionId = 'bank-details';
    } else {
      // @ts-expect-error
      this.args.context.nextTransitionId = 'success';
    }

    // @ts-expect-error
    if (!this.args.context.amount) {
      // @ts-expect-error
      return this.args.transitionToFlow({
        flowName: 'payment-activation',
        stepId: 'select-amount',
      });
    }
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get isLoading() {
    return !this.checkoutFramesLibraryReady || !this.gpayLibraryLoaded;
  }

  get locale() {
    let localeSecondPart = this.localeManager.locale === 'en' ? 'gb' : this.localeManager.locale;
    return `${this.localeManager.locale}-${localeSecondPart}`.toUpperCase();
  }

  get checkoutDisclaimerError() {
    if (this.ckoSessionId && this.provider === 'checkout') {
      return {
        translationKey: 'payment-activation-top-up.errors.card.content',
        link: {
          url: this.router.urlFor('flows', 'payment-activation', 'bank-details'),
          translationKey: 'payment-activation-top-up.errors.card.link',
        },
      };
    }

    return this.threeDSAuthenticationError;
  }

  get googPayDisclaimerError() {
    if (this.ckoSessionId && this.provider === 'google-pay') {
      return {
        translationKey: 'payment-activation-top-up.errors.google-Pay.content',
        link: {
          url: this.router.urlFor('flows', 'payment-activation', 'bank-details'),
          translationKey: 'payment-activation-top-up.errors.google-Pay.link',
        },
      };
    }

    return this.googlePayError;
  }

  onSubmitTask = dropTask(async (cardTokenized, context) => {
    let errorKey = context === 'checkout' ? 'threeDSAuthenticationError' : 'googlePayError';
    // @ts-expect-error
    this[errorKey] = null;

    let response;

    try {
      response = await this.payment.triggerPayment({
        payload: {
          organization_id: this.organizationManager.organization.id,
          token: cardTokenized.token,
          // @ts-expect-error
          amount: this.args.context.amount,
        },
        successURLPath: CHECKOUT_REDIRECTION.SUCCESS_STEP_ID,
        failureURLPath: CHECKOUT_REDIRECTION.FAILURE_STEP_ID,
        source: 'payment_activation',
      });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return;
    }

    let { status: responseStatus, response_code: responseCode } = response;

    if (responseStatus === 'Pending') {
      window.sessionStorage.setItem(DATA_CONTEXT, JSON.stringify({ provider: context }));

      // @ts-expect-error
      this.segment.track('onboarding_top_up_completed', { amount: this.args.context.amount });
      return window.location.replace(response._links.redirect.href);
    }

    if (responseStatus === 'Declined') {
      if (HARD_ERROR_CODE_REGEX.test(responseCode)) {
        // @ts-expect-error
        this[errorKey] = this.intl.t('on-boarding.top-up.error.generic-payment-decline-message');
      } else {
        // @ts-expect-error
        this[errorKey] = this.intl.t('on-boarding.top-up.error.generic-card-decline-message', {
          legalCountry: this.legalCountry,
        });
      }
    }
  });

  @action
  onCheckoutFramesReady() {
    this.checkoutFramesLibraryReady = true;
  }

  @action
  // @ts-expect-error
  async onGooglePayTokenReady(paymentData) {
    let payload = {
      type: 'googlepay',
      token_data: JSON.parse(paymentData.paymentMethodData.tokenizationData.token),
    };

    let gpayCardTokenized;
    try {
      gpayCardTokenized = await this.payment.requestCheckoutToken(payload);
    } catch {
      // @ts-expect-error
      this.googlePayError = this.intl.t('on-boarding.top-up.error.generic-card-decline-message', {
        legalCountry: this.legalCountry,
      });
      return;
    }

    await this.onSubmitTask.perform(gpayCardTokenized, 'google-pay').catch(ignoreCancelation);
  }

  @action
  // @ts-expect-error
  onGooglePayReadyToPay(isReady) {
    this.gpayLibraryLoaded = true;
    this.showGooglePaySection = isReady;
  }

  @action
  // @ts-expect-error
  onGooglePayError(error) {
    // A DOMException error is thrown when the user closes the payment sheet
    // and we don't care about it
    if (!(error instanceof DOMException)) {
      this.sentry.captureException(error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::PaymentActivation::CardDetails': typeof TopUpComponent;
  }
}
