/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';

const VIDEO_PATHS = {
  de: '/videos/sdd-collection/value-prop-de.webm',
  en: '/videos/sdd-collection/value-prop-en.webm',
  es: '/videos/sdd-collection/value-prop-es.webm',
  fr: '/videos/sdd-collection/value-prop-fr.webm',
  it: '/videos/sdd-collection/value-prop-it.webm',
};

const IMAGE_PATHS = {
  de: '/illustrations/sdd-collection/value-prop-de.webp',
  en: '/illustrations/sdd-collection/value-prop-en.webp',
  es: '/illustrations/sdd-collection/value-prop-es.webp',
  fr: '/illustrations/sdd-collection/value-prop-fr.webp',
  it: '/illustrations/sdd-collection/value-prop-it.webp',
};

interface IntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class IntroComponent extends Component<IntroSignature> {
  staticThemedAsset = StaticThemedAsset;

  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  // @ts-expect-error
  @reads('args.context.activation') activation;

  @tracked shouldLoadSkeleton = false;

  get points() {
    return [
      {
        title: this.intl.t('sdd-collections.selling-proposition.selling-point-1.title'),
        content: this.intl.t('sdd-collections.selling-proposition.selling-point-1.content', {
          collectionLimit: this.collectionLimitInformation.limit,
          // @ts-expect-error
          faqLink: this.collectionLimitInformation.faqLink,
          htmlSafe: true,
        }),
      },
      {
        title: this.depositSellingPoint.title,
        content: this.depositSellingPoint.content,
      },
      {
        title: this.intl.t('sdd-collections.selling-proposition.selling-point-3.title'),
        content: this.intl.t('sdd-collections.selling-proposition.selling-point-3.content', {
          planName: this.pricingFeeInformation.planName,
          pricingFee: this.pricingFeeInformation.pricingFee,
          // @ts-expect-error
          feesLink: this.pricingFeeInformation.feesLink,
          htmlSafe: true,
        }),
      },
    ];
  }

  get videoPath() {
    // @ts-expect-error
    return ['de', 'fr', 'es', 'it'].includes(this.localeManager.locale)
      ? // @ts-expect-error
        VIDEO_PATHS[this.localeManager.locale]
      : VIDEO_PATHS['en'];
  }

  get imagePath() {
    // @ts-expect-error
    return ['de', 'fr', 'es', 'it'].includes(this.localeManager.locale)
      ? // @ts-expect-error
        IMAGE_PATHS[this.localeManager.locale]
      : IMAGE_PATHS['en'];
  }

  get guardingDepositPercentage() {
    return this.intl.formatNumber(this.activation.guardingDepositPercentage, {
      style: 'percent',
    });
  }

  get collectionLimitInformation() {
    let limit = this.intl.formatNumber(this.activation.collectionLimit.value, {
      currency: this.activation.collectionLimit.currency,
      style: 'currency',
      minimumFractionDigits: 0,
    });

    let faqLink = htmlSafe(
      `<a
          href=${this.zendeskLocalization.getLocalizedArticle('sdd-collection-limit')}
          target="_blank"
          rel="noopener noreferrer"
          class="body-link"
        >
          ${this.intl.t('sdd-collections.selling-proposition.selling-point-1.link-text')}
        </a>`
    );

    return {
      limit,
      faqLink,
    };
  }

  get pricingFeeInformation() {
    let pricingFee = this.intl.formatNumber(
      this.subscriptionManager.options.directDebitCollectionSend.value,
      { style: 'currency', currency: 'EUR' }
    );

    let planName = this.subscriptionManager.currentPricePlan.localName;

    let feesLink = htmlSafe(
      `<a
          href=${this.zendeskLocalization.getLocalizedArticle(9235605)}
          target="_blank"
          rel="noopener noreferrer"
          class="body-link"
        >
          ${this.intl.t('sdd-collections.selling-proposition.selling-point-3.link-text')}
        </a>`
    );

    return {
      pricingFee,
      planName,
      feesLink,
    };
  }

  get depositSellingPoint() {
    if (this.activation.hasGuarding) {
      return {
        title: this.intl.t(
          'sdd-collections.selling-proposition.selling-point-2.guarding.title-new'
        ),
        content: htmlSafe(
          this.intl.t('sdd-collections.selling-proposition.selling-point-2.guarding.content-new', {
            guardingPeriodPercentage: this.guardingDepositPercentage,
          })
        ),
      };
    } else {
      return {
        title: this.intl.t('sdd-collections.selling-proposition.selling-point-2.no-guarding.title'),
        content: this.intl.t(
          'sdd-collections.selling-proposition.selling-point-2.no-guarding.content'
        ),
      };
    }
  }

  handleSetupTask = dropTask(async () => {
    this.segment.track('incoming-direct-debits-activation_usp_clicked');

    try {
      let { organization } = this.organizationManager;
      let isCountryItaly = organization.legalCountry === 'IT';
      let isCountrySpain = organization.legalCountry === 'ES';

      if (isCountryItaly || isCountrySpain) {
        this.shouldLoadSkeleton = true;

        await this.activation.fetchCid();
      }
    } catch {
      this.shouldLoadSkeleton = false;
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      return;
    }

    // @ts-expect-error
    this.args.transitionToNext();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SddCollection::Activation::Intro': typeof IntroComponent;
  }
}
