/* import __COLOCATED_TEMPLATE__ from './success-new-physical.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';
import { CardImage } from 'qonto/react/cards/components/card-image';

interface FlowsCardsSuccessNewPhysicalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsSuccessNewPhysical extends Component<FlowsCardsSuccessNewPhysicalSignature> {
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare abilities: Services['abilities'];

  cardImage = CardImage;

  // @ts-expect-error
  @reads('args.context.cardUpsellDesign') cardUpsellDesign;
  // @ts-expect-error
  @reads('args.context.cardUpsellLevel') cardUpsellLevel;

  animationReady = false;

  constructor(owner: unknown, args: FlowsCardsSuccessNewPhysicalSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { card } = this.args.context;

    // The existence of cardUpsellLevel means we're in the upsell flow
    if (this.cardUpsellLevel) {
      this.segment.track('cards_upsell_completed', {
        card_level_origin: CARD_LEVELS_TRACKING[card.cardLevel],
        card_level_upsell: CARD_LEVELS_TRACKING[this.cardUpsellLevel],
      });
    } else {
      // @ts-expect-error
      let { flowTrackingOrigin, isUserCardHolder } = this.args.context;
      this.segment.track('cards_order_completed', {
        card_holder: isUserCardHolder ? 'self' : 'team_member',
        card_type: this.trackingCardType,
        origin: flowTrackingOrigin,
      });
    }
  }

  get cardImageInfo() {
    // @ts-expect-error
    let { card } = this.args.context;
    return this.cardUpsellLevel
      ? {
          design: this.cardUpsellDesign,
          expDate: null,
          maskPan: '•'.repeat(16),
        }
      : {
          design: card.cardDesign,
          expDate: card.expDate,
          maskPan: card.displayedMaskPan,
        };
  }

  get trackingCardType() {
    // @ts-expect-error
    let { card } = this.args.context;
    return CARD_LEVELS_TRACKING[card.cardLevel];
  }

  get title() {
    // @ts-expect-error
    let { isUserCardHolder } = this.args.context;

    if (isUserCardHolder) {
      return this.intl.t('cards.steps.success.title.physical.holder');
    }

    return this.intl.t('cards.steps.success.title.physical.non-holder');
  }

  get subtitle() {
    // @ts-expect-error
    let { isUserCardHolder, card } = this.args.context;

    if (isUserCardHolder) {
      return this.abilities.cannot('see x pay card')
        ? this.intl.t('cards.steps.success.subtitle.physical.no-xpay')
        : this.intl.t('cards.steps.success.subtitle.physical.holder');
    }

    return this.intl.t('cards.steps.success.subtitle.physical.non-holder', {
      cardholder: card.holder.get('fullName'),
    });
  }

  @action
  handleOpenEducationalModal() {
    this.modals.open('card/modals/digital-first', {
      isFullScreenModal: true,
    });

    if (this.cardUpsellLevel) {
      this.segment.track('cards_upsell_success_digital_first_education_opened', {
        card_level_upsell: CARD_LEVELS_TRACKING[this.cardUpsellLevel],
      });
    } else {
      // @ts-expect-error
      let { flowTrackingOrigin } = this.args.context;
      this.segment.track('cards_order_digital_first_education_opened', {
        card_type: this.trackingCardType,
        origin: flowTrackingOrigin,
      });
    }
  }

  @action
  handleNext() {
    // @ts-expect-error
    let { transitionToNext } = this.args;

    if (this.cardUpsellLevel) {
      this.segment.track('cards_upsell_success_see_card', {
        card_level_upsell: CARD_LEVELS_TRACKING[this.cardUpsellLevel],
      });
    } else {
      // @ts-expect-error
      let { flowTrackingOrigin } = this.args.context;
      this.segment.track('cards_order_success_complete', {
        card_type: this.trackingCardType,
        origin: flowTrackingOrigin,
      });
    }

    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::SuccessNewPhysical': typeof FlowsCardsSuccessNewPhysical;
  }
}
