export default {
  "row": "Jl",
  "active": "JX",
  "cell": "JC",
  "name-cell": "Jk",
  "email-cell": "JJ",
  "actions-cell": "Js",
  "name-container": "JF",
  "quick-actions-wrapper": "Jg",
  "empty": "JD",
  "name-icon-container": "JV",
  "icon": "JL",
  "tooltip-container": "JT",
  "missing-details-container": "Jz",
  "missing-details-label": "Ja body-2",
  "warning-icon": "JH JL"
};
