export default {
  "chip": "JK",
  "chip--hidden": "JG",
  "chip--collapsed": "Jr",
  "chip--selected": "Jb",
  "chip--disabled": "Jy",
  "wrapper": "JU",
  "content": "Jj body-1",
  "clear": "JW",
  "clear-icon": "Ju"
};
