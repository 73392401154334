export default {
  "container": "cZi",
  "container--collapsed": "cZA",
  "spin": "cZY",
  "cta-tooltip": "cZh",
  "cta--collapsed": "cZf",
  "cta--expanded": "cZK",
  "collapsed-svg-icon": "cZG",
  "expanded-svg-icon": "cZr",
  "generate-logo-cta": "cZb",
  "badge": "cZy",
  "scaleDown": "cZU",
  "fadeIn": "cZj",
  "textRiseButton": "cZW",
  "cta-description": "cZu",
  "textRiseDescription": "cRS"
};
