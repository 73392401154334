/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { SubscriptionCloseStepsClosingDate } from 'qonto/react/components/subscription/close/closing-date';

interface SubscriptionCloseStepsReasonSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionCloseStepsReasonSuccessComponent extends Component<SubscriptionCloseStepsReasonSuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare segment: Services['segment'];
  @service declare flow: Services['flow'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @tracked animationReady = false;
  SubscriptionCloseStepsClosingDate = SubscriptionCloseStepsClosingDate;

  constructor(owner: unknown, args: SubscriptionCloseStepsReasonSuccessSignature['Args']) {
    super(owner, args);
    this.segment.track('account_closing_confirmation_displayed');
  }

  get provisionalDueDate() {
    return this.intl.formatDate(dayjs(this.flow.dataContext.provisionalDueDate).toDate(), {
      format: 'long',
    });
  }

  @action displayLottie() {
    // see https://github.com/emberjs/ember.js/issues/17202#issuecomment-437927226
    this.animationReady = true;
  }

  get isItalianOrganization() {
    return this.organizationManager.organization.isItalian;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::Success': typeof SubscriptionCloseStepsReasonSuccessComponent;
  }
}
