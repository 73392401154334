export default {
  "wrapper": "iL",
  "wrapper-new": "iT",
  "icon-list": "iz",
  "list-item": "ia",
  "box-wrapper": "iH",
  "panel": "iO",
  "panel-top": "im",
  "list": "ip",
  "container": "ix",
  "column": "iw",
  "icon": "iN",
  "column-right": "iv"
};
