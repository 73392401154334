/* import __COLOCATED_TEMPLATE__ from './currency-select.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { getFullCurrencyName } from 'qonto/utils/currency';

interface FormFieldsCurrencySelectSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FormFieldsCurrencySelectComponent extends Component<FormFieldsCurrencySelectSignature> {
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];

  get placeholder() {
    return this.intl.t('supplier-invoices.edit.modal.invoice-details.currency-placeholder');
  }

  get selectedCurrencyName() {
    // @ts-expect-error
    return this.args.selected ? this.getCurrencyName(this.args.selected) : '';
  }

  // @ts-expect-error
  getCurrencyName = currencyCode => {
    let currencyDisplayName = getFullCurrencyName({
      currencyCode,
      // @ts-expect-error
      locale: this.localeManager.locale,
    });
    return !currencyDisplayName || currencyCode === currencyDisplayName
      ? currencyCode
      : `${currencyCode} - ${currencyDisplayName}`;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::CurrencySelect': typeof FormFieldsCurrencySelectComponent;
  }
}
