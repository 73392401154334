/* import __COLOCATED_TEMPLATE__ from './validity-period.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

interface FlowsRequestsCardsValidityPeriodSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class FlowsRequestsCardsValidityPeriodComponent extends Component<FlowsRequestsCardsValidityPeriodSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  @tracked periodKey = 'tomorrow';
  @tracked customDate = null;

  datePicker = DatePicker;

  constructor(owner: unknown, args: FlowsRequestsCardsValidityPeriodSignature['Args']) {
    super(owner, args);

    this.trackRequestCreationValidityDisplayed();
  }

  get options() {
    return [
      {
        value: 'tomorrow',
        label: this.intl.t('requests.cards.steps.validity-period.chip.tomorrow'),
      },
      { value: 'week', label: this.intl.t('requests.cards.steps.validity-period.chip.one-week') },
      {
        value: '2-weeks',
        label: this.intl.t('requests.cards.steps.validity-period.chip.two-weeks'),
      },
      { value: 'month', label: this.intl.t('requests.cards.steps.validity-period.chip.one-month') },
    ];
  }

  get tomorrow() {
    return parseDate(dayjs().add(1, 'day').format(DATE_PICKER_FIELD_FORMAT));
  }

  get week() {
    return parseDate(dayjs().add(1, 'week').format(DATE_PICKER_FIELD_FORMAT));
  }

  get twoWeeks() {
    return parseDate(dayjs().add(2, 'week').format(DATE_PICKER_FIELD_FORMAT));
  }

  get month() {
    return parseDate(dayjs().add(1, 'day').add(1, 'month').format(DATE_PICKER_FIELD_FORMAT));
  }

  get furthestDate() {
    return parseDate(dayjs().add(1, 'years').format(DATE_PICKER_FIELD_FORMAT));
  }

  get selectedPeriodValue() {
    let periods = {
      tomorrow: this.tomorrow,
      week: this.week,
      '2-weeks': this.twoWeeks,
      month: this.month,
    };

    // @ts-expect-error
    return periods[this.periodKey] || this.customDate;
  }

  @action
  trackRequestCreationValidityDisplayed() {
    // @ts-expect-error
    let str = `${this.args.context.cardLevel} card`;
    this.segment.track('request_creation_validity_displayed', { request_type: str });
  }

  @action
  // @ts-expect-error
  setCustomDate(newDate) {
    this.updatePeriodKey(newDate?.toString());
    this.customDate = newDate;
  }

  // @ts-expect-error
  updatePeriodKey(date) {
    let periodKeys = {
      // @ts-expect-error
      [this.tomorrow]: 'tomorrow',
      // @ts-expect-error
      [this.week]: 'week',
      // @ts-expect-error
      [this.twoWeeks]: '2-weeks',
      // @ts-expect-error
      [this.month]: 'month',
    };

    // @ts-expect-error
    this.periodKey = periodKeys[date] || 'custom';
  }

  @action
  // @ts-expect-error
  continue(event) {
    event.preventDefault();

    // @ts-expect-error
    this.args.context.entity.preExpiresAt = dayjs(this.selectedPeriodValue.toString())
      .utc(true)
      .toDate();
    // @ts-expect-error
    this.args.transitionToNext();
  }

  @action
  // @ts-expect-error
  onChipChange(periodKey) {
    this.periodKey = periodKey;
  }

  customValidationMessages = {
    // @ts-expect-error
    valueMissing: this.intl.t('validations.errors.presence'),
    // @ts-expect-error
    rangeOverflow: this.intl.t('cards.steps.validity-period.datepicker.error.maximum-date'),
    // @ts-expect-error
    rangeUnderflow: this.intl.t('cards.steps.validity-period.datepicker.error.past-date', {
      today: dateToken({
        // @ts-expect-error
        date: this.tomorrow.toDate(),
        // @ts-expect-error
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.COMPACT,
      }),
    }),
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Requests::Cards::ValidityPeriod': typeof FlowsRequestsCardsValidityPeriodComponent;
  }
}
