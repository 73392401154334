/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { EmptyStatesDiscover, EmptyStatesInform } from '@repo/domain-kit/pricing';

// @ts-expect-error
import { LAYOUT, TRACKING_ORIGINS } from 'qonto/constants/empty-states/system';

interface EmptyStateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStateComponent extends Component<EmptyStateSignature> {
  emptyStatesDiscover = EmptyStatesDiscover;

  emptyStatesInform = EmptyStatesInform;

  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: EmptyStateSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let trackingData = this.args.emptyStateOptions?.tracking?.({
      isClickEvent: false,
    });
    if (trackingData?.name && trackingData.properties) {
      this.segment.track(trackingData.name, {
        ...trackingData.properties,
      });
    }
  }

  get isInformLayout() {
    // @ts-expect-error
    return !this.args.emptyStateOptions || this.args.emptyStateOptions.layout === LAYOUT.INFORM;
  }

  get isDiscoverPlainLayout() {
    // @ts-expect-error
    return this.args.emptyStateOptions.layout === LAYOUT.DISCOVER_PLAIN;
  }

  get shouldShowCTA() {
    // @ts-expect-error
    return this.args.emptyStateOptions.ctaText && !this.isDiscoverPlainLayout;
  }

  get primaryOrigin() {
    return TRACKING_ORIGINS.PRIMARY;
  }

  @action
  onDiscoverCtaClick() {
    // @ts-expect-error
    this.args.emptyStateOptions.onClick?.(TRACKING_ORIGINS.PRIMARY);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::Main': typeof EmptyStateComponent;
  }
}
