/* import __COLOCATED_TEMPLATE__ from './upsell.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import isFunction from 'qonto/utils/is-function';

interface CardUpsellProviderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardUpsellProvider extends Component<CardUpsellProviderSignature> {
  @service declare cardsManager: Services['cardsManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];

  upsellTask = dropTask(
    async (
      card,
      address,
      shipToBusiness,
      cardUpsellLevel,
      onSuccess,
      cardUpsellDesign,
      cardUpsellTypeOfPrint
    ) => {
      try {
        let response = await this.cardsManager.fetchCardUpsellTask.perform(
          card,
          address.serialize(),
          shipToBusiness,
          cardUpsellLevel,
          cardUpsellDesign,
          cardUpsellTypeOfPrint
        );
        if (isFunction(onSuccess)) {
          onSuccess(response.upsellCard.id);
        }
      } catch (error) {
        // @ts-expect-error
        if (hasMFAError(error?.errors)) {
          throw error;
        }

        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      }
    }
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Providers::Upsell': typeof CardUpsellProvider;
  }
}
