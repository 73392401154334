/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { getLabelsToUpdate } from 'qonto/utils/persist-labels';

interface CounterpartiesSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CounterpartiesSidebar extends Component<CounterpartiesSidebarSignature> {
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get selectedVatRate() {
    // @ts-expect-error
    return this.args.selectedVatRate ?? this.args.counterparty.vatRate;
  }

  get selectedLabels() {
    // @ts-expect-error
    let { selectedLabels, counterparty } = this.args;
    return selectedLabels || counterparty.labels;
  }

  get hasDirtyLabels() {
    // @ts-expect-error
    let { labels } = this.args.counterparty;

    if (!this.selectedLabels || this.selectedLabels.length !== labels.length) {
      return true;
    }

    // check if the set labels match the existing set of labels
    // @ts-expect-error
    return !this.selectedLabels.every(({ id }) => labels.some(label => id === label.id));
  }

  get hasDirtyVat() {
    // @ts-expect-error
    return this.selectedVatRate !== this.args.counterparty.vatRate;
  }

  get showActionsBar() {
    let hasDirtyAttributes = this.hasDirtyLabels || this.hasDirtyVat;
    // @ts-expect-error
    return hasDirtyAttributes && this.args.saveCounterpartyTask.isIdle;
  }

  @action
  // @ts-expect-error
  closeSidebar(counterpartyId) {
    // @ts-expect-error
    this.args.closeSidebar();
    // @ts-expect-error
    document.querySelector(`[data-item-id="${counterpartyId}"]`)?.focus();
  }

  @action
  // @ts-expect-error
  updateVat(amount) {
    // @ts-expect-error
    this.args.updateCounterpartyAttributes({ vatRate: amount, labels: this.selectedLabels });
  }

  @action
  // @ts-expect-error
  setSelectedLabels(labelList, label) {
    let labels = getLabelsToUpdate(this.selectedLabels, labelList, label);
    // @ts-expect-error
    this.args.updateCounterpartyAttributes({ vatRate: this.selectedVatRate, labels });
  }

  saveTask = dropTask(async () => {
    let counterpartyChanges = {
      labels: this.selectedLabels,
      vatRate: this.selectedVatRate,
      // @ts-expect-error
      transferTransactionsCount: this.args.counterparty.transferTransactionsCount,
    };

    // @ts-expect-error
    await this.args.saveCounterpartyTask.perform(counterpartyChanges);
  });

  confirmDeleteTask = dropTask(async () => {
    try {
      await this.modals.open('popup/destructive', {
        title: this.intl.t('counterparties.sidebar.delete-modal.title'),
        description: this.intl.t('counterparties.sidebar.delete-modal.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('btn.delete'),
        confirmTask: this.deleteCounterpartyTask,
      });
    } catch {
      // Show error toast message
      let message = this.intl.t('errors.internal_server_error');
      this.toastFlashMessages.toastError(message);
    }
  });

  deleteCounterpartyTask = dropTask(async closeModal => {
    // `unlinked()` is needed here because this sidebar can be destroyed
    // causing a cascading effect, halting the passed in task prematurely
    // @ts-expect-error
    await this.args.deleteCounterpartyTask.unlinked().perform(this.args.counterparty, closeModal);
  });

  editBeneficiaryTask = dropTask(async () => {
    // @ts-expect-error
    let { counterparty, onBeneficiaryUpdated } = this.args;
    await this.modals.open('beneficiary-form-modal', {
      isFullScreenModal: true,
      beneficiary: counterparty,
      isSupplierHub: true,
      refreshBeneficiariesList: onBeneficiaryUpdated,
    });
  });

  @action
  discardChanges() {
    // @ts-expect-error
    this.args.updateCounterpartyAttributes({ vatRate: null, labels: null });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Sidebar': typeof CounterpartiesSidebar;
  }
}
