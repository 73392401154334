/* import __COLOCATED_TEMPLATE__ from './nickname.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface CardSettingsNicknameSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardSettingsNicknameComponent extends Component<CardSettingsNicknameSignature> {
  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @tracked newNickname = this.args.nickname;
  // @ts-expect-error
  @tracked error;

  // @ts-expect-error
  @action updateNicknameField(value) {
    this.newNickname = value;
  }

  onSubmitTask = dropTask(async () => {
    let trimmedNickname = this.newNickname.trim();

    if (!trimmedNickname) {
      this.error = this.intl.t('cards.settings.nickname.error-empty');
    } else if (trimmedNickname.length > 40) {
      this.error = this.intl.t('cards.settings.nickname.error-too-long', { count: 40 });
    } else {
      try {
        // @ts-expect-error
        await this.args.onSaveTask.perform(trimmedNickname);
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        // @ts-expect-error
        if (this.errors.shouldFlash(error)) {
          // @ts-expect-error
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::Nickname': typeof CardSettingsNicknameComponent;
  }
}
