export default {
  "wrapper": "xV",
  "subtitle": "xL body-2",
  "legend": "xT",
  "table": "xz",
  "footer": "xa",
  "footer-total-c": "xH",
  "footer-total-d": "xO",
  "footer-balance": "xm"
};
