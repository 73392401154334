/* import __COLOCATED_TEMPLATE__ from './name.hbs'; */
import { ForbiddenError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { TEAM_NAME_MIN_LENGTH } from 'qonto/constants/teams';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface TeamsCreateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TeamsCreateComponent extends Component<TeamsCreateSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  @tracked value = null;
  @tracked error = null;

  createTask = dropTask(async () => {
    // @ts-expect-error
    if (!this.value || this.value.length < TEAM_NAME_MIN_LENGTH) {
      // @ts-expect-error
      this.error = this.intl.t('validations.errors.tooShort', { min: TEAM_NAME_MIN_LENGTH });

      return;
    }

    try {
      // @ts-expect-error
      let { team } = this.args.context;

      team.name = this.value;

      await team.save();
      await this.organizationManager.organization.loadTeams();

      this.segment.track('teams_new_team_created');

      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (error instanceof ForbiddenError) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  @action
  // @ts-expect-error
  updateField(value) {
    this.value = value;
    this.error = null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::CreateTeam::Name': typeof TeamsCreateComponent;
  }
}
