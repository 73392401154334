/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Header as CompletedHeader } from 'qonto/react/reimbursements/components/completed/table/header';
import { Header as PendingHeader } from 'qonto/react/reimbursements/components/pending/table/header';

interface EmptyStatesReimbursementsDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesReimbursementsDummyDataComponent extends Component<EmptyStatesReimbursementsDummyDataSignature> {
  pendingHeader = PendingHeader;
  completedHeader = CompletedHeader;

  @service declare subscriptionManager: Services['subscriptionManager'];
  sortBy = 'created_at:desc';
  noop() {}

  get hasBankAccountFeature() {
    return this.subscriptionManager.hasFeature('bank_account');
  }

  get hasFilter() {
    return this.subscriptionManager.hasFeature('team_management');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::Reimbursements::DummyData': typeof EmptyStatesReimbursementsDummyDataComponent;
  }
}
