export default {
  "panel-container": "dt",
  "selector-container": "dM",
  "container-title": "dP",
  "panel-title": "dl",
  "panel-title-text": "dX",
  "panel-icon": "dC",
  "icon": "dk",
  "animate-in": "dJ",
  "panel-content": "ds",
  "error-message": "dF"
};
