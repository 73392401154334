/* import __COLOCATED_TEMPLATE__ from './team-selection.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { waitForQueue } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { TeamPlaceholder } from 'qonto/react/components/budgets/create/team-placeholder';

interface FlowsBudgetsCreateBudgetTeamSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsBudgetsCreateBudgetTeamSelection extends Component<FlowsBudgetsCreateBudgetTeamSelectionSignature> {
  @service declare segment: Services['segment'];

  teamPlaceholder = TeamPlaceholder;

  constructor(owner: unknown, args: FlowsBudgetsCreateBudgetTeamSelectionSignature['Args']) {
    super(owner, args);
    this.trackTeamSelectionRendered();
  }

  async trackTeamSelectionRendered() {
    await waitForQueue('afterRender');
    this.segment.track('budget-creation_team-selection_displayed');
  }

  @action
  createTeam() {
    this.segment.track('budget-creation_new-team_clicked');
    // @ts-expect-error
    this.args.pushForwardFlow('create-team', 'name');
  }

  @action
  // @ts-expect-error
  nextStep({ team, memberships }) {
    // @ts-expect-error
    this.args.context.team = team;
    this.segment.track('budget-creation_team-selection_clicked');

    // @ts-expect-error
    let managers = memberships.filter(({ role }) => role === ROLES.MANAGER);
    // @ts-expect-error
    this.args.context.skipInviteManagerStep = managers.length > 0;

    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::CreateBudget::TeamSelection': typeof FlowsBudgetsCreateBudgetTeamSelection;
  }
}
