/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import Component from '@glimmer/component';

import { OnboardingIntro } from 'qonto/react/payment-links/components/flows/onboarding';

interface PaymentLinksOnboardingIntroSignature {
  // The arguments accepted by the component
  Args: {
    transitionToNext: () => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PaymentLinksOnboardingIntroComponent extends Component<PaymentLinksOnboardingIntroSignature> {
  OnboardingIntro = OnboardingIntro;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Onboarding::Intro': typeof PaymentLinksOnboardingIntroComponent;
  }
}
