/* import __COLOCATED_TEMPLATE__ from './button.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';

// @ts-expect-error
import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';

const POINTING_DEVICES = ['mouse', 'pen', 'touch'];

interface DualNavMainMenuButtonSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLIElement;
}

export default class DualNavMainMenuButtonComponent extends Component<DualNavMainMenuButtonSignature> {
  defaultItem;

  constructor(owner: unknown, args: DualNavMainMenuButtonSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { item, onPointerEnter, onMainMenuButtonPointerUp, onMainMenuButtonKeyboardEvent } =
      this.args;

    assert('<DualNav::MainMenu::Button /> must have @item', item);
    assert('<DualNav::MainMenu::Button /> must have @item.label', item.label);
    assert(
      '<DualNav::MainMenu::Button /> must have @onMainMenuButtonPointerUp',
      onMainMenuButtonPointerUp
    );
    assert(
      '<DualNav::MainMenu::Button /> must have @onMainMenuButtonKeyboardEvent',
      onMainMenuButtonKeyboardEvent
    );
    assert('<DualNav::MainMenu::Button /> must have @onPointerEnter', onPointerEnter);

    // @ts-expect-error
    this.defaultItem = this.args.item.groups.flatMap(({ items }) => items)[0];
  }

  get isHovered() {
    // @ts-expect-error
    let { item, hoveredMenuItemId } = this.args;

    return hoveredMenuItemId === item.id;
  }

  get shouldHideTooltip() {
    // @ts-expect-error
    let { item, state, activeMenuItemId } = this.args;
    return state !== DUAL_NAV_STATES.COLLAPSED || item.id === activeMenuItemId;
  }

  get shouldShowArrowIcon() {
    // @ts-expect-error
    let { state } = this.args;

    return state === DUAL_NAV_STATES.EXPANDED && this.isHovered;
  }

  get isSelected() {
    // @ts-expect-error
    let { item } = this.args;

    // @ts-expect-error
    return this.args.activeMenuItemId === item.id;
  }

  @action
  // @ts-expect-error
  handleKeyboardEvent(event) {
    // @ts-expect-error
    let { onMainMenuButtonKeyboardEvent, item } = this.args;
    let isKeyboardNavigation = !POINTING_DEVICES.includes(event.pointerType);
    if (isKeyboardNavigation) {
      onMainMenuButtonKeyboardEvent(item);
    }
  }

  @action
  handlePointerUp() {
    // @ts-expect-error
    let { onMainMenuButtonPointerUp, item, state } = this.args;

    if (state !== DUAL_NAV_STATES.RESPONSIVE) {
      this.defaultItem.link.transitionTo();
    }

    onMainMenuButtonPointerUp(item);
  }

  @action
  handlePointerEnter() {
    // @ts-expect-error
    let { onPointerEnter, item } = this.args;
    onPointerEnter(item);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::MainMenu::Button': typeof DualNavMainMenuButtonComponent;
  }
}
