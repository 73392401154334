/* import __COLOCATED_TEMPLATE__ from './dropdown-language.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { alias } from 'macro-decorators';

interface DropdownLanguageSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

/**
 * !!! Important !!!
 * This component was cope-paste from qonto-register
 * Source code can be found here:
 * component - https://gitlab.qonto.co/front/qonto-register-js/-/blob/master/app/components/dropdown-language.js
 * template - https://gitlab.qonto.co/front/qonto-register-js/-/blob/master/app/components/dropdown-language.hbs
 * styles - https://gitlab.qonto.co/front/qonto-register-js/-/blob/master/app/components/dropdown-language.module.css
 */
export default class DropdownLanguage extends Component<DropdownLanguageSignature> {
  @service declare localeManager: Services['localeManager'];
  @service declare userManager: Services['userManager'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];

  // @ts-expect-error
  locales = this.intl.getLocales;

  // @ts-expect-error
  @alias('localeManager.locale') locale;

  get selectedLanguage() {
    // @ts-expect-error
    return this.locales.find(({ code }) => code === this.localeManager.locale);
  }

  handleUpdateLanguageTask = dropTask(async language => {
    let prevLocale = this.locale;
    // @ts-expect-error
    await this.localeManager.setLocale(language.code, { canWriteCookie: true });

    let user = this.userManager.currentUser;

    try {
      // if we are not logged in we still want to be able to update the language
      if (user) {
        // @ts-expect-error
        user.set('locale', language.code);
        // @ts-expect-error
        if (!user.isNew) {
          // @ts-expect-error
          await user.save();
        }
      }
      // eslint-disable-next-line no-empty
    } catch {}

    this.segment.identify({ browser_locale: this.locale });
    this.segment.track('onboarding_dropdown_language_updated', {
      locale_from: prevLocale,
      locale_to: language.code,
      page_url: window.location.pathname,
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DropdownLanguage: typeof DropdownLanguage;
  }
}
