/* import __COLOCATED_TEMPLATE__ from './landing.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

interface FlowsMandateLandingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsMandateLandingComponent extends Component<FlowsMandateLandingSignature> {
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare modals: Services['modals'];

  // @ts-expect-error
  @reads('organizationManager.organization.legalCountry') legalCountry;

  constructor(owner: unknown, args: FlowsMandateLandingSignature['Args']) {
    super(owner, args);
    this.segment.track('app_sdd_creation_started');
  }

  @action
  // @ts-expect-error
  handleNext(hasReceivedMandate) {
    this.segment.track('app_sdd_debit_type_chosen', {
      mandate_received: hasReceivedMandate,
    });
    // @ts-expect-error
    this.args.context.hasReceivedMandate = hasReceivedMandate;
    // @ts-expect-error
    this.args.transitionToNext();
  }

  @action
  handleOpenSddEditoModal() {
    this.modals.open('sdd-edito-modal', {
      isFullScreenModal: true,
      legalCountry: this.legalCountry,
      organizationSlug: this.organizationManager.organization.slug,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Mandate::Landing': typeof FlowsMandateLandingComponent;
  }
}
