/* import __COLOCATED_TEMPLATE__ from './invite.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { CARD_FLOWS_TRACKING_ORIGINS } from 'qonto/constants/cards';
import { INVITATION_TYPES } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FlowsInviteSuccessInviteSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsInviteSuccessInviteComponent extends Component<FlowsInviteSuccessInviteSignature> {
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];
  @service declare flow: Services['flow'];

  get isGSAInviteTeamMember() {
    // @ts-expect-error
    return this.args.context.isGSAInviteTeamMember;
  }

  get isGuest() {
    // @ts-expect-error
    return this.args.context.invitationType === INVITATION_TYPES.GUEST;
  }

  get additionalActionText() {
    if (this.isGSAInviteTeamMember) {
      return this.intl.t('members.invite.success.secondary-cta-get-team-started');
    }

    if (this.isGuest) {
      return this.intl.t('accountant-access.invite.success.cta.invite-new');
    }

    return this.intl.t('members.invite.success.secondary-cta');
  }

  @action primaryAction() {
    if (this.isGSAInviteTeamMember) {
      this.segment.track('team_invite_selected', {
        origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION_SUCCESS_GET_STARTED,
      });
      // @ts-expect-error
      this.args.restartFlow({ initialStepId: 'role' });
    } else {
      // @ts-expect-error
      this.args.context.primaryActionTriggered = true;
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }

  @action
  secondaryAction() {
    if (this.isGuest) {
      let stepId = this.subscriptionManager.hasReachedAccountantLimit
        ? 'plan-upgrade'
        : 'personal-info';

      // @ts-expect-error
      if (this.flow.dataContext.isAccountingDiscoveryFlow) {
        this.restartAccountingDiscoveryFlowTask.perform(stepId).catch(ignoreCancelation);
      } else {
        // @ts-expect-error
        this.args.restartFlow();
        this.flowLinkManager.transitionTo({
          name: 'member-invite',
          stepId,
          queryParams: { invitationType: INVITATION_TYPES.GUEST },
        });
      }
    } else if (this.isGSAInviteTeamMember) {
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      // @ts-expect-error
      this.args.restartFlow();
    }
  }

  restartAccountingDiscoveryFlowTask = dropTask(async stepId => {
    // @ts-expect-error
    await this.args.restartFlow();
    // @ts-expect-error
    await this.args.pushForwardFlow('member-invite', stepId);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::Success::Invite': typeof FlowsInviteSuccessInviteComponent;
  }
}
