export default {
  "summary-description": "cqe",
  "detail": "cqB",
  "detail-value": "cqE",
  "account-icon": "cqd",
  "color-secondary": "cqI",
  "beneficiary-info": "cqt",
  "currency": "cqM",
  "beneficiary-name": "cqP",
  "separator": "cql",
  "instant-subtitle": "cqX",
  "instant-wrapper": "cqC",
  "instant-header": "cqk",
  "instant-toggle": "cqJ",
  "instant--disabled": "cqs",
  "limit-exceeded-disclaimer": "cqF",
  "sticky-panel": "cqg",
  "total-interest": "cqD",
  "tooltip-icon": "cqV",
  "tooltip": "cqL",
  "payment-options": "cqT",
  "starting-on-value": "cqz",
  "standard-transfer-tooltip": "cqa"
};
