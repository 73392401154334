export default {
  "card": "da",
  "row": "dH",
  "icon": "dO",
  "unrevokable": "dm",
  "active": "dp",
  "kyc-pending": "dx",
  "shareholder-info": "dw",
  "name": "dN",
  "iban": "dv",
  "tooltip": "do",
  "shareholder-capital": "dn",
  "shareholder-capital-amount": "di",
  "deposit-capital": "dA",
  "valid": "dY",
  "pending": "dh",
  "promised-capital-valid": "df",
  "promised-capital": "dK"
};
