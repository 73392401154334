/* import __COLOCATED_TEMPLATE__ from './extended.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

import { VAT_RATES } from 'qonto/constants/vat';

interface CounterpartiesFiltersExtendedSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CounterpartiesFiltersExtendedComponent extends Component<CounterpartiesFiltersExtendedSignature> {
  @service declare intl: Services['intl'];

  missingInformationOptions = [
    {
      // @ts-expect-error
      value: this.intl.t('counterparties.filters.missing-information.missing-vat'),
      key: 'missing_vat_rate',
    },
    {
      // @ts-expect-error
      value: this.intl.t('counterparties.filters.missing-information.missing-labels'),
      key: 'missing_labels',
    },
  ];

  get missingOptionsSelected() {
    // @ts-expect-error
    let { missingVAT, missingLabels } = this.args;
    return this.missingInformationOptions.filter(
      op =>
        (op.key === 'missing_vat_rate' && missingVAT) ||
        (op.key === 'missing_labels' && missingLabels)
    );
  }

  get missingOptionsLabel() {
    if (this.missingOptionsSelected?.length) {
      return `${this.intl.t('counterparties.filters.missing-information.title')} (${
        this.missingOptionsSelected.length
      })`;
    }
    return this.intl.t('counterparties.filters.missing-information.title');
  }

  get vatRatesLabel() {
    if (this.selectedVATOptions?.length) {
      return `${this.intl.t('counterparties.filters.vat-rates.title')} (${
        this.selectedVATOptions.length
      })`;
    }
    return this.intl.t('counterparties.filters.vat-rates.title');
  }

  @cached
  get vatOptions() {
    // @ts-expect-error
    let { vatRatesCategories } = this.args;
    // @ts-expect-error
    return vatRatesCategories?.map(key => ({
      value: this._textForVATOption(key),
      key,
    }));
  }

  get selectedVATOptions() {
    // @ts-expect-error
    let { vatRates } = this.args;

    // @ts-expect-error
    return this.vatOptions?.filter(vo => vatRates.includes(vo.key));
  }

  get allLabels() {
    // @ts-expect-error
    let { labelLists } = this.args;
    // @ts-expect-error
    return labelLists?.map(ll => [...ll.labels]).flat();
  }

  get selectedLabels() {
    let { allLabels } = this;
    // @ts-expect-error
    let { labelIds } = this.args;

    // @ts-expect-error
    return allLabels.filter(l => labelIds.includes(l.id));
  }

  @action
  // @ts-expect-error
  handleMissingInformationChange(selected) {
    if (Array.isArray(selected)) {
      // @ts-expect-error
      this.args.updateFilters({
        missingVAT: selected.some(op => op.key === 'missing_vat_rate'),
        missingLabels: selected.some(op => op.key === 'missing_labels'),
      });
    } else {
      // @ts-expect-error
      this.args.updateFilters({ missingVAT: false, missingLabels: false });
    }
  }

  // @ts-expect-error
  _textForVATOption(option) {
    if (option === VAT_RATES.NOT_AUTOMATIC) {
      return this.intl.t('counterparties.filters.vat-rates.not-automatic-option');
    }

    return this.intl.formatNumber(option / 100, {
      style: 'percent',
      minimumSignificantDigits: 1,
    });
  }

  @action
  // @ts-expect-error
  handleLabelsSelect(list, newSelection) {
    // @ts-expect-error
    let selectedLabelsFromOtherLists = this.selectedLabels.filter(l => l.labelList.id !== list.id);
    let newSelectedLabelIds = Array.isArray(newSelection) ? newSelection.map(sl => sl.key) : [];

    let allSelectedLabels = [
      ...newSelectedLabelIds,
      // @ts-expect-error
      ...selectedLabelsFromOtherLists.map(l => l.id),
    ].filter(Boolean);

    // @ts-expect-error
    this.args.updateFilters({ labelIds: allSelectedLabels });
  }

  @action
  // @ts-expect-error
  handleActivityTagSelect(value) {
    // @ts-expect-error
    this.args.updateFilters({ activityTag: value });
  }

  @action
  // @ts-expect-error
  handleVatSelectedChange(selected) {
    let selectedVATRates = Array.isArray(selected) ? selected.map(op => op.key) : [];
    // @ts-expect-error
    this.args.updateFilters({ vatRates: selectedVATRates });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Filters::Extended': typeof CounterpartiesFiltersExtendedComponent;
  }
}
