/* import __COLOCATED_TEMPLATE__ from './offer-card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface OfferCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OfferCardComponent extends Component<OfferCardSignature> {
  @service declare intl: Services['intl'];

  get formattedAmount() {
    // @ts-expect-error
    let { amount_min, amount_max } = this.args.offer.data;

    let compactAmountMin = this.intl.formatNumber(amount_min, {
      currency: 'EUR',
      style: 'currency',
      notation: 'compact',
    });

    let compactAmountMax = this.intl.formatNumber(amount_max, {
      currency: 'EUR',
      style: 'currency',
      notation: 'compact',
    });

    let translations = {
      range: this.intl.t('financing.offer-card.amount.range', {
        min_amount: compactAmountMin,
        max_amount: compactAmountMax,
      }),
      min: this.intl.t('financing.offer-card.amount.min', { min_amount: compactAmountMin }),
      max: this.intl.t('financing.offer-card.amount.max', { max_amount: compactAmountMax }),
    };

    switch (true) {
      case Boolean(amount_min && amount_max):
        return translations.range;
      case Boolean(amount_min):
        return translations.min;
      case Boolean(amount_max):
        return translations.max;
      default:
        return '-';
    }
  }

  get formattedDuration() {
    let { duration_min, duration_max, duration_fixed, duration_one_time_payment } =
      // @ts-expect-error
      this.args.offer.data;

    let translations = {
      range: this.intl.t('financing.offer-card.duration.months-range', {
        min_months: duration_min,
        max_months: duration_max,
      }),
      min: this.intl.t('financing.offer-card.duration.min-months', {
        min_months: duration_min,
      }),
      max: this.intl.t('financing.offer-card.duration.max-months', {
        max_months: duration_max,
      }),
      fixed: this.intl.t('financing.offer-card.duration.months-interval', {
        num_months: duration_fixed,
      }),
      'one-time': this.intl.t('financing.offer-card.duration.one-time'),
    };

    switch (true) {
      case Boolean(duration_min && duration_max):
        return translations.range;
      case Boolean(duration_min && !duration_max):
        return translations.min;
      case Boolean(!duration_min && duration_max):
        return translations.max;
      case Boolean(duration_fixed):
        return translations.fixed;
      case duration_one_time_payment:
        return translations['one-time'];
      default:
        return '-';
    }
  }

  get formattedInterestRate() {
    let { interest_rate_min, interest_rate_max, interest_rate_interval, interest_rate_fixed } =
      // @ts-expect-error
      this.args.offer.data;

    let rangeOptions = {
      min_rate: interest_rate_min,
      max_rate: interest_rate_max,
    };

    let translations = {
      range: {
        daily: this.intl.t('financing.offer-card.interest-rate.daily-range', rangeOptions),
        monthly: this.intl.t('financing.offer-card.interest-rate.monthly-range', rangeOptions),
        quarterly: this.intl.t('financing.offer-card.interest-rate.quaterly-range', rangeOptions),
        yearly: this.intl.t('financing.offer-card.interest-rate.yearly-range', rangeOptions),
        none: this.intl.t('financing.offer-card.interest-rate.range', rangeOptions),
      },
      min: {
        daily: this.intl.t('financing.offer-card.interest-rate.daily-min', {
          min_rate: interest_rate_min,
        }),
        monthly: this.intl.t('financing.offer-card.interest-rate.monthly-min', {
          min_rate: interest_rate_min,
        }),
        quarterly: this.intl.t('financing.offer-card.interest-rate.quaterly-min', {
          min_rate: interest_rate_min,
        }),
        yearly: this.intl.t('financing.offer-card.interest-rate.yearly-min', {
          min_rate: interest_rate_min,
        }),
      },
      max: {
        daily: this.intl.t('financing.offer-card.interest-rate.daily-max', {
          max_rate: interest_rate_max,
        }),
        monthly: this.intl.t('financing.offer-card.interest-rate.monthly-max', {
          max_rate: interest_rate_max,
        }),
        quarterly: this.intl.t('financing.offer-card.interest-rate.quaterly-max', {
          max_rate: interest_rate_max,
        }),
        yearly: this.intl.t('financing.offer-card.interest-rate.yearly-max', {
          max_rate: interest_rate_max,
        }),
      },
      fixed: {
        daily: this.intl.t('financing.offer-card.interest-rate.daily-interval', {
          rate: interest_rate_fixed,
        }),
        monthly: this.intl.t('financing.offer-card.interest-rate.monthly-interval', {
          rate: interest_rate_fixed,
        }),
        quarterly: this.intl.t('financing.offer-card.interest-rate.quaterly-interval', {
          rate: interest_rate_fixed,
        }),
        yearly: this.intl.t('financing.offer-card.interest-rate.yearly-interval', {
          rate: interest_rate_fixed,
        }),
        none: this.intl.t('financing.offer-card.interest-rate.fixed', {
          rate: interest_rate_fixed,
        }),
      },
    };

    switch (true) {
      case Boolean(interest_rate_min && interest_rate_max):
        // @ts-expect-error
        return translations.range[interest_rate_interval];
      case Boolean(interest_rate_min && !interest_rate_max):
        // @ts-expect-error
        return translations.min[interest_rate_interval];
      case Boolean(!interest_rate_min && interest_rate_max):
        // @ts-expect-error
        return translations.max[interest_rate_interval];
      case Boolean(interest_rate_fixed):
        // @ts-expect-error
        return translations.fixed[interest_rate_interval];
      default:
        return this.intl.t('financing.offer-card.interest-rate.not-available');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::OfferCard': typeof OfferCardComponent;
  }
}
