/* import __COLOCATED_TEMPLATE__ from './beneficiary-upload.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface BeneficiaryUploadSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BeneficiaryUploadComponent extends Component<BeneficiaryUploadSignature> {
  @tracked activityTag = null;

  @action
  // @ts-expect-error
  handleDeleteFile(multiBeneficiary) {
    multiBeneficiary.set('file', null);
  }

  @action
  // @ts-expect-error
  handleToggleCheck(beneficiary) {
    beneficiary.toggleProperty('selected');
  }

  @action
  // @ts-expect-error
  handleActivityTagSelection(multiBeneficiary, activityTagCode) {
    this.activityTag = activityTagCode;
    multiBeneficiary
      .get('beneficiaries')
      // @ts-expect-error
      .forEach(beneficiary => beneficiary.set('activityTag', activityTagCode));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BeneficiaryUpload: typeof BeneficiaryUploadComponent;
  }
}
