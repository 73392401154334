/* import __COLOCATED_TEMPLATE__ from './withdrawal-limits.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

interface FlowsCardsWithdrawalLimitsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsWithdrawalLimits extends Component<FlowsCardsWithdrawalLimitsSignature> {
  @service declare segment: Services['segment'];

  @action
  handleNext() {
    let {
      // @ts-expect-error
      context: { card, flowTrackingOrigin, isUserCardHolder },
      // @ts-expect-error
      transitionToNext,
    } = this.args;

    this.segment.track('cards_order_withdrawal_limits_set', {
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
      daily_withdrawal_limit: card.atmDailyLimitOption,
      withdrawal_limit: card.atmMonthlyLimit,
      origin: flowTrackingOrigin,
    });

    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::WithdrawalLimits': typeof FlowsCardsWithdrawalLimits;
  }
}
