/* import __COLOCATED_TEMPLATE__ from './umr-field.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { FR_CREDITORS_CODE } from 'qonto/constants/mandates';
import { formatUmr, handleFormatUmr } from 'qonto/utils/format-input';

interface UmrFieldSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class UmrField extends Component<UmrFieldSignature> {
  @service declare intl: Services['intl'];

  @tracked displayWarning = false;
  @tracked isFocused = false;
  // @ts-expect-error
  @tracked umr = formatUmr(this.args.value, this.args.creditorCode);

  get messageLevel() {
    if (this.displayHint) {
      return 'hint';
      // @ts-expect-error
    } else if (this.displayWarning && this.args.warning) {
      return 'warning';
      // @ts-expect-error
    } else if (this.displayError && this.args.error) {
      return 'error';
    }
  }

  get message() {
    if (this.displayHint) {
      return this.hint;
      // @ts-expect-error
    } else if (this.displayWarning && this.args.warning) {
      // @ts-expect-error
      return this.args.warning;
      // @ts-expect-error
    } else if (this.displayError && this.args.error) {
      // @ts-expect-error
      return this.args.error;
    }
  }

  get displayHint() {
    return this.isFocused && !this.displayWarning;
  }

  get displayError() {
    // @ts-expect-error
    return this.args.error && !this.displayHint;
  }

  get placeholder() {
    // @ts-expect-error
    let { creditorCode } = this.args;
    let { DGFIP, SIE, URSSAF } = FR_CREDITORS_CODE;

    switch (creditorCode) {
      case DGFIP:
        return this.intl.t('placeholders.mandate-umr.dgfip');
      case SIE:
        return this.intl.t('placeholders.mandate-umr.taxDepartment');
      case URSSAF:
        return this.intl.t('placeholders.mandate-umr.urssaf');
      default:
        return this.intl.t('placeholders.mandate-umr.other');
    }
  }

  get hint() {
    // @ts-expect-error
    let { creditorCode } = this.args;
    let { DGFIP, SIE, URSSAF } = FR_CREDITORS_CODE;

    switch (creditorCode) {
      case DGFIP:
        return this.intl.t('mandates.mandate-details.umr-hint.dgfip', {
          count: '34',
          letters: 'NN',
        });
      case SIE:
        return this.intl.t('mandates.mandate-details.umr-hint.sie', {
          count: '34',
          letters: 'NN',
        });
      case URSSAF:
        return this.intl.t('mandates.mandate-details.umr-hint.urssaf', {
          count: '35',
          letters: 'T',
        });
      default:
        return this.intl.t('mandates.mandate-details.umr-hint.other', {
          count: '35',
        });
    }
  }

  @action
  // @ts-expect-error
  handleOnFocusOut(event) {
    // @ts-expect-error
    let { creditorCode } = this.args;
    let { DGFIP, SIE, URSSAF } = FR_CREDITORS_CODE;
    this.isFocused = false;

    let { value } = event.target;
    this.displayWarning = Boolean(value);

    this.umr = value;
    let unauthorizedCharsRegexp = [DGFIP, SIE, URSSAF].includes(creditorCode)
      ? /[^A-Z0-9/\-?:().,'+]/g
      : /[^A-Z0-9/\-?:().,'+\s]/g;
    // @ts-expect-error
    this.args.onChange(value.replace(unauthorizedCharsRegexp, ''));
  }

  @action
  // @ts-expect-error
  handleOnInput(creditorCode, event) {
    this.displayWarning = false;
    this.isFocused = true;
    handleFormatUmr(event.target, creditorCode);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::UmrField': typeof UmrField;
  }
}
