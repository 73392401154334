/* import __COLOCATED_TEMPLATE__ from './requests-table.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RequestsTableSignature {
  Element: null;
  Args: {
    isLoading?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const RequestsTable = templateOnlyComponent<RequestsTableSignature>();

export default RequestsTable;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::RequestsTable': typeof RequestsTable;
  }
}
