export default {
  "header": "Vi",
  "header__main": "VA",
  "note": "VY",
  "review-status": "Vh",
  "reviewer-wrapper": "Vf",
  "reviewer": "VK",
  "decline-note": "VG",
  "decline-note-title": "Vr",
  "status-dot": "Vb",
  "approved": "Vy",
  "rejected": "VU"
};
