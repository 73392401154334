/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import Component from '@glimmer/component';

import { StepLayout } from 'qonto/react/components/flows/chargeback-claim/step-layout/step-layout';
import { Success } from 'qonto/react/components/flows/chargeback-claim/success';

// @ts-expect-error
import styles from './success.module.css';

interface FlowsCardChargebackSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardChargebackSuccessComponent extends Component<FlowsCardChargebackSuccessSignature> {
  stepLayout = StepLayout;
  success = Success;
  styles = styles;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::Success': typeof FlowsCardChargebackSuccessComponent;
  }
}
