/* import __COLOCATED_TEMPLATE__ from './delivery-address.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';
import { or, reads } from 'macro-decorators';

import { CARD_DELIVERY_ESTIMATE_DAYS, CARD_LEVELS_TRACKING, ORIGIN } from 'qonto/constants/cards';
import { KYC_STATUS } from 'qonto/constants/membership';
// @ts-expect-error
import { addBusinessDays, getCurrentParisDate } from 'qonto/utils/date';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const TRACKING_SHIPPING_INFO = {
  holder: 'personal',
  organization: 'professional',
  custom: 'new',
};

interface FlowsCardsDeliveryAddressSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsDeliveryAddress extends Component<FlowsCardsDeliveryAddressSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sensitiveActions: Services['sensitiveActions'];

  // @ts-expect-error
  @or('args.context.card.holder', 'args.context.holder') holder;

  // @ts-expect-error
  @reads('organizationManager.organization.legalCountry') legalCountry;
  // @ts-expect-error
  @reads('organizationManager.organization.name') organizationName;
  // @ts-expect-error
  @reads('organizationManager.organization.address.fullAddress') organizationFullAddress;
  // @ts-expect-error
  @reads('holder.fullName') holderFullName;
  // @ts-expect-error
  @reads('holder.address.fullAddress') holderFullAddress;

  get cardToDeliverCount() {
    // @ts-expect-error
    return this.args.context.cardToRenewCount ?? 1;
  }

  get title() {
    // @ts-expect-error
    return this.args.context.isOnboardingFlow
      ? this.intl.t('cards.steps.choose-delivery-address.title.onboarding')
      : this.intl.t('cards.steps.choose-delivery-address.title.default');
  }

  get canReadDeliveryDate() {
    // @ts-expect-error
    let { card, isCardsRenewal } = this.args.context;
    if (isCardsRenewal && this.legalCountry === 'FR') {
      return true;
    }
    return this.abilities.can('see estimated delivery date card', card);
  }

  get deliveryDate() {
    // @ts-expect-error
    let { card } = this.args.context;

    if (!this.canReadDeliveryDate) {
      return null;
    }
    let days = CARD_DELIVERY_ESTIMATE_DAYS[card?.cardLevel] || 5;

    return addBusinessDays(getCurrentParisDate(), days);
  }

  get subtitle() {
    if (this.isMetalCardChosen) {
      return this.intl.t('cards.steps.choose-delivery-address.subtitle-x-card');
    }

    // @ts-expect-error
    let { origin } = this.args.context;

    if (!this.isKycValidated && origin !== ORIGIN.MEMBER_INVITATION) {
      return this.intl.t('cards.steps.choose-delivery-address.subtitle-not-validated');
    }

    return this.intl.t('cards.steps.choose-delivery-address.subtitle', {
      count: this.cardToDeliverCount,
    });
  }

  get isMetalCardChosen() {
    // @ts-expect-error
    let { card, isCardsRenewal } = this.args.context;
    return !isCardsRenewal && card.isMetal;
  }

  get isKycValidated() {
    // @ts-expect-error
    let { card, isCardsRenewal } = this.args.context;
    return isCardsRenewal || card.get('holder.kycStatus') === KYC_STATUS.ACCEPTED;
  }

  get isShippingToBusiness() {
    // @ts-expect-error
    return this.args.context.shippingOption === 'organization';
  }

  get holderAddress() {
    let address = this.holder.get('address');
    return address.country ? address : null;
  }

  _getAppropriateCardShippingAddress() {
    // @ts-expect-error
    let shippingOption = this.args.context.shippingOption;
    switch (shippingOption) {
      case 'holder':
        return this.holderAddress;
      case 'organization':
        return this.organizationManager.organization.address;
      case 'custom':
        // @ts-expect-error
        return this.args.context.customShippingAddress;
    }
  }

  @action
  // @ts-expect-error
  updateShippingOption(option, renewTask) {
    // @ts-expect-error
    set(this.args.context, 'shippingOption', option);
    // @ts-expect-error
    let { cardUpsellLevel, isCardsRenewal, shippingOption } = this.args.context;

    if (!shippingOption) return false;

    if (isCardsRenewal) {
      this._renewCard(renewTask, shippingOption);
    } else if (cardUpsellLevel) {
      this._trackUpsellAddress(shippingOption, cardUpsellLevel);
    } else {
      this._updateAddress(shippingOption);
    }
  }

  @action
  openCustomAddressModal() {
    // @ts-expect-error
    let { customShippingAddress } = this.args.context;

    this.modals.open('address-form-modal', {
      cardToDeliverCount: this.cardToDeliverCount,
      isFullScreenModal: true,
      confirmTask: this.handleFormSubmitTask,
      customShippingAddress,
    });
  }

  handleFormSubmitTask = task(async address => {
    // @ts-expect-error
    await setProperties(this.args.context, {
      customShippingAddress: address,
      shippingOption: 'custom',
    });
  });

  // @ts-expect-error
  _renewCard(renewTask, shippingOption) {
    this.segment.track('cards_renewal_address_chosen', {
      // @ts-expect-error
      address_type: TRACKING_SHIPPING_INFO[shippingOption],
    });
    // @ts-expect-error
    this.sensitiveActions.runTask
      .perform(this._confirmRenewalAddressTask, renewTask)
      .catch(ignoreCancelation);
  }

  // @ts-expect-error
  _trackUpsellAddress(shippingOption, cardUpsellLevel) {
    // @ts-expect-error
    let { card, isUserCardHolder } = this.args.context;
    this.segment.track('cards_upsell_address_chosen', {
      // @ts-expect-error
      address_type: TRACKING_SHIPPING_INFO[shippingOption],
      card_level_origin: CARD_LEVELS_TRACKING[card.cardLevel],
      card_level_upsell: CARD_LEVELS_TRACKING[cardUpsellLevel],
      card_holder: isUserCardHolder ? 'self' : 'team_member',
    });

    // @ts-expect-error
    set(this.args.context, 'shippingAddress', this._getAppropriateCardShippingAddress());
    // @ts-expect-error
    set(this.args.context, 'shipToBusiness', this.isShippingToBusiness);
    // @ts-expect-error
    this.args.transitionToNext();
  }

  // @ts-expect-error
  _updateAddress(shippingOption) {
    // @ts-expect-error
    let { card, flowTrackingOrigin, isUserCardHolder } = this.args.context;

    this.segment.track('cards_order_address_chosen', {
      // @ts-expect-error
      address_type: TRACKING_SHIPPING_INFO[shippingOption],
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      origin: flowTrackingOrigin,
    });

    card.setProperties({
      address: this._getAppropriateCardShippingAddress(),
      shipToBusiness: this.isShippingToBusiness,
    });

    // @ts-expect-error
    this.args.transitionToNext();
  }

  _confirmRenewalAddressTask = task(async renewTask => {
    let address = this._getAppropriateCardShippingAddress().serialize();

    // @ts-expect-error
    set(this.args.context, 'address', address);
    // @ts-expect-error
    set(this.args.context, 'shipToBusiness', this.isShippingToBusiness);

    // in case there is a single expiring card, we go to the next step
    // @ts-expect-error
    if (this.args.context.hasSingleCardToRenew) return this.args.transitionToNext();

    // errors are handled in the renewTask itself
    let response = await renewTask.perform(address, this.isShippingToBusiness);

    // @ts-expect-error
    set(this.args.context, 'renewalCardsCount', response?.renewalCardsCount);
    // @ts-expect-error
    set(this.args.context, 'renewalCard', response?.renewalCard);

    if (response?.renewalCardsCount) {
      // @ts-expect-error
      this.args.transitionToNext();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::DeliveryAddress': typeof FlowsCardsDeliveryAddress;
  }
}
