export default {
  "row": "Cp",
  "active": "Cx",
  "animated": "Cw",
  "fadein-item": "CN",
  "cell": "Cv body-2",
  "cell-content": "Co",
  "no-padding": "Cn",
  "hidden": "Ci",
  "disabled": "CA",
  "not-disabled": "CY",
  "card-icon": "Ch",
  "card-infos": "Cf",
  "card-digits": "CK",
  "card-digits-warning": "CG",
  "status-puce": "Cr",
  "live": "Cb",
  "waiting-funds": "Cy",
  "ghost": "CU",
  "payment-limit-bar": "Cj",
  "align-right": "CW"
};
