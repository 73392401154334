/* import __COLOCATED_TEMPLATE__ from './remove-restrictions.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { task } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface CardModalsRemoveRestrictionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardModalsRemoveRestrictionsComponent extends Component<CardModalsRemoveRestrictionsSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];

  confirmTask = task(async () => {
    // @ts-expect-error
    let { card, resetType } = this.args.data;
    this.segment.track('reset_advanced-card-settings_confirmed', {
      reset_type: resetType,
    });
    let data = { card: {} };
    if (resetType === 'days-of-use') {
      // @ts-expect-error
      data.card.active_days = [0, 1, 2, 3, 4, 5, 6];
    } else if (resetType === 'spending-categories') {
      // @ts-expect-error
      data.card.category_tags = [];
    }
    try {
      let response = await apiAction(card, { method: 'PATCH', path: 'restrictions', data });
      this.store.pushPayload(response);
      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.success.restrictions-removed'));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
    // @ts-expect-error
    this.args.close();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::RemoveRestrictions': typeof CardModalsRemoveRestrictionsComponent;
  }
}
