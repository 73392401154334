/* import __COLOCATED_TEMPLATE__ from './signature.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Signature } from 'qonto/react/receivable-invoices/components/flows/quote/e-signature';

interface FlowsReceivableInvoicesQuoteEsignatureSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsReceivableInvoicesQuoteEsignatureSignatureComponent extends Component<FlowsReceivableInvoicesQuoteEsignatureSignature> {
  signature = Signature;

  @service declare zendeskWidget: Services['zendeskWidget'];

  constructor(owner: unknown, args: FlowsReceivableInvoicesQuoteEsignatureSignature['Args']) {
    super(owner, args);
    this.zendeskWidget.hide();
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    this.zendeskWidget.show();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ReceivableInvoices::QuoteEsignature::Signature': typeof FlowsReceivableInvoicesQuoteEsignatureSignatureComponent;
  }
}
