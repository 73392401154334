/* import __COLOCATED_TEMPLATE__ from './description.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL, chargbackClaimsNamespace } from 'qonto/constants/hosts';
import { CLAIM_DESCRIPTION_MIN_LENGTH, CLAIM_TYPES } from 'qonto/constants/transactions';
import { StepLayout } from 'qonto/react/components/flows/chargeback-claim/step-layout/step-layout';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsRequestCardsNoteSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsRequestCardsNoteComponent extends Component<FlowsRequestCardsNoteSignature> {
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  stepLayout = StepLayout;
  textAreaField = TextAreaField;
  descriptionMinLength = CLAIM_DESCRIPTION_MIN_LENGTH;

  @tracked description = null;
  @tracked errorMessage = null;

  @action
  // @ts-expect-error
  onUpdateDescription(value) {
    this.errorMessage = null;
    this.description = value;
  }

  get isFraud() {
    // @ts-expect-error
    return this.args.context?.claimType === CLAIM_TYPES.fraud;
  }

  submitTask = dropTask(async () => {
    // @ts-expect-error
    if (!this.description || this.description.trim().length < CLAIM_DESCRIPTION_MIN_LENGTH) {
      // @ts-expect-error
      this.errorMessage = this.intl.t('dispute-flow.additional-info.form.length-error', {
        amount: CLAIM_DESCRIPTION_MIN_LENGTH,
      });
      return;
    }

    if (this.isFraud) {
      this.segment.track('cards-chargeback_add-description-cta_clicked');
      // @ts-expect-error
      this.args.context.description = this.description;
      // @ts-expect-error
      this.args.transitionToNext();
      return;
    }

    try {
      // @ts-expect-error
      let { claimType, selectedTransactions, files } = this.args.context;

      let payload = {
        data: {
          type: 'claims',
          attributes: {
            type: claimType,
            description: this.description,
          },
          relationships: {
            transactions: {
              // @ts-expect-error
              data: selectedTransactions.map(transaction => ({
                type: 'transactions',
                id: transaction.id,
              })),
            },
            documents: {
              // @ts-expect-error
              data: files.flat().map(file => ({ id: file.id, type: 'documents' })),
            },
          },
        },
      };

      this.segment.track('cards-chargeback_submit-dispute-cta_clicked');

      await this.networkManager.request(`${apiBaseURL}/${chargbackClaimsNamespace}/claims`, {
        method: 'POST',
        data: payload,
      });

      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::Description': typeof FlowsRequestCardsNoteComponent;
  }
}
