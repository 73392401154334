/* import __COLOCATED_TEMPLATE__ from './quote.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Button, dateToken, Disclaimer, SkeletonLoader } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { allSettled, dropTask, restartableTask, timeout } from 'ember-concurrency';
import { TrackedObject } from 'tracked-built-ins';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import {
  CURRENCIES,
  type CurrencyCode,
  NEW_TARGET_CURRENCIES,
} from 'qonto/constants/international-out/currency';
import {
  DEFAULT_SOURCE_AMOUNT,
  ERROR_CODE,
  QUOTE_CREATION_STATUS,
} from 'qonto/constants/international-out/quote';
import { STANDING_FREQUENCIES } from 'qonto/constants/international-out/schedule';
import { STORAGE_KEYS } from 'qonto/constants/international-out/storage';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import type { DataContext } from 'qonto/routes/flows/setup/transfers/international-out/data-context';
import {
  QuoteType,
  RateType,
  type SchedulingOptions,
  StandingFrequency,
} from 'qonto/services/international-out/types';
import { createCurrency, extendCurrency } from 'qonto/utils/currency';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { formatExchangeRateWithDetails } from 'qonto/utils/international-out/format';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';
import transformKeys from 'qonto/utils/transform-keys';
// @ts-expect-error
import QuoteValidations from 'qonto/validations/quote';

// @ts-expect-error
function getInitialPayload({ invoice, quote }) {
  if (quote) {
    return {
      sourceAmount: quote.type === QuoteType.Source ? quote.sourceAmount : null,
      targetAmount: quote.type === QuoteType.Target ? quote.targetAmount : null,
    };
  }

  if (invoice) {
    return {
      sourceAmount: null,
      targetAmount: invoice.amount,
      targetCurrency: invoice.currency,
    };
  }
}

// @ts-expect-error
function getInitialState({ bankAccount, invoice, quote, settings }) {
  let { sourceCurrency, targetCurrency } = settings.preferred;

  let initialState = {
    bankAccount,
    sourceCurrency,
    targetCurrency,
  };

  if (quote) {
    return {
      ...initialState,
      sourceAmount: quote?.type === QuoteType.Source ? quote.sourceAmount : null,
      targetAmount: quote?.type === QuoteType.Target ? quote.targetAmount : null,
      targetCurrency: quote?.targetCurrency,
    };
  }

  if (invoice) {
    return {
      ...initialState,
      sourceAmount: null,
      targetAmount: invoice.amount,
      targetCurrency: invoice.currency,
    };
  }

  return initialState;
}

// @ts-expect-error
function getInitialSourceCurrency({ quote, settings }) {
  if (quote) {
    return createCurrency(quote.sourceCurrency);
  }

  return createCurrency(settings.preferred.sourceCurrency);
}

// @ts-expect-error
function getInitialTargetCurrency({ invoice, quote, settings }) {
  if (quote) {
    return createCurrency(quote.targetCurrency);
  }

  if (invoice) {
    return createCurrency(invoice.currency);
  }

  return createCurrency(settings.preferred.targetCurrency);
}

// @ts-expect-error
class QuoteModel extends EmberObject.extend(QuoteValidations) {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];

  @tracked type = null;
  @tracked bankAccount = null;
  @tracked sourceAmount = null;
  @tracked targetAmount = null;
  @tracked sourceCurrency = null;
  @tracked targetCurrency = null;
  @tracked rate = null;
  @tracked fees = null;

  errors = new TrackedObject();

  get shouldValidateTotalAmount() {
    return this.abilities.can('see balance bankAccount');
  }

  get totalAmount() {
    // @ts-expect-error
    return Number(this.sourceAmount || 0) + Number(this.fees?.total || 0);
  }

  clearErrors() {
    Object.keys(this.errors).forEach(attribute => delete this.errors[attribute]);
  }
}

interface Signature {
  Args: FlowStepArgs<DataContext>;
}

export default class FlowsTransfersInternationalOutNewQuoteComponent extends Component<Signature> {
  badge = Badge;
  button = Button;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  skeletonLoaderBlock: typeof SkeletonLoader.Block = SkeletonLoader.Block;

  @service declare errors: Services['errors'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked sourceCurrencies = [getInitialSourceCurrency(this.args.context)];
  @tracked targetCurrencies = [getInitialTargetCurrency(this.args.context)];

  @tracked lastQuoteCreationResult = null;
  @tracked isNewBadgeDisplayed = safeLocalStorage.getItem(STORAGE_KEYS.NEW_BADGE_SCHEDULE) === null;

  @tracked scheduleModal: void | { close: () => void } | null = null;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);

    let { origin } = this.args.context.settings;

    this.segment.track(EVENTS.START_FLOW, {
      ...(origin && { origin }),
    });

    // @ts-expect-error
    this.quoteModel = QuoteModel.create(
      // @ts-expect-error
      getOwner(this).ownerInjection(),
      getInitialState(this.args.context)
    );

    allSettled([
      this.getTargetCurrenciesTask.perform(),
      this.createAndUpdateQuoteTask.perform(getInitialPayload(this.args.context)),
    ])
      .then(([targetCurrenciesSettled]) => {
        if (targetCurrenciesSettled.state === 'fulfilled' && targetCurrenciesSettled.value) {
          this.targetCurrencies = targetCurrenciesSettled.value;
        }
      })
      .catch(ignoreCancelation);
  }

  get actionTask() {
    if (this.hasFailed) {
      return this.onRetryTask;
    }

    return this.onTransitionTask;
  }

  get extendedAndSortedSourceCurrencies() {
    // @ts-expect-error
    return this.#extendCurrencies(this.sourceCurrencies)?.sort((firstCurrency, secondCurrency) =>
      firstCurrency.code.localeCompare(secondCurrency.code)
    );
  }

  get extendedAndSortedTargetCurrencies() {
    return this.#extendCurrencies(this.targetCurrencies, NEW_TARGET_CURRENCIES)?.sort(
      // @ts-expect-error
      (firstCurrency, secondCurrency) => firstCurrency.code.localeCompare(secondCurrency.code)
    );
  }

  get feesTooltipMessage() {
    // @ts-expect-error
    if (this.quoteModel.targetCurrency === CURRENCIES.EUR) {
      return this.intl.t('international-out.quote.tooltips.eur-transfer-fee');
    }

    let { fees } = this.args.context;

    return this.intl.t('international-out.quote.tooltips.transfer-fee', {
      pricingFee: fees?.variable,
      minimumFee: fees?.minimum,
    });
  }

  get formattedDate(): string | null {
    if (this.#isScheduled) {
      const startDate = this.args.context.schedule?.startDate;

      if (!startDate || !this.intl.primaryLocale) return null;

      const formattedStartDate = dateToken({
        date: startDate,
        locale: this.intl.primaryLocale,
      });

      if (this.#isOneOff) {
        return this.intl.t('international-out.quote.schedule.for', {
          date: formattedStartDate,
        });
      }

      if (this.#isRecurring) {
        const isFirstOccurenceToday = dayjs(startDate, 'YYYY-MM-DD').isSame(dayjs(), 'day');
        return this.intl.t('international-out.quote.schedule.start', {
          startDate: isFirstOccurenceToday
            ? this.intl.t('international-out.quote.schedule.today')
            : formattedStartDate,
        });
      }
    }

    const formattedEstimatedDelivery = this.args.context.quote?.formattedEstimatedDelivery;

    if (formattedEstimatedDelivery) {
      return this.intl.t('international-out.quote.eta', {
        eta: formattedEstimatedDelivery,
      });
    }

    return null;
  }

  get formattedExchangeRate() {
    // @ts-expect-error
    return formatExchangeRateWithDetails(this.quoteModel.rate?.value || 0);
  }

  get formattedRecurrence(): string | null {
    if (!this.#isRecurring) {
      return null;
    }

    const endDate = this.args.context.schedule?.endDate;
    const frequency = this.intl.t(
      `international-out.schedule.frequency.${STANDING_FREQUENCIES[this.args.context.schedule?.standingFrequency as StandingFrequency]}`
    );

    return this.intl.t('international-out.quote.schedule.repeats', {
      frequency,
      endDate:
        endDate && this.intl.primaryLocale
          ? dateToken({
              date: endDate,
              locale: this.intl.primaryLocale,
            })
          : undefined,
    });
  }

  get hasFailed() {
    // @ts-expect-error
    return this.lastQuoteCreationResult?.status === QUOTE_CREATION_STATUS.UNEXPECTED_ERROR;
  }

  get isButtonDisplayed() {
    // @ts-expect-error
    return this.quoteModel.errors?.global?.code !== ERROR_CODE.NO_PAYMENT_OPTION_AVAILABLE;
  }

  get #isOneOff(): boolean {
    return Boolean(
      this.#isScheduled && this.args.context.schedule?.startDate && !this.#isRecurring
    );
  }

  get #isRecurring(): boolean {
    return (
      this.#isScheduled && this.args.context.schedule?.standingFrequency !== StandingFrequency.None
    );
  }

  get #isScheduled(): boolean {
    return Boolean(this.args.context.schedule);
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get targetCurrencyNotice() {
    // @ts-expect-error
    let { fees, targetCurrency } = this.quoteModel;
    let { targetCurrency: preferredTargetCurrency } = this.args.context.settings.preferred;

    if (targetCurrency === preferredTargetCurrency && fees === null) {
      return null;
    }

    switch (targetCurrency) {
      case CURRENCIES.EUR:
        return this.intl.t('international-out.quote.information.eur');
      case CURRENCIES.MYR:
        return this.intl.t('international-out.quote.information.myr');
      case CURRENCIES.USD:
        return this.intl.t('international-out.quote.information.usd');
      default:
        return null;
    }
  }

  @action
  openScheduleModal(): void {
    if (this.isNewBadgeDisplayed) {
      const isStored = safeLocalStorage.setItem(
        STORAGE_KEYS.NEW_BADGE_SCHEDULE,
        Date.now().toString()
      );

      if (isStored) {
        this.isNewBadgeDisplayed = false;
      }
    }

    this.segment.track(EVENTS.SCHEDULE_BUTTON_CLICKED);

    // @ts-expect-error
    const { type, sourceAmount, sourceCurrency, targetAmount, targetCurrency } = this.quoteModel;

    let amount: number | null = null;
    let currency: CurrencyCode | null = null;

    switch (type) {
      case QuoteType.Source:
        amount = sourceAmount;
        currency = sourceCurrency;
        break;
      case QuoteType.Target:
        amount = targetAmount;
        currency = targetCurrency;
        break;
      default:
        break;
    }

    this.scheduleModal = this.modals.open(
      'transfers/international-out/modals/schedule',
      {
        title: this.intl.t('international-out.schedule-modal.title.default'),
        onSubmit: this.setSchedule,
        ...(this.#isScheduled && { removeSchedule: this.removeSchedule }),
        schedule: this.args.context.schedule,
        ...(amount && { amount }),
        ...(currency && { currency }),
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: false,
        },
      }
    );
  }

  closeScheduleModal() {
    this.scheduleModal?.close();
    this.scheduleModal = null;
  }

  @action
  // @ts-expect-error
  updateBankAccount(account) {
    this.args.context.bankAccount = account;
    // @ts-expect-error
    this.quoteModel.bankAccount = account;
  }

  @action
  setSchedule(scheduleOptions: SchedulingOptions) {
    const isScheduled =
      !dayjs(scheduleOptions.startDate, 'YYYY-MM-DD').isSame(dayjs(), 'day') ||
      scheduleOptions.standingFrequency !== StandingFrequency.None;

    this.args.context.schedule = isScheduled ? scheduleOptions : null;

    this.segment.track(EVENTS.SCHEDULE_SAVED);

    this.closeScheduleModal();
  }

  @action
  removeSchedule() {
    this.args.context.schedule = null;
    this.closeScheduleModal();
  }

  createQuoteTask = dropTask(
    async ({
      targetAmount = null,
      sourceAmount = DEFAULT_SOURCE_AMOUNT,
      // @ts-expect-error
      targetCurrency = this.quoteModel.targetCurrency,
      // @ts-expect-error
      sourceCurrency = this.quoteModel.sourceCurrency,
    } = {}) => {
      let status = QUOTE_CREATION_STATUS.SUCCESS;
      let payload = {
        ...(sourceAmount && { sourceAmount: Number(sourceAmount) }),
        ...(targetAmount && { targetAmount: Number(targetAmount) }),
        sourceCurrency,
        targetCurrency,
      };

      try {
        return await this.internationalOutManager.createQuote(payload);
      } catch (error) {
        // @ts-expect-error
        if (error.errors?.[0] && Object.values(ERROR_CODE).includes(error.errors[0].code)) {
          status = QUOTE_CREATION_STATUS.VALIDATION_ERROR;

          // @ts-expect-error
          let { code, meta, source } = transformKeys(error.errors[0]);

          let {
            ABOVE_MAXIMUM_AMOUNT,
            BELOW_MINIMUM_AMOUNT,
            NO_PAYMENT_OPTION_AVAILABLE,
            TARGET_AMOUNT_VOLATILE,
          } = ERROR_CODE;

          let errorCodesMapping = {
            [ABOVE_MAXIMUM_AMOUNT]: {
              attribute: source?.pointer?.slice(1),
              message: this.intl.t('international-out.quote.errors.amount.too-high', {
                formattedAmount: meta?.formattedAmount,
              }),
            },
            [BELOW_MINIMUM_AMOUNT]: {
              attribute: source?.pointer?.slice(1),
              message: this.intl.t('international-out.quote.errors.amount.too-low', {
                formattedAmount: meta?.formattedAmount,
              }),
            },
            [NO_PAYMENT_OPTION_AVAILABLE]: {
              message: this.intl.t('international-out.quote.errors.currency.unavailable', {
                // @ts-expect-error
                targetCurrency: this.quoteModel.targetCurrency,
              }),
            },
            [TARGET_AMOUNT_VOLATILE]: {
              message: this.intl.t('international-out.quote.errors.exchange-rate.floating', {
                // @ts-expect-error
                sourceCurrency: this.quoteModel.sourceCurrency,
              }),
            },
          };

          if (code in errorCodesMapping) {
            // @ts-expect-error
            let { attribute, message } = errorCodesMapping[code];
            // @ts-expect-error
            this.quoteModel.errors[attribute ?? 'global'] = {
              code,
              message,
            };
          }

          return;
        }

        status = QUOTE_CREATION_STATUS.UNEXPECTED_ERROR;

        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(
          // @ts-expect-error
          this.errors.messageForStatus(error) || this.intl.t('toasts.errors.generic')
        );
      } finally {
        // @ts-expect-error
        this.lastQuoteCreationResult = {
          status,
          payload,
        };
      }
    }
  );

  createAndUpdateQuoteTask = dropTask(async payload => {
    // @ts-expect-error
    this.quoteModel.clearErrors();

    let response = await this.createQuoteTask.perform(payload);

    if (!response) return;

    let { quote, fees } = response;

    this.args.context.fees = fees;

    // @ts-expect-error
    if (!isEmpty(this.quoteModel.sourceAmount) || !isEmpty(this.quoteModel.targetAmount)) {
      this.#setQuote(quote, fees);
    } else {
      this.#setRate(quote.rate);
    }
  });

  getTargetCurrenciesTask = dropTask(async () => {
    try {
      return await this.internationalOutManager.getTargetCurrencies();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  onAmountUpdateTask = dropTask(async (amount, type) => {
    let amountAttribute = type === QuoteType.Source ? 'sourceAmount' : 'targetAmount';

    await timeout(DEBOUNCE_MS);

    // @ts-expect-error
    this.quoteModel[amountAttribute] = amount;

    // @ts-expect-error
    let { [amountAttribute]: amountValidations } = this.quoteModel.validations.attrs;
    if (isEmpty(amount) || amountValidations.isInvalid) {
      return;
    }

    this.segment.track(EVENTS.INPUT_AMOUNT, { amount_input_type: type });

    await this.createAndUpdateQuoteTask.perform({
      sourceAmount: type === QuoteType.Source ? amount : null,
      targetAmount: type === QuoteType.Target ? amount : null,
    });
  });

  onRetryTask = dropTask(async () => {
    if (!this.lastQuoteCreationResult) return;

    let { payload } = this.lastQuoteCreationResult;

    await this.createAndUpdateQuoteTask.perform({
      // @ts-expect-error
      ...payload,
      // @ts-expect-error
      sourceAmount: payload.sourceAmount || null,
      // @ts-expect-error
      targetAmount: payload.targetAmount || null,
    });
  });

  onSourceAmountUpdateTask = restartableTask(async amount => {
    await this.onAmountUpdateTask.perform(amount, QuoteType.Source);
  });

  onTargetAmountUpdateTask = restartableTask(async amount => {
    await this.onAmountUpdateTask.perform(amount, QuoteType.Target);
  });

  onTargetCurrencyUpdateTask = dropTask(async currencyCode => {
    // @ts-expect-error
    let { quoteModel } = this;
    let { sourceAmount, targetAmount, targetCurrency, type } = quoteModel;

    if (targetCurrency === currencyCode) return;

    quoteModel.targetCurrency = currencyCode;

    this.segment.track(EVENTS.SELECT_CURRENCY, { currency_type: QuoteType.Target });

    let { sourceAmount: sourceAmountValidations, targetAmount: targetAmountValidations } =
      quoteModel.validations.attrs;

    if (sourceAmountValidations.isInvalid || targetAmountValidations.isInvalid) return;

    let payload;

    if (type) {
      payload = {
        sourceAmount: type === QuoteType.Source ? sourceAmount : null,
        targetAmount: type === QuoteType.Target ? targetAmount : null,
      };
    }

    await this.createAndUpdateQuoteTask.perform(payload);
  });

  onTransitionTask = dropTask(async () => {
    // @ts-expect-error
    await this.quoteModel.validate();

    // @ts-expect-error
    let { sourceAmount, targetAmount, totalAmount } = this.quoteModel.validations.attrs;

    let hasValidationErrors =
      // @ts-expect-error
      isEmpty(this.quoteModel.sourceAmount) ||
      // @ts-expect-error
      isEmpty(this.quoteModel.targetAmount) ||
      sourceAmount.isInvalid ||
      targetAmount.isInvalid ||
      totalAmount.isInvalid;

    // @ts-expect-error
    let hasServerErrors = Boolean(Object.keys(this.quoteModel.errors).length);

    let isQuoteCreated = Boolean(this.args.context.quote);

    if (hasValidationErrors || hasServerErrors || !isQuoteCreated) {
      this.#scrollToError();
      return;
    }

    this.args.transitionToNext();
  });

  // @ts-expect-error
  #extendCurrencies = (currencies, newCurrenciesList = []) =>
    // @ts-expect-error
    currencies.map(currency =>
      extendCurrency({
        currency,
        // @ts-expect-error
        options: { locale: this.localeManager.locale, newCurrenciesList },
      })
    );

  #scrollToError() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  // @ts-expect-error
  #setQuote = (quote, fees) => {
    this.args.context.quote = quote;

    let { sourceAmount, targetAmount, rate, rateType, type } = quote;

    // @ts-expect-error
    this.quoteModel.setProperties({
      type,
      sourceAmount,
      targetAmount,
      rate: {
        value: rate,
        type: rateType,
      },
      fees: {
        total: fees.total,
      },
    });
  };

  // @ts-expect-error
  #setRate = rate => {
    this.args.context.quote = null;

    // @ts-expect-error
    this.quoteModel.setProperties({
      rate: {
        value: rate,
        type: RateType.Fixed,
      },
    });
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::New::Quote': typeof FlowsTransfersInternationalOutNewQuoteComponent;
  }
}
