/* import __COLOCATED_TEMPLATE__ from './managers-card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { NUM_OF_NAMES_TO_DISPLAY } from 'qonto/constants/budget';

interface BudgetDetailsManagersCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BudgetDetailsManagersCardComponent extends Component<BudgetDetailsManagersCardSignature> {
  @service declare intl: Services['intl'];

  get managersList() {
    // @ts-expect-error
    let { memberships, count } = this.args.managers;
    let translationVars = {
      count,
      managerNameOne: memberships[0]?.fullName,
      managerNameTwo: memberships[1]?.fullName,
      remaining: count - NUM_OF_NAMES_TO_DISPLAY,
    };

    if (count === 2) {
      return this.intl.t('team-budgets.section.card.two-supervisors.label', translationVars);
    }

    return this.intl.t('team-budgets.section.card.supervisors.label', translationVars);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budget::Details::ManagersCard': typeof BudgetDetailsManagersCardComponent;
  }
}
