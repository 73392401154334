/* import __COLOCATED_TEMPLATE__ from './select-field.hbs'; */
import Component from '@glimmer/component';

import { F24FormElementsLabel } from 'qonto/react/f24/components/form-elements';

interface SelectFieldSignature {
  Element: null;
  Args: {
    isDisabled?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class F24FormFieldsSelectFieldComponent extends Component<SelectFieldSignature> {
  F24FormElementsLabel = F24FormElementsLabel;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::FormFields::SelectField': typeof F24FormFieldsSelectFieldComponent;
  }
}
