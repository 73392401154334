/* import __COLOCATED_TEMPLATE__ from './display-panel.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DisplayPanelSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DisplayPanel = templateOnlyComponent<DisplayPanelSignature>();

export default DisplayPanel;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::DisplayPanel': typeof DisplayPanel;
  }
}
