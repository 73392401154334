/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface IndexSignature {
  Element: HTMLLIElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Index = templateOnlyComponent<IndexSignature>();

export default Index;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::Item::Index': typeof Index;
  }
}
