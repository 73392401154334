/* import __COLOCATED_TEMPLATE__ from './role-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface RoleSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RoleSelectionComponent extends Component<RoleSelectionSignature> {
  @service declare segment: Services['segment'];

  @action showComparison() {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    context.showComparison = true;
    if (context.isEditingMember) {
      this.segment.track('member_role_update_compare');
    } else {
      this.segment.track('team-invite_role-comparison_viewed');
    }
    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::RoleSelection': typeof RoleSelectionComponent;
  }
}
