/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action, get } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { alias } from 'macro-decorators';

// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface F24FormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24FormComponent extends Component<F24FormSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  @service declare store: Services['store'];

  // @ts-expect-error
  @alias('args.f24Order') f24Order;
  // @ts-expect-error
  @alias('args.disabled') disabled;

  // @ts-expect-error
  @action scrollIntoView({ element }) {
    // add 32px offset so looks nicer
    scrollIntoView(element, { offsetTop: 32 });
  }

  // @ts-expect-error
  @action onUpdatePayerAgent(attribute, value) {
    // @ts-expect-error
    let { payerAgent } = this.args.f24Order;
    payerAgent.set(attribute, value);
  }

  // @ts-expect-error
  @action onUpdatePayer(attribute, value) {
    // @ts-expect-error
    let { payer } = this.args.f24Order;
    payer.set(attribute, value);
  }

  // @ts-expect-error
  @action onUpdateItem(item, attribute, value) {
    item.set(attribute, value);
  }

  @action addErarioItem() {
    // @ts-expect-error
    let { erario } = this.args.f24Order.taxes;
    erario.taxList.push(this.store.createRecord('f24/erario-tax-item'));
  }

  @action addInailItem() {
    // @ts-expect-error
    let { inailTaxList } = this.args.f24Order.taxes;
    inailTaxList.push(this.store.createRecord('f24/inail-tax-item'));
  }

  @action addInpsItem() {
    // @ts-expect-error
    let { inpsTaxList } = this.args.f24Order.taxes;
    inpsTaxList.push(this.store.createRecord('f24/inps-tax-item'));
  }

  @action addRegioniItem() {
    // @ts-expect-error
    let { regioniTaxList } = this.args.f24Order.taxes;
    regioniTaxList.push(this.store.createRecord('f24/regioni-tax-item'));
  }

  @action addImuItem() {
    // @ts-expect-error
    let { imu } = this.args.f24Order.taxes;
    imu.taxList.push(this.store.createRecord('f24/imu-tax-item'));
  }

  @action addOthersItem() {
    // @ts-expect-error
    let { taxList } = this.args.f24Order.taxes.others;
    taxList.push(this.store.createRecord('f24/others-tax-item'));
  }

  // @ts-expect-error
  @action removeListItem(listName, index) {
    // @ts-expect-error
    get(this.args.f24Order, listName).splice(index, 1);
  }

  // @ts-expect-error
  nonZeroTaxListItem({ compensationAmount, taxAmount }) {
    return Boolean(compensationAmount) || Boolean(taxAmount);
  }

  get shouldHideErario() {
    return (
      this.disabled &&
      !this.f24Order.taxes.erario?.taxList.some(this.nonZeroTaxListItem) &&
      !this.f24Order.taxes.erario?.deedCode &&
      !this.f24Order.taxes.erario?.officeCode
    );
  }

  get shouldHideInps() {
    return this.disabled && !this.f24Order.taxes.inpsTaxList?.some(this.nonZeroTaxListItem);
  }

  get shouldHideRegioni() {
    return this.disabled && !this.f24Order.taxes.regioniTaxList?.some(this.nonZeroTaxListItem);
  }

  get shouldHideImu() {
    return (
      this.disabled &&
      !this.f24Order.taxes.imu?.taxList.some(this.nonZeroTaxListItem) &&
      !this.f24Order.taxes.imu?.operationCode &&
      !this.f24Order.taxes.imu?.deductionAmount
    );
  }

  get shouldHideInail() {
    return (
      this.disabled &&
      !this.f24Order.taxes.inailTaxList?.some(this.nonZeroTaxListItem) &&
      !this.f24Order.taxes.others?.taxList.some(this.nonZeroTaxListItem) &&
      !this.f24Order.taxes.others?.institutionCode
    );
  }

  get hasFinalChecksFailedError() {
    // @ts-expect-error
    return this.f24Order.errors.errorsFor('/').some(e => e.message === 'generic_error');
  }

  get hasErrorsContribuente() {
    return this.hasErrorsPayer || this.hasErrorsPayerAgent;
  }

  get hasErrorsPayer() {
    return (
      this.f24Order.payer?.errors.length ||
      this.f24Order.payer?.address.errors.length ||
      this.f24Order.errors.get('payer')?.length
    );
  }

  get hasErrorsPayerAgent() {
    return (
      this.f24Order.payerAgent?.errors.length || this.f24Order.errors.get('payerAgent')?.length
    );
  }

  get hasErrorsErario() {
    return (
      this.f24Order.taxes.erario?.errors.length ||
      // @ts-expect-error
      this.f24Order.taxes.erario?.taxList.some(t => t.errors.length) ||
      this.f24Order.taxes.errors.get('erario')?.length
    );
  }

  get hasErrorsInps() {
    return (
      // @ts-expect-error
      this.f24Order.taxes.inpsTaxList.some(t => t.errors.length) ||
      this.f24Order.errors.get('taxes/inps/taxList')?.length
    );
  }

  get hasErrorsRegioni() {
    return (
      // @ts-expect-error
      this.f24Order.taxes.regioniTaxList.some(t => t.errors.length) ||
      this.f24Order.errors.get('taxes/regioni/taxList')?.length
    );
  }

  get hasErrorsImu() {
    return (
      this.f24Order.taxes.imu?.errors.length ||
      // @ts-expect-error
      this.f24Order.taxes.imu?.taxList.some(t => t.errors.length) ||
      this.f24Order.taxes.errors.get('imu')?.length
    );
  }

  get hasErrorsInail() {
    return (
      // @ts-expect-error
      this.f24Order.taxes.inailTaxList.some(t => t.errors.length) ||
      this.f24Order.errors.get('taxes/otherInstitutions/inail/taxList')?.length
    );
  }

  get hasErrorsOthers() {
    return (
      this.f24Order.taxes.others?.errors.length ||
      // @ts-expect-error
      this.f24Order.taxes.others?.taxList.some(t => t.errors.length) ||
      this.f24Order.errors.get('taxes/otherInstitutions/others')?.length
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Form': typeof F24FormComponent;
  }
}
