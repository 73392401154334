export default {
  "row": "EC body-1",
  "cell": "Ek",
  "counterparty": "EJ",
  "counterparty-icon": "Es",
  "avatar": "EF",
  "icon": "Eg",
  "counterparty-content": "ED",
  "item-counterparty-name": "EV",
  "counterparty-name": "EL body-1",
  "counterparty-status": "ET",
  "member": "Ez",
  "method": "Ea",
  "operation-date": "EH",
  "amount": "EO",
  "remove-transaction": "Em",
  "menu-item": "Ep",
  "remove": "Ex",
  "tooltip": "Ew",
  "empty": "EN"
};
