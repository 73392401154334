/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { CARD_LEVELS, FORMAT_PRICE_OPTIONS, ORIGIN } from 'qonto/constants/cards';
import { importAsset } from 'qonto/helpers/import-asset';
import { CardReviewPanel } from 'qonto/react/cards/components/card-review-panel';
import { getCardFullName } from 'qonto/utils/cards/card-names';

interface FlowsCardsReviewSignature {
  // The arguments accepted by the component
  Args: {
    isFlowUpsell?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsCardsReview extends Component<FlowsCardsReviewSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  cardlevels = CARD_LEVELS;
  cardReviewPanel = CardReviewPanel;

  get cardPanelInfo() {
    return (
      // @ts-expect-error
      this.args.cardPanelInfo ?? {
        // @ts-expect-error
        icon: `card-${this.args.context.card.cardDesign}`,
        // @ts-expect-error
        displayNameKey: this.args.context.card.cardDesign,
        // @ts-expect-error
        displayTypeKey: this.args.context.card.cardLevel,
        // @ts-expect-error
        displayTypeOfPrint: this.args.context.card.typeOfPrint,
      }
    );
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get estimatedCosts() {
    // @ts-expect-error
    let { estimates, card } = this.args.context;
    return estimates[card.cardLevel] || {};
  }

  get hasCardDiscount() {
    return (
      this.estimatedCosts.discount_finish_date &&
      this.estimatedCosts.discounted_monthly_cost !== this.estimatedCosts.monthly_cost
    );
  }

  get deliveryCardCost() {
    return this.estimatedCosts.delivery_amount_cents / 100;
  }

  get monthlyCostSublabel() {
    if (this.estimatedCosts.amount > 0) {
      return this.intl.t('cards.steps.review.card-cost.sublabel', {
        computed_amount: this._formatPrice(this.estimatedCosts.amount || 0),
        legalCountry: this.legalCountry,
      });
    }

    return this.intl.t('cards.steps.review.card-cost.sublabel-free');
  }

  get monthlyCost() {
    if (this.estimatedCosts.amount > 0 || this.estimatedCosts.discount_finish_date) {
      return htmlSafe(
        `${this._formatPrice(this.estimatedCosts.monthly_cost || 0)} <span>${this.intl.t(
          'cards.steps.review.card-cost.pricing-period',
          {
            legalCountry: this.legalCountry,
          }
        )}</span>`
      );
    }

    return this.intl.t('cards.steps.review.card-cost.free');
  }

  get deliveryCostSublabel() {
    return this.deliveryCardCost > 0
      ? this.intl.t('cards.steps.review.printing-delivery-cost.sublabel-extra')
      : this.intl.t('cards.steps.review.printing-delivery-cost.sublabel-included');
  }

  get deliveryCost() {
    return this.deliveryCardCost > 0
      ? this._formatPrice(this.deliveryCardCost || 0)
      : this.intl.t('cards.steps.review.printing-delivery-cost.free');
  }

  get shouldShowSpecificDays() {
    // @ts-expect-error
    return this.args.context.card.activeDaysOption;
  }

  get shouldShowSpecificCategories() {
    return (
      // @ts-expect-error
      this.abilities.can('view restrictive categories card') && this.args.context.card.categoryTags
    );
  }

  get shouldShowMontlyCostTooltip() {
    return !this.estimatedCosts.discount_finish_date && this.estimatedCosts.amount > 0;
  }

  get displayedCardName() {
    return getCardFullName(
      this.cardPanelInfo.displayTypeKey,
      this.cardPanelInfo.displayNameKey,
      this.intl
    );
  }

  get cardImageUri() {
    return importAsset([`/illustrations/cards/${this.cardPanelInfo.icon}.svg`]);
  }

  get isOriginMemberInvitee() {
    // @ts-expect-error
    return this.args.context.origin === ORIGIN.MEMBER_INVITATION;
  }

  get shouldDisplayEditCardSettings() {
    // @ts-expect-error
    return this.args.context.displayEditCardSettings;
  }

  @action
  handleEditCardSettingsClick() {
    // @ts-expect-error
    let { card, flowTrackingOrigin } = this.args.context;
    this.segment.track('cards_edit-settings_clicked', {
      origin: flowTrackingOrigin,
      card_type: card.cardLevel,
    });
    // @ts-expect-error
    this.args.transitionToNext();
  }

  // @ts-expect-error
  _formatPrice(price) {
    // @ts-expect-error
    return this.intl.formatNumber(price, FORMAT_PRICE_OPTIONS);
  }

  @action
  handleClick() {
    // @ts-expect-error
    let { transitionToNext, context } = this.args;
    let { card, flowTrackingOrigin, isUserCardHolder } = context;
    this.segment.track('cards_order_review_completed', {
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      card_type: card.cardLevel,
      origin: flowTrackingOrigin,
    });
    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Review': typeof FlowsCardsReview;
  }
}
