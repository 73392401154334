/* import __COLOCATED_TEMPLATE__ from './context.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';

interface ContextSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class ContextComponent extends Component<ContextSignature> {
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare segment: Services['segment'];

  get planPriceWithVAT() {
    return this.subscriptionManager.planPriceWithVAT.value;
  }

  get isProRatedAmount() {
    let nextSubscriptionBillingDate = dayjs(
      this.subscriptionManager.currentSubscription?.nextSubscriptionBillingDate
    );
    if (nextSubscriptionBillingDate.isSame(nextSubscriptionBillingDate.startOf('month'))) {
      return false;
    }
    return true;
  }

  get nextBillingDate() {
    return dateToken({
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.COMPACT,
      date: String(this.subscriptionManager.currentSubscription?.nextSubscriptionBillingDate),
    });
  }

  get recurrence() {
    if (
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    ) {
      return this.intl.t('payment-activation-top-up.context.recurrence-annual');
    }
    return this.intl.t('payment-activation-top-up.context.recurrence-monthly');
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan.localName;
  }

  @action
  handleClick() {
    // @ts-expect-error
    let { transitionToNext } = this.args;

    this.segment.track('interstitial_top_up_clicked');

    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::PaymentActivation::Context': typeof ContextComponent;
  }
}
