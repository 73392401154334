/* import __COLOCATED_TEMPLATE__ from './restrictions.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge, Button } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface CardSidebarRestrictionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardSidebarRestrictionsComponent extends Component<CardSidebarRestrictionsSignature> {
  @service declare sentry: Services['sentry'];
  @service declare abilities: Services['abilities'];
  @service declare segment: Services['segment'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];
  button = Button;
  badge = Badge;

  BADGE_TYPE = BADGE_TYPE;
  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.trackUpsellViewed();
  }

  trackUpsellViewed() {
    if (this.organizationManager.organization?.hasCardAcsUpsellAb) {
      if (
        // @ts-expect-error
        this.abilities.can('update operational card settings card', this.args.card) &&
        this.abilities.can('view restrictions card')
      ) {
        return;
      }

      if (this.isUpsellShown || this.hasAvailableFreeTrial) {
        this.segment.track('upsell_advanced-card-settings_viewed');
      }
    }
  }

  get showActiveDaysOption() {
    // @ts-expect-error
    let activeDaysOptions = this.args.card?.activeDaysOption;
    let canViewRestrictions = this.abilities.can('update settings card');

    return activeDaysOptions || canViewRestrictions;
  }

  get showCategoryTagsOption() {
    if (this.abilities.cannot('view restrictive categories card')) {
      return false;
    }

    // @ts-expect-error
    let hasCategoryTags = this.args.card?.hasCategoryTags;
    let canViewRestrictions = this.abilities.can('update settings card');

    return hasCategoryTags || canViewRestrictions;
  }

  get canResetSettings() {
    let canUpdateSettings = this.abilities.can('update settings card');
    let canViewAdvancedSettings = this.abilities.can('view restrictions card');
    return !canViewAdvancedSettings && canUpdateSettings;
  }

  get isCategoriesResetIconShown() {
    // @ts-expect-error
    return this.args.card?.hasCategoryTags && this.canResetSettings;
  }

  get isDayResetIconShown() {
    // @ts-expect-error
    return this.args.card?.activeDaysOption && this.canResetSettings;
  }

  get isUpsellShown() {
    return (
      !this.abilities.can('view restrictions card') && this.abilities.can('update subscription')
    );
  }

  get hasAvailableFreeTrial() {
    return this.upsellManager.hasAvailableFreeTrial('card_advanced_settings');
  }

  @action
  // @ts-expect-error
  onReset(resetType) {
    this.segment.track('reset_advanced-card-settings_clicked', {
      reset_type: resetType,
    });
    this.modals.open('card/modals/remove-restrictions', {
      // @ts-expect-error
      card: this.args.card,
      resetType,
      title:
        resetType === 'days-of-use'
          ? this.intl.t('cards.modals.remove-restrictions.days.title')
          : this.intl.t('cards.modals.remove-restrictions.category.title'),
      description:
        resetType === 'days-of-use'
          ? this.intl.t('cards.modals.remove-restrictions.days.description')
          : this.intl.t('cards.modals.remove-restrictions.category.description'),
    });
  }

  onUpsellCardSettingsTask = dropTask(async () => {
    try {
      await this.upsellManager.upgradeRecommendationWithTransition('card_advanced_settings');
      this.segment.track('upsell_advanced-card-settings_clicked');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::Restrictions': typeof CardSidebarRestrictionsComponent;
  }
}
