/* import __COLOCATED_TEMPLATE__ from './payer-details-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

// @ts-expect-error
import { PAYER_OPTIONS } from 'qonto/constants/nrc-payments';

interface PayerDetailsFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PayerDetailsFormComponent extends Component<PayerDetailsFormSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  @tracked selectedOption = PAYER_OPTIONS.YOURSELF;
  @tracked legalName = '';
  @tracked documentId = '';
  @tracked acronym = '';

  @tracked legalNameError = '';
  @tracked documentIdError = '';
  @tracked acronymError = '';

  // @ts-expect-error
  @reads('args.context.nrcPayment') nrcPayment;

  constructor(owner: unknown, args: PayerDetailsFormSignature['Args']) {
    super(owner, args);
    this._initialPrefillFields();
    this.segment.track('nrc_taxpayer-details_opened');
  }

  _initialPrefillFields() {
    if (this.nrcPayment?.isPayingForSomeoneElse) {
      this.selectedOption = PAYER_OPTIONS.SOMEONE_ELSE;
      this.legalName = this.nrcPayment.legalName;
      this.documentId = this.nrcPayment.documentId;
      this.acronym = this.nrcPayment.acronym;
    } else {
      this.selectedOption = PAYER_OPTIONS.YOURSELF;
      this.prefillFormFields();
    }
  }

  get PAYER_OPTIONS() {
    return PAYER_OPTIONS;
  }

  get showAcronymField() {
    let isSoleProprietor = this.organizationManager.organization?.legalCode === 'ESC01';
    return this.selectedOption === PAYER_OPTIONS.SOMEONE_ELSE || isSoleProprietor;
  }

  @action
  // @ts-expect-error
  updatePayer(option) {
    this.selectedOption = option;
    this.clearFormInputs();
    if (option === PAYER_OPTIONS.YOURSELF) {
      this.prefillFormFields();
      this.segment.track('nrc_taxpayer-details_yourself_selected');
    } else {
      this.segment.track('nrc_taxpayer-details_others_selected');
    }
  }

  clearFormInputs() {
    this.clearErrors();
    this.legalName = '';
    this.documentId = '';
    this.acronym = '';
  }

  @action
  // @ts-expect-error
  updateLegalName(value) {
    this.legalName = value;
    this.legalNameError = '';
  }

  @action
  // @ts-expect-error
  updateDocumentId(value) {
    this.documentId = value;
    this.documentIdError = '';
  }

  @action
  // @ts-expect-error
  updateAcronym(value) {
    this.acronym = value;
    this.acronymError = '';
  }

  @action
  submitPayerDetails() {
    this.clearErrors();

    if (!this.legalName) {
      this.legalNameError = this.intl.t('aeat.pay.taxpayer-details.errors.legal-name.empty');
    }

    let nifRegex = /^(?:[A-Z]\d{7}[0-9|A-Z]|\d{8}[A-Z])$/;
    if (!this.documentId) {
      this.documentIdError = this.intl.t('aeat.pay.taxpayer-details.errors.nif.empty');
    } else if (!nifRegex.test(this.documentId)) {
      this.documentIdError = this.intl.t('aeat.pay.taxpayer-details.errors.nif.format');
    }

    if (
      this.acronym.length > 0 &&
      (this.acronym.length !== 4 || !/^[a-zA-Z0-9]{4}$/.test(this.acronym))
    ) {
      this.acronymError = this.intl.t('aeat.pay.taxpayer-details.errors.acronym.format');
    }

    if (this.legalNameError || this.documentIdError || this.acronymError) {
      return;
    }

    // @ts-expect-error
    this.args.context.nrcPayment.isPayingForSomeoneElse =
      this.selectedOption === PAYER_OPTIONS.SOMEONE_ELSE;
    // @ts-expect-error
    this.args.context.nrcPayment.legalName = this.legalName;
    // @ts-expect-error
    this.args.context.nrcPayment.documentId = this.documentId;
    // @ts-expect-error
    this.args.context.nrcPayment.acronym = this.acronym;
    this.segment.track('nrc_taxpayer-details_completed');
    // @ts-expect-error
    this.args.transitionToNext();
  }

  clearErrors() {
    this.legalNameError = '';
    this.documentIdError = '';
    this.acronymError = '';
  }

  prefillFormFields() {
    let { organization } = this.organizationManager;
    if (organization) {
      this.legalName = organization.legalName || '';
      this.documentId = organization.legalNumber || '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::NrcPayment::PayerDetailsForm': typeof PayerDetailsFormComponent;
  }
}
