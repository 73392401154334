/* import __COLOCATED_TEMPLATE__ from './imu.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { ERROR_KEYS } from 'qonto/constants/f24';
import { F24FormFieldsCheckboxField } from 'qonto/react/f24/components/checkbox-field';

const MAX_ITEMS_COUNT = 4;

export const FIELD_NAME_KEYS = {
  receiverCode: 'f24.manual-declaration.module.imu.section1.field2',
  isAmendment: 'f24.manual-declaration.module.imu.section1.checkbox1',
  isRealEstateVariation: 'f24.manual-declaration.module.imu.section1.checkbox2',
  isDeposit: 'f24.manual-declaration.module.imu.section1.checkbox3',
  isFinalPayment: 'f24.manual-declaration.module.imu.section1.checkbox4',
  realEstateCount: 'f24.manual-declaration.module.imu.section1.field3',
  taxCode: 'f24.manual-declaration.module.imu.section1.field4',
  referenceMonth: 'f24.manual-declaration.module.imu.section1.field5',
  referenceYear: 'f24.manual-declaration.module.imu.section1.field6',
  taxAmount: 'f24.manual-declaration.module.imu.section1.field7',
  compensationAmount: 'f24.manual-declaration.module.imu.section1.field8',
};

interface F24FormUmiSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class F24FormUmiComponent extends Component<F24FormUmiSignature> {
  f24FormFieldsCheckboxField = F24FormFieldsCheckboxField;
  fieldNameKeys = FIELD_NAME_KEYS;

  @action
  getErrorKey(code: keyof typeof ERROR_KEYS) {
    return ERROR_KEYS[code] ?? ERROR_KEYS.generic_error;
  }

  get isAddItemDisabled() {
    // @ts-expect-error
    return this.args.imu.taxList.length >= MAX_ITEMS_COUNT;
  }

  get totalTax() {
    return this.sumUp('taxAmount');
  }

  get totalCompensation() {
    return this.sumUp('compensationAmount');
  }

  get netTotal() {
    return Number(this.totalTax - this.totalCompensation).toFixed(2);
  }

  // @ts-expect-error
  sumUp(attributeName) {
    return (
      // @ts-expect-error
      this.args.imu?.taxList
        // @ts-expect-error
        .reduce((sum, item) => {
          return Number(item[attributeName] || 0) + sum;
        }, 0)
        .toFixed(2)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Form::Imu': typeof F24FormUmiComponent;
  }
}
