/* import __COLOCATED_TEMPLATE__ from './review-renewal.hbs'; */
import { set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

import { CARD_LEVELS, FORMAT_PRICE_OPTIONS } from 'qonto/constants/cards';
import { getMonthlyCost, getUpsellFreeUntil } from 'qonto/utils/cards/card-costs';
import { getDefaultDesignByLevel } from 'qonto/utils/cards/design.ts';

interface FlowsCardsReviewRenewalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsReviewRenewal extends Component<FlowsCardsReviewRenewalSignature> {
  @service declare intl: Services['intl'];

  get cardPanelInfo() {
    // @ts-expect-error
    let { cardDesign, typeOfPrint } = this.args.context;
    return {
      icon: `card-${cardDesign || this._getRenewalCardDesign()}`,
      displayNameKey: cardDesign || this._getRenewalCardDesign(),
      displayTypeKey: this._getRenewalCardlevel(),
      displayTypeOfPrint: typeOfPrint,
    };
  }

  get shippingAddress() {
    // @ts-expect-error
    let { address } = this.args.context;

    // TODO: use address model in all steps of renewal flow
    // the address is serialized in other steps where the renewTask is called
    // so we cannot use the fullAddress computed from the address model
    // we should use the address model everywhere and serialize it only in the card/providers/renew where it is actually needed
    // to avoid to add too much complexity with the FF, we duplicate temporary the fullAddress computation here
    let addressFirstPart = [
      address.first_line,
      address.second_line,
      address.third_line,
      address.zipcode,
    ]
      .filter(part => !isEmpty(part))
      .join(', ');
    let addressSecondPart = [address.city, address.country]
      .filter(part => !isEmpty(part))
      .join(', ');

    return `${addressFirstPart} ${addressSecondPart}`;
  }

  get shouldDisplayCosts() {
    // @ts-expect-error
    let { card, cardUpsellLevel } = this.args.context;
    return card.cardLevel !== CARD_LEVELS.STANDARD || cardUpsellLevel;
  }

  get upsellFreeUntilDate() {
    // @ts-expect-error
    return getUpsellFreeUntil(this._getRenewalCardlevel(), this.args.context.estimates);
  }

  get upsellMonthlyCost() {
    return this.intl.formatNumber(
      // @ts-expect-error
      getMonthlyCost(this._getRenewalCardlevel(), this.args.context.estimates),
      // @ts-expect-error
      FORMAT_PRICE_OPTIONS
    );
  }

  renewTask = task(async renewTask => {
    let { address, cardDesign, cardId, cardUpsellLevel, shipToBusiness, typeOfPrint } =
      // @ts-expect-error
      this.args.context;

    // errors are handled in the renewTask itself
    let response = await renewTask.perform(
      address,
      shipToBusiness,
      cardUpsellLevel,
      cardId,
      cardDesign,
      typeOfPrint
    );

    // @ts-expect-error
    set(this.args.context, 'renewalCardsCount', response?.renewalCardsCount);
    // @ts-expect-error
    set(this.args.context, 'renewalCard', response?.renewalCard);

    if (response?.renewalCardsCount) {
      // @ts-expect-error
      this.args.transitionToNext();
    }
  });

  _getRenewalCardlevel() {
    // @ts-expect-error
    let { card, cardUpsellLevel } = this.args.context;
    return cardUpsellLevel || card.cardLevel;
  }

  _getRenewalCardDesign() {
    return getDefaultDesignByLevel(this._getRenewalCardlevel());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ReviewRenewal': typeof FlowsCardsReviewRenewal;
  }
}
