export default {
  "row": "vq",
  "active": "vZ",
  "cell": "vR",
  "empty": "vQ",
  "supplier": "ve",
  "supplier-content": "vB",
  "declined": "vE",
  "total-repayment": "vd body-1",
  "amount": "vI",
  "supplier-avatar": "vt mr-16",
  "financing-status": "vM body-2",
  "installments": "vP body-2",
  "overflow-hidden": "vl",
  "ellipsis": "vX",
  "completed-on": "vC",
  "financed-amount": "vk body-2"
};
