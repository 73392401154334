/* import __COLOCATED_TEMPLATE__ from './erario.hbs'; */
import { action, get } from '@ember/object';
import Component from '@glimmer/component';

import { ERROR_KEYS } from 'qonto/constants/f24';

const MAX_ITEMS_COUNT = 6;

const FIELD_NAME_KEYS = {
  taxCode: 'f24.manual-declaration.module.erario.field1',
  referenceMonth: 'f24.manual-declaration.module.erario.field2',
  referenceYear: 'f24.manual-declaration.module.erario.field3',
  taxAmount: 'f24.manual-declaration.module.erario.field4',
  compensationAmount: 'f24.manual-declaration.module.erario.field5',
  officeCode: 'f24.manual-declaration.module.erario.field6',
  deedCode: 'f24.manual-declaration.module.erario.field7',
};

interface F24FormErarioSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class F24FormErario extends Component<F24FormErarioSignature> {
  fieldNameKeys = FIELD_NAME_KEYS;

  @action
  getErrorKey(code: keyof typeof ERROR_KEYS) {
    return ERROR_KEYS[code] ?? ERROR_KEYS.generic_error;
  }

  get hasOfficeCodeOrDeedCodeErrors() {
    // @ts-expect-error
    let { erario } = this.args;
    return erario.errors.get('officeCode')?.length || erario.errors.get('deedCode')?.length;
  }

  get isAddItemDisabled() {
    // @ts-expect-error
    return this.args.erario.taxList.length >= MAX_ITEMS_COUNT;
  }

  get totalTax() {
    return this.sumUp('taxAmount');
  }

  get totalCompensation() {
    return this.sumUp('compensationAmount');
  }

  get netTotal() {
    return Number(this.totalTax - this.totalCompensation).toFixed(2);
  }

  // @ts-expect-error
  sumUp(attributeName) {
    return (
      // @ts-expect-error
      this.args.erario?.taxList
        // @ts-expect-error
        .map(item => get(item, attributeName))
        // @ts-expect-error
        .filter(value => value)
        // @ts-expect-error
        .reduce((acc, value) => acc + Number(value), 0)
        .toFixed(2)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Form::Erario': typeof F24FormErario;
  }
}
