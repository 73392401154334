/* import __COLOCATED_TEMPLATE__ from './slider.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

interface GenerateLogoSliderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class GenerateLogoSliderComponent extends Component<GenerateLogoSliderSignature> {
  badgeHighlight = BadgeHighlight;

  @service declare segment: Services['segment'];

  get totalImages() {
    // @ts-expect-error
    return this.args.images.length;
  }

  get currentImage() {
    // @ts-expect-error
    return this.args.images[this.args.currentIndex];
  }

  get currentImageUrl() {
    return this.currentImage.url;
  }

  get currentImageAltText() {
    return this.currentImage.prompt;
  }

  get currentImagePosition() {
    // @ts-expect-error
    return `${this.args.currentIndex + 1} / ${this.totalImages}`;
  }

  // eslint-disable-next-line require-await
  showNextImageTask = task(async () => {
    // @ts-expect-error
    let newIndex = this.args.currentIndex < this.totalImages - 1 ? this.args.currentIndex + 1 : 0;
    // @ts-expect-error
    this.args.onIndexChange(newIndex);
  });

  // eslint-disable-next-line require-await
  showPreviousImageTask = task(async () => {
    // @ts-expect-error
    let newIndex = this.args.currentIndex > 0 ? this.args.currentIndex - 1 : this.totalImages - 1;
    // @ts-expect-error
    this.args.onIndexChange(newIndex);
  });

  @action
  trackDownloadEvent() {
    // @ts-expect-error
    this.args.onDownloadClicked?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GenerateLogo::Slider': typeof GenerateLogoSliderComponent;
  }
}
