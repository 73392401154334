/* import __COLOCATED_TEMPLATE__ from './upload-evidence.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface UploadEvidenceSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const UploadEvidence = templateOnlyComponent<UploadEvidenceSignature>();

export default UploadEvidence;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::UploadEvidence': typeof UploadEvidence;
  }
}
