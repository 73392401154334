/* import __COLOCATED_TEMPLATE__ from './trigger.hbs'; */
import Component from '@glimmer/component';

import { LanguageSelectTrigger } from 'qonto/react/components/bank-details/details-download/language-select/trigger';

interface LanguageSelectTriggerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class LanguageSelectTriggerComponent extends Component<LanguageSelectTriggerSignature> {
  languageSelectTrigger = LanguageSelectTrigger;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::DetailsDownload::LanguageSelect::Trigger': typeof LanguageSelectTriggerComponent;
  }
}
