/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface CapitalDepositLegalEntityCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositLegalEntityCard extends Component<CapitalDepositLegalEntityCardSignature> {
  @action
  // @ts-expect-error
  handleDelete(toggle) {
    // @ts-expect-error
    this.args.onDelete();
    toggle();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CapitalDeposit::LegalEntity::Card': typeof CapitalDepositLegalEntityCard;
  }
}
