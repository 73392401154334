/* import __COLOCATED_TEMPLATE__ from './failure.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Failure } from 'qonto/react/financing/components/flows/pay-later/application/failure';

interface FlowsFinancingPayLaterApplicationFailureSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterApplicationFailureComponent extends Component<FlowsFinancingPayLaterApplicationFailureSignature> {
  failure = Failure;

  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: FlowsFinancingPayLaterApplicationFailureSignature['Args']) {
    super(owner, args);
    this.segment.track('pay-later_signed_error_displayed', { origin: 'cockpit' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::Failure': typeof FlowsFinancingPayLaterApplicationFailureComponent;
  }
}
