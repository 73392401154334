export default {
  "header-cell": "lv",
  "header-content": "lo caption-bold",
  "active": "ln",
  "hidden": "li",
  "empty": "lA",
  "align-right": "lY",
  "no-padding": "lh",
  "request-type": "lf",
  "amount": "lK",
  "approval": "lG"
};
