/* import __COLOCATED_TEMPLATE__ from './choose-card-onboarding.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { waitForProperty } from 'ember-concurrency';
import window from 'ember-window-mock';

import { CARD_LEVELS_TRACKING, CUSTOMIZABLE_CARDS_LEVELS } from 'qonto/constants/cards';
import { getDefaultDesignByLevel } from 'qonto/utils/cards/design';

interface FlowsCardsChooseCardOnboardingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsChooseCardOnboarding extends Component<FlowsCardsChooseCardOnboardingSignature> {
  @service declare abilities: Services['abilities'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare payment: Services['payment'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare theme: Services['theme'];

  @tracked isVideoIntroFinished = false;

  INTRO_VIDEO_ID = `${guidFor(this)}-intro-video`;

  constructor(owner: unknown, args: FlowsCardsChooseCardOnboardingSignature['Args']) {
    super(owner, args);
    this.segment.track('cards_order_onboarding_landing');
    if (this.shouldPlayVideoIntro) {
      this.initVideoIntro();
    }
  }

  get shouldPlayVideoIntro() {
    // @ts-expect-error
    return this.args.context.shouldAnimateCards && !this.isVideoIntroFinished;
  }

  async initVideoIntro() {
    // @ts-expect-error
    await waitForProperty(this, 'INTRO_VIDEO_ID');

    if (macroCondition(isTesting())) {
      this.isVideoIntroFinished = true;
    }

    let hasReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (hasReducedMotion) {
      this.isVideoIntroFinished = true;
    }

    // This is to prevent video from not playing
    // because of browsers being overzealous with sound and autoplay
    // @ts-expect-error
    document.getElementById(this.INTRO_VIDEO_ID).muted = true;
  }

  @action
  setVideoIntroAsFinished() {
    this.isVideoIntroFinished = true;
  }

  @action
  // @ts-expect-error
  chooseCard(cardLevel) {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let { card, cardsLimits, flowTrackingOrigin } = context;

    let { payment_monthly_limit_maximum, atm_monthly_limit_maximum } = cardsLimits[cardLevel];

    card.setProperties({
      atmMonthlyLimit: atm_monthly_limit_maximum,
      cardLevel,
      paymentMonthlyLimit: payment_monthly_limit_maximum,
    });

    this.segment.track('cards_order_physical_type_chosen', {
      origin: flowTrackingOrigin,
      card_type: CARD_LEVELS_TRACKING[cardLevel],
    });

    context.shouldAnimateCards = false;
    context.showCardCustomization = CUSTOMIZABLE_CARDS_LEVELS.includes(cardLevel);
    let cardDesign = context.showCardCustomization ? null : getDefaultDesignByLevel(cardLevel);

    card.setProperties({
      cardDesign,
      typeOfPrint: null,
    });

    transitionToNext(context);
  }

  @action
  onSkipToTopupClick() {
    this.segment.track('onboarding_cards_skip_to_top_up');
    this.payment.onboardingCardSkipped = true;
    this.organizationManager.flagMembershipOnboarded();

    this.router.transitionTo(
      'onboarding.topup.amount',
      // @ts-expect-error
      this.args.context.card.organization.get('id')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCardOnboarding': typeof FlowsCardsChooseCardOnboarding;
  }
}
