export default {
  "header-cell": "LZ",
  "type": "LR",
  "request-date": "LQ",
  "amount": "Le",
  "quick-actions": "LB",
  "empty": "LE",
  "header-content": "Ld caption-bold",
  "active": "LI"
};
