/* import __COLOCATED_TEMPLATE__ from './banner.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BannerSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Banner = templateOnlyComponent<BannerSignature>();

export default Banner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Banner': typeof Banner;
  }
}
