/* import __COLOCATED_TEMPLATE__ from './pin-code.hbs'; */
import Component from '@glimmer/component';

interface CardModalsPinCodeSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardModalsPinCodeComponent extends Component<CardModalsPinCodeSignature> {
  get pinCodeCharacters() {
    // @ts-expect-error
    return this.args.data.pinCode?.split('');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::PinCode': typeof CardModalsPinCodeComponent;
  }
}
