/* import __COLOCATED_TEMPLATE__ from './connector-card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ConnectorCardSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ConnectorCard = templateOnlyComponent<ConnectorCardSignature>();

export default ConnectorCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::ConnectorCard': typeof ConnectorCard;
  }
}
