export default {
  "sidebar-header": "TC",
  "sidebar-header__top": "Tk",
  "sidebar-header__close-button": "TJ",
  "close-button": "Ts",
  "sidebar-header__body": "TF",
  "sidebar-header__content": "Tg",
  "sidebar-header__date": "TD",
  "infobox": "TV",
  "infobox--link": "TL",
  "ghost": "TT",
  "fail": "Tz",
  "success": "Ta"
};
