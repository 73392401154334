/* import __COLOCATED_TEMPLATE__ from './upload.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { buildWaiter } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Button } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { invariant } from 'es-toolkit';
import { reads } from 'macro-decorators';
import { TrackedObject } from 'tracked-built-ins';

import { XlsFile } from 'qonto/lib/xls-file';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const FILE_MAX_SIZE = 5 * 1e6; // 5MB

let waiter = buildWaiter('upload-waiter');

interface FlowsInvoicesProductsImportUploadSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class FlowsInvoicesProductsImportUpload extends Component<FlowsInvoicesProductsImportUploadSignature> {
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('args.context') context;

  // @ts-expect-error
  @tracked files = this.context.uploadedFiles ?? [];
  @tracked hideDropzone = true;

  button = Button;
  allowedFileExtensions = '.csv,.xls,.xlsx';
  uploadOptions = {
    callEndpoint: false,
  };

  get dropZoneLabel() {
    return this.intl.t('clients-import.upload.dropzone.label', {
      maxSize: formatFileSize(this.intl, FILE_MAX_SIZE),
    });
  }

  onFileUploadedTask = dropTask(async fileModel => {
    let token = waiter.beginAsync();

    this.segment.track('items-import_upload_started');

    // Normalize file model to match the format expected by the component API
    fileModel.file.filename = fileModel.file.name;
    fileModel.file.filesizeBytes = fileModel.file.size;
    fileModel.file.url = fileModel.file.fileUrl;

    try {
      this.#validateFileExtension(fileModel.file.filename);
      this.context.xlsFile = await XlsFile.parse(fileModel.file);
      this.context.uploadedFiles = [...this.files, fileModel.file];
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      this.context.uploadedFiles = [];
      this.files = [];
      this.hideDropzone = false;
      this.context.xlsFile = null;
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    } finally {
      waiter.endAsync(token);
    }
  });

  @action
  onRemoveFile() {
    this.context.uploadedFiles = [];
    this.files = [];
    this.hideDropzone = false;
    this.context.xlsFile = null;
    this.context.mapping = new TrackedObject({});
    this.context.status.isMapped = false;
  }

  #validateFileExtension(filename: string) {
    invariant(
      this.allowedFileExtensions.split(',').some(ext => filename.endsWith(ext)),
      'Invalid file type'
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Invoices::ProductsImport::Upload': typeof FlowsInvoicesProductsImportUpload;
  }
}
