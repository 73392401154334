/* import __COLOCATED_TEMPLATE__ from './error.hbs'; */
import Component from '@glimmer/component';

import { Error } from 'qonto/react/receivable-invoices/components/flows/quote/e-signature';

interface FlowsReceivableInvoicesQuoteEsignatureError {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsReceivableInvoicesQuoteEsignatureErrorComponent extends Component<FlowsReceivableInvoicesQuoteEsignatureError> {
  error = Error;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ReceivableInvoices::QuoteEsignature::Error': typeof FlowsReceivableInvoicesQuoteEsignatureErrorComponent;
  }
}
