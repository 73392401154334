/* import __COLOCATED_TEMPLATE__ from './introduction.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';

import type { DataContext } from './data-context';

interface SubscriptionCloseStepsIntroductionSignature {
  // The arguments accepted by the component
  Args: FlowStepArgs<DataContext>;
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionCloseStepsIntroductionComponent extends Component<SubscriptionCloseStepsIntroductionSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];
  @service declare flow: Services['flow'];
  @tracked isEligibleForVoucher = false;

  finishOn?: () => void;

  constructor(owner: unknown, args: SubscriptionCloseStepsIntroductionSignature['Args']) {
    super(owner, args);
    if (variation('feature--improve-retention-vouchers-ab-test')) {
      this.checkVoucherEligibilityTask.perform().catch(() => {});
    }
  }

  checkVoucherEligibilityTask = dropTask(async () => {
    try {
      if (variation('feature--improve-retention-vouchers-ab-test')) {
        let response = await this.organizationManager.organization.checkVoucherEligibility();
        this.isEligibleForVoucher = response.isEligible !== undefined ? response.isEligible : null;
        this.finishOn = response.finishOn !== undefined ? response.finishOn : null;
        this.flow.dataContext.finishOn = this.finishOn;
        this.flow.dataContext.isEligibleForVoucher = this.isEligibleForVoucher;
      }
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  get organizationName() {
    return this.organizationManager.organization.name;
  }

  get provisionalDueDate() {
    return this.intl.formatDate(dayjs(this.flow.dataContext.provisionalDueDate).toDate(), {
      format: 'long',
    });
  }

  get isItalianOrganization() {
    return this.organizationManager.organization.isItalian;
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan.localName;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription?.recurrence;
  }

  get closingDate() {
    let endOfMonth = dayjs().endOf('month').toDate();

    if (endOfMonth.getDate() === dayjs().toDate().getDate()) {
      let endOfNextMonth = dayjs().add(1, 'month').endOf('month').toDate();
      return this.intl.formatDate(endOfNextMonth, { format: 'long' });
    }

    return this.intl.formatDate(endOfMonth, { format: 'long' });
  }

  @action onSubmit(e: Event) {
    e.preventDefault();
    this.flow.dataContext.isItalianOrganization = this.isItalianOrganization;
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::Introduction': typeof SubscriptionCloseStepsIntroductionComponent;
  }
}
