/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface DualNavSettingsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DualNavSettingsComponent extends Component<DualNavSettingsSignature> {
  @tracked isDropdownOpen = false;

  get isButtonSelected() {
    // @ts-expect-error
    let { activeMenuItemId, items } = this.args;
    // @ts-expect-error
    return Boolean(items.find(item => item.id === activeMenuItemId));
  }

  // @ts-expect-error
  isItemSelected(item, activeMenuItemId) {
    return activeMenuItemId === item.id;
  }

  @action
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  @action
  // @ts-expect-error
  onSettingsDropdownButtonPointerUp(item) {
    this.closeDropdown();
    // @ts-expect-error
    this.args.onMainMenuButtonPointerUp(item);
  }

  @action
  // @ts-expect-error
  onSettingsDropdownLinkPointerUp(item) {
    // @ts-expect-error
    this.args.onMainMenuLinkPointerUp(item);
  }

  @action
  closeDropdown() {
    this.isDropdownOpen = false;
  }

  noopOnPointerEnter() {
    // in the case of the settings dropdown, we don't want the dual nav to react on hovering one of its items
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::Settings': typeof DualNavSettingsComponent;
  }
}
