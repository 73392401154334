/* import __COLOCATED_TEMPLATE__ from './skip-to-content.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SkipToContentSignature {
  Element: HTMLAnchorElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SkipToContent = templateOnlyComponent<SkipToContentSignature>();

export default SkipToContent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::SkipToContent': typeof SkipToContent;
  }
}
