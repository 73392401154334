export default {
  "checks": "fe",
  "content": "fB",
  "form": "fE",
  "title": "fd",
  "description": "fI",
  "wrapper": "ft",
  "aside": "fM",
  "amount-field": "fP"
};
