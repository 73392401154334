/* import __COLOCATED_TEMPLATE__ from './article.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ArticleSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Article = templateOnlyComponent<ArticleSignature>();

export default Article;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Article': typeof Article;
  }
}
