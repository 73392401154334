/* import __COLOCATED_TEMPLATE__ from './recurrence-field.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RecurrenceFieldSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const RecurrenceField = templateOnlyComponent<RecurrenceFieldSignature>();

export default RecurrenceField;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::RecurrenceField': typeof RecurrenceField;
  }
}
