export default {
  "row": "Ln",
  "cell": "Li body-2",
  "empty": "LA",
  "cell-content": "LY",
  "cell-content--amount": "Lh body-1",
  "icon": "Lf",
  "description": "LK",
  "tertiary": "LG",
  "details": "Lr"
};
