/* import __COLOCATED_TEMPLATE__ from './toggle-embossed.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { CARD_PRINT_TYPES } from 'qonto/constants/cards';

interface CardUToggleEmbossedSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardUToggleEmbossed extends Component<CardUToggleEmbossedSignature> {
  // @ts-expect-error
  @tracked isEmbossed = this.args.typeOfPrint === CARD_PRINT_TYPES.EMBOSSED;

  @action
  // @ts-expect-error
  toggleTypeOfPrint(isEmbossed) {
    this.isEmbossed = isEmbossed;
    // @ts-expect-error
    this.args.onTypeOfPrintChange(isEmbossed ? CARD_PRINT_TYPES.EMBOSSED : CARD_PRINT_TYPES.PRINT);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::ToggleEmbossed': typeof CardUToggleEmbossed;
  }
}
