export default {
  "step-container": "Ks",
  "content": "KF",
  "detail": "Kg",
  "secondary": "KD",
  "reference": "KV",
  "fees-label": "KL",
  "fees-tooltip": "KT",
  "separator": "Kz",
  "mt-32": "Ka"
};
