/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SelectorToggle } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { PAY_LATER_FLOW_ORIGIN } from 'qonto/constants/financing';
import {
  OPERATION_TYPES,
  SALARY_PAYMENT_PURPOSE,
  SALARY_PAYMENT_TRANSFER_DISCOVERABILITY_STORAGE_KEY,
} from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { PromotionalBanner } from 'qonto/react/components/transfers/international-out/promotional-banner';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface FlowsTransfersSepaNewDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaNewDetailsComponent extends Component<FlowsTransfersSepaNewDetailsSignature> {
  promotionalBanner = PromotionalBanner;
  payrollSelectorToggle = SelectorToggle;
  instructionalTooltip = InstructionalTooltip;

  @service declare abilities: Services['abilities'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];

  @tracked isValidationEnabled = false;
  @tracked isInstructionalTooltipDismissed = false;

  get beneficiary() {
    return this.sepaTransfer.get('beneficiary');
  }

  get sepaTransfer() {
    // @ts-expect-error
    return this.args.context.sepaTransfer;
  }

  get showPayLaterToggle() {
    return (
      this.abilities.can('view pay later toggle in financing') &&
      // @ts-expect-error
      this.args.context.isPayLaterEligible
    );
  }

  get payrollSelected() {
    // @ts-expect-error
    return this.args.context.sepaTransfer.purpose === SALARY_PAYMENT_PURPOSE;
  }

  get origin() {
    return PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY;
  }

  get isPayLaterToggleDisabled() {
    let organization = this.sepaTransfer.get('organization');

    return organization?.get('hasPreventPayLaterToggle') === true;
  }

  get shouldDisplayInstructionalTooltip() {
    const isStorageEmpty = !safeLocalStorage.getItem(
      SALARY_PAYMENT_TRANSFER_DISCOVERABILITY_STORAGE_KEY
    );

    if (isStorageEmpty) {
      this.segment.track('discovery_product-instructional-tooltip_displayed', {
        feature: 'single_transfer_payroll',
      });
    }

    return isStorageEmpty;
  }

  get isNextButtonDisabled() {
    return this.sepaTransfer.occurrencesCount === 0;
  }

  onClickNextTask = dropTask(async sepaTransfer => {
    await sepaTransfer.validate();

    this.isValidationEnabled = true;

    let { reference, bankAccount, amount, scheduledDate } = sepaTransfer.validations.attrs;
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let { origin, isPayLater, invoice } = context;

    if (reference.isInvalid || bankAccount.isInvalid || scheduledDate.isInvalid) {
      this._scrollToErrorField();
      return;
    }

    if (amount.isInvalid) {
      this._scrollToErrorField();

      let shouldShowLowBalanceModal =
        this.showPayLaterToggle && !isPayLater && !this.isPayLaterToggleDisabled;

      if (shouldShowLowBalanceModal) {
        this.handleLowBalanceTask.perform().catch(ignoreCancelation);
      }

      return;
    }

    this.segment.track('transfer-sepa_amount_submitted', {
      ...(origin && { origin }),
      ...(invoice && {
        is_einvoice: invoice?.isElectronicInvoice ?? false,
      }),
    });

    if (isPayLater) {
      // @ts-expect-error
      return this.args.transitionToFlow({
        flowName: 'pay-later-transfer',
        stepId: 'invoice-upload',
        queryParams: {
          transferId: sepaTransfer.idempotencyKey,
          origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
        },
      });
    }

    transitionToNext();
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  @action
  onPayrollSelectorChange(isEnabled: boolean) {
    this.segment.track('transfer-sepa_payroll-toggle_switch', {
      state: isEnabled ? 'on' : 'off',
      feature: 'single_transfer_payroll',
    });

    if (isEnabled) {
      safeLocalStorage.setItem(
        SALARY_PAYMENT_TRANSFER_DISCOVERABILITY_STORAGE_KEY,
        // @ts-expect-error
        true
      );
      this.isInstructionalTooltipDismissed = true;
    }

    // @ts-expect-error
    this.args.context.sepaTransfer.purpose = isEnabled ? SALARY_PAYMENT_PURPOSE : null;
  }

  @action
  handleDismissInstructionalTooltip() {
    safeLocalStorage.setItem(
      SALARY_PAYMENT_TRANSFER_DISCOVERABILITY_STORAGE_KEY,
      // @ts-expect-error
      true
    );
    this.isInstructionalTooltipDismissed = true;
    this.segment.track('discovery_product-instructional-tooltip_closed', {
      feature: 'single_transfer_payroll',
    });
  }

  @action
  togglePayLater() {
    // @ts-expect-error
    let { context } = this.args;

    context.isPayLater = !context.isPayLater;

    if (context.isPayLater) {
      this.isValidationEnabled = false;
    }

    this.sepaTransfer.operationType = context.isPayLater
      ? OPERATION_TYPES.PAY_LATER
      : OPERATION_TYPES.SCHEDULED;

    this.segment.track('pay_later-toggle_switch', {
      state: context.isPayLater ? 'on' : 'off',
    });
  }

  handleLowBalanceTask = dropTask(async () => {
    let isLowBalanceScenario =
      Number(this.sepaTransfer.amount) > Number(this.sepaTransfer.bankAccount.authorizedBalance);

    if (!isLowBalanceScenario) return;

    await this.modals.open('transfers/sepa/pay-later/low-balance-modal', {
      confirmTask: this.redirectToPayLaterFlowTask,
    });
  });

  redirectToPayLaterFlowTask = dropTask(async close => {
    this.segment.track('pay_later-lower_balance_continue_clicked', {
      origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
    });

    await close();

    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
        transferId: this.sepaTransfer.idempotencyKey,
      },
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::New::Details': typeof FlowsTransfersSepaNewDetailsComponent;
  }
}
