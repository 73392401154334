/* import __COLOCATED_TEMPLATE__ from './edit-exercise.hbs'; */
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

interface FlowsBudgetsEditExerciseSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsBudgetsEditExercise extends Component<FlowsBudgetsEditExerciseSignature> {
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare modals: Services['modals'];

  // @ts-expect-error
  @tracked exercise;

  constructor(owner: unknown, args: FlowsBudgetsEditExerciseSignature['Args']) {
    super(owner, args);
    next(() => this.#initExercise());
  }

  #initExercise() {
    // @ts-expect-error
    let { exerciseId, budget } = this.args.context;

    if (exerciseId) {
      this.exercise = this.#findAndUpdateExercise(budget, exerciseId);
    } else {
      this.exercise = this.#createExercise(budget);
    }
    this.segment.track('edit-budget_choose-amounts_displayed');
  }

  // @ts-expect-error
  #findAndUpdateExercise(budget, exerciseId) {
    // @ts-expect-error
    let exercise = budget.exercises.find(({ id }) => id === exerciseId);
    // @ts-expect-error
    let startDate = this.args.context.selectedStartDate;
    // @ts-expect-error
    let endDate = this.args.context.selectedEndDate;
    exercise.updateStartAndEndDates({
      startDate,
      endDate,
    });
    return exercise;
  }

  // @ts-expect-error
  #createExercise(budget) {
    let exercise = this.store.createRecord('exercise', {
      budget,
      // @ts-expect-error
      startDate: this.args.context.selectedStartDate,
      // @ts-expect-error
      endDate: this.args.context.selectedEndDate,
    });
    exercise.initPeriods();
    return exercise;
  }

  submitTask = dropTask(async () => {
    if (
      (this.exercise.hasDirtyAttributes && !this.exercise.isStarted) ||
      // @ts-expect-error
      this.exercise.periods.some(period => period.hasDirtyAttributes)
    ) {
      await this.exercise.save();
    }
    this.segment.track('edit-budget_save_clicked');
    // @ts-expect-error
    this.args.transitionToNext();
  });

  deleteTask = dropTask(async () => {
    this.segment.track('edit-budget_delete-period_clicked');

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.title'),
      description: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('team-budgets.edit.edit-exercise.delete-period.modal.button'),
    });

    // @ts-expect-error
    if (result === 'confirm') {
      this.segment.track('edit-budget_delete-period-confirmation_clicked');
      await this.exercise.deleteRecord();
      await this.exercise.save();
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      this.segment.track('edit-budget_delete-period-confirmation-back_clicked');
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::EditBudget::EditExercise': typeof FlowsBudgetsEditExercise;
  }
}
