/* import __COLOCATED_TEMPLATE__ from './invoice-expired.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlowsTransfersSepaPayLaterErrorsInvoiceExpiredSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaPayLaterErrorsInvoiceExpiredComponent extends Component<FlowsTransfersSepaPayLaterErrorsInvoiceExpiredSignature> {
  @service declare payByInvoiceUploadManager: Services['payByInvoiceUploadManager'];

  @action
  handleRestart() {
    // @ts-expect-error
    this.payByInvoiceUploadManager.resetState();
    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        invoiceId: null,
        reset: true,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::PayLater::Errors::InvoiceExpired': typeof FlowsTransfersSepaPayLaterErrorsInvoiceExpiredComponent;
  }
}
