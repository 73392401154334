/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { FlowsCheckSuccess } from 'qonto/react/components/flows/checks/success';

interface FlowsCheckSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCheckSuccessComponent extends Component<FlowsCheckSuccessSignature> {
  success = FlowsCheckSuccess;

  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];

  @action restart() {
    this.segment.track('checks_deposit_new_check');
    // @ts-expect-error
    this.args.restartFlow();
  }

  @action handleNext() {
    this.segment.track('checks_deposit_close');
    // @ts-expect-error
    this.args.transitionToNext();
  }

  get shippingAddress() {
    return (
      // @ts-expect-error
      this.args.context.check.shippingAddress ?? this.intl.t('checks.success.check_send_address')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Check::Success': typeof FlowsCheckSuccessComponent;
  }
}
