export default {
  "step-container": "Ue",
  "flex-table": "UB",
  "flex-row": "UE",
  "flex-cell": "Ud",
  "with-border": "UI",
  "with-border-top": "Ut",
  "secondary": "UM",
  "flex-info": "UP",
  "amount": "Ul",
  "legal-name": "UX"
};
