/* import __COLOCATED_TEMPLATE__ from './preset.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';

interface FiltersPresetSignature {
  // The arguments accepted by the component
  Args: {
    isFetchingTransactions?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FiltersPresetComponent extends Component<FiltersPresetSignature> {
  spinner = Spinner;

  elementId = guidFor(this);
  presetButtonId = `${this.elementId}-preset-button`;

  get formData() {
    // @ts-expect-error
    if (this.args.cachedFilterGroup) {
      let formData = {
        id: this.elementId,
        query: {
          filter_group: {
            // @ts-expect-error
            conditional: this.args.cachedFilterGroup.conditional,
            // @ts-expect-error
            expressions: this.args.cachedFilterGroup.expressions,
          },
        },
      };
      return formData;
    } else {
      // @ts-expect-error
      return this.args.cachedFormData.find(({ id }) => id === this.elementId);
    }
  }

  get isActive() {
    return (
      // @ts-expect-error
      (this.elementId === this.args.activeId || this.args.cachedFilterGroup) && // @ts-expect-error
      !this.args.selectedPreset
    );
  }

  get isLoading() {
    return this.args.isFetchingTransactions && this.isActive;
  }

  get showCounter() {
    return !isNaN(this.count) && this.isActive;
  }

  get count() {
    // @ts-expect-error
    return this.isActive ? this.args.count : this.args.presetCount;
  }

  @action
  // @ts-expect-error
  togglePresetDropdown(showMenu) {
    // @ts-expect-error
    if (this.args.queryParamsFilters && showMenu) {
      // @ts-expect-error
      let filterGroup = this.args.queryParamsFilters;
      let formData = {
        id: this.elementId,
        // @ts-expect-error
        query: { ...this.args.queryOptions, filter_group: filterGroup },
      };

      // @ts-expect-error
      this.args.updateActiveFilterId(this.elementId);
      // @ts-expect-error
      this.args.refreshCache(formData, true);
    }
  }

  @action
  // @ts-expect-error
  clearFilters(toggle) {
    // @ts-expect-error
    this.args.clearFilters();
    toggle();
  }

  @action
  // @ts-expect-error
  onSubmit(toggle, filterGroup) {
    // @ts-expect-error
    let query = { ...this.args.queryOptions, filter_group: filterGroup };

    if (query.pagination?.page) {
      query.pagination.page = 1;
    }

    // @ts-expect-error
    this.args.applyFilters(query, this.elementId);
    toggle();
  }

  @action
  // @ts-expect-error
  refreshCache(filterGroup) {
    let formData = {
      id: this.elementId,
      // @ts-expect-error
      query: { ...this.args.queryOptions, filter_group: filterGroup },
    };

    // @ts-expect-error
    this.args.refreshCache(formData, true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Filters::Preset': typeof FiltersPresetComponent;
  }
}
