/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, waitForQueue } from 'ember-concurrency';

import { PERIOD_KEYS } from 'qonto/constants/bookkeeping';

interface BookkeepingPeriodSelectorDropdownSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BookkeepingPeriodSelectorDropdownComponent extends Component<BookkeepingPeriodSelectorDropdownSignature> {
  @service('segment')
  declare segment: Services['segment'];

  @tracked customPeriodIsOpen = false;

  lastIndex = 0;

  @action
  // @ts-expect-error
  onInitDropDown(dropDownState) {
    // @ts-expect-error
    this.dropDownState = dropDownState;
    // @ts-expect-error
    if (this.args.onInit) {
      // @ts-expect-error
      this.args.onInit(...arguments);
    }
  }

  @action
  getIndex() {
    return this.lastIndex++;
  }

  @action
  onClose() {
    this.lastIndex = 0;
    this.customPeriodIsOpen = false;
  }

  onOpenTask = dropTask(async () => {
    await waitForQueue('afterRender');
    // @ts-expect-error
    this.focusedOptionElement?.focus();
  });

  @action
  // @ts-expect-error
  selectItem(index) {
    // @ts-expect-error
    let selectedItem = this.args.options[index];
    if (this.isCustom(selectedItem.key)) {
      this.customPeriodIsOpen = true;
    } else {
      // @ts-expect-error
      this.args.update(selectedItem);
      // @ts-expect-error
      this.dropDownState.actions.close();
    }
  }

  @action
  // @ts-expect-error
  updateCustomPeriod({ startDate, endDate }) {
    let item = Object.assign({}, this.customPeriodItem, { startDate, endDate });
    // @ts-expect-error
    this.args.update(item);
    // @ts-expect-error
    this.dropDownState.actions.close();
  }

  closeCustomPeriodTask = dropTask(async () => {
    this.lastIndex = 0;
    this.customPeriodIsOpen = false;
    await waitForQueue('afterRender');
    // @ts-expect-error
    this.focusedOptionElement?.focus();
  });

  @action
  // @ts-expect-error
  onTriggerKeyDown(dropdown, event) {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        // @ts-expect-error
        this.dropDownState.actions.open();
        break;
    }
  }

  @action
  // @ts-expect-error
  onOptionKeyDown(event) {
    let { focusedOptionElement } = this;
    // @ts-expect-error
    let focusedIndex = Number(focusedOptionElement?.dataset.optionIndex);

    switch (event.key) {
      case 'ArrowDown':
        // @ts-expect-error
        this.dropdownElement.querySelector(`[data-option-index="${focusedIndex + 1}"]`)?.focus();
        break;
      case 'ArrowUp':
        // @ts-expect-error
        this.dropdownElement.querySelector(`[data-option-index="${focusedIndex - 1}"]`)?.focus();
        break;
      case 'Enter':
      case ' ':
        // @ts-expect-error
        focusedOptionElement?.click();
        break;
      case 'Escape':
        // @ts-expect-error
        this.dropDownState.actions.close();
        break;
    }
  }

  @action
  // @ts-expect-error
  onOptionMouseOver(e) {
    e.currentTarget.focus();
  }

  get dropdownElement() {
    // @ts-expect-error
    return document.getElementById(`ember-basic-dropdown-content-${this.dropDownState.uniqueId}`);
  }

  get focusedOptionElement() {
    return (
      this.dropdownElement?.querySelector('[role=option]:focus') ||
      this.dropdownElement?.querySelector('[role=option]:hover') ||
      this.dropdownElement?.querySelector('[role=option][aria-current=true]') ||
      this.dropdownElement?.querySelector('[role=option][data-option-index="0"]')
    );
  }

  get selectedIndex() {
    // @ts-expect-error
    return this.args.options.findIndex(({ key }) => key === this.args.value?.key);
  }

  // @ts-expect-error
  isCustom(key) {
    return key === PERIOD_KEYS.CUSTOM_PERIOD;
  }

  get customPeriodItem() {
    // @ts-expect-error
    if (this.isCustom(this.args.value?.key)) {
      // @ts-expect-error
      return this.args.value;
    } else {
      // @ts-expect-error
      return this.args.options.find(({ key }) => this.isCustom(key));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Bookkeeping::PeriodSelector::Dropdown': typeof BookkeepingPeriodSelectorDropdownComponent;
  }
}
