/* import __COLOCATED_TEMPLATE__ from './forgot-password-form.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ForgotPasswordFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ForgotPasswordForm extends Component<ForgotPasswordFormSignature> {
  spinner = Spinner;

  @service declare store: Services['store'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  @tracked requested = false;
  @tracked user = null;

  // @ts-expect-error
  @reads('user.didValidate') isEmailValidationEnabled;

  constructor(owner: unknown, args: ForgotPasswordFormSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.user = this.store.createRecord('user');
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    // Ember Data 3.28 does not like calling `unloadRecord()` on a store
    // instance that is already in "destroying" mode. Since we can skip the
    // cleanup in this case anyway we just return early here.
    if (this.store.isDestroying || this.store.isDestroyed) return;

    // @ts-expect-error
    this.user.unloadRecord();
  }

  forgotPasswordTask = dropTask(async email => {
    // @ts-expect-error
    this.user.set('didValidate', true);

    // @ts-expect-error
    if (!this.user.validations.attrs.email.isTruelyValid) {
      return;
    }

    try {
      // @ts-expect-error
      await this.args.forgotPassword(email);
      this.requested = true;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ForgotPasswordForm: typeof ForgotPasswordForm;
  }
}
