export default {
  "wrapper": "pf",
  "legend": "pK",
  "grid": "pG",
  "subtitle": "pr body-2",
  "fiscal-code": "pb",
  "personal-data-name": "py",
  "personal-data-birth": "pU",
  "personal-data": "pj",
  "tax-residence": "pW",
  "payer-agent": "pu",
  "errors": "xS",
  "business": "xc",
  "address": "xq"
};
