/* import __COLOCATED_TEMPLATE__ from './success-invitee-physical-one.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';
import { CardImage } from 'qonto/react/cards/components/card-image';

interface FlowsCardsSuccessInviteePhysicalOneSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsSuccessInviteePhysicalOne extends Component<FlowsCardsSuccessInviteePhysicalOneSignature> {
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare flow: Services['flow'];
  @service declare flowLinkManager: Services['flowLinkManager'];

  cardImage = CardImage;

  cardPan: string = '•'.repeat(16);

  constructor(owner: unknown, args: FlowsCardsSuccessInviteePhysicalOneSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { flowTrackingOrigin, card } = this.args.context;
    this.segment.track('cards_order_completed', {
      card_type: CARD_LEVELS_TRACKING[card.cardLevel],
      origin: flowTrackingOrigin,
    });
  }

  @action
  handleReviewClick() {
    // @ts-expect-error
    let { card, flowTrackingOrigin } = this.args.context;
    this.segment.track('user-access_details_displayed', {
      origin: flowTrackingOrigin,
    });
    this.router.replaceWith('members.active.member', card.holder.id);
  }

  @action
  handleInviteNewClick() {
    // @ts-expect-error
    let { flowTrackingOrigin } = this.args.context;
    this.segment.track('team_invite_selected', {
      origin: flowTrackingOrigin,
    });
    this.flow.reset();
    this.flowLinkManager.replaceWith({ name: 'member-invite' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::SuccessInviteePhysicalOne': typeof FlowsCardsSuccessInviteePhysicalOne;
  }
}
