/* import __COLOCATED_TEMPLATE__ from './choose-card-digital.hbs'; */
import { set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS, CARD_LEVELS_TRACKING, ORIGIN } from 'qonto/constants/cards';
import { ChooseCardDigital } from 'qonto/react/cards/components/flows/choose-card-digital';
import { getDefaultDesignByLevel } from 'qonto/utils/cards/design';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsCardsChooseVirtualCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsChooseVirtualCard extends Component<FlowsCardsChooseVirtualCardSignature> {
  chooseCardDigital = ChooseCardDigital;

  @service declare cardsManager: Services['cardsManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare abilities: Services['abilities'];
  @service declare upsellManager: Services['upsellManager'];

  CARD_LEVELS = CARD_LEVELS;

  get currentPricePlanRecurrence() {
    // @ts-expect-error
    return this.args.context.currentSubscription?.recurrence;
  }

  chooseCardTask = dropTask(async cardLevel => {
    let {
      bankAccount,
      card,
      cardsLimits,
      estimates,
      flowTrackingOrigin,
      hasMultipleActiveAccounts,
      origin,
      // @ts-expect-error
    } = this.args.context;

    try {
      // when coming back from the subscription change flow, we have to re-fetch the card costs which where initially missing (like the Instant card costs)
      // (this cannot be handled in the beforeFlow hook of the flow, because it's not re-triggered when coming back to a primary flow)
      if (!estimates[cardLevel]) {
        let { organization } = this.organizationManager;
        let cardsCosts = await this.cardsManager.fetchCardsCostsTask.perform(organization.id);
        estimates = cardsCosts.estimates;
        // @ts-expect-error
        set(this.args.context, 'estimates', estimates);
      }

      if (bankAccount.authorizedBalance < estimates[cardLevel].amount) {
        let singleMessage = this.intl.t('cards.new.choose-type.balance-warning.single');
        let multiMessage = this.intl.t('cards.new.choose-type.balance-warning.multi', {
          accountName: bankAccount.name,
        });

        let toastMessage = hasMultipleActiveAccounts ? multiMessage : singleMessage;
        this.toastFlashMessages.toastError(toastMessage);
        return null;
      }

      let limits = cardsLimits[cardLevel];
      let cardDesign = getDefaultDesignByLevel(cardLevel);

      if (cardLevel === CARD_LEVELS.FLASH) {
        card.setProperties({
          cardDesign,
          cardLevel,
          paymentLifespanLimit: Math.max(...limits.payment_lifespan_limit),
          preExpiresAt: dayjs().endOf('day').toDate(),
        });
      } else {
        card.setProperties({
          cardDesign,
          cardLevel,
          paymentMonthlyLimit: limits.payment_monthly_limit_maximum,
        });
      }

      this.segment.track('cards_order_virtual_type_chosen', {
        card_type: CARD_LEVELS_TRACKING[cardLevel],
        origin: flowTrackingOrigin,
      });

      if (hasMultipleActiveAccounts) {
        // @ts-expect-error
        return this.args.pushForwardFlow(`card-new-${cardLevel}`, 'bank-account');
      }

      let stepId = 'holder';

      if (origin === ORIGIN.MEMBER_INVITATION) {
        switch (cardLevel) {
          case CARD_LEVELS.VIRTUAL:
            stepId = this.abilities.can('view restrictions card')
              ? 'restrictions'
              : 'payment-limits';
            break;
          case CARD_LEVELS.FLASH:
            stepId = 'payment-lifespan-limit';
            break;
          case CARD_LEVELS.ADVERTISING:
            stepId = 'payment-limits';
            break;
        }
      }

      // @ts-expect-error
      this.args.pushForwardFlow(`card-new-${cardLevel}`, stepId);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  onUpsellSuccess = ({ recurrence, addonCode }: { recurrence: string; addonCode: string }) => {
    // @ts-expect-error
    this.args.context.recurrence = recurrence;
    // @ts-expect-error
    this.args.context.addonCode = addonCode;
    // @ts-expect-error
    this.args.pushFlow('addon-change', 'addons');
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCardDigital': typeof FlowsCardsChooseVirtualCard;
  }
}
