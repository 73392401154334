/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { ONBOARDING_TRACKING_EVENTS } from 'qonto/constants/receivable-invoice';

interface AccountReceivableOnboardingSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AccountReceivableOnboardingSuccess extends Component<AccountReceivableOnboardingSuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare flow: Services['flow'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];

  get shouldDisplayGotoRecurringInvoicesLink() {
    // @ts-expect-error
    return this.flow.refererPage.includes('invoice-subscriptions');
  }

  get shouldDisplayImportClientsButton() {
    // @ts-expect-error
    return this.flow.dataContext.onboardingState.prefilledAt !== null;
  }

  get subtitleText() {
    return this.shouldDisplayImportClientsButton
      ? this.intl.t('receivable-invoices.onboarding.success.subtitle-import-clients')
      : this.intl.t('receivable-invoices.onboarding.success.subtitle');
  }

  @action
  trackOnContinueClick() {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.COMPLETE_CONFIRMED);
  }

  @action
  goToImportClients() {
    this.segment.track('client-import_started', {
      source: 'account-receivable-onboarding',
    });

    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'invoice-clients-import',
      stepId: 'initial',
    });
    this.trackOnContinueClick();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::Success': typeof AccountReceivableOnboardingSuccess;
  }
}
