/* import __COLOCATED_TEMPLATE__ from './poi.hbs'; */
import { ForbiddenError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { DateField } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
// @ts-expect-error
import TEMPLATE_VALUES from 'qonto/constants/env-helper';

// `nationalities` is an optional field in the UI.
// That's why it is not part of these fields.
export const FIELDS = [
  'nationality',
  'birthdate',
  'birthCity',
  'birthZipcode',
  'birthCountry',
  'address.country',
  'proofOfIdentity',
  'proofOfIdentitySubtype',
];

const POI_TYPES = ['eu_id', 'eu_passport', 'resident_card'];

interface CapitalDepositStakeholderPoiSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositStakeholderPoiComponent extends Component<CapitalDepositStakeholderPoiSignature> {
  dateField = DateField;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @reads('args.context.stakeholder.didValidate') isValidationEnabled;
  // @ts-expect-error
  @reads('args.context.stakeholder.validations.attrs.birthdate') birthDateValidation;

  poiTypes = POI_TYPES;
  poiMaxSize = TEMPLATE_VALUES.poiMaxSize;

  get minBirthDate() {
    return parseDate('1900-01-01');
  }

  get maxBirthDate() {
    return parseDate(dayjs().format(DATE_PICKER_FIELD_FORMAT));
  }

  get poiTypesTranslationsLookup() {
    let { legalCountry } = this.organizationManager.organization;

    return {
      eu_id: this.intl.t('doc-types.poi.eu-id', { legalCountry }),
      eu_passport: this.intl.t('doc-types.poi.eu-passport'),
      resident_card: this.intl.t('doc-types.poi.resident-card'),
    };
  }

  get birthDateErrorMessage() {
    return this.isValidationEnabled ? this.birthDateValidation.message : null;
  }

  get dropzoneText() {
    return this.intl.t('labels.upload-message', {
      maxSize: formatFileSize(this.intl, this.poiMaxSize),
    });
  }

  get localFiles() {
    // @ts-expect-error
    return this.args.context?.stakeholder?.proofOfIdentity?.files;
  }

  submitTask = dropTask(async stakeholder => {
    let { validations } = await stakeholder.validate({ on: FIELDS });

    stakeholder.set('didValidate', true);

    if (validations.isValid) {
      try {
        // @ts-expect-error
        await this.args.context.saveStakeholderTask.perform(stakeholder);
        this.segment.track('kdeposit_physical_shareholder_personal_information_filled');
        // @ts-expect-error
        this.args.transitionToNext();
      } catch (error) {
        if (error instanceof ForbiddenError) {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.forbidden_error'));
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          this.sentry.captureException(error);
        }
      }
    }
  });

  get stakeholderBirthday() {
    // @ts-expect-error
    let birthdate = this.args.context.stakeholder.birthdate;
    return birthdate ? parseDate(birthdate) : birthdate;
  }

  @action
  // @ts-expect-error
  onBirthdayChange(birthdate) {
    // @ts-expect-error
    this.args.context.stakeholder.birthdate = birthdate?.toString();
  }

  @action
  // @ts-expect-error
  updateMembershipNationalities(countryCodes) {
    // @ts-expect-error
    this.args.context.stakeholder.nationalities = countryCodes;
  }

  @action
  // @ts-expect-error
  handleSelectType(stakeholder, docSubtype) {
    if (stakeholder.proofOfIdentity) {
      stakeholder.proofOfIdentity.docSubtype = docSubtype;
    } else {
      let proofOfIdentity = this.store.createRecord('document', {
        docSubtype,
        docType: 'poi',
        files: [],
      });
      if (!stakeholder.documents.includes(proofOfIdentity)) {
        stakeholder.documents.push(proofOfIdentity);
      }
    }
  }

  @action
  // @ts-expect-error
  handleAddDocument(stakeholder, docSubtype, file) {
    if (stakeholder.proofOfIdentity) {
      if (!stakeholder.proofOfIdentity.files.includes(file)) {
        stakeholder.proofOfIdentity.files.push(file);
      }
      // Since `proofOfIdentity` is a getter on the `documents`,
      // we have to notify that CP needs to read from it again.
      stakeholder.notifyPropertyChange('documents');
    } else {
      let proofOfIdentity = this.store.createRecord('document', {
        docSubtype,
        docType: 'poi',
        files: [file],
      });
      if (!stakeholder.documents.includes(proofOfIdentity)) {
        stakeholder.documents.push(proofOfIdentity);
      }
    }
  }

  @action
  // @ts-expect-error
  handleRemoveFileFromDocument(stakeholder, file) {
    let { files } = stakeholder.proofOfIdentity;
    let index = files.indexOf(file);
    files.splice(index, 1);

    if (files.length === 0) {
      return stakeholder.proofOfIdentity.destroyRecord();
    }
  }

  @action
  // @ts-expect-error
  handleOpenFilePreviewModal(stakeholder, file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
      model: stakeholder,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CapitalDeposit::Stakeholder::Poi': typeof CapitalDepositStakeholderPoiComponent;
  }
}
