export default {
  "container": "Ab",
  "container-metal": "Ay",
  "content": "AU",
  "button": "Aj",
  "embossed-selector": "AW",
  "carousel-container": "Au",
  "carousel": "YS",
  "asset": "Yc",
  "poster": "Yq",
  "video-embossed": "YZ",
  "video": "YR",
  "active": "YQ",
  "embossed": "Ye",
  "safari": "YB",
  "poster-open": "YE"
};
