/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface SuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SuccessComponent extends Component<SuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  @action
  handleCloseClick() {
    // @ts-expect-error
    let { transitionToNext } = this.args;

    this.segment.track('reimbursement_flow_complete_close');

    transitionToNext();
  }

  @action
  handleSuccessClick() {
    this.router.transitionTo('requests.landing', {
      queryParams: { origin: 'reimbursement_request' },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::RequestExpenseReport::Success': typeof SuccessComponent;
  }
}
