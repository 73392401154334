/* import __COLOCATED_TEMPLATE__ from './avatar-confirm-dialog.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import LocalFile from 'qonto/objects/local-file';

interface AvatarConfirmDialogSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AvatarConfirmDialog extends Component<AvatarConfirmDialogSignature> {
  spinner = Spinner;

  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked file;

  get title() {
    // @ts-expect-error
    return this.args.data.title || this.intl.t('btn.upload_photo');
  }

  get cancelText() {
    // @ts-expect-error
    return this.args.data.cancelText || this.intl.t('btn.cancel');
  }

  get ctaText() {
    // @ts-expect-error
    return this.args.data.ctaText || this.intl.t('btn.submit');
  }

  @action
  cancelEvent() {
    // @ts-expect-error
    this.args.close?.();

    // @ts-expect-error
    return this.args.data.cancelEvent?.() || (() => {});
  }

  @action
  // @ts-expect-error
  handleFinalFile(file) {
    this.file = file;
  }

  handleConfirmTask = dropTask(async () => {
    // @ts-expect-error
    let { data, close } = this.args;
    let { avatarCandidate, confirmTask, hideCropper } = data;

    if (hideCropper) {
      this.file = LocalFile.create({
        fileName: avatarCandidate.fileName,
        fileContentType: 'image/png',
        fileUrl: avatarCandidate.fileUrl,
      });
    }
    await confirmTask.perform(close, this.file);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AvatarConfirmDialog: typeof AvatarConfirmDialog;
  }
}
