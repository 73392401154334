/* import __COLOCATED_TEMPLATE__ from './beneficiary-trust-popup.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface BeneficiaryTrustPopupSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BeneficiaryTrustPopup extends Component<BeneficiaryTrustPopupSignature> {
  @service declare segment: Services['segment'];

  @action
  handleClick() {
    // @ts-expect-error
    this.args.close();
    this.segment.track('beneficiary_trust_modal_cancel');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BeneficiaryTrustPopup: typeof BeneficiaryTrustPopup;
  }
}
