/* import __COLOCATED_TEMPLATE__ from './solution-wizard.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

// @ts-expect-error
import { TRAY_WIZARD_EVENTS } from 'qonto/constants/listeners';

interface QontoHubSetupSolutionWizardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLIFrameElement;
}

export default class QontoHubSetupSolutionWizardComponent extends Component<QontoHubSetupSolutionWizardSignature> {
  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    window.removeEventListener('message', this.handleMessage);
  }

  @action
  addEventListener() {
    window.addEventListener('message', this.handleMessage);
  }

  @action
  // @ts-expect-error
  handleMessage(evt) {
    // @ts-expect-error
    if (evt.origin === new URL(this.args.url).origin || window.location.origin) {
      if (evt.data.type === TRAY_WIZARD_EVENTS.CANCEL) {
        // @ts-expect-error
        this.args.onCancel?.();
      }
      if (evt.data.type === TRAY_WIZARD_EVENTS.FINISH) {
        // @ts-expect-error
        this.args.onFinish?.();
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::Setup::SolutionWizard': typeof QontoHubSetupSolutionWizardComponent;
  }
}
