/* import __COLOCATED_TEMPLATE__ from './message.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';

interface FlowsConciergeMessageSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsConciergeMessage extends Component<FlowsConciergeMessageSignature> {
  @service declare intl: Services['intl'];

  textAreaField = TextAreaField;

  // @ts-expect-error
  @tracked errorMessage;

  @action
  // @ts-expect-error
  handleOnInput(event) {
    let { value: message } = event.target;
    this.errorMessage = null;
    // @ts-expect-error
    this.args.context.conciergeRequest.inquiry = message;
  }

  @action
  handleNext() {
    // @ts-expect-error
    let { inquiry } = this.args.context.conciergeRequest;
    if (!inquiry || !inquiry.length) {
      this.errorMessage = this.intl.t('validations.errors.blank');
    } else {
      this.errorMessage = null;
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Concierge::Message': typeof FlowsConciergeMessage;
  }
}
