/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface BudgetsContentSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BudgetsContentComponent extends Component<BudgetsContentSignature> {
  @tracked scrolledTop = true;

  // @ts-expect-error
  @action updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budget::Content': typeof BudgetsContentComponent;
  }
}
