/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { CODES } from 'qonto/constants/empty-states/system';

interface EmptyStatesRequestsTasksDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesRequestsTasksDummyDataComponent extends Component<EmptyStatesRequestsTasksDummyDataSignature> {
  sortBy = `created_at:desc`;
  noop() {}

  get isRequestsDummyData() {
    // @ts-expect-error
    return this.args.emptyStateCode === CODES.ES_A3;
  }

  get isTasksDummyData() {
    // @ts-expect-error
    return this.args.emptyStateCode === CODES.ES_I3;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::TasksAndRequests::DummyData': typeof EmptyStatesRequestsTasksDummyDataComponent;
  }
}
