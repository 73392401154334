/* import __COLOCATED_TEMPLATE__ from './payment-limit.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS, FORMAT_PRICE_OPTIONS } from 'qonto/constants/cards';

interface CardSettingsPaymentLimitsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardSettingsPaymentLimitsComponent extends Component<CardSettingsPaymentLimitsSignature> {
  @service declare intl: Services['intl'];
  @tracked isAutoFocusDisabledDaily = true;
  @tracked isAutoFocusDisabledTransaction = true;

  // @ts-expect-error
  changeset = new Changeset(this.args.card);

  get hasTransactionLimit() {
    // @ts-expect-error
    return this.args.card.cardLevel !== CARD_LEVELS.ADVERTISING;
  }

  get monthlyLimitError() {
    let amount = this.changeset.paymentMonthlyLimit;

    if (amount > this.maxMonthlyLimit) {
      let formattedMaxMonthlyLimit = this.intl.formatNumber(
        this.maxMonthlyLimit,
        // @ts-expect-error
        FORMAT_PRICE_OPTIONS
      );

      return this.intl.t('cards.settings.payment-limit.error.too-high-limit', {
        amount: formattedMaxMonthlyLimit,
      });
    } else if (amount === null) {
      return this.intl.t('validations.errors.blank');
    }
  }

  get dailyLimitError() {
    let amount = this.changeset.paymentDailyLimit;
    let isChecked = this.changeset.paymentDailyLimitOption;
    if (amount > this.maxDailyLimit) {
      return this.intl.t('cards.settings.error.daily-limit-higher-than-monthly');
    } else if (isChecked && amount === null) {
      return this.intl.t('validations.errors.blank');
    }
  }

  get transactionLimitError() {
    let amount = this.changeset.paymentTransactionLimit;
    let isChecked = this.changeset.paymentTransactionLimitOption;
    if (amount > this.maxTransactionLimit) {
      return this.intl.t('cards.settings.payment-limit.error.too-high-per-transaction-limit');
    } else if (isChecked && amount === null) {
      return this.intl.t('validations.errors.blank');
    }
  }

  get cardLimitsOptions() {
    // @ts-expect-error
    return this.args.cardsMaxLimits[this.args.card.cardLevel];
  }

  get maxMonthlyLimit() {
    return this.cardLimitsOptions.payment_monthly_limit_maximum;
  }

  get maxDailyLimit() {
    let { changeset } = this;
    return changeset.paymentMonthlyLimit < this.maxMonthlyLimit
      ? changeset.paymentMonthlyLimit
      : this.maxMonthlyLimit;
  }

  get maxTransactionLimit() {
    let { changeset } = this;
    return changeset.paymentDailyLimit < this.maxDailyLimit
      ? changeset.paymentDailyLimit
      : this.maxDailyLimit;
  }

  get hasError() {
    let { changeset } = this;
    let hasInvalidDailyLimit =
      changeset.paymentDailyLimitOption &&
      (this.dailyLimitError || typeof changeset.paymentDailyLimit !== 'number');

    let hasInvalidTransactionLimit =
      changeset.paymentTransactionLimitOption &&
      (this.transactionLimitError || typeof changeset.paymentTransactionLimit !== 'number');

    return (
      this.monthlyLimitError ||
      typeof changeset.paymentMonthlyLimit !== 'number' ||
      hasInvalidDailyLimit ||
      hasInvalidTransactionLimit
    );
  }

  get isPaymentLimitExtended() {
    return this.maxMonthlyLimit === this.cardLimitsOptions.extended_payment_monthly_limit_maximum;
  }

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    // @ts-expect-error
    if (!this.args.disablePersistingState && !this.hasError) {
      this.changeset.execute();
    }
  }

  @action
  // @ts-expect-error
  setProperty(prop, val) {
    this.changeset.set(prop, val);
    this.persistState();
  }

  submitTask = dropTask(async () => {
    if (this.hasError) return;

    // This is meant as a failsafe for keyboard navigation because the AmountInput does not handle a fixed numberOfDecimal on arrow up or arrow down value update
    if (!Number.isInteger(this.changeset.paymentMonthlyLimit)) {
      this.setProperty('paymentMonthlyLimit', this.changeset.paymentMonthlyLimit.toFixed(0));
    }

    let isMonthlyLimitUpdatedAboveInitialMax = false;

    // @ts-expect-error
    if (this.args.card.isMetal) {
      let changedMonthlyLimit = this.changeset.changes.find(
        // @ts-expect-error
        change => change.key === 'paymentMonthlyLimit'
      );
      isMonthlyLimitUpdatedAboveInitialMax =
        changedMonthlyLimit?.value > this.cardLimitsOptions.initial_payment_monthly_limit_maximum;
    }

    this.changeset.execute();
    // @ts-expect-error
    await this.args.onSubmit(isMonthlyLimitUpdatedAboveInitialMax);
  });

  @action
  toggleTransactionLimit() {
    let { changeset } = this;
    let toggleTo = !changeset.paymentTransactionLimitOption;
    changeset.set('paymentTransactionLimit', toggleTo ? this.maxTransactionLimit : undefined);
    changeset.set('paymentTransactionLimitOption', toggleTo);
    this.isAutoFocusDisabledTransaction = false;
    this.persistState();
  }

  @action
  toggleDailyLimit() {
    let { changeset } = this;
    let toggleTo = !changeset.paymentDailyLimitOption;
    changeset.set('paymentDailyLimit', toggleTo ? this.maxDailyLimit : undefined);
    changeset.set('paymentDailyLimitOption', toggleTo);
    this.isAutoFocusDisabledDaily = false;
    this.persistState();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::PaymentLimit': typeof CardSettingsPaymentLimitsComponent;
  }
}
