/* import __COLOCATED_TEMPLATE__ from './vat.hbs'; */
import Component from '@glimmer/component';

import { VAT_RATES } from 'qonto/constants/vat';

const { NOT_AUTOMATIC, OTHER } = VAT_RATES;

interface CounterpartiesSidebarVatSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CounterpartiesSidebarVat extends Component<CounterpartiesSidebarVatSignature> {
  notSet = NOT_AUTOMATIC;

  get filteredValues() {
    // @ts-expect-error
    return this.args.values?.filter(value => value !== NOT_AUTOMATIC && value !== OTHER);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Sidebar::Vat': typeof CounterpartiesSidebarVat;
  }
}
