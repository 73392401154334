export default {
  "container": "nj",
  "container-content": "nW",
  "content": "nu",
  "underline": "iS",
  "inline-content": "ic",
  "payment-rate": "iq",
  "payment-subtitle": "iZ",
  "grey-text": "iR",
  "earnings-header": "iQ",
  "earnings-tooltip": "ie"
};
