/* import __COLOCATED_TEMPLATE__ from './custom-label.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

interface FiltersCustomLabelSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FiltersCustomLabel extends Component<FiltersCustomLabelSignature> {
  @service declare intl: Services['intl'];

  get selectedOptions() {
    // @ts-expect-error
    let { selectedLabels } = this.args;
    let { options } = this;

    if (!selectedLabels) return [];

    // @ts-expect-error
    return options.filter(option => selectedLabels.find(id => id === option.key));
  }

  @cached
  get options() {
    // @ts-expect-error
    let { showEmptyOption } = this.args;

    if (showEmptyOption) {
      return [this.emptyOption, ...this.labelOptions];
    }

    return this.labelOptions;
  }

  get labelOptions() {
    // @ts-expect-error
    let { list } = this.args;

    // @ts-expect-error
    return list.labels.map(({ id, name }) => ({ key: id, value: name }));
  }

  get label() {
    // @ts-expect-error
    let { name } = this.args.list;

    if (this.selectedOptions.length) {
      return `${name} (${this.selectedOptions.length})`;
    }

    return name;
  }

  get emptyOption() {
    return {
      key: 'missing',
      value: this.intl.t('custom-labels.filter.missing-label'),
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Filters::CustomLabel': typeof FiltersCustomLabel;
  }
}
