/* import __COLOCATED_TEMPLATE__ from './success-reorder.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CardImage } from 'qonto/react/cards/components/card-image';

interface FlowsCardsSuccessReorderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsSuccessReorder extends Component<FlowsCardsSuccessReorderSignature> {
  @service declare intl: Services['intl'];

  cardImage = CardImage;

  get title() {
    // @ts-expect-error
    return this.args.context.isUserCardHolder
      ? this.intl.t('cards.steps.success-reorder.title.holder')
      : this.intl.t('cards.steps.success-reorder.title.non-holder');
  }

  get subtitle() {
    // @ts-expect-error
    return this.args.context.isUserCardHolder
      ? this.intl.t('cards.steps.success-reorder.subtitle.holder')
      : this.intl.t('cards.steps.success-reorder.subtitle.non-holder');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::SuccessReorder': typeof FlowsCardsSuccessReorder;
  }
}
