/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { Item } from 'qonto/react/f24/components/table/index';
import { F24TablePlaceholder } from 'qonto/react/f24/components/table/placeholder';

interface F24TableSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24TableComponent extends Component<F24TableSignature> {
  item = Item;

  F24TablePlaceholder = F24TablePlaceholder;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Table': typeof F24TableComponent;
  }
}
