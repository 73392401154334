export default {
  "invite-review": "yd",
  "invite-review-info": "yI",
  "invite-review-info--invitee": "yt",
  "disclaimer-button": "yM",
  "info-block": "yP",
  "ev": "yl",
  "tooltip": "yX",
  "description": "yC"
};
