export default {
  "wrapper": "bI",
  "promotion-title": "bt",
  "comparison-table": "bM",
  "table-head": "bP",
  "title-wrapper": "bl",
  "illustration-wrapper": "bX",
  "illustration": "bC",
  "role-wrapper": "bk",
  "role-name": "bJ",
  "role-disabled": "bs",
  "role-access": "bF",
  "disabled-caption": "bg",
  "table-head-sticky": "bD",
  "show-border": "bV",
  "group-row": "bL",
  "table-body": "bT",
  "group": "bz",
  "table-body-row": "ba",
  "feature-description": "bH body-2",
  "feature": "bO body-2",
  "group-spacer": "bm"
};
