/* import __COLOCATED_TEMPLATE__ from './virtual-card-provide-kyc-intro.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlowsCardsKycVirtualCardProvideKycIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsCardsKycVirtualCardProvideKycIntro extends Component<FlowsCardsKycVirtualCardProvideKycIntroSignature> {
  @service declare segment: Services['segment'];

  @action
  handleClick() {
    // @ts-expect-error
    let { transitionToNext } = this.args;

    this.segment.track('submit_kyc_information_start', {
      origin: 'card_flow',
    });

    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Kyc::VirtualCardProvideKycIntro': typeof FlowsCardsKycVirtualCardProvideKycIntro;
  }
}
