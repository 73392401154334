/* import __COLOCATED_TEMPLATE__ from './italian-tax-regime-selector.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

// @ts-expect-error
import taxRegimes from 'qonto/constants/it-tax-regimes';

interface EinvoiceActivationFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EinvoiceActivationFormComponent extends Component<EinvoiceActivationFormSignature> {
  get selectedTaxRegimeOption() {
    // @ts-expect-error
    return this.taxRegimeOptions.find(option => option.key === this.args.taxRegime);
  }

  @cached
  get taxRegimeOptions() {
    return Object.entries(taxRegimes).map(([key, value]) => ({ key, value: `${key} - ${value}` }));
  }

  // @ts-expect-error
  @action updateTaxRegimeOption({ key }) {
    // @ts-expect-error
    this.args.onChange(key);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::CompanyDetails::ItalianTaxRegimeSelector': typeof EinvoiceActivationFormComponent;
  }
}
