export default {
  "connection-sidebar-header": "Fx",
  "title": "Fw",
  "close": "FN",
  "api-client": "Fv",
  "api-client-avatar": "Fo",
  "api-client-name": "Fn",
  "disclaimer": "Fi",
  "expired": "FA",
  "connect-button": "FY"
};
