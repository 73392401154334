/* import __COLOCATED_TEMPLATE__ from './dummy-table.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DummyTableSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DummyTable = templateOnlyComponent<DummyTableSignature>();

export default DummyTable;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::DummyTable': typeof DummyTable;
  }
}
