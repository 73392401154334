export default {
  "container": "tj",
  "is-sidebar-content": "tW",
  "no-sep": "tu",
  "details": "MS",
  "detail": "Mc",
  "name": "Mq",
  "content": "MZ",
  "content-text": "MR",
  "break-word": "MQ",
  "primary": "Me",
  "section": "MB",
  "infos-container": "ME",
  "infos-disclaimer": "Md",
  "upsell-container": "MI"
};
