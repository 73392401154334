/* import __COLOCATED_TEMPLATE__ from './request-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker, Disclaimer } from '@repo/design-system-kit';
import { dropTask, restartableTask } from 'ember-concurrency';

import { apiBaseURL, requestsV4Namespace } from 'qonto/constants/hosts';
import { MILEAGE_VEHICLE_DETAILS_STORAGE_KEY } from 'qonto/constants/requests';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { EditVehicle } from 'qonto/react/components/flows/request-mileage/request-details/edit-vehicle';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const IBAN_ERROR_CODES = ['not_valid_iban', 'not_a_string', 'blank', 'invalid'];

interface RequestDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestDetailsComponent extends Component<RequestDetailsSignature> {
  editVehicle = EditVehicle;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare abilities: Services['abilities'];
  @service declare modals: Services['modals'];

  // @ts-expect-error
  @tracked tripDate = this.args.context.requestMileage.tripDate
    ? // @ts-expect-error
      parseDate(this.args.context.requestMileage.tripDate)
    : null;
  // @ts-expect-error
  @tracked purpose = this.args.context.requestMileage.note;
  // @ts-expect-error
  @tracked iban = this.args.context.requestMileage.iban;
  @tracked amount = null;
  @tracked rate = null;
  @tracked ibanEdited = false;
  @tracked amountLoading = false;
  @tracked amountRequestError = false;

  @tracked hasTripDateError = false;
  @tracked purposeError = null;
  @tracked ibanError = null;

  datePicker = DatePicker;

  constructor(owner: unknown, args: RequestDetailsSignature['Args']) {
    super(owner, args);
    // We disable the eslint rule because errors are handled in the inner tasks
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.fetchAmountTask.perform();
  }

  get ibanDisclaimer() {
    // @ts-expect-error
    if (!this.args.context.ibanExists) {
      return this.intl.t('requests.mileage.steps.request-details.iban.description');
    }
    if (this.ibanEdited) {
      return this.intl.t('requests.mileage.steps.request-details.iban.edited');
    }

    return '';
  }

  get vehicleInfo() {
    // @ts-expect-error
    let { vehicles, requestMileage } = this.args.context;
    let { type, power, electric } = requestMileage.vehicle;
    let typeCopy = vehicles[type].label;
    // @ts-expect-error
    let powerCopy = vehicles[type].powerOptions?.find(option => option.value === power).label;
    return {
      type: typeCopy,
      power: powerCopy,
      electric,
    };
  }

  get distanceKms() {
    // @ts-expect-error
    return (this.args.context.requestMileage?.distanceMeters / 1000).toFixed(2);
  }

  get continueButtonLabel() {
    if (this.abilities.can('review mileage request')) {
      return this.intl.t('requests.mileage.steps.request-details.cta-admin');
    }

    return this.intl.t('requests.mileage.steps.request-details.cta');
  }

  @action
  // @ts-expect-error
  handleChangeTripDate(date, isInvalid) {
    this.tripDate = date;
    this.hasTripDateError = isInvalid;
    // @ts-expect-error
    this.args.context.requestMileage.tripDate = date?.toString();
  }

  @action
  // @ts-expect-error
  handleChangePurpose(value) {
    this.purpose = value;
    // @ts-expect-error
    this.args.context.requestMileage.note = value;
    // Reset purpose error
    this.purposeError = null;
  }

  @action
  // @ts-expect-error
  handleChangeIban(value) {
    this.iban = value;
    // @ts-expect-error
    this.args.context.requestMileage.iban = value;
    // Reset iban error
    this.ibanError = null;
    this.ibanEdited = true;
  }

  @action
  handleClickEditVehicle() {
    // @ts-expect-error
    this.args.context.shouldGoToVehicleDetailsStep = true;
    this.segment.track('request_edit_vehicle_clicked', {
      origin: 'mileage_request',
    });
    // @ts-expect-error
    this.args.transitionToNext();
  }

  fetchAmountTask = restartableTask(async () => {
    // @ts-expect-error
    let { requestMileage } = this.args.context;
    this.amountLoading = true;
    try {
      let { organization } = this.organizationManager;

      let { id: organizationId } = organization;

      if (organization.isFrench && requestMileage.vehicle.electric === undefined) {
        requestMileage.vehicle.electric = false;
      }

      let vehicleData = requestMileage.vehicle ? { ...requestMileage.vehicle } : null;
      if (vehicleData && vehicleData.power === null) delete vehicleData.power;

      let response = await this.networkManager.request(
        `${apiBaseURL}/${requestsV4Namespace}/requests/mileages/calculate_amount`,
        {
          method: 'POST',
          headers: { 'X-Qonto-Organization-ID': organizationId },
          data: JSON.stringify({
            organization_id: organizationId,
            // @ts-expect-error
            distance_meters: this.args.context.requestMileage.distanceMeters,
            ...(vehicleData && { vehicle: vehicleData }),
          }),
        }
      );

      this.amount = response.amount;
      this.rate = response.mileage_calculation.rate ?? response.mileage_calculation.rates[0];
      // @ts-expect-error
      this.mileageCalculation = response.mileage_calculation;
      // @ts-expect-error
      this.args.context.requestMileage.amount = response.amount;
    } catch (error) {
      // We don't want to handle this specific error
      // so we just log the error to sentry
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.amountRequestError = true;
    } finally {
      this.amountLoading = false;
    }
  });

  saveMileageRequestTask = dropTask(async () => {
    try {
      this.validate();
      if (this.ibanEdited) {
        await this.saveIbanTask.perform();
      }

      if (this.hasErrors) {
        return;
      }

      // @ts-expect-error
      await this.args.context.requestMileage.save();

      this.segment.track('request_created', {
        request_type: 'mileage',
        role: this.organizationManager.membership.role,
      });

      // @ts-expect-error
      if (this.args.context.requestMileage.vehicle) {
        // @ts-expect-error
        this._saveVehicleDetailsLocalStorage(this.args.context.requestMileage.vehicle);
      }

      // @ts-expect-error
      this.args.context.shouldGoToVehicleDetailsStep = false;
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.unexpected_error_occured'));
    }
  });

  saveIbanTask = dropTask(async () => {
    let { membership } = this.organizationManager;

    try {
      // @ts-expect-error
      membership.iban = this.args.context.requestMileage.iban;

      // @ts-expect-error
      if (!this.currentIban) {
        await membership.createIban();
      } else {
        await membership.updateIban();
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.handleIbanError(error);
    }
  });

  // @ts-expect-error
  handleIbanError(error) {
    if (
      Array.isArray(error.errors) &&
      // @ts-expect-error
      error.errors.some(({ detail }) => IBAN_ERROR_CODES.includes(detail.code))
    ) {
      // @ts-expect-error
      this.ibanError = this.intl.t('validations.errors.invalid_iban');
    }
  }

  customValidationMessages = {
    // @ts-expect-error
    valueMissing: this.intl.t('validations.errors.blank'),
  };

  validate() {
    // @ts-expect-error
    this.purposeError = !this.purpose ? this.intl.t('validations.errors.blank') : null;
    // @ts-expect-error
    this.ibanError = !this.iban ? this.intl.t('validations.errors.blank') : null;
  }

  // @ts-expect-error
  _saveVehicleDetailsLocalStorage(vehicle) {
    return safeLocalStorage.setItem(MILEAGE_VEHICLE_DETAILS_STORAGE_KEY, JSON.stringify(vehicle));
  }

  get hasErrors() {
    return Boolean(this.hasTripDateError || this.purposeError || this.ibanError);
  }

  get shouldHideCalculationDetails() {
    let { organization } = this.organizationManager;
    return this.abilities.can('use mileage improvements request') && organization.isFrench;
  }

  @action
  handleCalculationAmountClick() {
    this.segment.track('request_calculation-details_clicked', {
      origin: 'mileage_request',
    });

    this.modals.open('mileage/modals/mileage-calculation-details', {
      isFullScreenModal: true,
      amount: this.amount,
      // @ts-expect-error
      mileageCalculation: this.mileageCalculation,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::RequestMileage::RequestDetails': typeof RequestDetailsComponent;
  }
}
