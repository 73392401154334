/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';
import fade from 'ember-animated/transitions/fade';
import { task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

interface ByLawsUploadSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ByLawsUploadComponent extends Component<ByLawsUploadSignature> {
  checkbox = Checkbox;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare featuresManager: Services['featuresManager'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @reads('featuresManager.flags.qontoNotary') isQontoNotaryEnabled;

  @tracked isFundsOpen = false;
  @tracked isStatementOpen = false;
  @tracked isConfirmationChecked = false;
  @tracked showAnnexCheckboxError = false;

  transition = fade;

  @action
  toggleFundsPanel() {
    this.isFundsOpen = !this.isFundsOpen;
  }

  @action
  toggleStatementsPanel() {
    this.isStatementOpen = !this.isStatementOpen;
  }

  @action
  toggleConfirmationChecked() {
    this.isConfirmationChecked = !this.isConfirmationChecked;

    if (this.isConfirmationChecked) {
      this.showAnnexCheckboxError = false;
    }
  }

  saveByLawsTask = task(async () => {
    this.segment.track('kdeposit_bylaws_annex_confirmed');

    if (this.isConfirmationChecked) {
      this.segment.track('kdeposit_bylaws_annex_checked');
      try {
        // @ts-expect-error
        await this.args.saveDocuments();
        // @ts-expect-error
        this.args.goNext();
      } catch (error) {
        let message = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
        this.sentry.captureException(error);
      }
    } else {
      this.showAnnexCheckboxError = true;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ByLaws::Confirm': typeof ByLawsUploadComponent;
  }
}
