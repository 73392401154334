/* import __COLOCATED_TEMPLATE__ from './upload-other.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface UploadOtherSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const UploadOther = templateOnlyComponent<UploadOtherSignature>();

export default UploadOther;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::UploadOther': typeof UploadOther;
  }
}
