/* import __COLOCATED_TEMPLATE__ from './receipt.hbs'; */
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask, rawTimeout } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export const WAIT_FOR_OCR_SCAN = macroCondition(isTesting()) ? 500 : 6000;
export const OCR_EVENT_NAME = 'expense_reports_receipt.analyzed';
const RECEIPT_MAX_SIZE = 15 * 1e6; // 15MB

interface ReceiptSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReceiptComponent extends Component<ReceiptSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare localeManager: Services['localeManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  fileMaxFileSize = RECEIPT_MAX_SIZE;

  @tracked files = [];
  @tracked processing = false;
  // @ts-expect-error
  @tracked model = this.args.context.requestExpenseReport;

  constructor(owner: unknown, args: ReceiptSignature['Args']) {
    super(owner, args);

    schedule('afterRender', () => (this.model.attachments = []));
    // @ts-expect-error
    this.notifierManager.on(OCR_EVENT_NAME, this, 'handleVATandAmount');
  }

  willDestroy() {
    // @ts-expect-error
    this.notifierManager.off(OCR_EVENT_NAME, this, 'handleVATandAmount');
    this.triggerReceiptOCRTask.cancelAll();
    this.timeoutEventTask.cancelAll();

    super.willDestroy();
  }

  get hasBankAccountFeature() {
    return this.subscriptionManager.hasFeature('bank_account');
  }

  get dropZoneLabel() {
    return this.intl.t('labels.upload-message', {
      maxSize: formatFileSize(this.intl, RECEIPT_MAX_SIZE),
    });
  }

  get uploadOptions() {
    let { organization, currentAccount } = this.organizationManager;

    return {
      payload: {
        fileKey: 'attachment[file]',
        data: {
          'attachment[organization_id]': organization.id,
          'attachment[bank_account_id]': currentAccount,
        },
      },
      url: 'v3/attachments',
      model: 'attachment',
    };
  }

  get amount() {
    return { value: null, currency: 'EUR' };
  }

  @action
  handleCancelFile() {
    this.segment.track('reimbursement_request_inprogress_cancel_started');
    this.files = [];
  }

  @action
  // @ts-expect-error
  handleFileUploaded(fileModel, attachment) {
    if (fileModel) {
      let { id, file } = attachment;

      fileModel.file.filename = fileModel.file.name;
      fileModel.file.filesizeBytes = fileModel.file.size;
      fileModel.file.url = file.fileUrl;

      // @ts-expect-error
      this.files = [...this.files, fileModel.file];

      this.model.attachments = [attachment];

      this.triggerReceiptOCRTask.perform(id).catch(ignoreCancelation);
      this.timeoutEventTask.perform().catch(ignoreCancelation);

      this.segment.track('reimbursement_receipt_upload_confirmed');
    }
  }

  @action
  // @ts-expect-error
  handleFileUploadedError(error) {
    if (error) {
      this.segment.track('reimbursement_receipt_upload_failed');
      return this.toastFlashMessages.toastError(
        this.intl.t('requests.reimbursement.steps.loading.failed')
      );
    }
  }

  // @ts-expect-error
  handleVATandAmount({ organization_id, object }) {
    let { organization } = this.organizationManager;

    if (organization_id === organization.id) {
      let { amount, vat_amount, vat_rate } = object.expense_reports_receipt;

      this.model.amount = amount ?? this.amount;
      this.model.vatAmount = vat_amount;
      this.model.vatRate = parseFloat(vat_rate);
      this.processing = false;

      this._checkVATAndAmount(this.model.amount, this.model.vatRate);
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }

  triggerReceiptOCRTask = dropTask(async attachmentId => {
    this.processing = true;
    this.segment.track('reimbursement_receipt_upload_filepicker');

    let organizationId = this.organizationManager.organization.id;

    let url = `${apiBaseURL}/v3/expense_reports_receipts`;
    let data = {
      expense_reports_receipt: { attachment_id: attachmentId, organization_id: organizationId },
    };

    try {
      await this.networkManager.request(url, {
        method: 'POST',
        data: JSON.stringify(data),
      });
    } catch (error) {
      // @ts-expect-error
      if (error?.status === 422) {
        this.sentry.captureException(error);
      }
    }
  });

  timeoutEventTask = dropTask(async () => {
    await rawTimeout(WAIT_FOR_OCR_SCAN);
    this.model.amount = this.amount;
    this.model.vatAmount = null;
    this.model.vatRate = null;
    this.processing = false;

    this._checkVATAndAmount(this.model.amount, this.model.vatRate);
    // @ts-expect-error
    this.args.transitionToNext();
  });

  // @ts-expect-error
  _checkVATAndAmount(amount, vatRate) {
    if (!amount?.value && !vatRate) {
      this.segment.track('reimbursement_OCR_scan_failed');
      this.toastFlashMessages.toastInfo(
        this.intl.t('requests.reimbursement.steps.bank-details.toast'),
        // @ts-expect-error
        'icon_info_rounded_filled'
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::RequestExpenseReport::Receipt': typeof ReceiptComponent;
  }
}
