/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FooterSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Footer = templateOnlyComponent<FooterSignature>();

export default Footer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::SidebarSubscription::Footer': typeof Footer;
  }
}
