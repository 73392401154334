import { run } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask, task, waitForProperty, waitForQueue } from 'ember-concurrency';

import ENV from 'qonto/config/environment';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const CULTURES = {
  fr: 'fr_FR',
  sv: 'sv_SE',
  de: 'de_DE',
  zh: 'zh_CN',
  da: 'da_DK',
  nl: 'nl_NL',
  es: 'es_ES',
  pt: 'pt_BR',
  pl: 'pl_PL',
};

const FOREVER = new Promise(() => {});

interface HellosignDocumentSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class HellosignDocument extends Component<HellosignDocumentSignature> {
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  async loadHelloSign() {
    try {
      // @ts-expect-error
      return (await import('hellosign-embedded/umd/embedded.production.min')).default;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  }

  // @ts-expect-error
  @tracked HelloSign;

  constructor(owner: unknown, args: HellosignDocumentSignature['Args']) {
    super(owner, args);
    /* eslint-disable ember-concurrency/no-perform-without-catch */
    this.setupTask.perform(); // Errors are handled in the task
    this.openTask.perform(); // Task will never fail and should be close when the component is destroyed
    /* eslint-enable ember-concurrency/no-perform-without-catch */
  }

  setupTask = dropTask(async () => {
    if (macroCondition(isTesting())) {
      class FakeHelloSign {
        constructor() {
          // @ts-expect-error
          this._listeners = new Map();
        }

        init() {}

        open() {
          setTimeout(() => this._sendMessage('sign'), 0);
        }

        close() {}

        // @ts-expect-error
        on(type, callback) {
          // @ts-expect-error
          this._listeners.set(type, callback);
        }

        // @ts-expect-error
        _sendMessage(event) {
          // @ts-expect-error
          this._listeners.get(event)();
        }
      }

      this.HelloSign = FakeHelloSign;
    } else {
      this.HelloSign = await this.loadHelloSign();
    }
  });

  openTask = task(async () => {
    await waitForQueue('afterRender');
    // @ts-expect-error
    await waitForProperty(this, 'args.url');

    // Will place HS container in the div locally and on test ENV once the testing strategy is decided
    // const hsOptions = ['development', 'test'].includes(ENV.environment)
    //   ? {container: document.querySelector('.l-block')}
    //   : {}

    // @ts-expect-error
    await waitForProperty(this, 'HelloSign');

    let { HelloSign } = this;
    // @ts-expect-error
    let { url, contractType } = this.args;
    // @ts-expect-error
    let { clientID, skipDomainVerification, debug } = ENV.helloSign[contractType];

    // @ts-expect-error
    let locale = CULTURES[this.localeManager.locale] || 'en_US';

    let helloSign = new HelloSign({ clientId: clientID });

    helloSign.open(url, { allowCancel: true, debug, skipDomainVerification, locale });

    helloSign.on('sign', () => {
      helloSign.close();
      // @ts-expect-error
      run(() => this.args.onSigned());
    });
    // @ts-expect-error
    helloSign.on('decline', () => run(() => this.args.onDeclined()));
    // @ts-expect-error
    helloSign.on('cancel', () => run(() => this.args.onCanceled()));
    // @ts-expect-error
    helloSign.on('error', () => run(() => this.args.onError()));

    try {
      // we will wait "forever" here, which means that the `finally` block
      // gets executed once the component is destroyed.
      await FOREVER;
    } finally {
      helloSign.close();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HellosignDocument: typeof HellosignDocument;
  }
}
