export default {
  "modal": "gs",
  "features": "gF",
  "close-button": "gg",
  "header": "gD title-2",
  "header-text": "gV body-2",
  "feature": "gL",
  "subheader": "gT title-4",
  "text": "gz body-2",
  "cta": "ga",
  "container-img": "gH",
  "img": "gO",
  "link": "gm"
};
