/* import __COLOCATED_TEMPLATE__ from './card-acquirer-value-proposition-wrapper.hbs'; */
import Component from '@glimmer/component';

import { CardAcquirerValuePropositionModal } from 'qonto/react/card-acquirer/components/modals/value-proposition';

interface CardAcquirerValuePropositionWrapperSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
}

export default class CardAcquirerValuePropositionWrapper extends Component<CardAcquirerValuePropositionWrapperSignature> {
  cardAcquirerValuePropositionModal = CardAcquirerValuePropositionModal;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::CardAcquirerValuePropositionWrapper': typeof CardAcquirerValuePropositionWrapper;
  }
}
