/* import __COLOCATED_TEMPLATE__ from './set-pin.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { PinCodeInput } from 'qonto/react/cards/components/pin-code-input';

interface FLowsCardsSetPinSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FLowsCardsSetPin extends Component<FLowsCardsSetPinSignature> {
  pinCodeInput = PinCodeInput;

  @service declare intl: Services['intl'];

  @tracked pinError = '';

  @action
  // @ts-expect-error
  pinChanged(pin) {
    // @ts-expect-error
    let { card } = this.args.context;
    card.pin = pin;

    this.clearInputError();
    this.validatePin(card);
  }

  @action
  // @ts-expect-error
  continueToNextStep(event) {
    event.preventDefault();

    // @ts-expect-error
    let { card } = this.args.context;

    this.validatePin(card);

    if (card.validations.attrs.pin.isValid) {
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }

  // @ts-expect-error
  validatePin(card) {
    if (!this.isNumber(card.pin)) {
      this.pinError = this.intl.t('validations.errors.pin_not_a_number');
    } else if (!card.pin || card.pin.length < 4) {
      this.pinError = this.intl.t('validations.errors.pin_invalid_length');
    } else if (card.pin.length === 4) {
      this.pinError = card.validations.attrs.pin.message;
    }
  }

  clearInputError() {
    this.pinError = '';
  }

  // @ts-expect-error
  isNumber(pin) {
    return !pin || /^[0-9]+$/.test(pin);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::SetPin': typeof FLowsCardsSetPin;
  }
}
