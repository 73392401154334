/* import __COLOCATED_TEMPLATE__ from './sort-cell.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { SortIcon } from 'qonto/react/components/paginated-table';

interface ClientsTableHeaderSortCellSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableCellElement;
}

export default class ClientsTableHeaderSortCellComponent extends Component<ClientsTableHeaderSortCellSignature> {
  sortIcon = SortIcon;

  get sortField() {
    // @ts-expect-error
    let { sortBy } = this.args;
    return sortBy.includes(':') ? sortBy.split(':')[0] : sortBy;
  }

  get isAscending() {
    // @ts-expect-error
    let { sortBy } = this.args;
    return sortBy.includes('asc');
  }

  @action
  // @ts-expect-error
  handleSortBy(sortBy) {
    // @ts-expect-error
    let { handleSortBy } = this.args;

    //Revert the order when we click on the same column
    if (sortBy === this.sortField) {
      sortBy = this.isAscending ? sortBy.split(':')[0] : sortBy + ':asc';
    }

    return handleSortBy?.(sortBy);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Clients::Table::Header::SortCell': typeof ClientsTableHeaderSortCellComponent;
  }
}
