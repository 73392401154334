export default {
  "wrapper": "Qx",
  "title": "Qw",
  "button-icon": "QN",
  "download-button": "Qv",
  "specification-list": "Qo",
  "certified-details-wrapper": "Qn",
  "details-wrapper": "Qi",
  "certified-specification-list": "QA",
  "specification": "QY",
  "icon": "Qh",
  "cross": "Qf",
  "checkmark": "QK",
  "pit-link": "QG"
};
