/* import __COLOCATED_TEMPLATE__ from './success-retention.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

interface SubscriptionRetentionCloseStepsReasonSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionRetentionCloseStepsReasonSuccessComponent extends Component<SubscriptionRetentionCloseStepsReasonSuccessSignature> {
  lottiePlayer = LottiePlayer;
  @service declare flow: Services['flow'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  @tracked animationReady = false;

  constructor(owner: unknown, args: SubscriptionRetentionCloseStepsReasonSuccessSignature['Args']) {
    super(owner, args);
    this.flow.dataContext.continueClosure = false;
    this.segment.track('account-closure_voucher-offer_completed', {
      current_plan: this.currentPlan,
    });
  }

  @action redirectToBilling() {
    this.router.transitionTo('settings.price-plan.discounts');
  }

  get currentPlan() {
    return this.subscriptionManager.currentPricePlan?.localName;
  }

  @action displayLottie() {
    // see https://github.com/emberjs/ember.js/issues/17202#issuecomment-437927226
    this.animationReady = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::SuccessRetention': typeof SubscriptionRetentionCloseStepsReasonSuccessComponent;
  }
}
