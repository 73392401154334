/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
// @ts-nocheck
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge, ExternalLink } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import { NAVATIC_EMPLOYEE_TOUR_URLS } from 'qonto/constants/member/role-selection';
import { ROLES } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FlowsMemberRoleSelectionItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsMemberRoleSelectionItemComponent extends Component<FlowsMemberRoleSelectionItemSignature> {
  badgeHighlight = Badge;
  externalLink = ExternalLink;

  @service declare memberManager: Services['memberManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare segment: Services['segment'];

  @action onClick() {
    this.onClickTask.perform().catch(ignoreCancelation);
  }

  @action onNavatticTourClick() {
    this.segment.track('team-onboarding_employee-view_product-tour_clicked');
  }

  BADGE_TYPE = BADGE_TYPE;

  onClickTask = dropTask(async () => {
    // @ts-expect-error
    let { context, transitionToNext, role, pushFlow } = this.args;
    await this.memberManager.handleSelectRoleTask
      .unlinked()
      // @ts-expect-error
      .perform(context, role, transitionToNext, pushFlow);
  });

  get navaticEmployeeTourHref() {
    return NAVATIC_EMPLOYEE_TOUR_URLS[this.localeManager.locale];
  }

  get displayNavatticEmployeeTour() {
    // @ts-expect-error
    let { role } = this.args;
    return (
      variation('feature--boolean-add-navattic-tour-invitation-funnel') &&
      role.key === ROLES.EMPLOYEE &&
      !role.currentRole
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::RoleSelection::Item': typeof FlowsMemberRoleSelectionItemComponent;
  }
}
