/* import __COLOCATED_TEMPLATE__ from './upload-documents.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TrackedArray } from 'tracked-built-ins';

import { CLAIM_TYPES } from 'qonto/constants/transactions';
import { StepLayout } from 'qonto/react/components/flows/chargeback-claim/step-layout/step-layout';

interface FlowsCardChargebackUploadDocumentsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardChargebackUploadDocumentsComponent extends Component<FlowsCardChargebackUploadDocumentsSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  stepLayout = StepLayout;

  errors = new TrackedArray();

  get isAtm() {
    // @ts-expect-error
    return this.args.context.claimType === CLAIM_TYPES.atm;
  }

  get subtitle() {
    return this.isAtm
      ? this.intl.t('dispute-flow.documents-upload.subtitle.atm')
      : this.intl.t('dispute-flow.documents-upload.subtitle.fraud');
  }

  @action
  continue() {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;

    this.segment.track('cards-chargeback_document-upload-continue-cta_clicked', {
      type: context.claimType,
    });

    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::UploadDocuments': typeof FlowsCardChargebackUploadDocumentsComponent;
  }
}
