export default {
  "step-container": "ccU",
  "content": "ccj",
  "sticky-panel": "ccW",
  "banner": "ccu",
  "banner-content": "cqS",
  "banner-text": "cqc",
  "banner-image": "cqq",
  "banner-title": "cqZ",
  "banner-description": "cqR body-2",
  "link": "cqQ"
};
