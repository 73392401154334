/* import __COLOCATED_TEMPLATE__ from './info.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

const FIELDS = ['firstName', 'lastName', 'email', 'capitalAmount'];

interface CapitalDepositStakeholderInfoSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CapitalDepositStakeholderInfoComponent extends Component<CapitalDepositStakeholderInfoSignature> {
  amountField = AmountField;

  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('args.context.stakeholder.didValidate') isValidationEnabled;
  @tracked isAmountInputErrorVisible = false;

  nextStepTask = dropTask(async stakeholder => {
    let s = await stakeholder.validate({ on: FIELDS });
    this.isAmountInputErrorVisible = true;

    if (s.validations.isValid) {
      this.isAmountInputErrorVisible = false;
      stakeholder.set('didValidate', false);
      this.segment.track('kdeposit_shareholder_capital_shares_filled');
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      stakeholder.set('didValidate', true);
    }
  });

  @action
  // @ts-expect-error
  updateCapitalAmount(value) {
    this.isAmountInputErrorVisible = false;
    // @ts-expect-error
    this.args.context.stakeholder.capitalAmount = parseFloat(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CapitalDeposit::Stakeholder::Info': typeof CapitalDepositStakeholderInfoComponent;
  }
}
