/* import __COLOCATED_TEMPLATE__ from './connector-card-loading.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ConnectorCardLoadingSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ConnectorCardLoading = templateOnlyComponent<ConnectorCardLoadingSignature>();

export default ConnectorCardLoading;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::ConnectorCardLoading': typeof ConnectorCardLoading;
  }
}
