/* import __COLOCATED_TEMPLATE__ from './reason.hbs'; */
/* eslint-disable ember/no-side-effects */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { RadioButtonControlled, TextAreaField } from '@repo/design-system-kit';
import { wait } from 'ember-animated';
import { easeIn, easeOut } from 'ember-animated/easings/cosine';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { apiBaseURL } from 'qonto/constants/hosts';

import type { DataContext } from './data-context';

interface Signature {
  // The transfer quote cannot be `null`, as this step is only accessible once a quote has been created
  Args: FlowStepArgs<DataContext>;
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}
export default class SubscriptionCloseReasonComponent extends Component<Signature> {
  textAreaField = TextAreaField;
  radioButtonControlled = RadioButtonControlled;

  @tracked showError = false;
  @tracked selectedReason: { key: string } | null = null;
  @tracked bannerVisible: boolean | null = null;
  @tracked currentPlan = null;
  @tracked suggestedPlan = null;
  @tracked memoizedBannerType = null;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare flow: Services['flow'];
  @service declare router: Services['router'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare store: Services['store'];
  @service declare zendeskWidget: Services['zendeskWidget'];

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.selectedReason = this.args.context.selectedReason || null;
    this.bannerVisible = this.args.context.isBannerVisible || null;
    this.initializePlans();
    this.zendeskWidget.show();
  }

  async initializePlans() {
    try {
      // eslint-disable-next-line no-restricted-syntax
      let currentPlanGroup = this.subscriptionManager.currentPricePlan.groupCode;
      let suggestedPlanCode = this.suggestedDowngradePlan;

      let allPricePlans = await this.store.query('subscription-product', { type: 'core' });

      // @ts-expect-error
      this.currentPlan = allPricePlans.find(plan => plan.code.includes(currentPlanGroup));
      // @ts-expect-error
      this.suggestedPlan = suggestedPlanCode
        ? allPricePlans.find(plan => plan.code.includes(suggestedPlanCode))
        : null;

      this.memoizedBannerType = null;
    } catch (error) {
      this.sentry.captureException(error);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  // @ts-expect-error
  #formatPrice(price) {
    if (!price) return '';
    return this.intl.formatNumber(Number(price.perMonthAmount.value), {
      currency: price.perMonthAmount.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
      notation: 'compact',
    });
  }

  fetchCsCallbackTask = dropTask(async () => {
    try {
      let { organization } = this.organizationManager;
      let { closingReasonCategory } = this.flow.dataContext;

      this.flow.dataContext.shouldDisplayCallbackBanner = this.shouldDisplayCallbackBanner;

      const url = `${apiBaseURL}/v3/organizations/${organization.id}/deactivation/cs_call_eligibility?reason_category=${closingReasonCategory?.key}`;

      const response = await this.networkManager.request(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Qonto-Organization-ID': organization.id,
        },
      });

      if (this.flow.dataContext.shouldDisplayCallbackBanner) {
        this.flow.dataContext.shouldDisplayCsCallback = false;
      } else {
        this.flow.dataContext.shouldDisplayCsCallback = response.cs_callback;
      }
    } catch {
      this.flow.dataContext.shouldDisplayCsCallback = false;
      this.flow.dataContext.shouldDisplayCallbackBanner = false;
      this.toastFlashMessages.toastError(
        this.intl.t('subscription.account-closing.book-a-call.error-toast')
      );
    }
  });
  // @ts-expect-error
  getPlanPrice(planType, priceType) {
    let plan;
    switch (planType) {
      case 'current':
        plan = this.currentPlan;
        break;
      case 'suggested':
        plan = this.suggestedPlan;
        break;
      default:
        return '';
    }

    let price;
    switch (priceType) {
      case 'monthly':
        // @ts-expect-error
        price = plan?.monthlyPrice;
        break;
      case 'annual':
        // @ts-expect-error
        price = plan?.annualPrice;
        break;
      default:
        return '';
    }

    return this.#formatPrice(price);
  }

  get currentPlanPriceMonthly() {
    return this.getPlanPrice('current', 'monthly');
  }

  get currentPlanPriceAnnual() {
    return this.getPlanPrice('current', 'annual');
  }

  get suggestedPlanPriceMonthly() {
    return this.getPlanPrice('suggested', 'monthly');
  }

  get suggestedPlanPriceAnnual() {
    return this.getPlanPrice('suggested', 'annual');
  }

  get suggestedPlanBillingInfo() {
    // @ts-expect-error
    if (!this.suggestedPlan || !this.suggestedPlan.annualPrice) return '';
    // @ts-expect-error
    return this.suggestedPlan.annualPrice.billingRecurrence;
  }

  get currentPlanBillingInfo() {
    // @ts-expect-error
    if (!this.currentPlan || !this.currentPlan.annualPrice) return '';
    // @ts-expect-error
    return this.currentPlan.annualPrice.billingRecurrence;
  }

  get suggestedDowngradePlan() {
    let downgradeMapping = {
      solo_smart: 'solo_basic',
      solo_premium: 'solo_smart',
      team_business: 'team_essential',
      team_enterprise: 'team_business',
    };

    // eslint-disable-next-line no-restricted-syntax
    let currentPlanCode = this.subscriptionManager.currentPricePlan.groupCode;
    // @ts-expect-error
    return downgradeMapping[currentPlanCode] || null;
  }

  get bannerType() {
    if (this.memoizedBannerType !== null) {
      return this.memoizedBannerType;
    }

    let { currentSubscription, currentPricePlan } = this.subscriptionManager;
    let recurrence = currentSubscription?.recurrence;
    let currentPlanGroup = currentPricePlan?.groupCode;
    let selectedReasonKey = this.selectedReason?.key;

    if (selectedReasonKey !== 'account_fees') {
      this.memoizedBannerType = null;
      return null;
    }

    let isPartOfABTest = variation('feature--improve-retention-ab-test');
    let isExpandedCsCallback = variation('feature--boolean-expand-cs-callback-retention');

    if (recurrence === 'monthly') {
      if (['solo_basic', 'team_essential'].includes(currentPlanGroup)) {
        if (!isExpandedCsCallback) {
          this.trackEventOnce('account-closing_change-recurrence_displayed', {
            current_plan: currentPlanGroup,
          });
          // @ts-expect-error
          this.memoizedBannerType = 'switch-to-annual';
          return 'switch-to-annual';
        }
      } else {
        if (isPartOfABTest) {
          // Only show switch-to-annual banner if CS callback feature is OFF
          if (!isExpandedCsCallback) {
            this.trackEventOnce('account-closing_change-recurrence_displayed', {
              current_plan: currentPlanGroup,
            });
            this.trackEventOnce('account-closing_subscription-fees-tactics_displayed', {
              Retention_tactic: 'change_recurrence',
            });
            // @ts-expect-error
            this.memoizedBannerType = 'switch-to-annual';
            return 'switch-to-annual';
          }
        } else {
          this.trackEventOnce('account-closing_switch-plan_displayed', {
            current_plan: currentPlanGroup,
            suggested_plan: this.suggestedDowngradePlan,
          });
          this.trackEventOnce('account-closing_subscription-fees-tactics_displayed', {
            Retention_tactic: 'switch_plan',
          });
          // @ts-expect-error
          this.memoizedBannerType = 'downgrade-plan';
          return 'downgrade-plan';
        }
      }
    } else if (recurrence === 'annual') {
      if (!['solo_basic', 'team_essential'].includes(currentPlanGroup)) {
        this.trackEventOnce('account-closing_annual-switch-plan_displayed', {
          current_plan: currentPlanGroup,
          suggested_plan: this.suggestedDowngradePlan,
        });
        // @ts-expect-error
        this.memoizedBannerType = 'annual-downgrade-plan';
        return 'annual-downgrade-plan';
      }
    }

    this.memoizedBannerType = null;
    return null;
  }

  get shouldDisplayCallbackBanner() {
    const legalCountry = this.organizationManager.organization.legalCountry;
    const isNL = legalCountry === 'NL';

    // eslint-disable-next-line no-restricted-syntax -- We need to check the groupCode for price plan validation
    const currentGroupCode = this.subscriptionManager?.currentPricePlan?.groupCode;
    const isSoloBasic = currentGroupCode === 'solo_basic';
    const isTeamEssential = currentGroupCode === 'team_essential';

    const closingReasonCategory = this.args.context.closingReasonCategory?.key;
    const isCompanyClosing = closingReasonCategory === 'company_closure';
    const isMissingFeatures = closingReasonCategory === 'missing_features';
    const isPrice = closingReasonCategory === 'price';
    const isServiceQuality = closingReasonCategory === 'service_quality';

    let shouldDisplay = false;

    if (isMissingFeatures) {
      shouldDisplay = !isSoloBasic && !isNL;
    } else if (isPrice) {
      shouldDisplay = !isSoloBasic && !isTeamEssential && !isNL;
    } else if (!isCompanyClosing && !isServiceQuality) {
      shouldDisplay = true;
    }

    const isDisplayingBanner = shouldDisplay;

    if (isDisplayingBanner) {
      this.segment.track('account-closing_cs-callback_banner-displayed', {
        pricePlan: this.subscriptionManager.currentPricePlan?.code,
        closingReasonCategory,
      });
    }

    return isDisplayingBanner;
  }

  // @ts-expect-error
  trackEventOnce(eventName, data) {
    // @ts-expect-error
    if (!this.segment.eventCache) {
      // @ts-expect-error
      this.segment.eventCache = new Set();
    }
    // @ts-expect-error
    if (!this.segment.eventCache.has(eventName)) {
      this.segment.track(eventName, data);
      // @ts-expect-error
      this.segment.eventCache.add(eventName);
    }
  }

  get shouldShowBanner() {
    if (this.bannerType === 'downgrade-plan' || this.bannerType === 'annual-downgrade-plan') {
      this.initializePlans();
    }
    return this.bannerType !== null;
  }

  // @ts-expect-error
  *customFadeTransition({ insertedSprites, removedSprites, duration = 75, delay = 25 }) {
    for (let sprite of insertedSprites) {
      yield wait(delay);
      fadeIn(sprite, { duration, easing: easeIn });
    }

    for (let sprite of removedSprites) {
      yield wait(delay);
      fadeOut(sprite, { duration, easing: easeOut });
    }
  }

  get hasError() {
    let { hasReasonError, subReasonError } = this;
    return hasReasonError || subReasonError;
  }

  get hasReasonError() {
    let { closingReasonSubCategory } = this.args.context;
    return !closingReasonSubCategory;
  }

  get subReasonError() {
    let { closingReasonDetails, closingReasonSubCategory } = this.args.context;
    return closingReasonSubCategory?.items?.length && !closingReasonDetails
      ? this.intl.t('subscription.account-closing.reason.select-reason')
      : null;
  }

  @action selectReason(item: DataContext['selectedReason' | 'closingReasonSubCategory']) {
    let { context } = this.args;
    this.showError = false;
    context.closingReasonSubCategory = item;
    context.closingReasonDetails = null;
    context.closingReasonDetailsOther = null;
    this.selectedReason = item;
    this.bannerVisible = this.shouldShowBanner;
    context.bannerVisible = this.bannerVisible;
    this.args.context.bannerType = this.bannerType;

    if (this.selectedReason?.key !== 'account_fees') {
      context.bannerVisible = false;
      context.bannerType = null;
    }
  }

  @action selectSubReason(item: DataContext['closingReasonDetails']) {
    let { context } = this.args;
    this.showError = false;
    context.closingReasonDetails = item;
    context.closingReasonDetailsOther = null;
  }

  submitTask = dropTask(async e => {
    if (this.hasError) {
      this.showError = true;
      return;
    }

    let { closingReasonDetails, closingReasonDetailsOther } = this.args.context;
    this.segment.track('account_closing_additional_details_completed', {
      reason_details: closingReasonDetails?.key,
      reason_details_other: closingReasonDetailsOther,
    });

    // Track continue closure based on banner type
    if (this.bannerType === 'switch-to-annual') {
      this.segment.track('account-closing_change-recurrence_continue-closure', {
        // @ts-expect-error
        current_plan: this.currentPlan.localName,
      });
    } else if (this.bannerType === 'downgrade-plan') {
      this.segment.track('account-closing_switch-plan_continue-closure', {
        // @ts-expect-error
        current_plan: this.currentPlan.localName,
      });
    } else if (this.bannerType === 'annual-downgrade-plan') {
      this.segment.track('account-closing_annual-switch-plan_continue-closure', {
        // @ts-expect-error
        current_plan: this.currentPlan.localName,
      });
    }
    this.flow.dataContext.hearFromYou =
      !this.subscriptionManager?.currentPricePlan?.code?.startsWith(`solo_basic_`);

    try {
      if (variation('feature--boolean-expand-cs-callback-retention')) {
        await this.fetchCsCallbackTask.perform();
      }
    } finally {
      this.args.transitionToNext();
    }
  });

  @action
  transitionToSubscription() {
    let recurrence =
      this.bannerType === 'switch-to-annual'
        ? 'annual'
        : this.subscriptionManager.currentSubscription?.recurrence;
    let changeType = '';

    if (this.bannerType === 'switch-to-annual' && this.currentPlan) {
      changeType = 'change-recurrence';
      this.segment.track('account-closing_change-recurrence_clicked', {
        // @ts-expect-error
        current_plan: this.currentPlan.localName,
      });
    } else if (this.bannerType === 'downgrade-plan' && this.currentPlan && this.suggestedPlan) {
      changeType = 'switch-plan';
      this.segment.track('account-closing_switch-plan_clicked', {
        // @ts-expect-error
        current_plan: this.currentPlan.localName,
        // @ts-expect-error
        suggested_plan: this.suggestedPlan.localName,
      });
    } else if (
      this.bannerType === 'annual-downgrade-plan' &&
      this.currentPlan &&
      this.suggestedPlan
    ) {
      changeType = 'annual-switch-plan';
      this.segment.track('account-closing_annual-switch-plan_clicked', {
        // @ts-expect-error
        current_plan: this.currentPlan.localName,
        // @ts-expect-error
        suggested_plan: this.suggestedPlan.localName,
      });
    }

    this.args.transitionToFlow({
      flowName: 'subscription-change',
      stepId: 'plans',
      queryParams: {
        recurrence,
        changeType,
        // @ts-expect-error
        currentPlan: this.currentPlan.localName,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::Reason': typeof SubscriptionCloseReasonComponent;
  }
}
