/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import Component from '@glimmer/component';

import { Success } from 'qonto/react/components/flows/f24/success';

interface F24ManualDeclarationSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24ManualDeclarationSuccessComponent extends Component<F24ManualDeclarationSuccessSignature> {
  SuccessScreen = Success;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::F24::ManualDeclaration::Success': typeof F24ManualDeclarationSuccessComponent;
  }
}
