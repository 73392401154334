/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { chargbackClaimsNamespace } from 'qonto/constants/hosts';

interface FlowsChargebackClaimUploadSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsChargebackClaimUploadComponent extends Component<FlowsChargebackClaimUploadSignature> {
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  get uploadOptions() {
    return { url: `${chargbackClaimsNamespace}/claims/documents` };
  }

  get fileExtensions() {
    return '.png,.pdf,.jpeg,.jpg';
  }

  @action
  // @ts-expect-error
  addLocalFile(file) {
    // @ts-expect-error
    this.args.files.push(file);
  }

  @action
  // @ts-expect-error
  deleteLocalFile(file) {
    // @ts-expect-error
    let { files } = this.args;
    file.deleteRecord();
    let index = files.indexOf(file);
    files.splice(index, 1);
  }

  @action
  // @ts-expect-error
  handlePreviewFile(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      selectedFile: file,
      deletable: false,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::Upload::Index': typeof FlowsChargebackClaimUploadComponent;
  }
}
