export default {
  "item": "OZ",
  "qonto-pilot-nav-item": "OR",
  "wrapper": "OQ",
  "collapsed": "Oe",
  "label": "OB body-1",
  "icon-wrapper": "OE",
  "icon": "Od",
  "key": "OI",
  "keys": "Ot"
};
