/* import __COLOCATED_TEMPLATE__ from './upload-documents.hbs'; */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import TEMPLATE_VALUES from 'qonto/constants/env-helper';

export const DOCUMENT_TYPES = {
  passport: 'eu-passport',
  idCard: 'eu-id-card',
  residentPermit: 'eu-resident-permit',
};

interface FlowsKYCUploadDocumentsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsKYCUploadDocuments extends Component<FlowsKYCUploadDocumentsSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare modals: Services['modals'];

  @tracked error = null;
  @tracked showError = false;

  @service declare organizationManager: Services['organizationManager'];
  // @ts-expect-error
  @reads('organizationManager.organization.legalCountry') legalCountry;

  get title() {
    let { idCard, residentPermit, passport } = DOCUMENT_TYPES;

    // @ts-expect-error
    switch (this.args.context.documentType) {
      case idCard: {
        return this.intl.t('kyc.on-demand.document-upload.eu-id-card.title', {
          legalCountry: this.legalCountry,
        });
      }
      case passport: {
        return this.intl.t('kyc.on-demand.document-upload.eu-passport.title');
      }
      case residentPermit: {
        return this.intl.t('kyc.on-demand.document-upload.eu-resident-permit.title');
      }
    }
  }

  get documents() {
    // @ts-expect-error
    return this.args.context.documents;
  }

  get documentType() {
    // @ts-expect-error
    return this.args.context.documentType;
  }

  get uploaderLabel() {
    let { idCard, residentPermit, passport } = DOCUMENT_TYPES;

    switch (this.documentType) {
      case idCard: {
        return this.intl.t('kyc.on-demand.document-upload.eu-id-card.uploader-text');
      }
      case passport: {
        return this.intl.t('kyc.on-demand.document-upload.eu-passport.uploader-text');
      }
      case residentPermit: {
        return this.intl.t('kyc.on-demand.document-upload.eu-resident-permit.uploader-text');
      }
    }
  }

  get documentsDropzoneText() {
    return this.intl.t('labels.upload-message', {
      maxSize: formatFileSize(this.intl, TEMPLATE_VALUES.poaMaxSize),
    });
  }

  resetError() {
    this.error = null;
    this.showError = false;
  }

  handleAddDocument = dropTask(async file => {
    this.resetError();
    let fileEntity = {
      fileName: file.name,
      fileSize: file.size,
      fileContentType: file.type,
      fileUrl: file.url,
    };
    // @ts-expect-error
    await file.readAsDataURL().then(data => {
      set(fileEntity, 'fileUrl', data);
    });

    file.queue?.remove(file);
    this.documents.push(fileEntity);
  });

  @action
  // @ts-expect-error
  handleRemoveFileFromDocument(file) {
    let index = this.documents.indexOf(file);
    this.documents.splice(index, 1);
  }

  @action
  confirmTask() {
    if (this.documents.length === 0) {
      this.showError = true;
      // @ts-expect-error
      this.error = this.intl.t('validations.errors.upload_id_error');
      this.segment.track('submit_kyc_information_poi_upload_failed');
      return;
    }
    this.segment.track('submit_kyc_information_poi_upload_confirmed');
    // @ts-expect-error
    this.args.transitionToNext();
  }

  @action
  // @ts-expect-error
  handleOpenFilePreviewModal(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Kyc::UploadDocuments': typeof FlowsKYCUploadDocuments;
  }
}
