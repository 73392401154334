/* import __COLOCATED_TEMPLATE__ from './multi-requests-table.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { PlaceholderRow } from 'qonto/react/components/direct-debit-collections/multi-requests-table';

interface DirectDebitCollectionsMultiRequestsTableSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DirectDebitCollectionsMultiRequestsTableComponent extends Component<DirectDebitCollectionsMultiRequestsTableSignature> {
  @action
  onBankAccountInteractions() {}

  placeholderRow = PlaceholderRow;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::MultiRequestsTable': typeof DirectDebitCollectionsMultiRequestsTableComponent;
  }
}
