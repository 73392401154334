/* import __COLOCATED_TEMPLATE__ from './edit-client.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsDirectDebitCollectionsEditClientSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsDirectDebitCollectionsEditClientComponent extends Component<FlowsDirectDebitCollectionsEditClientSignature> {
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked isValidationEnabled = false;

  showMissingEmailDisclaimer = false;

  constructor(owner: unknown, args: FlowsDirectDebitCollectionsEditClientSignature['Args']) {
    super(owner, args);

    if (!this.client.email) {
      this.showMissingEmailDisclaimer = true;
    }
  }

  get client() {
    // @ts-expect-error
    return this.args.context.clientToEdit;
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (!this.client || this.client.isDestroyed || this.client.isDestroying) return;

    this.client.rollbackAttributes();
  }

  onSaveClientTask = dropTask(async editedClient => {
    this.isValidationEnabled = true;

    // we need to enable SDD-specific FE validation condition here
    // as "client-hub" model is also used in Invoices with different default validation
    editedClient.isSddClient = true;

    editedClient.validate({}, false);

    if (editedClient.validations.isValid) {
      try {
        await editedClient.save();
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));

        return;
      } finally {
        // prevent SDD-specific FE validation being applied in Invoices
        editedClient.isSddClient = false;
      }

      // @ts-expect-error
      this.args.context.directDebitSubscription.client = editedClient;

      // @ts-expect-error
      this.args.transitionToNext();

      this.segment.track('incoming-direct-debit-flow_client_selected', {
        client_address: Boolean(editedClient.billingAddress?.streetAddress),
        client_selection: 'edited',
      });
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::DirectDebitCollections::Client::EditClient': typeof FlowsDirectDebitCollectionsEditClientComponent;
  }
}
