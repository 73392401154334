/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { PAYMENT_STATUSES } from 'qonto/constants/card-acquirer';

interface CardAcquirerPaymentsTableFiltersSignature {
  // The arguments accepted by the component
  Args: {
    status: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardAcquirerPaymentsTableFilters extends Component<CardAcquirerPaymentsTableFiltersSignature> {
  @service declare intl: Services['intl'];

  get statusFilters() {
    let { COMPLETED, DECLINED, PROCESSING } = PAYMENT_STATUSES;

    return [
      { label: this.intl.t('tap-to-pay.payments.status.processing'), value: PROCESSING },
      { label: this.intl.t('tap-to-pay.payments.status.completed'), value: COMPLETED },
      { label: this.intl.t('tap-to-pay.payments.status.declined'), value: DECLINED },
    ];
  }

  get statusValue() {
    let { status } = this.args;

    return status && this.statusFilters.find(s => s.value === status);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardAcquirer::Payments::Table::Filters': typeof CardAcquirerPaymentsTableFilters;
  }
}
