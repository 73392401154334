/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { alias } from 'macro-decorators';

import { FlowCheckDetails } from 'qonto/react/components/flows/checks/details';
// @ts-expect-error
import ChecksDetailsValidations from 'qonto/validations/forms/checks-details';

interface ErrorItem {
  source?: {
    pointer?: string;
  };
  code?: string;
  detail?: string;
  title?: string;
}
interface ApiError extends Error {
  errors?: ErrorItem[];
  status?: number;
}

// @ts-expect-error
export default class FlowsCheckDetailsComponent extends Component.extend(ChecksDetailsValidations) {
  FlowCheckDetails = FlowCheckDetails;

  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare zendeskLocalization: Services['zendesk-localization'];

  // @ts-expect-error
  @alias('context.check') check;

  @tracked showAmountErrorMessage = false;
  @tracked showCheckErrorMessage = false;

  tagName = '';
  shouldHighlightAmount = false;
  shouldHighlightCheckId = false;

  get hasExceedingAmount() {
    return this.get('check.amount') > this.context.maxCheckDepositAmount;
  }

  /*
    This is a workaround to a known issue with ember-intl/cp-validations, where the addon tries to spread `htmlSafe: true` to
    intl.t(), which then treats it like a variable to replace in the translation key and then fails miserably.
    Link to issue :  https://github.com/ember-intl/cp-validations/issues/49
   */
  get invalidAmountErrorMessage() {
    if (this.hasExceedingAmount) {
      let options = {
        minimumFractionDigits: 0,
        currency: 'EUR',
        style: 'currency' as 'currency',
      };

      return this.intl.t('validations.errors.checks.amountCents', {
        checkAmount: this.intl.formatNumber(this.get('check.amount'), options),
        htmlSafe: true,
        faqUrl: this.zendeskLocalization.getLocalizedArticle(5259491),
      });
    }
    return undefined;
  }

  get amountErrorMessage(): string | undefined {
    return this.showAmountErrorMessage
      ? this.invalidAmountErrorMessage || this.validations.attrs.check.amount.message
      : undefined;
  }

  get checkErrorMessage() {
    return this.cmc7ErrorMessage || this.rlmcErrorMessage;
  }

  get cmc7ErrorMessage(): string | undefined {
    return this.showCheckErrorMessage ? this.validations.attrs.check.cmc7.message : undefined;
  }

  get rlmcErrorMessage(): string | undefined {
    return this.showCheckErrorMessage ? this.validations.attrs.check.rlmc.message : undefined;
  }

  confirmFlightTask = dropTask(async () => {
    let { check } = this;

    // Clear previous API action errors
    // eslint-disable-next-line ember/no-array-prototype-extensions
    check.errors.clear();

    let { validations } = await check.validate();

    this.showAmountErrorMessage = true;
    this.showCheckErrorMessage = true;

    if (!validations.get('isValid')) {
      return;
    }

    this.segment.track('checks_deposit_amount_and_numb_submitted');

    try {
      await check.confirm();
    } catch (error: unknown) {
      const apiError = error as ApiError;
      const errors = apiError.errors || [];

      let hasAmountCentsError = errors.some(({ source }) =>
        source?.pointer?.includes('amount_cents')
      );

      this.segment.track('checks_deposit_amount_validation_failure', {
        error: hasAmountCentsError ? 'amount' : 'other',
      });

      if (apiError.status !== 422) {
        this.sentry.captureException(error);
      }

      return;
    }

    this.segment.track('checks_deposit_amount_validation_success');
    await this.transitionToNext();
  });

  @action
  handleCmc7Update(value: string) {
    this.showCheckErrorMessage = false;
    this.set('check.cmc7', value);
  }

  @action
  handleRlmcUpdate(value: string) {
    this.showCheckErrorMessage = false;
    this.set('check.rlmc', value);
  }

  @action
  handleCheckIdFocus() {
    this.setProperties({
      shouldHighlightAmount: false,
      shouldHighlightCheckId: true,
    });
  }

  @action
  handleCheckIdBlur() {
    this.set('shouldHighlightCheckId', false);
  }

  @action
  onAmountChange(amountValue: string) {
    if (this.check.amount === amountValue) {
      return;
    }

    this.check.amount = amountValue || undefined;
    this.showAmountErrorMessage = false;

    this.segment.track('checks_deposit_amount_entered', {
      amount_entered: parseFloat(amountValue),
      exceeding_amount: this.hasExceedingAmount,
    });
  }

  @action
  onAmountFocusChange(isFocused: boolean) {
    this.set('shouldHighlightAmount', isFocused);
  }
}
