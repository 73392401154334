/* import __COLOCATED_TEMPLATE__ from './page-loading.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PageLoadingSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PageLoading = templateOnlyComponent<PageLoadingSignature>();

export default PageLoading;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Guest::PageLoading': typeof PageLoading;
  }
}
